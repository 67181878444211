<template>
  <div>
    <b-card no-body>
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col lg="8" md="8" sm="12">
          <b-row align-h="between" align-v="middle">
            <b-col lg="2" md="4" sm="12" class="mb-1 mt-0-5 ml-1 d-flex align-items-center">
              <div class="form-select">
                Show
                <b-form-select
                  value="10"
                  v-on:change="getSelectedPageSize"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
            <b-col v-if="!isAlertType" lg="2" md="4" sm="12" class="mb-1 mt-0-5 ml-3 d-flex align-items-center">
              <b-form-select
                v-model="selectedSecurityOption"
                :options="securityOptions"
                style="width: 160px;"
                size="sm"
              ></b-form-select>
            </b-col>
            <b-col v-if="!isAlertType" lg="2" md="4" sm="12" class="mb-1 mt-0-5 ml-3 pt-0-5 d-flex align-items-center">
              <div class="abnormal-select">
                <b-form-checkbox v-model="showAbnormalOnly" size="md">
                  Only Abnormal
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col v-if="!isAlertType" lg="2" md="4" sm="12" class="mb-1 mt-0-5 ml-3 d-flex align-items-center">
              <div class="abnormal-select">
                <ThreatFilter
                  v-on:threatFilterChange="threatFilterUpdate"
                  :threatOptions="threatOptions"
                >
                </ThreatFilter>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" md="4" sm="12" class="mb-1 d-flex align-items-center justify-content-end">
          <b-row>
            <SearchBar>
              <template #input>
                <b-form-input v-model="filter" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="serverFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(ip)="row">
                <IpWrapper
                  :ip="row.value"
                  :timeRange="timeRange"
                >
                </IpWrapper>
              </template>
              <template #head(transfersPerSecond)="data">
                <span v-b-tooltip.hover title="Transactions Per Second">
                  {{ data.label }}
                </span>
              </template>
              <template #head(bitsPerSecond)="data">
                <span v-b-tooltip.hover title="Bits Per Second">
                  {{ data.label }}
                </span>
              </template>
              <template #head(context)>
                <span v-b-tooltip.hover :title="columnNames.contextTooltip">
                  {{ columnNames.context }}
                </span>
              </template>
              <template #head(hostname)>
                <span v-b-tooltip.hover :title="columnNames.hostnameTooltip">
                  {{ columnNames.hostname }}
                </span>
              </template>
              <template #cell(asn)="row">
                <span v-b-tooltip.hover :title="row.value">
                  {{ collapseText(row.value, 48)}}
                </span>
              </template>
              <template #cell(behavior)="row">
                <SecurityBehavior :behavior="row.value"></SecurityBehavior>
              </template>
              <template #cell(context)="row">
                <span v-b-tooltip.hover :title="row.value">
                  {{ collapseText(contextFormatting(row.value), 64) }}
                </span>
              </template>
              <template #cell(transfersPerSecond)="row">
                {{ row.value.toFixed(3) }}
              </template>
              <template #cell(bitsPerSecond)="row">
                {{ readableBps(row.value) }}
              </template>
              <template #cell(hostname)="row">
                <div v-b-tooltip.hover :title="hostnameFormatter(row.item.dnsNamesForAddress, ',', ', ')">
                  {{ collapseText(hostnameFormatter(row.item.dnsNamesForAddress, ',', ', '), 32) }}
                </div>
              </template>
              <template #cell(threatFeed)="row">
                <ThreatFeedIcons
                  :threatFeedInfo="row.item.threatFeeds"
                  :threatFeedTypes="threatFeedTypes"
                  :uniqueId="row.item.ip">
                </ThreatFeedIcons>
              </template>
              <template
                #cell(pcapDownload)="row"
                v-if="showPcapDownloadOption"
              >
                <PcapDownload
                  :metaData="row.item"
                  :timeRange="timeRange"
                >
                </PcapDownload>
              </template>
              <template v-if="isAlertType" #cell(details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Message
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Message:</b></b-col>
                    <b-col>{{ row.item.message }}</b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <h5
                  class="mb-2 mx-1 text-right"
                >
                  <span
                    class="total-addresses-wrapper"
                    v-b-tooltip.hover
                    :title="getTotalAddressesTooltip"
                  >
                    Total Addresses: {{ addressesInfo.addressesCount }}
                  </span>
                </h5>
              </b-col>
              <b-col>
                <ArrowPagination
                  :nextPageAvailable="nextPageAvailable"
                  :totalItemsCount="addressesInfo.addressesCount"
                  :currentPageCount="this.topServers.length"
                  :paginationParams="paginationParams"
                  :sortOptions="paginationSortOptions"
                >
                </ArrowPagination>
              </b-col>
            </b-row>
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
          <SpinnerCmpt v-else class="mb-1"></SpinnerCmpt>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import Please from 'pleasejs';

import ArrowPagination from '@/xvisor/components/ArrowPagination.vue';
import IpWrapper from '@/xvisor/components/IpWrapper.vue';
import readableBps from '@/xvisor/utilities/readableBps';
import PcapDownload from '@/xvisor/components/PcapDownload.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SecurityBehavior from '@/xvisor/components/security/SecurityBehavior.vue';
import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import hostnameFormatter from '@/xvisor/utilities/hostnameFormatter';
import securityOptions from '@/xvisor/constants/securityOptions';
import threatRole from '@/xvisor/constants/threatRole';
import trafficType from '@/xvisor/constants/trafficType';
import ThreatFeedIcons from '@/xvisor/components/ThreatFeedIcons.vue';
import ThreatFilter from '@/xvisor/components/security/ThreatFilter.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    addressCountUrl: {
      type: String,
      required: true,
    },
    serviceType: {
      type: String,
      required: true,
    },
  },
  components: {
    ArrowPagination,
    IpWrapper,
    PcapDownload,
    SearchBar,
    SecurityBehavior,
    SpinnerCmpt,
    ThreatFeedIcons,
    ThreatFilter,
  },
  data() {
    return {
      currentPage: 1,
      deletePitUrl: '/security/pit/delete',
      paginationParams: {
        from: 0,
        size: 10,
        pitId: '',
        key: 'transfersPerSecond',
        desc: true,
      },
      nextPageAvailable: false,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      paginationSortOptions: [
        { label: 'Highest TPS', key: 'transfersPerSecond', desc: true },
        { label: 'Lowest TPS', key: 'transfersPerSecond', desc: false },
        { label: 'Highest BPS', key: 'bitsPerSecond', desc: true },
        { label: 'Lowest BPS', key: 'bitsPerSecond', desc: false },
      ],
      selectedSecurityOption: securityOptions.enterpriseServers.value,
      selectedThreatFilters: [],
      securityOptions: Object.values(securityOptions),
      showAbnormalOnly: false,
      threatOptions: [],
      threatFeedTypes: {
        all: {
          name: 'Select All',
          type: 'all',
        },
      },
      getNewPitUrl: '/security/pit/createid',
      getThreatFeedsUrl: '/security/threatfeeds.json',
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'ip', label: 'IP Address', sortable: false },
        { key: 'hostname', label: 'Hostname(s)', sortable: true },
        { key: 'asn', label: 'ASN', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'transfersPerSecond', label: 'TPS', sortable: true },
        { key: 'bitsPerSecond', label: 'BPS', sortable: true },
        { key: 'context', label: 'TLD', sortable: false },
        { key: 'threatFeed', label: 'Threat', sortable: false },
        { key: 'behavior', label: 'Behavior', sortable: true },
        { key: 'details', label: '', sortable: false },
        { key: 'pcapDownload', label: 'PCAP', sortable: false },
      ],
      topServers: [],
      addressesInfo: {},
      loaded: false,
      selected: [],
      searchText: '',
      trafficType,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.topServers && this.topServers.length > 0;
    },
    selectedRole() {
      return this.selectedSecurityOption.role;
    },
    selectedServer() {
      return this.selectedSecurityOption.server;
    },
    selectedAbnormal() {
      return this.showAbnormalOnly;
    },
    selectedFiltersChange() {
      return [this.selectedSecurityOption.role, this.selectedSecurityOption.server, this.showAbnormalOnly];
    },
    // A separate table field is required for the DNS component since DNS doesn't have BPS information.
    computedTableFields() {
      let filtered = this.tableFields;
      if (!this.showPcapDownloadOption) {
        filtered = filtered.filter((field) => field.key !== 'pcapDownload');
      }
      if (this.serviceType === trafficType.dns.value) {
        return filtered.filter((field) => field.key !== 'bitsPerSecond');
      }
      return filtered;
    },
    serverFilter() {
      this.getThreatTypeAndCount();
      return this.topServers.filter((server) => this.includeBySelectedThreatType(server));
    },
    isAlertType() {
      return this.serviceType === trafficType.alert.value;
    },
    columnNames() {
      if (this.serviceType === trafficType.dns.value) {
        return {
          context: 'TLD Queried',
          contextTooltip: 'Top level domain frequently queried',
          hostname: 'Hostname(s)',
          hostnameTooltip: 'Name(s) associated with address',
        };
      }
      if (this.serviceType === trafficType.alert.value) {
        return {
          context: 'Server',
          contextTooltip: 'Frequent Server of Client',
          hostname: 'Hostname(s)',
          hostnameTooltip: 'Name(s) associated with address',
        };
      }
      if (this.selectedRole === threatRole.server.value) {
        return {
          context: 'Client',
          contextTooltip: 'Frequent Client of Server',
          hostname: 'Hostname(s)',
          hostnameTooltip: 'Name(s) associated with address',
        };
      }
      if (this.selectedRole === threatRole.client.value) {
        return {
          context: 'Server',
          contextTooltip: 'Frequent Server of Client',
          hostname: 'Hostname(s)',
          hostnameTooltip: 'Name(s) associated with address',
        };
      }
      return {
        context: 'Client',
        contextTooltip: 'Frequent client of server',
        hostname: 'Hostname(s)',
        hostnameTooltip: 'Name(s) associated with address',
      };
    },
    getTotalAddressesTooltip() {
      const roleMap = {
        server: 'Server',
        client: 'Client',
      };
      const serverMap = {
        enterprise: 'Enterprise',
        internet: 'Internet',
        all: 'Enterprise and Internet',
      };
      const selectedRole = roleMap[this.selectedSecurityOption.role];
      const selectedServer = serverMap[this.selectedSecurityOption.server];
      return `Count of All Unique ${selectedServer} ${selectedRole} Addresses`;
    },
    getAddressCountParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          role: this.selectedRole,
          server: this.selectedServer,
          abnormal: this.showAbnormalOnly,
          searchtext: this.filter,
        },
      };
    },
    getThreatAnalyticsParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          role: this.selectedRole,
          server: this.selectedServer,
          abnormal: this.showAbnormalOnly,
          searchtext: this.filter,
          from: this.paginationParams.from, // If less than or equal to 0, disable prev page arrow.
          size: this.paginationParams.size + 1, // Determine whether to disable next page arrow.
          pitId: this.paginationParams.pitId,
          key: this.paginationParams.key,
          desc: this.paginationParams.desc,
        },
      };
    },
    showPcapDownloadOption() {
      const hourToMs = 3600000;
      const show = (this.timeRange.end.getTime() - this.timeRange.start.getTime()) <= (6 * hourToMs);
      return show;
    },
  },
  mounted() {
    this.getPitId();
  },
  methods: {
    getPitId() {
      this.$http
        .get(this.getNewPitUrl)
        .then((response) => {
          const { id } = response.data;
          this.paginationParams.pitId = id;
          this.httpGet();
        });
    },
    deletePitId() {
      if (this.paginationParams.pitId !== '') {
        this.$http.delete(this.deletePitUrl, {
          params: {
            id: this.paginationParams.pitId,
          },
        });
      }
    },
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.url, this.getThreatAnalyticsParams),
          this.$http.get(this.addressCountUrl, this.getAddressCountParams),
          this.$http.get(this.getThreatFeedsUrl),
        ])
        .then(axios.spread((topServRes, addressCountRes, threatFeedsRes) => {
          if (topServRes.data.length > this.paginationParams.size) {
            this.nextPageAvailable = true;
            this.topServers = topServRes.data.slice(0, this.paginationParams.size);
          } else {
            this.nextPageAvailable = false;
            this.topServers = topServRes.data;
          }
          this.addressesInfo = addressCountRes.data;
          // Store possible threat types in a key-value object.
          threatFeedsRes.data.forEach((val) => { this.threatFeedTypes[val.type] = val; });
          this.getThreatTypeAndCount();
          this.getThreatIconColors();
        }))
        .finally(() => { this.loaded = true; });
    },
    getSelectedPageSize(selected) {
      this.paginationParams.from = 0;
      this.paginationParams.size = selected;
    },
    getThreatTypeAndCount() {
      // Determine threat type and their counts after filters are applied.
      const feedTypes = Object.values(this.threatFeedTypes);

      this.threatOptions = feedTypes.map((option) => {
        let count = 0;
        this.topServers.forEach((server) => {
          if (server.threatFeeds && server.threatFeeds.length) {
            if (option.type === 'all') {
              count += 1;
            } else {
              server.threatFeeds.forEach((feed) => {
                if (feed === option.type) {
                  count += 1;
                }
              });
            }
          }
        });
        return { threatName: option.name, threatType: option.type, threatCount: count };
      });
    },
    contextFormatting(context) {
      if (this.serviceType === trafficType.dns.value || this.serviceType === trafficType.other.value) {
        return context;
      }
      return this.removePortFromString(context);
    },
    resetPaginationParams() {
      this.paginationParams = {
        ...this.paginationParams,
        from: 0,
      };
    },
    removePortFromString(str) {
      const index1 = str.indexOf('(');
      if (index1 < 0) {
        return str;
      }
      return str.substring(0, index1 - 1);
    },
    includeBySelectedThreatType(server) {
      if ((!this.selectedThreatFilters.length)
        || (this.selectedThreatFilters[0] === 'all' && server.threatFeeds && server.threatFeeds.length > 0)
        || (server.threatFeeds && this.threatTypeExists(server.threatFeeds))) {
        return true;
      }
      return false;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    threatTypeExists(threatFeeds) {
      let threatMatches = true;
      this.selectedThreatFilters.forEach((threat) => {
        threatMatches = threatFeeds.includes(threat);
      });
      return threatMatches;
    },
    threatFilterUpdate(threatFilters) {
      this.selectedThreatFilters = threatFilters;
      this.filteredLength = this.serverFilter.length;
      this.currentPage = 1;
    },
    getThreatIconColors() {
      const feedTypesLength = Object.keys(this.threatFeedTypes).length;
      const hsvs = [
        {
          h: 24,
          s: 1,
          v: 0.5,
        },
        {
          h: 220,
          s: 1,
          v: 0.7,
        },
        {
          h: 264,
          s: 1,
          v: 0.5,
        },
      ];
      const allColors = hsvs.map((hsv) => {
        const scheme = Please.make_scheme(
          hsv,
          {
            scheme_type: 'analogous',
            format: 'hex',
          },
        );
        return scheme;
      }).flat();
      const randomColors = allColors.sort(() => 0.5 - Math.random()).slice(0, feedTypesLength);
      Object.keys(this.threatFeedTypes).forEach((key, index) => {
        this.threatFeedTypes[key].style = randomColors[index];
      });
    },
    readableBps,
    collapseText,
    hostnameFormatter,
  },
  watch: {
    paginationParams: {
      handler() {
        if (this.paginationParams.pitId !== '') {
          this.loaded = false;
          this.$http.get(this.url, this.getThreatAnalyticsParams)
            .then((topServRes) => {
              if (topServRes.data.length > this.paginationParams.size) {
                this.nextPageAvailable = true;
                this.topServers = topServRes.data.slice(0, this.paginationParams.size);
              } else {
                this.nextPageAvailable = false;
                this.topServers = topServRes.data;
              }
              this.getThreatTypeAndCount();
            }).catch(() => {
              this.resetPaginationParams();
              this.getPitId();
            }).finally(() => { this.loaded = true; });
        }
      },
      deep: true,
    },
    selected() {
      SecurityEventBus.$emit('update-selected-server', this.selected[0]);
    },
    selectedRole() {
      SecurityEventBus.$emit('update-selected-role', this.selectedRole);
      SecurityEventBus.$emit('update-selected-server', null);
    },
    selectedServer() {
      this.filteredLength = this.serverFilter.length;
      this.currentPage = 1;
    },
    selectedFiltersChange() {
      this.resetPaginationParams();
      this.httpGet();
    },
    url() {
      this.httpGet();
    },
    topServers() {
      this.filteredLength = this.serverFilter.length;
    },
    filter() {
      this.resetPaginationParams();
      this.httpGet();
    },
    showAbnormalOnly() {
      this.filteredLength = this.serverFilter.length;
      this.currentPage = 1;
    },
    timeRange() {
      this.httpGet();
    },
  },
};
</script>

<style scoped>
.form-select{
  min-width: 160px;
  width: 18%;
}
.abnormal-select{
  min-width: 160px;
  width: 18%;
}
</style>

<template>
  <div>
    <b-button
      size="sm"
      variant="primary"
      id="show-btn"
      @click="showModal"
    >
      Download xTend
    </b-button>

    <b-modal
      ref="link-modal"
      title="Select xTend Installer"
      hide-footer
    >
    <div class="text-center">
      <b-list-group>
        <a :href="handleDownload('windows')" download>
          <b-list-group-item>
            Windows
          </b-list-group-item>
        </a>
        <a :href="handleDownload('linux')" download>
          <b-list-group-item>
            Linux
          </b-list-group-item>
        </a>
      </b-list-group>
    </div>
    <b-button class="mt-3" variant="primary" block @click="hideModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    showModal() {
      this.$refs['link-modal'].show();
    },
    handleDownload(platform) {
      let link;
      if (platform === 'windows') {
        link = 'https://xvisor.blob.core.windows.net/public/xtend/enterprise/windows/xtend_amd64.msi';
      } else if (platform === 'linux') {
        link = 'https://xvisor.blob.core.windows.net/public/xtend/enterprise/linux/deb/installers/xtend_amd64.tar.gz';
      }
      return link;
    },
    hideModal() {
      this.$refs['link-modal'].hide();
    },
  },
};
</script>

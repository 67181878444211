<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="apache-echarts-default-graph"
  ></e-chart>
  <div v-else-if="loaded" class="ml-1">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import mbpsConverter from '@/xvisor/utilities/mbpsConverter';
import convertMbpsByMaxValUnit from '@/xvisor/utilities/convertMbpsByMaxValUnit';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    chartName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    allTimes() {
      return this.series.map((item) => {
        const trafficData = item.traffics
          ? item.traffics.map((traffic) => traffic.time)
          : [];
        return trafficData;
      }).flat();
    },
    timeSet() {
      return new Set(this.allTimes);
    },
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      return this.series.map((item) => {
        const trafficData = item.traffics
          ? item.traffics.map((point) => [
            moment(point.time).valueOf(),
            point.value,
          ])
          : [];
        return {
          name: item.name,
          type: 'line',
          symbol: 'circle',
          symbolSize: 5,
          data: trafficData,
        };
      });
    },
    yAxisLabelUnits() {
      let max = 0;
      this.series.forEach((element) => {
        element.traffics.forEach((traffic) => {
          if (traffic.value > max) {
            max = traffic.value;
          }
        });
      });
      return mbpsConverter(max).unit;
    },
    chartOptions() {
      return {
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.blue4,
          colorPaletteShade.indigo4,
          colorPaletteShade.purple4,
          colorPaletteShade.green4,
        ],
        grid: [
          {
            left: 50,
            right: 30,
            top: 30,
            bottom: 30,
          },
        ],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${this.tooltipFormatter(info)}
            </div>
          `,
        },
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            show: false,
          },
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: this.yAxisLabelUnits,
            nameLocation: 'center',
            nameGap: 27,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => this.yAxisLabelFormatter(value),
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity6(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    yAxisLabelFormatter(value) {
      let MaxVal = 0;
      this.series.forEach((item) => {
        item.traffics.forEach((element) => {
          if (element.value > MaxVal) {
            MaxVal = element.value;
          }
        });
      });
      const maxValUnit = mbpsConverter(MaxVal).unit;
      return `${convertMbpsByMaxValUnit(value, maxValUnit)}`;
    },
    tooltipFormatter(info) {
      let tooltipMessage = '';
      info.forEach((element) => {
        const { rate, unit } = mbpsConverter(element.value[1]);
        tooltipMessage += `
          ${element.marker} ${element.seriesName}: ${rate.toFixed(2)} ${unit}
          <br>
        `;
      });
      return tooltipMessage;
    },
  },
};
</script>

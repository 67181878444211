export default function formatNodeDelay(delays) {
  let delayFormat = '';
  const idDelaysMap = new Map();
  delays.forEach((delay) => {
    const id = `${delay.xomeId},${delay.probeId}`;
    if (idDelaysMap.has(id)) {
      idDelaysMap.get(id).push(delay.medianDelay);
    } else {
      idDelaysMap.set(id, [delay.medianDelay]);
    }
  });
  idDelaysMap.forEach((values, key) => {
    const xomeId = key.split(',')[0];
    const probeId = key.split(',')[1];
    let max = values[0];
    let min = values[0];
    let sum = 0;
    let avg = values[0];
    values.forEach((value) => {
      if (value > max) {
        max = value;
      }
      if (value < min) {
        min = value;
      }
      sum += value;
    });
    avg = sum / values.length;
    delayFormat = delayFormat.concat(' ', '<br>');
    delayFormat = delayFormat.concat(' ', '&emsp;&emsp;');
    delayFormat = delayFormat.concat('Max: ', Number(max).toFixed(1));
    delayFormat = delayFormat.concat(' ', 'ms');
    delayFormat = delayFormat.concat('', ', ');
    delayFormat = delayFormat.concat('Avg: ', Number(avg).toFixed(1));
    delayFormat = delayFormat.concat(' ', 'ms');
    delayFormat = delayFormat.concat('', ', ');
    delayFormat = delayFormat.concat('Min: ', Number(min).toFixed(1));
    delayFormat = delayFormat.concat(' ', 'ms');
    delayFormat = delayFormat.concat(' ', '(');
    delayFormat = delayFormat.concat('XomeId: ', xomeId);
    delayFormat = delayFormat.concat('', ', ');
    delayFormat = delayFormat.concat('ProbeId: ', probeId);
    delayFormat = delayFormat.concat('', ')');
  });
  return delayFormat;
}

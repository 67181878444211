<template>
  <div>
    <b-card no-body class="mx-n3">
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col lg="7" md="12" sm="12">
          <b-row align-h="between">
            <b-col lg="4" md="4" sm="12" class="mb-1">
              <div class="form-select">
                Show
                <b-form-select
                  v-model="perPage"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" md="12" sm="12" class="mb-1">
          <b-row align-h="end">
            <SearchBar :hasErrorMessage="true">
              <template #input>
                <b-form-input v-model="filter" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              id="xtend-alerts-table"
              class="table-with-actions-min-height px-2"
              responsive
              outlined
              :items="alertsFilter"
              :fields="computedTableFields"
              :tbody-tr-class="rowClass"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(severity)="row">
                <SeverityBehavior :severity="row.value"></SeverityBehavior>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
    <AlertDetails :url="url" :timeRange="timeRange"></AlertDetails>
  </div>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import hostnameFormatter from '@/xvisor/utilities/hostnameFormatter';
import SeverityBehavior from '@/xvisor/components/xtend/tabs/SeverityBehavior.vue';
import XomesEventBus from '@/xvisor/bus/XomesEventBus';
import AlertDetails from '@/xvisor/components/xtend/tabs/AlertsDetails.vue';
import granularity from '@/xvisor/utilities/granularity';
import formatNumberWithCommas from '@/xvisor/utilities/addCommaToNumber';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchBar,
    SeverityBehavior,
    AlertDetails,
  },
  data() {
    return {
      alerts: [],
      loaded: false,
      sortBy: 'isHighlighted',
      sortDesc: true,
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'info', label: 'Info', sortable: true },
        { key: 'count', label: 'Count', sortable: true },
        { key: 'severity', label: 'Severity', sortable: true },
      ],
      selected: [],
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.alerts && this.alerts.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.alerts.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    alertsFilter() {
      const retVal = this.alerts;
      return retVal;
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
    shortTimeRange() {
      this.httpGet();
    },
    selected() {
      const selectedAlert = this.selected[0];
      XomesEventBus.$emit('update-selected-alert', selectedAlert);
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      const summaryEndpoint = '/individualxtendalertsummary.json';
      const fullUrl = this.url + summaryEndpoint;

      this.loaded = false;
      this.$http
        .get(fullUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            selectedStart: this.shortTimeRange.start.toISOString(),
            selectedEnd: this.shortTimeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.alerts = response.data; })
        .finally(() => { this.loaded = true; });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return '';
      if (item.isHighlighted === true) return 'table-info';
      return '';
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    collapseText,
    hostnameFormatter,
    formatNumberWithCommas,
  },
};
</script>

<style scoped>
  .form-select{
    min-width: 160px;
    width: 20%;
  }
</style>

<template>
  <div v-if="timestampsInfo && timestampsInfo.length <= timesDisplayLimit" style="display: flex;">
    <div v-for="(val, index) in timestampsInfo" v-bind:key="index" style="margin-right: 10px;">
      <span>{{ formatDate(val) }}</span>
      <span v-if="index+1 < timestampsInfo.length">, </span>
    </div>
  </div>
  <div v-else>
    <span
      data-html="true"
      v-b-tooltip.hover.html="timesTooltipContent"
    >
      <b-badge pill class="text-white badge-primary" :id="getPopoverId(uniqueId)">
        ...
      </b-badge>
    </span>
  </div>
</template>

<script>
import moment from 'moment';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  props: {
    timestampsInfo: {
      type: Array,
      required: true,
    },
    uniqueId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    timesTooltipContent() {
      const header = `${this.timestampsInfo.length} Timestamps<hr>`;
      const formattedTimestamps = this.timestampsInfo.map((timestamp) => this.formatDate(timestamp)).join('<br>');
      return `${header}${formattedTimestamps}`;
    },
  },
  data() {
    return {
      timesDisplayLimit: 2,
    };
  },
  methods: {
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateYearTime);
    },
    getPopoverId() {
      return `times-popover-${String(this.uniqueId)}`;
    },
  },
};
</script>

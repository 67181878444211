<template>
  <div>
    <b-card class="mx-n3">
      <b-row>
        <FlowEventHeatMap :url="urls.eventTsUrl" :timeRange="timeRange" class="mb-n4"></FlowEventHeatMap>
      </b-row>
      <b-row align-v="center" align-h="between">
        <b-col>
          <h5>Pathroutes</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <PathroutesWrapper
            :urls="urls.pathroutes"
            :shortTimeRange="shortTimeRange"
            :timeRange="timeRange"
          ></PathroutesWrapper>
        </b-col>
      </b-row>
    </b-card>
    <ProbeStatusTable
      :url="urls.probesTableUrl"
      :timeRange="timeRange"
      class="mt-n1"
      margin="mx-n3 mt-n2"
      :alertId="alertId"
    ></ProbeStatusTable>
    <ProbeStatusDetails
      :timeRange="timeRange"
      :alertId="alertId"
      margin="mx-n3 mt-n2"
    ></ProbeStatusDetails>
  </div>
</template>

<script>
import FlowEventHeatMap from '@/xvisor/components/app/flowsTab/FlowEventHeatMap.vue';
import ProbeStatusDetails from '@/xvisor/components/app/networkTab/ProbeStatusDetails.vue';
import ProbeStatusTable from '@/xvisor/components/app/networkTab/ProbeStatusTable.vue';
import PathroutesWrapper from '@/xvisor/components/app/networkTab/pathroutes/PathroutesWrapper.vue';
import issueLayers from '@/xvisor/constants/issueLayers';

export default {
  props: {
    urls: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
    alertId: {
      type: String,
    },
  },
  components: {
    FlowEventHeatMap,
    ProbeStatusDetails,
    ProbeStatusTable,
    PathroutesWrapper,
  },
  data() {
    return {
      issueLayers,
    };
  },
};
</script>

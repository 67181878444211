export default {
  data() {
    const { infraId } = this.$route.params;

    return {
      infraId,
    };
  },
  computed: {
    infraName() {
      return this.infraId;
    },
  },
};

/** Convert milliseconds to human readable format.
 *
 * @param millis The number of milliseconds to convert.
 * @param decimal The number of decimal places in the result.
 * @return Readable format of milliseconds.
 * Ex.
 *  readableMillis(14205966, 1) => 236.8min
 *  readableMillis(1000, 1) => 1s
 */
export default function readableMillis(millis, decimal = 1) {
  if (millis < 0) throw new RangeError('Milliseconds must be non-negative');
  let value;
  let unit;
  if (millis < 1000) {
    value = millis;
    unit = 'ms';
  } else if (millis < 1000 * 60) {
    value = millis / 1000;
    unit = 's';
  } else {
    value = millis / (1000 * 60);
    unit = 'min';
  }
  return `${value.toFixed(decimal)}${unit}`;
}

<template>
  <div>
    <b-button
      @click="savePanel"
      variant="flat"
      class="bookmark"
      size="sm"
    >
      <b-icon v-if="isSaved"
        icon="bookmark-fill"
        variant="primary"
      />
      <b-icon v-else icon="bookmark" />
    </b-button>
    <b-button
      @click="deletePanel"
      variant="flat"
      class="cancel-button"
      size="sm"
    >
      <b-icon icon="trash" />
    </b-button>
  </div>
</template>

<script>

export default {
  props: {
    isSaved: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    savePanel() {
      this.$emit('save-panel');
    },
    deletePanel() {
      this.$emit('delete-panel');
    },
  },
};
</script>

<style scoped>
.bookmark {
  position: absolute;
  right: 40px;
  top: 5px;
}
.cancel-button {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>

<template>
  <div>
    <b-card class="mx-n2 mt-n1">
      <b-row class="mt-0-5" align-v="center" align-h="between">
        <b-col cols="12" md="7">
          <h6>{{ appInfo.name }}</h6>
        </b-col>
        <b-col cols="12" md="5">
          <TimeRangeSelector
            :minDate="minDate"
            :maxDate="maxDate"
            :initRange="timeRange"
            @change-time-range="updateStateTimeRange"
          ></TimeRangeSelector>
        </b-col>
      </b-row>
      <b-row class="my-0-5">
        <b-col>
          <TimeBarAlertsTroubleshooting
            :alertCountUrl="urls.alertCountUrl"
            :alertsUrl="urls.alertsUrl"
            :timeRange="timeRange"
            :condensedBar="true"
            @update-short-time-range="(newRange) => { this.shortTimeRange = newRange; }"
            @update-focused-alert="(alert) => { this.focusedAlert = alert; }"
            class="m-n2"
          ></TimeBarAlertsTroubleshooting>
        </b-col>
      </b-row>
    </b-card>
    <AppTabs
      v-if="hasAppInfo"
      :userIp="userIp"
      :appInfo="appInfo"
      class="mt-n3"
      :timeRange="timeRange"
      :shortTimeRange="shortTimeRange"
      :alertId="focusedAlert ? focusedAlert.id : undefined"
      :urls="urls"
    ></AppTabs>
  </div>
</template>

<script>
import AppTabs from '@/xvisor/components/app/AppTabs.vue';
import TimeBarAlertsTroubleshooting from '@/xvisor/components/issue/TimeBarAlertsTroubleshooting.vue';
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';

export default {
  mixins: [timeRangeMixin],
  components: {
    AppTabs,
    TimeBarAlertsTroubleshooting,
  },
  data() {
    return {
      appInfo: {
        name: 'Application',
        isEndpointBased: false,
      },
      hasAppInfo: false,
      focusedAlert: undefined,
      userIp: this.$route.params.userIp,
    };
  },
  computed: {
    urls() {
      const appId = parseInt(this.$route.params.id, 10);
      const urlPrefix = `/apps/${appId}`;
      return {
        monAppId: appId,
        alertCountUrl: `${urlPrefix}/alerts/countts.json`,
        alertsUrl: `${urlPrefix}/alerts.json`,
        appInfoUrl: `${urlPrefix}/info.json`,
        usersCountUrl: `${urlPrefix}/appusers/top/count.json`,
        usersListUrl: `${urlPrefix}/appusers/top/list.json`,
        networkTabUrls: {
          probesTableUrl: `${urlPrefix}/targets/list.json`,
          eventTsUrl: `${urlPrefix}/events/network/countts.json`,
          pathroutes: {
            layoutUrl: `${urlPrefix}/pathroutes/layout.json`,
            sourcenamesUrl: `${urlPrefix}/pathroutes/sourcenames.json`,
            destnamesUrl: `${urlPrefix}/pathroutes/destnames.json`,
            metricsUrl: `${urlPrefix}/pathroutes/links/metrics.json`,
            linkBaselineUrl: `${urlPrefix}/pathroutes/links/baselines.json`,
            blocksUrl: `${urlPrefix}/pathroutes/blocks.json`,
            geolocationsUrl: `${urlPrefix}/pathroutes/geolocation.json`,
            delayAndLossUrl: `${urlPrefix}/pathroutes/nodes/metrics.json`,
            nodeBaselineUrl: `${urlPrefix}/pathroutes/nodes/baselines.json`,
            asnUrl: `${urlPrefix}/pathroutes/asn.json`,
            eventsUrl: `${urlPrefix}/pathroutes/events.json`,
            nodeHopRtdUrl: `${urlPrefix}/pathroutes/nodes/hoprtdts.json`,
          },
        },
        depTabUrls: {
          alertDistributionUrl: `${urlPrefix}/alerts/counttotals.json`,
          errorRatioUrl: `${urlPrefix}/summary/errorratio.json`,
          healthRatioUrl: `${urlPrefix}/summary/healthratio.json`,
          responseMillisUrl: `${urlPrefix}/summary/responsemillis.json`,
          throughputUrl: `${urlPrefix}/summary/throughput.json`,
          componentUrl: `${urlPrefix}/cmpts/overview.json`,
        },
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.focusedAlert = undefined;
      this.httpGet();
    },
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.urls.appInfoUrl)
        .then((response) => {
          this.appInfo = response.data;
          this.hasAppInfo = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0.5rem 1.25rem;
}
</style>

<template>
  <InfraBase #default="{ timeRange, urls }">
    <b-card class="mx-n2">
      <b-row>
        <b-col>
          <h6>Top Blocked Ips</h6>
          <InfraFirewallTopIp
            :timeRange="timeRange"
            :url="urls.topBlockedIpUrl">
          </InfraFirewallTopIp>
        </b-col>
        <b-col>
          <h6>Top Allowed Ips</h6>
          <InfraFirewallTopIp
            :timeRange="timeRange"
            :url="urls.topAllowedIpUrl">
          </InfraFirewallTopIp>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mx-n2">
      <h6>Firewall Accepted vs. Denied Counts</h6>
      <TsStackedBar
        :timeRange="timeRange"
        :url="urls.acceptDenyTsUrl"
      >
      </TsStackedBar>
    </b-card>
  </InfraBase>
</template>

<script>
import InfraBase from '@/xvisor/views/InfraBase.vue';
import TsStackedBar from '@/xvisor/components/TsStackedBarChart.vue';
import InfraFirewallTopIp from '@/xvisor/views/InfraFirewallTopIp.vue';

export default {
  components: {
    InfraBase,
    TsStackedBar,
    InfraFirewallTopIp,
  },
};
</script>

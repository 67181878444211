<template>
  <div>
    <!-- Download Section -->
    <span id="credentials" class="anchor"></span>
    <h1>Download</h1>
    <p>
      Download xTend and Get Xtend Credential:
      <DownloadXtend></DownloadXtend>
    </p>

    <!-- Credentials Section -->
    <span id="credentials" class="anchor"></span>
    <h1>Credentials</h1>

    <h2>1. Log in to your xVisor Dashboard</h2>
    <p>Begin by accessing your xVisor Dashboard with your credentials.</p>

    <h2>2. Navigate to the Settings Page</h2>
    <p>From the dashboard, locate and click on the Settings option.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-settings.png"
        class="responsive-img-sm"
        alt="Settings Page"
      />
    </p>

    <h2>3. Select the “API Keys” Tab</h2>
    <p>On the Settings page, find the sidebar navigation and click on the API Keys.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-apikey.png"
        class="responsive-img-sm"
        alt="API Keys Tab"
      />
    </p>

    <h2>4. Go to the “xTend Registration” Tab</h2>
    <p>While on the API Keys page, look for another tab labeled xTend Registration and click on it.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-registration.png"
        class="responsive-img-sm"
        alt="xTend Registration Tab"
      />
    </p>

    <h2>5. Generate Your Registration Key</h2>
    <p>
      Finally, click on the Registration Key button. This will generate the credential required to register
      your xTend device. Note that this credential is valid for one week and can be used on multiple xTend devices.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-apikey-copy.png"
        class="responsive-img-sm"
        alt="Generate Registration Key"
      />
    </p>

    <!-- PowerShell Section -->
    <span id="powerShell" class="anchor"></span>
    <h1>PowerShell(Run As Administrator)</h1>

    <h2>1. Download the Installer (if not yet downloaded)</h2>
    <p>
      If the installer file is not already on your computer, you can download it from the web. Navigate to the desired
      folder where you want to download the installer, then run the following command in PowerShell:
    </p>
    <pre>
      <code>
        Invoke-WebRequest –Uri https://xvisor.blob.core.windows.net/public/xtend/enterprise/windows/xtend_amd64.msi
        -OutFile xtend.msi
      </code>
    </pre>

    <h2>2. Install xTend</h2>
    <p>
      Once the installer file is downloaded, navigate to the same folder where the installer is located,
      and replace <code>&lt;credential&gt;</code> with your actual credential then run the following command in
      PowerShell to install xTend:
    </p>
    <pre><code>.\xtend_amd64.msi CREDENTIAL=&lt;credential&gt; /qn</code></pre>
  </div>
</template>

<script>
import DownloadXtend from '@/xvisor/components/xtend/DownloadXtend.vue';

export default {
  components: {
    DownloadXtend,
  },
};
</script>

<style scoped>
.anchor {
  top: -75px;
  position: relative;
  z-index: 5;
}
</style>

<template>
  <div>
    <b-card id="infraInfo" class="mx-n2">
      <b-row align-v="center">
        <b-col cols="6">
          <InfraInfo :url="urls.infoUrl" :infraInfo="infrastructureInfo" :timeRange="timeRange"></InfraInfo>
        </b-col>
        <b-col cols="6">
          <TimeRangeSelector
            :minDate="minDate"
            :maxDate="maxDate"
            :initRange="timeRange"
            @change-time-range="updateStateTimeRange"
          ></TimeRangeSelector>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <TimeBarAlertsTroubleshooting v-if="loaded"
            :alertCountUrl="urls.alertCountUrl"
            :timeRange="timeRange"
            :isInfraPage="true"
            :condensedBar="true"
            @update-short-time-range="(newRange) => { this.shortTimeRange = newRange; }"
            @update-focused-alert="(alert) => { this.focusedAlert = alert; }"
            class="m-n2"
          ></TimeBarAlertsTroubleshooting>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body class="mx-n2 infraContent">
      <slot :timeRange="timeRange" :urls="urls"></slot>
    </b-card>
  </div>
</template>

<script>
import InfraInfo from '@/xvisor/components/infra/InfraInfo.vue';
import TimeBarAlertsTroubleshooting from '@/xvisor/components/issue/TimeBarAlertsTroubleshooting.vue';
import infraIpsMixin from '@/xvisor/components/infra/infraIpsMixin';
import infraIdMixin from '@/xvisor/components/infra/infraIdMixin';
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';

export default {
  mixins: [infraIpsMixin, infraIdMixin, timeRangeMixin],
  components: {
    InfraInfo,
    TimeBarAlertsTroubleshooting,
  },
  computed: {
    urls() {
      // TODO: Change the first part of URL from `/nes` to `/infra`.
      // And change the ID from integer ID to dynamic string ID depending on infra type.
      const infrasUrlPrefix = `/infra/routers/${this.infraIp}`;
      const infraFirewallPrefix = `/infra/firewall/${this.infraId}`;

      return {
        infoUrl: `${infrasUrlPrefix}/info.json`,
        alertCountUrl: `/infra/${this.infraId}/alerts/countts.json`,

        // Traffic tab
        appTraffTotalsUrl: `${infrasUrlPrefix}/apps/trafftotals.json`,
        appFlowGraphUrl: `${infrasUrlPrefix}/prefixapps/flowgraph.json`,
        totalTraffUrl: `${infrasUrlPrefix}/traffts.json`,
        traffByTopAppsUrl: `${infrasUrlPrefix}/topapps/traffts.json`,
        traffByTopProtosUrl: `${infrasUrlPrefix}/topprotos/traffts.json`,

        // Firewall Information
        acceptDenyTsUrl: `${infraFirewallPrefix}/acceptdenyts.json`,
        topBlockedIpUrl: `${infraFirewallPrefix}/topblockedips.json`,
        topAllowedIpUrl: `${infraFirewallPrefix}/topallowedips.json`,
      };
    },
    infrastructureInfo() {
      return { ip: this.infraIp };
    },
  },
  mounted() {
    this.httpGet();
  },
  data() {
    return {
      loaded: false,
      infraElementId: null,
    };
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(`/infra/routers/${this.infraIp}/id.json`)
        .then((response) => { this.infraElementId = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0.5rem 1.25rem;
}

// For medium to mobile screens.
#infraInfo {
  position: fixed;
  top: 70px;
  margin: 1.3rem 0.5rem 0;
  width: calc(100% - 1rem);
  border-radius: 0.428rem !important;
  z-index: 12;
  margin-top: -0.9rem;
}

// The infra content needs to be placed below the sticky info.
.infraContent {
  margin-top: 6.1rem;
}

// For large screens.
@media (min-width: 1200px) {
  #infraInfo {
    width: calc(100% - 4.6rem);
  }
}

.menu-expanded #infraInfo {
  width: calc(100% - 4.6rem - 210px);
}
</style>

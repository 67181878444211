<template>
  <div>
    <h2 class="mb-2">{{ $t('General Settings') }}</h2>
    <b-tabs
      vertical
      lazy
      pills
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab>
        <template #title>
          <b-icon icon="person" class="mr-1" />
          <span class="font-weight-bold">{{ $t(tabs.users.name) }}</span>
        </template>
        <UsersTable :url="usersUrl"></UsersTable>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon icon="files" class="mr-1" />
          <span class="font-weight-bold">{{ $t(tabs.xomes.name) }}</span>
        </template>
        <XomeSettings></XomeSettings>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon icon="laptop" class="mr-1" />
          <span class="font-weight-bold">{{ $t(tabs.xtends.name) }}</span>
        </template>
        <XtendSettings></XtendSettings>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon icon="building" class="mr-1" />
          <span class="font-weight-bold">{{ $t(tabs.offices.name) }}</span>
        </template>
        <ZoneSettings :url="officesUrl"></ZoneSettings>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon icon="key" class="mr-1" />
          <span class="font-weight-bold">{{ $t(tabs.apiKeys.name) }}</span>
        </template>
        <b-tabs>
          <b-tab title="API Registration">
            <ApiKeysTable :url="apiKeyUrl"></ApiKeysTable>
          </b-tab>
          <b-tab title="xTend Registration">
            <XtendKeysTable></XtendKeysTable>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ApiKeysTable from '@/xvisor/components/settings/api-key/ApiKeysTable.vue';
import UsersTable from '@/xvisor/components/settings/users/UsersTable.vue';
import XomeSettings from '@/xvisor/components/settings/xources/XomeSettings.vue';
import XtendSettings from '@/xvisor/components/settings/xtends/XtendSettings.vue';
import XtendKeysTable from '@/xvisor/components/settings/xtend-registration/XtendKeysTable.vue';
import ZoneSettings from '@/xvisor/components/settings/zones/ZoneSettings.vue';

export default {
  data() {
    return {
      apiKeyUrl: '/settings/apikey/list.json',
      usersUrl: '/user/list.json',
      officesUrl: '/offices/list.json',
    };
  },
  components: {
    ApiKeysTable,
    UsersTable,
    XomeSettings,
    XtendKeysTable,
    XtendSettings,
    ZoneSettings,
  },
  computed: {
    tabs() {
      return {
        users: { name: 'Users', index: 0 },
        xomes: { name: 'XOMEs', index: 1 },
        xtends: { name: 'xTends', index: 2 },
        offices: { name: 'Offices/Zones', index: 3 },
        apiKeys: { name: 'API Keys', index: 4 },
      };
    },
  },
};
</script>

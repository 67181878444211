<template>
  <b-card no-body class="mx-n3 mt-n2">
    <b-row class="ml-0-5 my-1">
      <b-col cols="6">
        <h6>{{ selectedHeader }}</h6>
      </b-col>
    </b-row>
    <b-row v-if="hasUserSelection" class="mx-1">
      <b-col>
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <h6>Traffic</h6>
            <AppInOutChart
              :timeRange="timeRange"
              :url="chartUrls.appInOutChartUrl"
              :issueTimeSelected="issueTimeSelected"
            ></AppInOutChart>
            <h6>Transaction Counts</h6>
            <AppUserTransactionCounts
              :timeRange="timeRange"
              :url="chartUrls.appUserTransactionUrl"
              :userIp="selectedUser.ip"
              :issueTimeSelected="issueTimeSelected"
            ></AppUserTransactionCounts>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <h6>Connectivity for {{ selectedUser.ip }}</h6>
            <TopIpsEchartVue
              :timeRange="timeRange"
              :url="chartUrls.topIpsUrl"
              :ip="selectedUser.ip"
            ></TopIpsEchartVue>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col lg="6" md="6" sm="12">
            <h6>TCP RTT</h6>
            <TcpUdpMetrics
              :timeRange="timeRange"
              metric="rtt"
              :url="chartUrls.tcpRttUrl"
              :eventUrl="chartUrls.tcpRttEventUrl"
              :appId="monAppId"
              :issueTimeSelected="issueTimeSelected"
            ></TcpUdpMetrics>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <h6>TCP Retransmissions</h6>
            <TcpUdpMetrics
              :timeRange="timeRange"
              metric="retrans"
              :url="chartUrls.tcpRetransUrl"
              :eventUrl="chartUrls.tcpRetransEventUrl"
              :appId="monAppId"
              :issueTimeSelected="issueTimeSelected"
            ></TcpUdpMetrics>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col lg="6" md="6" sm="12">
            <h6>UDP RTT</h6>
            <TcpUdpMetrics
              :timeRange="timeRange"
              metric="udpRtt"
              :url="chartUrls.udpRttUrl"
              :eventUrl="chartUrls.udpRttEventUrl"
              :appId="monAppId"
              :issueTimeSelected="issueTimeSelected"
            ></TcpUdpMetrics>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <h6>UDP Loss</h6>
            <TcpUdpMetrics
              :timeRange="timeRange"
              metric="udpLoss"
              :url="chartUrls.udpLossUrl"
              :eventUrl="chartUrls.udpLossEventUrl"
              :appId="monAppId"
              :issueTimeSelected="issueTimeSelected"
            ></TcpUdpMetrics>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col lg="6" md="6" sm="12">
            <h6>UDP Jitter</h6>
            <TcpUdpMetrics
              :timeRange="timeRange"
              metric="udpJitter"
              :url="chartUrls.udpJitterUrl"
              :eventUrl="chartUrls.udpJitterEventUrl"
              :appId="monAppId"
              :issueTimeSelected="issueTimeSelected"
            ></TcpUdpMetrics>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import moment from 'moment';

import AppInOutChart from '@/xvisor/components/app/userTab/AppInOutChart.vue';
import AppUserTransactionCounts from '@/xvisor/components/app/userTab/AppUserTransactionCounts.vue';
import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import TcpUdpMetrics from '@/xvisor/components/app/userTab/TcpUdpMetrics.vue';
import TopIpsEchartVue from '@/xvisor/components/app/userTab/TopIpsEchart.vue';
import UsersEventBus from '@/xvisor/bus/UsersEventBus';

export default {
  components: {
    AppInOutChart,
    AppUserTransactionCounts,
    TcpUdpMetrics,
    TopIpsEchartVue,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    monAppId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedUser: null,
      issueTimeSelected: null,
    };
  },
  computed: {
    selectedHeader() {
      if (!this.hasUserSelection) return 'No User Selected';
      if (this.selectedUser.name !== this.selectedUser.ip) {
        return `Details for ${this.selectedUser.name} (${this.selectedUser.ip})`;
      }
      return `Details for ${this.selectedUser.ip}`;
    },
    hasUserSelection() {
      return this.selectedUser !== null && this.selectedUser !== undefined;
    },
    chartUrls() {
      let clientIp = 'undefined';
      if (this.selectedUser) {
        clientIp = this.selectedUser.ip;
      }
      return {
        tcpRetransUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/tcpretrans.json`,
        tcpRetransEventUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/event/tcpretrans.json`,
        tcpRttUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/tcprtt.json`,
        tcpRttEventUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/event/tcprtt.json`,
        udpLossUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/udploss.json`,
        udpLossEventUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/event/udploss.json`,
        udpJitterUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/udpjitter.json`,
        udpJitterEventUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/event/udpjitter.json`,
        udpRttUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/udprtt.json`,
        udpRttEventUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/event/udprtt.json`,
        appInOutChartUrl: `/appusers/${clientIp}/applayer/${this.monAppId}/flowsts.json`,
        appUserTransactionUrl: `/apps/${this.monAppId}/transcountts.json`,
        topIpsUrl: `/apps/${this.monAppId}/appuser/${this.selectedUser.ip}/topdevices/flowcounts.json`,
      };
    },
  },
  watch: {
    timeRange() {
      this.selectedUser = null;
    },
  },
  created() {
    UsersEventBus.$on('update-selected-user', (user) => {
      this.selectedUser = user;
    });
  },
  mounted() {
    UsersEventBus.$on('update-time-range', (newTimeRange) => { this.timeRange = newTimeRange; });
    IssueTimeBarEventBus.$on('update-filters', (filters) => {
      this.issueTimeSelected = moment(filters.shortTimeRange.start).valueOf();
    });
  },
};
</script>

<style scoped>
.chart-title {
  font-family: sans-serif;
  color: white;
}
</style>

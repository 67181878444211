<template>
  <div>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="openModal(integrationInfo[integration.type].name)"
      v-b-tooltip.hover.variant.bottom
      title="Edit"
    >
      <b-icon icon="pencil" />
    </b-button>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.removeIntegration.$refs.modal.show()"
      v-b-tooltip.hover.variant.bottom
      title="Delete"
    >
      <b-icon icon="trash" />
    </b-button>
    <EditNewRelicModal
      ref="updateIntegrationNewRelic"
      :id="integration.id"
      :integration="integration"
      :versions="integrationInfo[integrationTypes.newRelic].versions"
    ></EditNewRelicModal>
    <DeleteIntegrationModal ref="removeIntegration" :id="integration.id"></DeleteIntegrationModal>
  </div>
</template>

<script>
import DeleteIntegrationModal from '@/xvisor/components/integrations/DeleteIntegrationModal.vue';
import EditNewRelicModal from '@/xvisor/components/integrations/EditNewRelicModal.vue';
import { integrationInfo, integrationTypes } from '@/xvisor/constants/integrations';

export default {
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  components: {
    DeleteIntegrationModal,
    EditNewRelicModal,
  },
  data() {
    return {
      integrationInfo,
      integrationTypes,
    };
  },
  methods: {
    openModal(typeName) {
      const reference = `updateIntegration${typeName}`;
      if (this.$refs[reference]) {
        this.$refs[reference].$refs.modal.show();
      }
    },
  },
};
</script>

<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="apache-echarts-flow-stats-graph"
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import numberUtils from '@/xvisor/utilities/numberUtils';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const SYMBOL_SIZE_MAX = 50;
const SYMBOL_SIZE_MIN = 1;

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      userCountData: [],
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity6(this.timeRange),
          },
        })
        .then((response) => { this.userCountData = response.data; })
        .finally(() => { this.loaded = true; });
    },
    scaleValue(value) {
      const scaledValue = numberUtils.mapNumberFromRangeToRange(
        value,
        this.valueMin,
        this.valueMax,
        SYMBOL_SIZE_MIN,
        SYMBOL_SIZE_MAX,
      );
      return scaledValue;
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.userCountData && this.userCountData.length > 0;
    },
    allTimes() {
      return this.userCountData.map((point) => point.time);
    },
    allValues() {
      return this.userCountData.map((point) => point.value);
    },
    timeSet() {
      return new Set(this.allTimes);
    },
    timeRangeMax() {
      return Math.max(...this.allTimes.map((element) => new Date(element)));
    },
    timeRangeMin() {
      return Math.min(...this.allTimes.map((element) => new Date(element)));
    },
    valueMax() {
      return Math.max(...this.allValues);
    },
    valueMin() {
      return Math.min(...this.allValues);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.teal4, colorPaletteShade.blue4],
        tooltip: {
          trigger: 'axis',
          position: 'top',
          renderMode: 'html',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              <div class="apache-echarts-tooltip-timestamp">
                ${moment(info[0].value[0]).format('MMM D h:mm A')}
              </div>
              <div>
                ${info[0].marker} User Count: ${info[0].value[1]}
              </div>
            </div>
          `,
        },
        title: {
          text: 'Connectivity',
          textStyle: {
            color: this.toggleColor,
            fontSize: 12,
          },
        },
        singleAxis: [
          {
            min: this.timeRangeMin,
            max: this.timeRangeMax,
            top: '30%',
            left: 30,
            right: 50,
            height: '50%',
            type: 'time',
            data: this.allTimes,
            axisLabel: {
              interval: 2,
              color: this.toggleColor,
              formatter: (value) => moment(value).format(this.timeSeriesFormat),
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
          },
        ],
        series: [
          {
            singleAxisIndex: 0,
            type: 'scatter',
            coordinateSystem: 'singleAxis',
            data: this.userCountData.map((point) => ([point.time, point.value])),
            symbolSize: (value) => this.scaleValue(value[1]),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.apache-echarts-flow-stats-graph {
  width: 100% !important;
  height: 100px !important;
}
</style>

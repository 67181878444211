import AlertsTable from '@/xvisor/components/launchPad/AlertsTable.vue';
import AppAlertDistribution from '@/xvisor/components/launchPad/AppAlertDistribution.vue';
import InfraElemAlertDistribution from '@/xvisor/components/launchPad/InfraElemAlertDistribution.vue';
import ServiceAlertDistribution from '@/xvisor/components/launchPad/ServiceAlertDistribution.vue';
import TrafficStats from '@/xvisor/components/launchPad/TrafficStats.vue';
import TrafficTable from '@/xvisor/components/launchPad/TrafficTable.vue';
import TrafficStatisticTable from '@/xvisor/components/launchPad/TrafficStatisticTable.vue';
import XtendStatisticTable from '@/xvisor/components/launchPad/XtendStatisticTable.vue';

const componentEnum = {
  alertsTable: 'AlertsTable',
  appAlertDistribution: 'AppAlertDistribution',
  infraElemAlertDistribution: 'InfraElemAlertDistribution',
  serviceAlertDistribution: 'ServiceAlertDistribution',
  trafficTable: 'TrafficTable',
  trafficStats: 'TrafficStats',
  trafficStatisticTable: 'TrafficStatisticTable',
  xtendStatisticTable: 'XtendStatisticTable',
};

export default {
  data() {
    return {
      componentEnum,
      componentEnumToInfo: new Map([
        [
          componentEnum.trafficTable,
          {
            title: 'Discovered Applications',
            url: '/appclasses/toptrafftotals.json',
            component: TrafficTable,
            query: 'What are the discovered applications?',
            isDefaultPanel: true,
          },
        ],
        [
          componentEnum.trafficStats,
          {
            title: 'Top Discovered Applications',
            url: '/appclasses/toptraffts.json',
            component: TrafficStats,
            query: 'What are the top discovered applications?',
            isDefaultPanel: true,
          },
        ],
        [
          componentEnum.alertsTable,
          {
            title: 'Application Alerts',
            url: '/apps/alerts/list.json',
            component: AlertsTable,
            query: 'What are the application alerts?',
          },
        ],
        [
          componentEnum.appAlertDistribution,
          {
            title: 'App Alerts Distribution',
            url: '/alerts/topappszones/counttotals.json',
            component: AppAlertDistribution,
            query: 'What are the application alert distributions?',
          },
        ],
        [
          componentEnum.infraElemAlertDistribution,
          {
            title: 'Infrastructure Element Alerts Distribution',
            url: '/alerts/topnesapps/counttotals.json',
            component: InfraElemAlertDistribution,
            query: 'What are the infrastructure element alert distributions?',
          },
        ],
        [
          componentEnum.serviceAlertDistribution,
          {
            title: 'Service Alerts Distribution',
            url: '/alerts/topsvcsapps/counttotals.json',
            component: ServiceAlertDistribution,
            query: 'What are the network service alert distributions?',
          },
        ],
        [
          componentEnum.trafficStatisticTable,
          {
            title: 'Traffic Statistics',
            url: '/zones/trafficstats.json',
            component: TrafficStatisticTable,
            query: 'What are the traffic statistics?',
          },
        ],
        [
          componentEnum.xtendStatisticTable,
          {
            title: 'Endpoint Statistics',
            url: '/xtends/xtendalertsummary.json',
            component: XtendStatisticTable,
            query: 'What are the endpoint statistics?',
          },
        ],
      ]),
    };
  },
};

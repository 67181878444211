<template>
  <InfraBase>
    <b-tabs card end lazy>
      <b-tab title="Metrics">
        <MetricsTab></MetricsTab>
      </b-tab>
      <b-tab title="Traffic">
        <TrafficTab></TrafficTab>
      </b-tab>
      <b-tab title="Interfaces">
        <IfacesTab></IfacesTab>
      </b-tab>
      <b-tab title="Logs">
        <LogsTab></LogsTab>
      </b-tab>
    </b-tabs>
  </InfraBase>
</template>

<script>
import IfacesTab from '@/xvisor/components/infra/interfaces/IfacesTab.vue';
import InfraBase from '@/xvisor/views/InfraBase.vue';
import LogsTab from '@/xvisor/components/infra/logs/LogsTab.vue';
import MetricsTab from '@/xvisor/components/infra/MetricsTab.vue';
import TrafficTab from '@/xvisor/components/infra/traffic/TrafficTab.vue';

export default {
  components: {
    IfacesTab,
    InfraBase,
    LogsTab,
    MetricsTab,
    TrafficTab,
  },
};
</script>

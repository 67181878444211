<template>
  <div>
    <b-row v-if="loaded">
      <b-col lg="6" md="6" sm="12"
         v-for="(domainObject, index) in domainObjects"
        :key="index"
      >
        <DomainColumnChart class="mt-2-0"
          chartId="domain metrics"
          :timeRange="timeRange"
          :height="200"
          :issueTimeSelected="issueTimeSelected"
          :domainObject="domainObject"
          :targetId="targetId"
        ></DomainColumnChart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';

import granularity from '@/xvisor/utilities/granularity';

import DomainColumnChart from '@/xvisor/components/app/DomainColumnChart.vue';

export default {
  components: {
    DomainColumnChart,
  },
  props: {
    urls: {
      type: Object,
      required: true,
    },
    height: {
      Number,
      default: 100,
    },
    chartId: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    issueTimeSelected: {
      type: Number,
    },
    targetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      delay: [],
      allLoss: [],
      domainObjects: [],
      loaded: false,
      asnNumberToDelay: new Map(),
      asnNumberToBaseline: new Map(),
      asnNumberToLoss: new Map(),
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
        },
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    urls() {
      this.httpGet();
    },
  },
  methods: {
    populateDomainMaps() {
      this.asnNumberToDelay = new Map();
      this.asnNumberToBaseline = new Map();
      this.asnNumberToLoss = new Map();
      this.delay.forEach((ts) => {
        const asnNumber = this.extractAsnNumber(ts.name);
        const delayOrBaseline = this.differentiateDelayAndBaseline(ts.name);
        if (delayOrBaseline === 'Delay') {
          this.asnNumberToDelay.set(asnNumber, ts.points);
        } else {
          this.asnNumberToBaseline.set(asnNumber, ts.points);
        }
      });
      this.allLoss.forEach((ts) => {
        this.asnNumberToLoss.set(ts.name.split(' ')[0], ts.points);
      });
    },
    extractAsnNumber(timeSeriesName) {
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(timeSeriesName);
      return matches[1].split(' ')[1];
    },
    differentiateDelayAndBaseline(timeSeriesName) {
      const timeSeriesNameSplit = timeSeriesName.split(' ');
      if (timeSeriesNameSplit.includes('Baseline')) {
        return 'Baseline';
      }
      return 'Delay';
    },
    populateDomainObjects() {
      this.domainObjects = [];
      Array.from(this.asnNumberToDelay.keys()).forEach((asnNumber) => {
        this.domainObjects.push({
          points: this.asnNumberToDelay.get(asnNumber),
          baseline: this.asnNumberToBaseline.get(asnNumber),
          loss: this.asnNumberToLoss.has(asnNumber)
            ? this.asnNumberToLoss.get(asnNumber) : [],
          asnNumber,
        });
      });
    },
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.urls.tsUrl, this.httpParams),
          this.$http.get(this.urls.lossUrl, this.httpParams),
        ])
        .then(axios.spread((tsRes, lossRes) => {
          this.delay = tsRes.data;
          this.allLoss = lossRes.data;
          this.populateDomainMaps();
          this.populateDomainObjects();
        }))
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<template>
  <b-button
    size="sm"
    @click="httpRequest"
    :disabled="shouldDisableButton"
    :variant="getButtonVariant"
  >
    <span v-if="checkingFileStatus">
      Loading
      <b-spinner small variant="primary" />
    </span>
    <span v-else-if="hasDownloadError">
      Download Error
    </span>
    <span v-else-if="hasFetchError">
      Fetch Error
    </span>
    <span v-else-if="isDownloadingFile">
      Downloading
      <b-spinner small variant="primary" />
    </span>
    <span v-else-if="canDownload"> Download </span>
    <span v-else> Fetch </span>
  </b-button>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    metaData: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.uuid = uuidv4();
  },
  data() {
    return {
      uuid: null,
      loopForFileStatus: false,
      isDownloading: false,
      downloadError: false,
      fetchError: false,
      blobLink: null,
      filePath: '',
    };
  },
  computed: {
    getFileMetadata() {
      return {
        sourceIp: this.metaData.ip,
        startTime: this.timeRange.start.toISOString(),
        endTime: this.timeRange.end.toISOString(),
      };
    },
    getSubmitPcapFetchUrl() {
      return `/pcap/file/${this.uuid}/submit`;
    },
    getCheckPcapFileStatus() {
      return `/pcap/file/${this.uuid}/status`;
    },
    getDownloadPcapFileUrl() {
      return `/pcap/file/download?filePath=${this.filePath}`;
    },
    canDownload() {
      return this.filePath.length > 0;
    },
    isDownloadingFile() {
      return this.isDownloading;
    },
    hasDownloadError() {
      return this.downloadError;
    },
    hasFetchError() {
      return this.fetchError;
    },
    checkingFileStatus() {
      return this.loopForFileStatus;
    },
    shouldDisableButton() {
      return (this.loopForFileStatus || this.isDownloading) && !this.downloadError;
    },
    getButtonVariant() {
      if (this.hasDownloadError || this.hasFetchError) {
        return 'danger';
      }
      if (this.canDownload) {
        return 'success';
      }
      return 'secondary';
    },
  },
  methods: {
    httpRequest() {
      if (this.canDownload) {
        this.downloadError = false;
        if (this.blobLink !== null) {
          this.blobLink.click();
          return;
        }
        (async () => {
          try {
            this.isDownloading = true;
            const response = await fetch(this.getDownloadPcapFileUrl);
            const reader = response.body.getReader();
            const chunks = [];
            while (true) {
              // eslint-disable-next-line
              const { done, value } = await reader.read();
              if (done) {
                break;
              }
              chunks.push(value);
            }
            this.isDownloading = false;
            const blob = new Blob(chunks);
            const urlBlob = window.URL.createObjectURL(blob);
            this.blobLink = document.createElement('a');
            this.blobLink.style.display = 'none';
            this.blobLink.href = urlBlob;
            this.blobLink.download = this.filePath;
            document.body.appendChild(this.blobLink);
            this.blobLink.click();
          } catch {
            this.downloadAvailable = false;
            this.downloadError = true;
            this.blobLink = null;
          }
        })();
      } else {
        this.fetchError = false;
        this.$http
          .post(this.getSubmitPcapFetchUrl, this.getFileMetadata)
          .then(() => {
            this.loopForFileStatus = true;
          })
          .catch(() => {
            this.loopForFileStatus = false;
            this.fetchError = true;
          })
          .finally(() => {
            (async () => {
              while (this.checkingFileStatus) {
                // eslint-disable-next-line
                await new Promise((r) => {
                  this.$http
                    .get(this.getCheckPcapFileStatus)
                    .then((response) => {
                      if (response.data.filePath !== '') {
                        this.loopForFileStatus = false;
                        this.filePath = response.data.filePath;
                      }
                    })
                    .catch(() => {
                      this.loopForFileStatus = false;
                      this.fetchError = true;
                    });
                  setTimeout(r, 5000);
                });
              }
            })();
          });
      }
    },
  },
};
</script>
<style scoped>
button:disabled{
  cursor: not-allowed;
}
</style>

<template>
  <b-badge pill :variant="statusVariant" class="badge-width">
    {{ statusCheck(this.status) }}
  </b-badge>
</template>

<script>
import { reportStatuses } from '@/xvisor/constants/reports';

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reportStatus: {
        ready: reportStatuses.ready,
        pending: reportStatuses.pending,
      },
    };
  },
  computed: {
    statusVariant() {
      if (this.status === this.reportStatus.ready) return 'success';
      return 'warning';
    },
  },
  methods: {
    // Return pending if status isn't a valid string.
    statusCheck(status) {
      if (status !== this.reportStatus.ready && status !== this.reportStatus.pending) {
        return this.reportStatus.pending;
      }
      return status;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-width{
  width: 75px;
}
</style>

<template>
  <b-badge
    pill
    :class="getBadgeClass"
    class="badge-width"
    v-b-tooltip.hover
    :title="this.behaviorCheck(this.severity)"
  >
    {{ this.collapseText(this.behaviorCheck(this.severity), 14) }}
  </b-badge>
</template>

<script>
import collapseText from '@/xvisor/utilities/collapseText';
import severityBehavior from '@/xvisor/constants/xtendSeverity';

export default {
  props: {
    severity: {
      type: String,
      required: true,
    },
  },
  computed: {
    getBadgeClass() {
      if (this.severity === severityBehavior.notice.name) return 'badge-notice';
      if (this.severity === severityBehavior.warning.name) return 'badge-warning';
      if (this.severity === severityBehavior.critical.name) return 'badge-critical';
      return 'badge-notice';
    },
  },
  methods: {
    // Return Normal if severity isn't a valid string.
    behaviorCheck(severity) {
      if (severity === severityBehavior.notice.name
          || severity === severityBehavior.warning.name
          || severity === severityBehavior.critical.name) {
        return severity;
      }
      return severityBehavior.notice.name;
    },
    collapseText,
  },
};
</script>

<style scoped>
.badge-width{
  min-width: 40px;
  width: 50%;
}
.badge-notice {
  background-color: #9F7AEA;
  color: white;
}
.badge-warning {
  background-color: #FF9F43;
  color: white;
}
.badge-critical {
  background-color: #dc3545;
  color: white;
}
</style>

<template>
  <b-modal ref="modal" title="Delete Application" centered>
    Are you sure you want to delete this Application?
    You cannot undo this action and it will result in all associated data being removed.
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-left mr-1" @click="deleteApplication">Submit</b-button>
        <b-button variant="outline-primary" class="float-left" @click="$refs.modal.hide()">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      deleteUrl: `/apps/${this.id}`,
    };
  },
  methods: {
    deleteApplication() {
      this.$http
        .delete(this.deleteUrl)
        .then(() => {
          this.$bvToast.toast('Successfully deleted application', { variant: 'success' });
          this.$refs.modal.hide();
          this.$emit('update');
        })
        .catch(() => { this.$bvToast.toast('Failed to delete application', { variant: 'danger' }); });
    },
  },
};
</script>

<template>
  <b-card class="mx-n3">
    <div v-if="appCmptList.length === 0" class="ml-n0">{{ $t("No Data Available") }}</div>
    <b-tabs v-else content-class="mt-2" lazy>
      <b-tab v-for="(cmpt, index) in appCmptList.slice(start, end)" :key="cmpt.cmptId" :title="cmpt.name">
        <b-row>
          <b-col lg="6" md="12" sm="12">
            <AppUserTransactionCounts
              :elementId="index"
              :timeRange="timeRange"
              :url="cmpt.transDataUrl"
              :cmptId="cmpt.cmptId"
            ></AppUserTransactionCounts>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <InsightMsgs :url="cmpt.transInsightsUrl" :timeRange="timeRange" class="mt-2"></InsightMsgs>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <TransSrvrsTable
            :transDataUrl="cmpt.transDataUrl"
            :appId="cmpt.appId"
            :cmptId="cmpt.cmptId"
            :timeRange="timeRange"
            class="mt-n1 w-100 ml-1 mr-2">
          </TransSrvrsTable>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import AppUserTransactionCounts from '@/xvisor/components/app/userTab/AppUserTransactionCounts.vue';
import InsightMsgs from '@/xvisor/components/app/InsightMsgs.vue';
import TransSrvrsTable from '@/xvisor/components/app/transactionsTab/TransSrvrsTable.vue';

export default {
  props: {
    appCmptList: {
      type: Array,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppUserTransactionCounts,
    InsightMsgs,
    TransSrvrsTable,
  },
  data() {
    return {
      /** The current page's number in the pagination. */
      currentPage: 1,
      /** The maximum number of tiles per page. */
      maxPageItems: 9,
      /** Store the cmpt's id that do not have any transaction data. */
      cmptIdsNoData: [],
    };
  },
  methods: {
    elementId(index) {
      return `trans-count-heatmap-${index}`;
    },
    moveCmptToEnd(cmpt, noData) {
      if (this.cmptIdsNoData.find((id) => id === cmpt.cmptId) === undefined && noData) {
        this.cmptIdsNoData.push(cmpt.cmptId);
        const appIndex = this.appCmptList.findIndex((item) => item.cmptId === cmpt.cmptId);
        // Remove the application from the list.
        this.appCmptList.splice(appIndex, 1);
        // Add the application to the end of the list.
        this.appCmptList.push(cmpt);
      }
    },
  },
  computed: {
    start() { return (this.currentPage - 1) * this.maxPageItems; },
    end() { return this.start + this.maxPageItems; },
  },
};
</script>

<template>
  <b-card no-body class="mx-n2 mt-n1">
    <OpsTable></OpsTable>
  </b-card>
</template>

<script>
import OpsTable from '@/xvisor/components/ops/OpsTable.vue';

export default {
  components: {
    OpsTable,
  },
};
</script>

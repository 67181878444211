<template>
  <div>
    <b-card no-body class="mx-n2 mt-n2">
      <b-row class="mx-0-5 my-1" align-h="between" align-v="center">
        <b-col lg="4" md="12" sm="12">
          <h4>{{ $t('Alerts') }}</h4>
        </b-col>
        <b-col lg="4" md="12" sm="12">
          <!-- TODO: Implement resolved filtering for alerts table. -->
        </b-col>
        <b-col lg="4" md="12" sm="12">
          <SearchBar>
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
        </b-col>
      </b-row>
      <b-table
        id="alerts-table"
        class="table-with-actions-min-height"
        responsive
        small
        primary-key="id"
        :items="alerts"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: colWidth(field.key), minWidth: colMinWidth(field.key) }"
          >
        </template>
        <template #cell(alertInfo)="row">
          <b-row>
            <b-col>{{ row.item.msg }}</b-col>
          </b-row>
          <b-row>
            <b-col>
              Raised on {{ formatDate(row.item.time) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                variant="flat-primary"
                @click="row.toggleDetails"
                class="p-0"
              >
                {{ row.item.eventCount }} events
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #row-details="row">
          <AlertEventsTable :alertId="row.item.id" :timeRange="timeRange"></AlertEventsTable>
        </template>
        <template #cell(severity)="row">
          <b-badge pill :variant="alertStatusVariant(row.value)">
            {{ alertStatusText(row.value)}}
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="paginationTotalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        class="mt-1 mr-1"
        ariaControls="alerts-table"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';

import AlertEventsTable from '@/xvisor/components/app/AlertEventsTable.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import alertSeverities from '@/xvisor/constants/alertSeverities';
import colorPalette from '@/xvisor/constants/colorPalette';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  components: {
    AlertEventsTable,
    SearchBar,
  },
  props: {
    selectedAppName: {
      type: String,
      default: '',
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alerts: [],
      perPage: 20,
      resolved: false,
      currentPage: 1,
      filter: '',
      filteredLength: null,
      tableFields: [
        { key: 'name', label: 'App Name', sortable: true },
        { key: 'alertInfo', label: 'Alert Info' },
        { key: 'severity', label: 'Severity', sortable: true },
      ],
      colorPalette,
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.alerts.length;
    },
  },
  watch: {
    selectedAppName() {
      this.filter = this.selectedAppName;
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    alertStatusVariant(status) {
      if (status === alertSeverities.critical) return 'danger';
      if (status === alertSeverities.warning) return 'warning';
      return 'success';
    },
    alertStatusText(status) {
      if (status === alertSeverities.critical) return 'Critical';
      if (status === alertSeverities.warning) return 'Warning';
      return 'Notice';
    },
    httpGet() {
      this.$http
        .get('/apps/alerts/list.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.alerts = response.data; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
    colWidth(fieldKey) {
      switch (fieldKey) {
        case 'name': return '10%';
        case 'alertInfo': return '35%';
        case 'severity': return '5%';
        default: return '180px';
      }
    },
    colMinWidth(fieldKey) {
      switch (fieldKey) {
        case 'name': return '100px';
        case 'alertInfo': return '300px';
        case 'severity': return '100px';
        default: return '80px';
      }
    },
  },
};
</script>

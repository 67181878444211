<template>
  <b-modal
    size="xl"
    ref="vulnModal"
    :title="modalTitle"
    hide-footer
  >
    <VulnerabilityTable :vulnerabilities="vulnerabilities" />
  </b-modal>
</template>

<script>
import VulnerabilityEventBus from '@/xvisor/components/xtend/tabs/VulnerabilityEventBus';
import VulnerabilityTable from '@/xvisor/components/xtend/tabs/VulnerabilityTable.vue';

export default {
  props: {
    softwareId: {
      type: String,
      required: true,
    },
    package: {
      type: Object,
      required: true,
    },
    vulnerabilities: {
      type: Array,
      required: true,
    },
  },
  components: {
    VulnerabilityTable,
  },
  computed: {
    modalTitle() {
      let title = this.package.name;
      if (this.package.version) {
        title += ` | ${this.package.version}`;
      }
      if (this.package.publisher) {
        title += ` | ${this.package.publisher}`;
      }
      return title;
    },
  },
  mounted() {
    // Listen for the event to open the modal
    VulnerabilityEventBus.$on('show-modal', this.showModal);
  },
  methods: {
    showModal(softwareId) {
      // Event bus shared by all pills and modals, so verify that we are opening the correct modal.
      if (this.softwareId === softwareId) {
        this.$refs.vulnModal.show();
      }
    },
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    VulnerabilityEventBus.$off('show-modal', this.showModal);
  },
};
</script>

<template>
  <div>
    <UserTable :url="url" :countUrl="countUrl" :timeRange="timeRange"></UserTable>
    <UserDetails :monAppId="monAppId" :timeRange="timeRange"></UserDetails>
  </div>
</template>

<script>
import UserDetails from '@/xvisor/components/app/userTab/UserDetails.vue';
import UserTable from '@/xvisor/components/app/userTab/UserTable.vue';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    countUrl: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    monAppId: {
      type: Number,
      required: true,
    },
  },
  components: {
    UserTable,
    UserDetails,
  },
  data() {
    return {};
  },
};
</script>

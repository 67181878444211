<template>
  <b-modal ref="modal" title="Update Zone" @ok="editZone">
    <validation-observer ref="form" @keydown.enter="editZone">
      <validation-provider name="name" rules="required" v-slot="{ errors }">
        <b-form-group label="Name *">
          <b-form-input
            autofocus
            v-model="updatedZone.name"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="subnets" rules="required|prefixListValid" v-slot="{ errors }">
        <b-form-group label="Subnets">
          <b-form-input
            v-model="updatedZone.subnets"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import ZonesEventBus from '@/xvisor/bus/ZonesEventBus';

export default {
  props: {
    zone: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      updatedZone: {
        name: '',
        subnets: '',
      },
    };
  },
  created() {
    this.updatedZone.name = this.zone.name;
    this.updatedZone.subnets = this.zone.subnets && this.zone.subnets.length
      ? this.zone.subnets.join(', ')
      : '';
  },
  methods: {
    getFormattedZone() {
      return {
        name: this.updatedZone.name,
        subnets: this.updatedZone.subnets.split(','),
      };
    },
    editZone() {
      this.$http
        .post(this.url, this.getFormattedZone())
        .then(() => {
          this.$bvToast.toast('Successfully updated zone', { title: 'Notice', variant: 'success' });
          this.$refs.modal.hide();
          ZonesEventBus.$emit('refresh');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to update zone', { title: 'Notice', variant: 'danger' });
        });
    },
  },
};
</script>

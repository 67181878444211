<template>
  <div>
    <RadialProgressBar
      v-if="hasData"
      v-b-tooltip.hover
      :title="tooltipTitle"
      :inputData="parseFloat(this.memoryUsage)"
      label="Memory"
    ></RadialProgressBar>
    <div v-else-if="loaded">
      {{ $t("No Data Available") }}
    </div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import RadialProgressBar from '@/xvisor/components/agents/RadialProgressBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import memoryUsageText from '@/xvisor/utilities/memoryUsageText';

export default {
  components: {
    RadialProgressBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      memory: undefined,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    tooltipTitle() {
      return `${memoryUsageText.bytesText(this.memory)} (${memoryUsageText.percentText(this.memory)})`;
    },
    memoryUsage() {
      return ((this.memory.usedBytes / this.memory.totalBytes) * 100).toFixed(1);
    },
    hasData() {
      return this.loaded && this.memory;
    },
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.memory = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

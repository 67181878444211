<template>
  <div>
    <b-card no-body class="mx-n2">
      <b-row class="mt-2 mx-0-5" align-h="between" align-v="center">
        <b-col cols="6">
          <h4>XOMEs</h4>
        </b-col>
        <b-col cols="6">
          <b-button
            size="sm"
            variant="primary"
            v-b-modal.addXomeModalAlt
            class="mx-auto text-nowrap"
            style="float:right;"
          >
            + Add XOME
          </b-button>
        </b-col>
      </b-row>
      <b-row class="my-1 mx-0-5" align-h="between" align-v="center">
        <b-col cols="12" md="6">
          <ShowEntries>
            <template #input>
              <b-form-select
                v-model="perPage"
                :options="options"
                size="sm"
              ></b-form-select>
            </template>
          </ShowEntries>
        </b-col>
        <b-col v-if="hasData" cols="12" md="6">
          <SearchBar :hasToolTip="true" :hasErrorMessage="true">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
            <template #errorMessage>
              <small class="text-danger" v-if="!validQuery">Invalid filter, performing regular search.</small>
            </template>
            <template #tooltip>
              <FilterToolTip :filterFields="filterOptions"></FilterToolTip>
            </template>
          </SearchBar>
        </b-col>
      </b-row>
      <b-table
        v-if="hasData"
        class="table-with-actions-min-height"
        responsive
        striped
        outlined
        hover
        primary-key="id"
        :items="xomes"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
        :filter-function="filterTable"
      >
        <template #cell(name)="row">
          <div>
            <span :id="`popover-${row.item.name}`">
              {{ row.item.name }}
            </span>
            <b-popover
              :target="`popover-${row.item.name}`"
              placement="bottomright"
              triggers="hover focus"
            >
              <strong>Name</strong>: {{ row.item.name }} <br>
              <strong>IP</strong>: {{ row.item.ip }}
            </b-popover>
          </div>
        </template>
        <template #cell(zone)="row">
          <ZoneLocation :zoneInfo="row.item.zoneInfo"></ZoneLocation>
        </template>
        <template #cell(status)="row">
          <StatusLabel :url="getStatusUrl(row.item.id)"></StatusLabel>
        </template>
        <template #cell(resources)="row">
          <b-row class="resource-col-width">
            <b-col cols="6">
              <XomeCpu :url="getCpuUrl(row.item.id)"></XomeCpu>
            </b-col>
            <b-col cols="6">
              <XomeMemory :url="getMemoryUrl(row.item.id)"></XomeMemory>
            </b-col>
          </b-row>
        </template>
        <template #cell(actions)="row">
          <XomeActions
            :deleteUrl="getDeleteUrl(row.item.id)"
            :xomeId="row.item.id"
            @deleted="httpGet"
            class="float-right"
          ></XomeActions>
        </template>
      </b-table>
      <div v-else-if="loaded" class="ml-2">{{ $t("No Data Available") }}</div>
      <SpinnerCmpt v-else></SpinnerCmpt>
      <b-pagination
        v-model="currentPage"
        :total-rows="paginationTotalRows"
        :per-page="perPage"
        align="right"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-2 mx-1"
      />
    </b-card>
    <AddXome modalId="addXomeModalAlt" @added="onXomeAdded"></AddXome>
    <b-modal id="xomeRegistrationModalAlt" hide-footer title="XOME Registration">
      <XomeRegisterInstructions
        :xomeRegisterInfo="xomeRegisterInfo"
        @finished="closeXomeRegisterInstructions"
      ></XomeRegisterInstructions>
    </b-modal>
  </div>
</template>

<script>
import { compileExpression } from 'filtrex';

import AddXome from '@/xvisor/components/xome/AddXome.vue';
import FilterToolTip from '@/xvisor/components/agents/FilterToolTip.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import StatusLabel from '@/xvisor/components/StatusLabel.vue';
import XomeActions from '@/xvisor/components/xome/XomeActions.vue';
import XomeCpu from '@/xvisor/components/agents/XomeCpu.vue';
import XomeMemory from '@/xvisor/components/agents/XomeMemory.vue';
import XomeRegisterInstructions from '@/xvisor/components/xome/XomeRegisterInstructions.vue';
import XomesEventBus from '@/xvisor/bus/XomesEventBus';
import ZoneLocation from '@/xvisor/components/agents/ZoneLocation.vue';

export default {
  components: {
    AddXome,
    FilterToolTip,
    SearchBar,
    ShowEntries,
    SpinnerCmpt,
    StatusLabel,
    XomeActions,
    XomeCpu,
    XomeMemory,
    XomeRegisterInstructions,
    ZoneLocation,
  },
  data() {
    return {
      tableFields: [
        { key: 'name', label: 'Name' },
        { key: 'zone', label: 'Zone' },
        { key: 'resources', label: 'Resource Usage' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: '' },
      ],
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      filterOptions: [
        { name: 'name', example: '(e.g., name == "XomeName"' },
        { name: 'ip', example: '(e.g., ip == "192.168.0.1")' },
        { name: 'cloudProvider', example: '(e.g., cloudProvider == "aws")' },
        { name: 'zoneName', example: '(e.g., zoneName == "zoneName")' },
      ],
      xomes: [],
      loaded: false,
      currentPage: 1,
      perPage: 5,
      filter: null,
      filteredLength: null,
      validQuery: true,
      xomeRegisterInfo: null,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.xomes && this.xomes.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.xomes.length;
    },
  },
  mounted() {
    XomesEventBus.$on('added', this.httpGet);
    XomesEventBus.$on('renamed', this.httpGet);
    XomesEventBus.$on('deleted', this.httpGet);
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get('/xomes/list.json')
        .then((response) => { this.xomes = response.data; })
        .finally(() => { this.loaded = true; });
    },
    getCpuUrl(id) {
      return `/xomes/${id}/cpu.json`;
    },
    getDeleteUrl(id) {
      return `/xomes/${id}`;
    },
    getMemoryUrl(id) {
      return `/xomes/${id}/memory.json`;
    },
    getStatusUrl(id) {
      return `/xomes/${id}/status.json`;
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    filterTable(row, filter) {
      const item = {
        id: row.id,
        ip: row.ip,
        name: row.name,
        cloudProvider: row.zoneInfo.cloudProvider,
        cloudZoneId: row.zoneInfo.cloudZoneId,
        zoneName: row.zoneInfo.name,
        officeId: row.zoneInfo.officeId,
      };

      let val = 0;
      try {
        const myFilter = compileExpression(filter);
        val = myFilter(item);
        this.validQuery = true;
      } catch (err) {
        this.validQuery = false;
      }

      // If the filter doesn't match treat it as a normal search.
      if (val === 0 || filter.split(' ').length === 1) {
        return JSON.stringify(item).includes(filter);
      }
      return val;
    },

    // TODO: Avoid duplicating these in multiple places.
    onXomeAdded(xomeRegisterInfo) {
      this.$bvModal.hide('addXomeModalAlt');
      this.xomeRegisterInfo = xomeRegisterInfo;
      this.$bvModal.show('xomeRegistrationModalAlt');
    },
    closeXomeRegisterInstructions() {
      this.$bvModal.hide('xomeRegistrationModalAlt');
    },
  },
};
</script>

<style scoped>
.resource-col-width{
  width: 200px;
}
.popover-content{
  font-size: 10px;
}
</style>

<template>
  <b-row>
    <b-col lg="3" md="3" sm="12" order="1">
      <b-card class="sticky-card">
        <h5 class="kb-title">
          <b-icon icon="info-circle" class="mr-1" />
          Covered Topics
        </h5>
        <vue-perfect-scrollbar :settings="scrollSettings" class="scroll-style">
          <ul class="mt-1 v-list">
            <li v-for="(topic, index) in relatedTopics" :key="index" class="ml-n2">
              <b-link :href="`#${topic.href}`" class="list-item">{{ topic.name }}</b-link>
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-card>
    </b-col>
    <b-col lg="9" md="9" sm="12" order="2">
      <b-card>
        <h1 class="mb-1" align="center">xVisor Web Portal</h1>
        <div id="Content" class="question-content">
          <WebPortalContent></WebPortalContent>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import WebPortalContent from '@/xvisor/views/knowledgeBase/components/WebPortalContent.vue';

export default {
  components: {
    VuePerfectScrollbar,
    WebPortalContent,
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 50,
      },
      relatedTopics: [
        { name: 'Ennetix xVisor Web Portal', href: '2-ennetix-xvisor-web-portal' },
        { name: 'Sign Up and Login', href: '2-1-sign-up-and-log-in' },
        { name: 'Overview Page', href: '2-2-overview-page' },
        { name: 'Add XOME', href: '2-2-1-add-xome' },
        { name: 'Rest of Overview Page', href: '2-2-2-rest-of-overview-page' },
        { name: 'Add Custom Application', href: '2-2-3-add-custom-application' },
        { name: 'Applications Summary Page', href: '2-3-applications-summary-page' },
        { name: 'Application Details Page', href: '2-4-application-details-page' },
        { name: 'Overview Tab', href: '2-4-1-overview-tab' },
        { name: 'Users Tab', href: '2-4-2-users-tab' },
        { name: 'Transactions Tab', href: '2-4-3-transactions-tab' },
        { name: 'Flows Tab', href: '2-4-4-flows-tab' },
        { name: 'Network Tab', href: '2-4-5-network-tab' },
        { name: 'Service Tab', href: '2-4-6-service-tab' },
        { name: 'Alerts Panel', href: '2-4-7-alerts-panel' },
        { name: 'Network Elements Page', href: '2-5-network-elements-page' },
        { name: 'Individual Network Element Page', href: '2-6-individual-network-element-page' },
        { name: 'xTends Page', href: '2-7-xtends-page' },
        { name: 'Individual xTend Page', href: '2-8-individual-xtend-page' },
        { name: 'Reports Page', href: '2-9-reports-page' },
        { name: 'Settings Page', href: '2-10-settings-page' },
        { name: 'System Tab', href: '2-10-1-system-tab' },
        { name: 'XOMEs Tab', href: '2-10-2-xome-s-tab' },
        { name: 'Users Tab', href: '2-10-3-user-s-tab' },
        { name: 'Teams Tab', href: '2-10-4-team-s-tab' },
        { name: 'Offices Zones Tab', href: '2-10-5-offices-zones-tab' },
        { name: 'Maintenance Tab', href: '2-10-6-maintenance-tab' },
        { name: 'Security Tab', href: '2-10-7-security-tab' },
        { name: 'Reports Tab', href: '2-10-8-reports-tab' },
        { name: 'Activity Logs', href: '2-10-9-activity-logs' },
        { name: 'User Profile Page', href: '2-11-user-profile-page' },
        { name: 'Reset Password', href: '2-11-1-reset-password' },
        { name: 'Status Page', href: '2-12-status-page' },
      ],
    };
  },
};
</script>

<template>
  <b-card no-body>
    <b-row class="mx-0-5 my-1" align-h="start" align-v="center">
      <b-col cols="12" md="6">
        <AddOffice :url="addOfficeUrl" class=""></AddOffice>
        <AddZones :url="addZonesUrl" class="ml-1"></AddZones>
      </b-col>
    </b-row>
    <OfficesTable :offices="offices"></OfficesTable>
  </b-card>
</template>

<script>
import AddOffice from '@/xvisor/components/settings/zones/AddOffice.vue';
import AddZones from '@/xvisor/components/settings/zones/AddZones.vue';
import OfficesTable from '@/xvisor/components/settings/zones/OfficesTable.vue';
import ZonesEventBus from '@/xvisor/bus/ZonesEventBus';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    AddZones,
    AddOffice,
    OfficesTable,
  },
  data() {
    return {
      offices: [],

      addOfficeUrl: '/offices/add',
      addZonesUrl: '/zones/add',
    };
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.offices = response.data; });
    },
  },
  mounted() {
    this.httpGet();
    ZonesEventBus.$on('refresh', this.httpGet);
  },
};
</script>

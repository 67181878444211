<template>
  <b-table
    :items="vulnerabilities"
    :fields="fields"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
  >
    <template #cell(id)="data">
      <a :href="cveLink(data.value)" target="_blank">{{ data.value }}</a>
    </template>
    <template #cell(score.base)="data">
      <VulnerabilityScore :score="data.value" />
    </template>
  </b-table>
</template>

<script>
import VulnerabilityScore from '@/xvisor/components/xtend/tabs/VulnerabilityScore.vue';

export default {
  props: {
    vulnerabilities: {
      type: Array,
      required: true,
    },
  },
  components: {
    VulnerabilityScore,
  },
  data() {
    return {
      sortBy: 'score.base',
      sortDesc: true,
      fields: [
        {
          key: 'id',
          label: 'Name',
          sortable: true,
          class: 'col-2',
        },
        { key: 'description', label: 'Description' },
        { key: 'score.base', label: 'Score', sortable: true },
      ],
    };
  },
  methods: {
    cveLink(cveId) {
      return `https://nvd.nist.gov/vuln/detail/${cveId}`;
    },
  },
};
</script>

<template>
  <div>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.deleteApplication.$refs.modal.show()"
      v-b-tooltip.hover.variant.bottom
      title="Delete"
    >
      <b-icon icon="trash" />
    </b-button>
    <DeleteApplicationModal ref="deleteApplication" :id="id" @update="modalUpdate"></DeleteApplicationModal>
  </div>
</template>

<script>
import DeleteApplicationModal from '@/xvisor/components/app/DeleteApplicationModal.vue';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    DeleteApplicationModal,
  },
  methods: {
    modalUpdate() {
      this.$emit('update');
    },
  },
};
</script>

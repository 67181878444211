<template>
  <b-form @submit.prevent="submitNewDns">
    <b-form-group>
      <b-input-group>
        <b-form-input v-model="dnsForm.dnsServer" placeholder="Enter IP" required></b-form-input>
        <b-button type="submit" variant="primary">Add</b-button>
      </b-input-group>
    </b-form-group>
  </b-form>
</template>

<script>
import DnsEventBus from '@/xvisor/bus/DnsEventBus';

export default {
  data() {
    return {
      dnsForm: {
        dnsServer: '',
      },
      addDnsUrl: '/policysettings/dnsServer/add',
    };
  },
  methods: {
    submitNewDns() {
      this.$http
        .post(this.addDnsUrl, this.dnsForm)
        .then(() => {
          this.$bvToast.toast('Successfully added approved DNS IP', { variant: 'success' });
          DnsEventBus.$emit('changed');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to add approved DNS IP', { variant: 'danger' });
        });
    },
  },
};
</script>

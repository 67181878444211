<template>
  <div>
    <SuricataAlertsSummary
      v-if="hasData"
      :timeRange="timeRange"
    ></SuricataAlertsSummary>
    <b-card no-body>
      <b-row class="mx-2 mt-2" v-if="hasData">
        <h4 class="mb-0">Observed Alerts</h4>
      </b-row>
      <b-row class="mx-1 mt-0-5 mb-0-5" align-h="between" v-if="hasData">
        <b-col lg="8" md="8" sm="12">
          <b-row align-h="between" align-v="middle">
            <b-col lg="2" md="4" sm="12" class="mb-1 mt-0-5 d-flex align-items-center">
              <div class="form-select">
                Show
                <b-form-select
                  value="10"
                  v-on:change="getSelectedPageSize"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
            <b-col class="mb-1 mt-0-5 ml-4 d-flex align-items-center">
              <Multiselect
                :multiple="true"
                placeholder="Filter by alert severity"
                v-model="severitySelectValues"
                :options="severityOptions"
                :showLabels="false"
                :closeOnSelect="false"
                :searchable="false"
                style="width: 250px;"
              >
              </Multiselect>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" md="4" sm="12" class="mb-1 d-flex align-items-center justify-content-end">
          <b-row>
            <SearchBar :hasToolTip="false" :hasErrorMessage="true">
              <template #input>
                <b-form-input v-model="filter" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              v-if="hasData"
              id="alerts-table"
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :filter="filter"
              @filtered="onFiltered"
              :items="alertsFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
            <template #cell(severityLevel)="row">
              <b-badge
                pill
                class="suricata-alert-badge"
                :variant="alertStatusVariant(row.value)"
              >
                {{ severityMap[row.value] }}
              </b-badge>
            </template>
            </b-table>
            <b-row>
              <b-col>
                  <h5
                    class="mt-2 mx-1 text-right"
                  >
                    <span
                      class="total-signatures-wrapper"
                      v-b-tooltip.hover
                      :title="getTotalSignaturesTooltip"
                    >
                      Total Signatures: {{ paginationTotalRows }}
                    </span>
                  </h5>
                </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="paginationTotalRows"
                  :per-page="perPage"
                  align="right"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="my-2 mx-1"
                />
              </b-col>
            </b-row>
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 my-1">
            {{ $t("No Data Available") }}
          </div>
          <SpinnerCmpt v-else class="mb-1"></SpinnerCmpt>
        </b-col>
      </b-row>
    </b-card>
    <SuricataAlertDetails
      v-if="hasData"
      :timeRange="timeRange"
      :selectedAlert="getSelectedAlert"
    ></SuricataAlertDetails>
  </div>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SuricataAlertDetails from '@/xvisor/components/security/SuricataAlertDetails.vue';
import SuricataAlertsSummary from '@/xvisor/components/security/SuricataAlertsSummary.vue';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    Multiselect,
    SearchBar,
    SpinnerCmpt,
    SuricataAlertDetails,
    SuricataAlertsSummary,
  },
  data() {
    return {
      alerts: [],
      currentPage: 1,
      perPage: 10,
      loaded: false,
      filter: null,
      paginationTotalRows: null,
      tableFields: [
        {
          key: 'severityLevel',
          label: 'Severity',
          sortable: true,
          sortDirection: 'asc',
        },
        {
          key: 'signature',
          label: 'Signature',
          sortable: false,
        },
        {
          key: 'count',
          label: 'Count',
          sortable: true,
        },
      ],
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      selectedAlert: [],
      severitySelectValues: [],
      severityMap: {
        1: 'High',
        2: 'Medium',
        3: 'Low',
      },
      severityOptions: ['Low', 'Medium', 'High'],
      url: '/alerts/suricata/alertcounts.json',
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.alerts && this.alerts.length > 0;
    },
    alertsFilter() {
      const alerts = this.alerts.filter((alert) => this.includeBySelectedSeverityType(alert));
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.paginationTotalRows = alerts.length;
      return alerts;
    },
    computedTableFields() {
      return this.tableFields;
    },
    getSelectedAlert() {
      return this.selectedAlert;
    },
    getPaginationTotalRows() {
      return this.paginationTotalRows;
    },
    getTotalSignaturesTooltip() {
      return 'Count of All Unique Alert Signatures';
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  methods: {
    alertStatusVariant(status) {
      const variantMap = {
        1: 'danger',
        2: 'warning',
        3: 'success',
      };
      return variantMap[status];
    },
    getSelectedPageSize(selected) {
      this.currentPage = 1;
      this.perPage = selected;
    },
    onFiltered(filteredItems) {
      this.paginationTotalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selectedAlert = items;
    },
    includeBySelectedSeverityType(alert) {
      if (!this.severitySelectValues.length
        || this.severitySelectValues.includes(this.severityMap[alert.severityLevel])) {
        return true;
      }
      return false;
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, this.httpParams)
        .then((response) => {
          this.alerts = response.data;
          this.paginationTotalRows = response.data.length;
        }).finally(() => { this.loaded = true; });
    },
  },
};
</script>
<style>
.suricata-alert-badge{
  min-width: 60px;
  width: 50%;
}
</style>

<template>
  <div v-if="groupAlerts.length > 0" class="slider-3d mb-n2">
    <swiper
      class="swiper"
      :options="swiperOption"
      @slideChange="onSlide"
      ref="swiper"
    >
      <swiper-slide
        v-for="(alerts, index) in groupAlerts.map((timeAlerts) => timeAlerts.alerts)"
        :key="index"
      >
        <b-row
          id="alerts-row"
          v-for="alert in alerts.slice(start, end)" :key="alert.id"
          class="mx-0-5"
        >
          <b-card class="alert-swiper-card">
            <b-row class="mb-1 mt-1">
              <b-col lg="10" md="10" sm="10" xs="10" class="text-sm">
                {{ formatDate(alert.time) }}
              </b-col>
              <b-col lg="2" md="2" sm="2" xs="2">
                <b-badge pill :variant="getVariant(alert.severity)">{{ alert.layer }}</b-badge>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <span class="text-sm" v-if="alert.type !== ''"><b>{{ alert.type }}: </b>{{ alert.msg }}</span>
                <b-button
                  variant="flat-primary"
                  class="text-sm p-0 ml-1"
                  @click="alertClick(alert)"
                >
                  Details...
                </b-button>
              </b-col>
            </b-row>
            <b-row class="w-100">
              <b-col cols="12">
                <b-pagination
                  aria-controls="alerts-row"
                  v-model="currentPage"
                  :total-rows="alerts.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  align="right"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import moment from 'moment';

import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import alertSeverities from '@/xvisor/constants/alertSeverities';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  props: {
    groupAlerts: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    groupAlerts() {
      this.currentPage = 1;
    },
    currentPage() {
      IssueTimeBarEventBus.$emit('alert-page-change');
    },
  },
  data() {
    return {
      swiperOption: {
        effect: 'coverflow',
        speed: 10,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 70,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      maxPage: 50,
      currentPage: 1,
      perPage: 1,
    };
  },
  computed: {
    start() { return this.currentPage - 1; },
    end() { return this.currentPage; },
  },
  methods: {
    onSlide() {
      this.currentPage = 1;
      const swiperIndex = this.$refs.swiper.$swiper.activeIndex;
      IssueTimeBarEventBus.$emit('swiper-slide-change', {
        start: this.groupAlerts[swiperIndex].start,
        end: this.groupAlerts[swiperIndex].end,
      });
    },
    formatDate(date) {
      return moment(date).format(momentTimeFormat.dateTime);
    },
    getVariant(severity) {
      let variant = 'secondary';
      if (severity === alertSeverities.critical) {
        variant = 'danger';
      }
      if (severity === alertSeverities.warning) {
        variant = 'warning';
      }
      return variant;
    },
    alertClick(alert) {
      this.$emit('alert-click', alert);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-3d {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 50px;

  .swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
      max-width: 800px;
      border-radius: 0.428rem;
    }
  }
}

.alert-swiper-card {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  text-align: left;
  background-position: center;
  background-size: cover;
  background-color: #f8f8f8;
  color: black;
  border-radius: 0.428rem;
}

.dark-layout .alert-swiper-card {
  background-color: #161d31 !important;
  color: white !important;
}
</style>

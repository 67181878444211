<template>
  <b-col cols="12">
    <b-row>
      <b-col cols="12">
        <validation-provider name="Metric Type" rules="required" v-slot="{ errors }">
          <b-form-group label="By*" label-cols-md="4">
            <b-form-select
              v-model="params.metricType"
              :options="metricTypes"
              :state="errors[0] ? false : null"
              @change="emitUpdateSelection"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Metric Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      metricTypes: [
        'bytes',
        'packets',
        'flows',
      ],
      params: {
        metricType: null,
      },
    };
  },
  methods: {
    emitUpdateSelection() {
      this.$emit('change-devices-params', this.params);
    },
  },
};
</script>

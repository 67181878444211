<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="stacked-bar-chart"
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import readableBytes from '@/xvisor/utilities/readableBytes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    url() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded
        && this.series
        && (this.series.inPoints.length > 0 && this.series.outPoints.length > 0);
    },
    allAppPoints() {
      return this.series.inPoints.concat(this.series.outPoints);
    },
    allAppTimes() {
      return this.allAppPoints.map((point) => point.time);
    },
    timeSet() {
      return new Set(this.allAppTimes);
    },
    maxBytes() {
      const allBytes = this.allAppPoints.map((point) => point.value);
      return Math.max(...allBytes);
    },
    timeRangeMax() {
      const maxDate = new Date(
        Math.max(...this.allAppTimes.map((element) => new Date(element))),
      );
      return maxDate;
    },
    timeRangeMin() {
      const minDate = new Date(
        Math.min(
          ...this.allAppTimes.map((element) => new Date(element)),
        ),
      );
      return minDate;
    },
    seriesInPoints() {
      return this.series.inPoints.map((point) => [point.time, point.value]);
    },
    seriesOutPoints() {
      return this.series.outPoints.map((point) => [point.time, point.value]);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.teal4, colorPaletteShade.blue4],
        title: {
          text: 'In/Out',
          textStyle: {
            color: this.toggleColor,
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          renderMode: 'html',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              <div class="apache-echarts-tooltip-timestamp">
                ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              </div>
              <div>
                ${info[0].marker} In: ${readableBytes(info[0].value[1])}<br>
                ${info[1].marker} Out: ${readableBytes(info[1].value[1])}<br>
              </div>
            </div>
          `,
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: 10,
              opacity: 0.2,
            },
          },
          position: (point) => [point[0] + 20, point[1] - 55],
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all',
            },
          ],
        },
        grid: [
          {
            top: '12%',
            left: 50,
            right: 50,
            containLabel: false,
            height: '38%',
          },
          {
            left: 50,
            right: 50,
            containLabel: false,
            top: '54%',
            height: '38%',
          },
        ],
        xAxis: [
          {
            type: 'time',
            min: this.timeRangeMin,
            max: this.timeRangeMax,
            offset: 7,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
                alignWithLabel: true,
              },
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              onZero: true,
            },
            axisLabel: {
              formatter: (value) => moment(value).format(this.timeSeriesFormat),
              fontSize: 9,
              show: false,
            },
            splitLine: {
              show: false,
            },

          },
          {
            gridIndex: 1,
            type: 'time',
            min: this.timeRangeMin,
            max: this.timeRangeMax,
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              onZero: true,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
                alignWithLabel: true,
              },
            },
            position: 'top',
            show: true,
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          {
            type: 'value',
            alignTicks: true,
            inverse: true,
            gridIndex: 1,
            max: this.maxBytes,
            axisLabel: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: 'In Points',
            barWidth: '80%',
            data: this.seriesInPoints,
            type: 'bar',
          },
          {
            name: 'Out Points',
            yAxisIndex: 1,
            xAxisIndex: 1,
            barWidth: '80%',
            data: this.seriesOutPoints,
            type: 'bar',
            showSymbol: false,
          },
        ],
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            monAppId: this.appId,
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    readableBytes,
  },
};
</script>

<style scoped>
.stacked-bar-chart {
  height: 100px !important;
}
</style>

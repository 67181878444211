<template>
  <b-card class="mx-n2 mt-n2">
    <b-row class="mb-1" align-h="between">
      <b-col cols="12" md="6">
        <h4>{{ $t("Available Integrations") }}</h4>
      </b-col>
      <b-col lg="5" md="5" sm="12">
        <b-input-group size="sm" class="input-group-merge" >
          <b-input-group-prepend is-text>
            <b-icon icon="search" font-scale="1" />
          </b-input-group-prepend>
          <b-form-input v-model="filter" type="search" placeholder="Search" />
        </b-input-group>
      </b-col>
    </b-row>
    <div class="d-flex flex-wrap">
      <b-card
        v-for="(integration, index) in filteredIntegrations"
        :key="index"
        v-b-tooltip.hover.top="integration.name"
        class="text-center icon-card mb-2 mx-50"
      >
        <b-icon
          icon="plus"
          font-scale="1"
          class="cursor-pointer float-right mt-n1 mr-n1"
          @click="openModal(index, integration.name)"
        />
        <b-card-text class="icon-name mb-0 mt-1">
          {{ integration.name }}
        </b-card-text>
        <AddAternityModal
          v-if="integration.name.toLowerCase() === integrationTypes.aternity"
          :url="addUrl"
          :ref="`addIntegrationAternity${index}`"
          :integration="integration"
        ></AddAternityModal>
        <AddNewRelicModal
          v-if="integration.name.toLowerCase() === integrationTypes.newRelic"
          :url="addUrl"
          :ref="`addIntegrationNewRelic${index}`"
          :integration="integration"
        ></AddNewRelicModal>
      </b-card>
    </div>
  </b-card>
</template>

<script>
import AddAternityModal from '@/xvisor/components/integrations/AddAternityModal.vue';
import AddNewRelicModal from '@/xvisor/components/integrations/AddNewRelicModal.vue';
import { integrationInfo, integrationTypes } from '@/xvisor/constants/integrations';

export default {
  components: {
    AddAternityModal,
    AddNewRelicModal,
  },
  data() {
    return {
      addUrl: '/integrations/add',
      integrationTypes,
      filter: '',
    };
  },
  computed: {
    filteredIntegrations() {
      const searchQueryIntegration = this.filter.toLowerCase();
      return Object.values(integrationInfo).filter((item) => item.name.toLowerCase().includes(searchQueryIntegration));
    },
  },
  methods: {
    openModal(index, typeName) {
      const reference = `addIntegration${typeName}${index}`;
      if (this.$refs[reference]) {
        this.$refs[reference][0].$refs.modal.show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Overwrites default styling so each integration has a box shadow.
.card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
// Allows integrations to be seen when the dark theme is toggled.
.dark-layout .card .card {
  border: 1px solid #404656;
}
// Fixed-width for all available integrations.
.icon-card {
  width: 140px;
}
</style>

<template>
  <div>
    <b-button size="sm" variant="primary" v-b-modal.add-users class="text-nowrap">
      + Add User(s)
    </b-button>
    <b-modal id="add-users" title="Add Users" size="lg" centered>
      <validation-observer ref="addUser" @keydown.enter="addUsers">
        <b-form>
          <b-row :key="index" v-for="(newUser, index) in newUsers">
            <b-col lg="3" md="3" sm="12">
              <b-form-input
                autofocus
                placeholder="Name"
                v-model="newUser.name"
                class="form-control-merge"
              ></b-form-input>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <validation-provider name="email" rules="required|email" v-slot="{ errors }">
                <b-form-group>
                  <b-form-input
                    autofocus
                    placeholder="Email"
                    type="email"
                    v-model="newUser.email"
                    :state="errors[0] ? false : null"
                    class="form-control-merge"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <validation-provider rules="required" name="Role" v-slot="{ errors }">
                <b-form-group>
                  <b-form-select v-model="newUser.superAdmin" :state="errors[0] ? false : null">
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>Role</b-form-select-option>
                    </template>
                    <!-- TODO: Change boolean super admin role to a role base values.-->
                    <b-form-select-option :value="false">Viewer</b-form-select-option>
                    <b-form-select-option :value="true">Admin</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-button @click="deleteRow(index)" variant="outline-primary">
                <b-icon icon="trash" />
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row align-h="end">
        <b-col cols="3">
          <b-button size="sm" variant="primary" @click="addRow">
            <b-icon icon="plus"></b-icon> Add User
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-left mr-1" @click="addUsers">Submit</b-button>
          <b-button variant="outline-primary" class="float-left" @click="$bvModal.hide('add-users')">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import UsersEventBus from '@/xvisor/bus/UsersEventBus';

export default {
  data() {
    return {
      newUsers: [],
    };
  },
  mounted() {
    this.addRow();
  },
  methods: {
    addRow() {
      this.newUsers.push({
        name: '',
        email: '',
        superAdmin: undefined,
      });
    },
    deleteRow(index) {
      this.newUsers.splice(index, 1);
    },
    addUsers() {
      this.$refs.addUser.validate().then((result) => {
        if (result) {
          this.$http
            .post('/user/addusers', this.newUsers)
            .then(() => {
              this.$bvToast.toast('Successfully added user(s)', { title: 'Notice', variant: 'success' });
              this.newUsers = [];
              this.$bvModal.hide('add-users');
              UsersEventBus.$emit('added-users');
            })
            .catch(() => {
              this.$bvToast.toast('Failed to add user(s)', { title: 'Notice', variant: 'danger' });
            });
        }
      });
    },
  },
};
</script>

<template>
  <div class="mx-n3">
    <b-row>
      <b-col>
        <b-card>
          <DependencyTableTabs
            :timeRange="timeRange"
            :url="urls.componentUrl"
            :trafficRuleUrl="trafficRulesUrl"
            @select-row="handleSelectEvent"
          ></DependencyTableTabs>
          <b-col cols="6">
            <h6>{{ selectedHeader }}</h6>
          </b-col>
          <DependencyTableDetails
            v-if="selectedCmpt"
            :timeRange="timeRange"
            :url="dependencyDetailsUrl"
          ></DependencyTableDetails>
        </b-card>
      </b-col>
    </b-row>
    <div id="panels-row">
      <div class="col-md-15">
        <b-card class="panel" sub-title="Alerts" sub-title-text-variant="">
          <AlertDistribution
            :url="urls.alertDistributionUrl"
            :timeRange="timeRange"
          ></AlertDistribution>
        </b-card>
      </div>
      <div class="col-md-15">
        <b-card sub-title="Health" class="panel" sub-title-text-variant="">
          <HeatmapEchart
            :url="urls.healthRatioUrl"
            :timeRange="timeRange"
            :colorRange="heatmapColorRange"
            height="3rem"
            spacing="mt-3-5"
          ></HeatmapEchart>
        </b-card>
      </div>
      <div class="col-md-15">
        <b-card sub-title="Response Time" class="pt-0 panel" sub-title-text-variant="">
          <DepLineChart
            chartId="Response Time"
            chartUnit="ms"
            :timeRange="timeRange"
            :url="urls.responseMillisUrl"
          ></DepLineChart>
        </b-card>
      </div>
      <div class="col-md-15">
        <b-card sub-title="Throughput" class="pt-0 panel" sub-title-text-variant="">
          <DepLineChart
            chartId="Throughput"
            chartUnit="RPS/PPS"
            :timeRange="timeRange"
            :url="urls.throughputUrl"
          ></DepLineChart>
        </b-card>
      </div>
      <div class="col-md-15">
        <b-card sub-title="Error Rate" class="pt-0 panel" sub-title-text-variant="">
          <DepLineChart
            chartId="Error Rate"
            chartUnit="%"
            :percentage="true"
            :timeRange="timeRange"
            :url="urls.errorRatioUrl"
          ></DepLineChart>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import AlertDistribution from '@/xvisor/components/app/AlertDistribution.vue';
import DepLineChart from '@/xvisor/components/app/DepLineChart.vue';
import DependencyTableTabs from '@/xvisor/components/app/overviewTab/DependencyTableTabs.vue';
import DependencyTableDetails from '@/xvisor/components/app/overviewTab/DependencyTableDetails.vue';
import HeatmapEchart from '@/xvisor/components/HeatmapEchart.vue';
import colorPalette from '@/xvisor/constants/colorPalette';

const CRITICAL_MIN_THRESHOLD = 0.0;
const CRITICAL_THRESHOLD = 0.6;
const WARNING_THRESHOLD = 0.8;
const HEALTHY_MAX_THRESHOLD = 1.0;

export default {
  props: {
    urls: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    appId: {
      type: Number,
      required: true,
    },
  },
  components: {
    AlertDistribution,
    DepLineChart,
    DependencyTableTabs,
    DependencyTableDetails,
    HeatmapEchart,
  },
  data() {
    return {
      loaded: false,
      selectedCmpt: null,
    };
  },
  computed: {
    heatmapColorRange() {
      return {
        precision: 1,
        show: false,
        pieces: [
          {
            max: HEALTHY_MAX_THRESHOLD,
            min: WARNING_THRESHOLD,
            color: colorPalette.green,
          },
          {
            max: WARNING_THRESHOLD,
            min: CRITICAL_THRESHOLD,
            color: colorPalette.yellow,
          },
          {
            max: CRITICAL_THRESHOLD,
            min: CRITICAL_MIN_THRESHOLD,
            color: colorPalette.red,
          },
        ],
      };
    },
    selectedHeader() {
      if (!this.selectedCmpt) return 'No Component Selected';
      return '';
    },
    dependencyDetailsUrl() {
      if (this.selectedCmpt) {
        const { id } = this.selectedCmpt;
        return `/apps/${this.appId}/cmpts/${id}/zones.json`;
      }
      return '';
    },
    trafficRulesUrl() {
      return `/apps/${this.appId}/cmpts/trafficrules.json`;
    },
  },
  methods: {
    handleSelectEvent(item) {
      this.selectedCmpt = item;
    },
  },
};
</script>

<style scoped lang="scss">
#panels-row {
   margin-left: -0.25rem;
   margin-right: -0.25rem;
}
.panel {
  margin-top: -1.5rem;
  height: 200px;
  margin-left: -0.5rem;
  margin-right: -0.5rem;;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}
</style>

<template>
  <e-chart
    :options="this.chartOptions"
    @click="handleClickEvent"
    autoresize
  ></e-chart>
</template>

<script>
import moment from 'moment';

import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import colorPalette from '@/xvisor/constants/colorPalette';
import timeFormat from '@/xvisor/utilities/timeFormat';
import serviceFields from '@/xvisor/constants/serviceFields';

export default {
  props: {
    queries: {
      type: Array,
      required: true,
    },
    topGraphField: {
      type: String,
      required: true,
    },
    bottomGraphField: {
      type: String,
      required: true,
    },
  },
  computed: {
    timeSet() {
      return new Set(this.queries.map((query) => (query.timestamp)));
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    queriesReceived() {
      return this.queries.map((query) => [
        moment(query.timestamp).valueOf(),
        query[this.topGraphField],
      ]);
    },
    queriesSent() {
      return this.queries.map((query) => [
        moment(query.timestamp).valueOf(),
        query[this.bottomGraphField],
      ]);
    },
    toggleColor() {
      const skin = {
        value: this.$store.state.appConfig.layout.skin,
      };
      if (skin.value !== 'dark') return colorPalette.gray;
      // Same color as original chart in dark-layout.
      return colorPalette.lightGray;
    },
    formatTitle() {
      if (this.topGraphField === serviceFields.queriesReceivedCountsShortTerm.value) {
        return 'Short Term Average';
      } if (this.topGraphField === serviceFields.queriesReceivedCountsLongTerm.value) {
        return 'Long Term Average';
      } if (this.topGraphField === serviceFields.clientAttemptTpsPeakShortTerm.value) {
        return 'Client Attempt/Accept';
      } if (this.topGraphField === serviceFields.clientBpsSentPeakShortTerm.value) {
        return 'Client Received/Sent';
      } if (this.topGraphField === serviceFields.serverAcceptTpsPeakShortTerm.value) {
        return 'Server Accept Short/Long Term Average';
      } if (this.topGraphField === serviceFields.serverBpsSentPeakShortTerm.value) {
        return 'Server Received/Sent';
      }
      return '';
    },
    yAxisUnits() {
      if (this.topGraphField === serviceFields.clientBpsSentPeakShortTerm.value) {
        return 'Bits Per Second';
      } if (this.topGraphField === serviceFields.clientBpsReceivedPeakShortTerm.value) {
        return 'Bits Per Second';
      } if (this.topGraphField === serviceFields.serverBpsSentPeakShortTerm.value) {
        return 'Bits Per Second';
      } if (this.topGraphField === serviceFields.serverBpsReceivedPeakShortTerm.value) {
        return 'Bits Per Second';
      }
      return 'Transactions Per Second';
    },
    chartOptions() {
      return {
        title: {
          text: this.formatTitle,
          left: 'center',
          textStyle: {
            color: this.toggleColor,
          },
        },
        color: [colorPaletteShade.teal4, colorPaletteShade.blue4],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
              <div class="apache-echarts-tooltip">
                <div class="apache-echarts-tooltip-timestamp mb-n2">
                  ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
                </div>
                <br>
                ${info[0].marker} ${info[0].seriesName}: ${info[0].value[1].toFixed(3)} ${this.yAxisUnits}
                <br>
                ${info[1].marker} ${info[1].seriesName}: ${info[1].value[1].toFixed(3)} ${this.yAxisUnits}
              </div>
            `,
          axisPointer: {
            type: 'line',
            animation: false,
            label: {
              backgroundColor: colorPalette.tooltipBackground,
            },
          },
        },
        legend: {
          data: [this.seriesNameFormatter(this.topGraphField), this.seriesNameFormatter(this.bottomGraphField)],
          show: true,
          top: 30,
          left: 30,
          textStyle: {
            color: this.toggleColor,
          },
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all',
            },
          ],
        },
        grid: [
          {
            left: 70,
            right: 50,
            height: '38%',
          },
          {
            left: 70,
            right: 50,
            top: '56%',
            height: '38%',
          },
        ],
        xAxis: [
          {
            type: 'time',
            boundaryGap: false,
            offset: 7,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              onZero: true,
            },
            axisLabel: {
              formatter: (value) => moment(value).format(this.timeSeriesFormat),
              fontSize: 9,
            },
          },
          {
            gridIndex: 1,
            type: 'time',
            boundaryGap: false,
            axisLine: {
              onZero: true,
            },
            axisLabel: {
              show: false,
            },
            position: 'top',
            show: true,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: this.yAxisUnits,
            nameLocation: 'center',
            nameGap: 50,
            axisLabel: {
              formatter: (value) => `${value.toFixed(2)}`,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
          },
          {
            type: 'value',
            alignTicks: true,
            inverse: true,
            gridIndex: 1,
            name: this.yAxisUnits,
            nameLocation: 'center',
            nameGap: 50,
            axisLabel: {
              formatter: (value) => `${value.toFixed(2)}`,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
          },
        ],
        series: [
          {
            name: this.seriesNameFormatter(this.topGraphField),
            data: this.queriesReceived,
            type: 'line',
            areaStyle: {},
          },
          {
            name: this.seriesNameFormatter(this.bottomGraphField),
            yAxisIndex: 1,
            xAxisIndex: 1,
            data: this.queriesSent,
            type: 'line',
            areaStyle: {},
          },
        ],
      };
    },
  },
  methods: {
    // Sends timestamp to the ServerDetails component to show query full server details.
    handleClickEvent(event) {
      SecurityEventBus
        .$emit('update-selected-timestamp', this.queries[event.dataIndex].timestamp);
    },
    seriesNameFormatter(name) {
      if (name === serviceFields.queriesReceivedCountsShortTerm.value) {
        return 'Received Short Term';
      } if (name === serviceFields.queriesMadeCountsShortTerm.value) {
        return 'Sent Short Term';
      } if (name === serviceFields.queriesReceivedCountsLongTerm.value) {
        return 'Received Long Term';
      } if (name === serviceFields.queriesMadeCountsLongTerm.value) {
        return 'Sent Long Term';
      } if (name === serviceFields.clientAttemptTpsPeakShortTerm.value) {
        return 'Attempt Short Term';
      } if (name === serviceFields.clientAcceptTpsPeakShortTerm.value) {
        return 'Accept Short Term';
      } if (name === serviceFields.clientBpsSentPeakShortTerm.value) {
        return 'Sent Short Term';
      } if (name === serviceFields.clientBpsReceivedPeakShortTerm.value) {
        return 'Received Short Term';
      } if (name === serviceFields.serverAcceptTpsPeakShortTerm.value) {
        return 'Accept Short Term';
      } if (name === serviceFields.serverAcceptTpsPeakLongTerm.value) {
        return 'Accept Long Term';
      } if (name === serviceFields.serverBpsSentPeakShortTerm.value) {
        return 'Sent Short Term';
      } if (name === serviceFields.serverBpsReceivedPeakShortTerm.value) {
        return 'Received Short Term';
      }
      return 'Sent';
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 550px !important;
}
</style>

import collapseText from '@/xvisor/utilities/collapseText';
import formatNodeDelay from '@/xvisor/components/app/networkTab/pathroutes/formatNodeDelay';
import formatNodeDelayBaseline from '@/xvisor/components/app/networkTab/pathroutes/formatNodeDelayBaseline';

export default class PathroutesNode {
  constructor(
    layoutData,
    geolocationMap,
    sourcenameMap,
    destnameMap,
    nodeMetricMap,
    nodeDelayBaselineMap,
    asnMap,
    sizeOfAsns,
  ) {
    // Layout (the first) pathroutes endpoint.
    this.id = layoutData.id;
    this.ip = layoutData.ip;
    this.coordinate = layoutData.coordinate;
    this.probeIds = layoutData.probeIds;

    // Source Node
    this.isAgent = layoutData.isAgent;

    // Destination Node
    this.isProbeTarget = layoutData.isProbeTarget;
    this.isResponsive = layoutData.isResponsive;

    // Source and Destination name pathroutes endpont.
    if (sourcenameMap[this.id] && this.isAgent) {
      this.name = sourcenameMap[this.id];
    } else if (destnameMap[this.id] && this.isProbeTarget) {
      this.name = destnameMap[this.id];
    } else {
      this.name = null;
    }

    // Node blocks endpoint.
    if (asnMap[this.id]) {
      this.asnInfo = asnMap[this.id];
    } else {
      this.asnInfo = null;
    }
    // Used for coloring different categories on echarts.
    this.size = sizeOfAsns - 1;
    this.echartsIndex = 4;
    this.blockId = null;

    // Geolocation endpoint.
    if (geolocationMap[this.id]) {
      this.city = geolocationMap[this.id].city;
      this.country = geolocationMap[this.id].country;
    } else {
      this.city = null;
      this.country = null;
    }

    // Delay and Loss
    if (nodeMetricMap[this.id]) {
      const [delays, loss] = nodeMetricMap[this.id];
      if (delays) {
        delays.forEach((delay) => {
          if (!this.probeIds.includes(delay.probeId)) {
            this.probeIds.push(delay.probeId);
          }
        });
      }
      this.delays = delays;
      this.avgLoss = Number(loss).toFixed(1);
    } else {
      this.delays = null;
      this.avgLoss = null;
    }

    // Baseline Delay
    if (nodeDelayBaselineMap[this.id]) {
      const baselineDelays = nodeDelayBaselineMap[this.id];
      this.baselineDelays = baselineDelays;
    }
  }

  setBlockInformation(echartsIndex, blockId) {
    this.echartsIndex = echartsIndex;
    this.blockId = blockId;
  }

  getEchartsFormatted() {
    const LOSS_THRESHOLD = 5;
    return {
      name: this.id,
      probeIds: this.probeIds,
      x: this.coordinate.x,
      y: this.coordinate.y,
      // Coloring different nodes on echarts.
      // If node has no asninfo, group it into no domain group representing by size.
      category: this.asnInfo ? this.asnInfo.index : this.size,
      emphasis: {
        label: {
          show: false,
        },
      },
      itemStyle: {
        color: this.isResponsive ? this.color : 'gray',
        borderColor: this.avgLoss > LOSS_THRESHOLD ? 'red' : 'rgba(255, 255, 255, 0)',
        borderWidth: 2,
      },
      label: {
        position: this.isAgent === true ? 'left' : 'right',
        show: this.name && (this.isAgent || this.isProbeTarget),
        formatter: collapseText(`${this.name} (${this.ip})`, 15),
      },
      tooltip: {
        formatter: () => {
          let nameIpFormat = '';
          if (this.name && (this.isAgent || this.isProbeTarget)) {
            nameIpFormat = `${this.name} (${this.ip})`;
          } else {
            nameIpFormat = this.isResponsive ? `${this.ip}` : `${this.id}`;
          }
          let asnFormat = '';
          let asnNameFormat = '';
          if (this.asnInfo && this.asnInfo.asnName > 0) {
            asnNameFormat = `(AS ${this.asnInfo.asnName})`;
          }
          if (this.asnInfo && this.isResponsive) {
            asnFormat = `Domain Name: ${this.asnInfo.orgName} ${asnNameFormat}`;
          }
          let locationFormat = '';
          if (this.city && this.country) {
            locationFormat = `Location: ${this.city}, ${this.country}`;
          } else if (this.country) {
            locationFormat = `Location: ${this.country}`;
          } else if (this.isResponsive) {
            locationFormat = 'Location: Unknown';
          }
          let delayFormat = '';
          if (this.delays && this.delays.length !== 0) {
            delayFormat = formatNodeDelay(this.delays);
          } else {
            delayFormat = 'N/A';
          }
          let bslDelayFormat = '';
          if (this.baselineDelays && this.baselineDelays.length !== 0) {
            bslDelayFormat = formatNodeDelayBaseline(this.baselineDelays);
          } else {
            bslDelayFormat = 'N/A';
          }
          let lossFormat = '';
          if (this.avgLoss && !this.isAgent) {
            if (this.avgLoss > 0) {
              lossFormat = `${this.avgLoss}%`;
            } else {
              lossFormat = 'N/A';
            }
          }
          let highLossFormat = '';
          if (this.avgLoss > LOSS_THRESHOLD) {
            highLossFormat = 'High Loss To Node';
          }
          return `<div class="apache-echarts-tooltip">
                    <div>${nameIpFormat}</div><hr>
                    <div>${asnFormat}</div>
                    <div>${locationFormat}</div>
                    ${delayFormat !== '' && this.isResponsive ? `<div>Delay: ${delayFormat}</div>` : ''}
                    ${bslDelayFormat !== '' && this.isResponsive ? `<div>Baseline Delay: ${bslDelayFormat}</div>` : ''}
                    ${lossFormat !== '' && this.isResponsive ? `<div>Average Loss: ${lossFormat}</div>` : ''}
                    <div style="color: red">${highLossFormat}</div>
                  </div>`;
        },
      },
      symbolSize: 8,
    };
  }
}

<template>
  <div v-if="xtends">
    <b-table
      id="xtends-table"
      class="table-with-min-height"
      responsive
      :fields="tableFields"
      :items="tableItems"
      primary-key="id"
      :per-page="maxItems"
      :current-page="currentPage"
    >
    <template #head(autoUpdate)="">
      Enable Auto-Update
      <b-icon-info-circle id="auto-update-tooltip" class=""/>
      <b-tooltip target="auto-update-tooltip">
        When enabled, xTend will update itself automatically.
      </b-tooltip>
    </template>
    <template class="" #cell(config)="row">
      <b-dropdown class="my-1" style="width: 106px" :text="capitalizeFirstCharacter(row.item.config)">
        <b-dropdown-item @click="openModal(row.item, 'cloud')">Cloud</b-dropdown-item>
        <b-dropdown-item @click="openModal(row.item, 'hybrid')">Hybrid</b-dropdown-item>
        <b-dropdown-item @click="openModal(row.item, 'local')">Local</b-dropdown-item>
      </b-dropdown>
    </template>
    <template class="" #cell(autoUpdate)="row">
      <b-form-checkbox
        inline
        class="mb-1"
        v-model="row.item.autoUpdate"
        name="auto-update"
        @change="enableAutoUpdate(row.item)"
        switch
      >
      </b-form-checkbox>
    </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="tableItems.length"
      :per-page="maxItems"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      ariaControls="xtends-table"
    ></b-pagination>
    <template>
      <XtendConfigModal
        v-for="config in configList"
        :key="config.config"
        @update="updateConfig"
        :ref="config.config"
        :xtend="selectedXtend"
        :configDesc="config.configDesc"
        :configTitle="config.configTitle"
        :config="config.config"
      />
    </template>
  </div>
</template>

<script>
import XtendConfigModal from '@/xvisor/components/settings/xtends/XtendConfigModal.vue';
import { cloud, hybrid, local } from '@/xvisor/components/settings/xtends/configDescriptions';

export default {
  props: {
    xtendId: {
      type: String,
    },
    xtendName: {
      type: String,
    },
  },
  components: {
    XtendConfigModal,
  },
  data() {
    return {
      xtends: {},
      maxItems: 4,
      currentPage: 1,
      tableFields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'config', label: 'Configuration', sortable: true },
        { key: 'autoUpdate', label: 'Auto-Update', sortable: true },
      ],
      selectedXtend: undefined,
      configList: [
        {
          config: 'cloud',
          configDesc: cloud,
          configTitle: 'Cloud',
        },
        {
          config: 'hybrid',
          configDesc: hybrid,
          configTitle: 'Hybrid',
        },
        {
          config: 'local',
          configDesc: local,
          configTitle: 'Local',
        },
      ],
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    tableItems() {
      return Object.keys(this.xtends).length > 0 ? Object.values(this.xtends) : [];
    },
  },
  methods: {
    capitalizeFirstCharacter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    syncXtends() {
      this.xtendsUpdate = JSON.parse(JSON.stringify(this.xtends));
    },
    openModal(item, modal) {
      this.selectedXtend = item;
      const modalRef = this.$refs[modal][0];
      if (modalRef) {
        modalRef.showModal = true;
      }
    },
    updateConfig(xtend, newConfigValue) {
      const xtendUpdated = xtend;
      xtendUpdated.config = newConfigValue;
      this.httpPatch(xtendUpdated, 'config');
    },
    enableAutoUpdate(updateXtend) {
      this.httpPatch(updateXtend, 'autoUpdate');
    },
    httpGet() {
      this.$http
        .get('/settings/xtends/list.json')
        .then((response) => {
          this.xtends = response.data.reduce((acc, {
            id, name, config, autoUpdate,
          }) => {
            acc[id] = {
              name,
              config,
              autoUpdate,
              id,
            };
            return acc;
          }, {});
        });
    },
    httpPatch(xtendToUpdate, field) {
      const path = `/settings/xtends/${xtendToUpdate.id}/config`;
      const body = {};
      body[field] = xtendToUpdate[field];
      this.$http
        .patch(path, body)
        .then((response) => {
          this.$bvToast.toast('Successfully updated xTend Configuration', { title: 'Notice', variant: 'success' });
          const { id } = response.data;
          this.xtends[id] = response.data;
        })
        .catch(() => {
          this.$bvToast.toast('Failed to udpate xTend Configuration', { title: 'Notice', variant: 'danger' });
        });
    },
  },
};
</script>

<template>
  <b-card no-body class="mt-1">
    <b-row class="mx-2 mt-2">
      <h4 class="mb-0">Summary</h4>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <e-chart
          :options="topHostsChartOptions"
          autoresize
          class="top-hosts-chart"
        ></e-chart>
      </b-col>
      <b-col>
        <e-chart
          :options="topProtosChartOptions"
          autoresize
          class="top-protos-chart"
        ></e-chart>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mx-2 mt-3 mb-2 justify-content-md-center">
      <b-col>
        <h5 class="text-center mb-1">
          Top
          <b-form-select
            :value="isDestination"
            v-on:change="getSelectedAlertDirection"
            :options="alertDirectionOptions"
            style="width: 150px;"
            size="md"
          ></b-form-select>
          Countries
        </h5>
        <b-table
          v-if="topCountriesItems.length"
          ref="countryTable"
          class="table-with-actions-min-height"
          responsive
          outlined
          select-mode="single"
          selectable
          @row-selected="onCountryRowSelected"
          :items="topCountriesItems"
          :fields="topCountriesTableFields"
        >
        </b-table>
        <h6
          v-else
          class="text-center mt-1"
        >
          Top Countries Data Unavailable
        </h6>
      </b-col>
      <b-col>
        <e-chart
          :options="topCitiesChartOptions"
          autoresize
          class="top-protos-chart"
        ></e-chart>
      </b-col>
      <b-col>
        <e-chart
          :options="topAlertCategoriesChartOptions"
          autoresize
          class="top-protos-chart"
        ></e-chart>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import 'echarts/lib/chart/pie';

import granularity from '@/xvisor/utilities/granularity';
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      topHostsUrl: '/alerts/suricata/summary/tophosts.json',
      topProtosUrl: '/alerts/suricata/summary/topprotos.json',
      topCountriesUrl: '/alerts/suricata/summary/topcountries.json',
      loaded: false,
      selectedCountry: null,
      isDestination: true,
      alertDirectionOptions: [
        { value: true, text: 'Destination' },
        { value: false, text: 'Origin' },
      ],
      topCountriesTableFields: [
        {
          key: 'countryName',
          label: 'Country',
        },
        {
          key: 'count',
          label: 'Alerts',
        },
      ],
      topHostsData: {
        raw: [],
        timestamps: [],
        hosts: [],
        counts: [],
      },
      topProtosData: {
        raw: [],
        data: [],
      },
      topCountriesData: {
        raw: [],
        cities: {},
        alertCategories: {},
      },
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    isDestination() {
      this.httpGetTopCountries();
    },
  },
  computed: {
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    topCountriesItems() {
      return this.topCountriesData.raw;
    },
    topHostsChartTitle() {
      return this.topHostsDataSeries
        && this.topHostsDataSeries.length ? 'Top Alerting Hosts' : 'Top Hosts Data Unavailable';
    },
    topHostsChartOptions() {
      return {
        title: {
          text: this.topHostsChartTitle,
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontStyle: 'normal',
            fontSize: 14,
          },
        },
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.orange5,
          colorPaletteShade.indigo4,
          colorPaletteShade.purple4,
          colorPaletteShade.green4,
        ],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class='apache-echarts-tooltip'>
              Number of Alerts Per Host <br>
              ${this.tooltipFormatter(info)}
            </div>
          `,
        },
        grid: {
          left: 100,
          right: 100,
          top: 50,
          bottom: 50,
        },
        legend: {
          bottom: 4,
          textStyle: {
            color: this.toggleColor,
          },
        },
        xAxis: {
          type: 'category',
          data: this.topHostsData.timestamps,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(momentTimeFormat.dateTime),
            fontSize: 9,
          },
        },
        yAxis: {
          type: 'value',
          name: 'Alert Count',
          nameLocation: 'center',
          nameGap: 35,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            fontSize: 9,
          },
        },
        series: this.topHostsDataSeries,
      };
    },
    topHostsDataSeries() {
      return this.topHostsData.hosts.map((name, sid) => {
        const series = {
          name,
          type: 'bar',
          stack: 'total',
          barWidth: '30%',
          label: {
            show: false,
          },
          data: this.topHostsData.counts[sid],
        };
        return series;
      });
    },
    topProtosChartTitle() {
      return this.topProtosData.data
        && this.topProtosData.data.length
        ? 'Top Network Transports' : 'Top Network Transports Data Unavailable';
    },
    topProtosChartOptions() {
      return {
        title: {
          text: this.topProtosChartTitle,
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontStyle: 'normal',
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 4,
          textStyle: {
            color: this.toggleColor,
          },
        },
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.orange5,
          colorPaletteShade.indigo4,
          colorPaletteShade.purple4,
          colorPaletteShade.green4,
        ],
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.topProtosData.data,
          },
        ],
      };
    },
    topCitiesChartTitle() {
      return this.topCountriesData.cities[this.selectedCountry]
        && this.topCountriesData.cities[this.selectedCountry].length
        ? `Top Alerting Cities In ${this.selectedCountry}`
        : 'No Top Cities Data Available';
    },
    topCitiesChartOptions() {
      return {
        title: {
          text: this.topCitiesChartTitle,
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontStyle: 'normal',
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 4,
          textStyle: {
            color: this.toggleColor,
          },
        },
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.orange5,
          colorPaletteShade.indigo4,
          colorPaletteShade.purple4,
          colorPaletteShade.green4,
        ],
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.topCountriesData.cities[this.selectedCountry],
          },
        ],
      };
    },
    topAlertCategoriesChartTitle() {
      return this.topCountriesData.alertCategories[this.selectedCountry]
        && this.topCountriesData.alertCategories[this.selectedCountry].length
        ? `Top Alert Categories In ${this.selectedCountry}`
        : 'No Top Alert Categories Available';
    },
    topAlertCategoriesChartOptions() {
      return {
        title: {
          text: this.topAlertCategoriesChartTitle,
          left: 'center',
          textStyle: {
            color: '#ffffff',
            fontStyle: 'normal',
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: (info) => `
            <div class='apache-echarts-tooltip'>
              <b>Top Signatures In This Category</b><br>
              ${this.categoryTooltipFormatter(info)}
            </div>
          `,
        },
        legend: {
          bottom: 6,
          textStyle: {
            color: this.toggleColor,
          },
        },
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.orange5,
          colorPaletteShade.indigo4,
          colorPaletteShade.purple4,
          colorPaletteShade.green4,
        ],
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: this.topCountriesData.alertCategories[this.selectedCountry],
          },
        ],
      };
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
          isDestination: this.isDestination,
        },
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.topHostsUrl, this.httpParams),
          this.$http.get(this.topProtosUrl, this.httpParams),
          this.$http.get(this.topCountriesUrl, this.httpParams),
        ])
        .then(axios.spread((topHostsResponse, topProtosResponse, topCountriesResponse) => {
          this.topHostsData.raw = topHostsResponse.data;
          this.topProtosData.raw = topProtosResponse.data;
          this.topCountriesData.raw = topCountriesResponse.data;
        }))
        .finally(() => {
          this.processTopHostsData();
          this.processTopProtosData();
          this.processTopCountriesData();
          if (this.topCountriesItems.length) {
            this.$refs.countryTable.selectRow(0);
          }
          this.loaded = true;
        });
    },
    httpGetTopCountries() {
      this.loaded = false;
      this.$http.get(this.topCountriesUrl, this.httpParams)
        .then((response) => { this.topCountriesData.raw = response.data; })
        .finally(() => {
          this.processTopCountriesData();
          if (this.topCountriesItems.length) {
            this.$refs.countryTable.selectRow(0);
          }
          this.loaded = true;
        });
    },
    getSelectedAlertDirection(selected) {
      this.isDestination = selected;
    },
    processTopHostsData() {
      const timestamps = [];
      const hosts = [];
      const counts = [];
      this.topHostsData.raw.forEach((host) => {
        hosts.push(host.name);
        const row = [];
        host.points.forEach((point) => {
          if (!timestamps.includes(point.time)) {
            timestamps.push(point.time);
          }
          row.push(point.value);
        });
        counts.push(row);
      });
      this.topHostsData = {
        ...this.topHostsData,
        timestamps,
        hosts,
        counts,
      };
    },
    processTopProtosData() {
      const data = [];
      this.topProtosData.raw.forEach((item) => {
        data.push(({
          name: item.proto,
          value: item.count,
        }));
      });
      this.topProtosData = {
        ...this.topProtosData,
        data,
      };
    },
    processTopCountriesData() {
      const cities = {};
      const alertCategories = {};
      this.topCountriesData.raw.forEach((item) => {
        cities[item.countryName] = item.cities.map((el) => {
          const city = {
            name: el.cityName,
            value: el.count,
          };
          return city;
        });
        alertCategories[item.countryName] = item.categories.map((el) => {
          const category = {
            name: el.alertCategory,
            value: el.count,
            alerts: el.alerts,
          };
          return category;
        });
      });
      this.topCountriesData.cities = cities;
      this.topCountriesData.alertCategories = alertCategories;
    },
    categoryTooltipFormatter(info) {
      let tooltipMessage = '';
      info.data.alerts.forEach((element) => {
        tooltipMessage += `
          ${element.signature}: ${element.count}
          <br>
        `;
      });
      return tooltipMessage;
    },
    tooltipFormatter(info) {
      let tooltipMessage = '';
      info.forEach((element) => {
        tooltipMessage += `
          ${element.marker} ${element.seriesName}: ${element.value}
          <br>
        `;
      });
      return tooltipMessage;
    },
    onCountryRowSelected(items) {
      this.selectedCountry = items[0].countryName;
    },
  },
};
</script>
<style scoped>
.top-hosts-chart {
  height: 400px !important;
}
.top-protos-chart {
  height: 400px !important;
}
</style>

<template>
  <e-chart
    :options="chartOptions"
    autoresize
    class="radial-progress-bar"
  ></e-chart>
</template>

<script>
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    inputData: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        title: {
          left: 'center',
          top: 'center',
          text: `${this.label} \n ${this.inputData}%`,
          textStyle: {
            fontSize: 11,
            color: this.toggleColor,
          },
        },
        polar: {
          radius: ['75%', '100%'],
        },
        angleAxis: {
          max: 100,
          startAngle: 90,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        series: {
          type: 'bar',
          data: [this.inputData],
          coordinateSystem: 'polar',
          color: colorPalette.blue,
          label: {
            show: true,
            position: 'middle',
            formatter: '{b}: {c}%',
          },
          showBackground: true,
          backgroundStyle: {
            color: colorPalette.lightgrey,
            opacity: 0.3,
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.radial-progress-bar {
  height: 80px;
}
</style>

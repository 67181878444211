<template>
  <div>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <h5>Utilization</h5>
        <Heatmap :url="urls.interfaceUtilizationsUrl" :timeRange="timeRange"></Heatmap>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <h5>Alert Distribution</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>Interface Selector</h5>
        <IfaceSelector :urls="urls" :timeRange="timeRange"></IfaceSelector>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Heatmap from '@/xvisor/components/infra/interfaces/Heatmap.vue';
import IfaceSelector from '@/xvisor/components/infra/interfaces/IfaceSelector.vue';
import infraIpsMixin from '@/xvisor/components/infra/infraIpsMixin';

export default {
  components: {
    Heatmap,
    IfaceSelector,
  },
  mixins: [infraIpsMixin],
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  computed: {
    urls() {
      return {
        ifacesUrl: `/infra/routers/${this.infraIp}/ifaces/list.json`,
        interfaceUtilizationsUrl: `/infra/routers/${this.infraIp}/ifaceutilizations.json`,
      };
    },
  },
};
</script>

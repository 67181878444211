<template>
    <div v-if="hasAppData" class="">
    <b-row class="mb-1" align-v="center" align-h="end">
        <b-col lg="6" md="12" sm="12">
        <SearchBar>
            <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
        </SearchBar>
        </b-col>
    </b-row>
    <b-table
        hover
        pagination
        responsive
        :class="reduceTableHeight ? 'table-height-half' : ''"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        :items="appSeries"
        primaryKey="name"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="filterOn"
        @filtered="onFiltered"
    >
        <template #cell(bytes)="data">
        {{ readableBytes(data.value) }}
        </template>
        <template #cell(isMon)="data">
        <b-button
            @click="appLink(data.item.id)"
            variant="flat-primary"
            v-if="data.value"
        >
            <b-icon icon="chevron-double-right" />
        </b-button>
        <b-button
            @click="addMonApp(data.item.addFromTemplUrl)"
            variant="flat-primary"
            v-else
        >
            <b-icon icon="bookmark" />
        </b-button>
        </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        class="my-1"
    />
    </div>
    <div v-else-if="appsLoaded">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import readableBytes from '@/xvisor/utilities/readableBytes';

export default {
  components: {
    SearchBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    reduceTableHeight: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      appSeries: [],
      appsLoaded: false,
      currentPage: 1,
      perPage: this.reduceTableHeight ? 3 : 5,
      totalRows: 0,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'App Name', sortable: true },
        { key: 'bytes', label: 'Traffic', sortable: true },
        { key: 'isMon', label: '' },
      ],
    };
  },
  computed: {
    hasAppData() {
      return this.appsLoaded && this.appSeries && this.appSeries.length > 0;
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          zoneId: this.zoneId,
        },
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
    reduceTableHeight() {
      this.perPage = this.reduceTableHeight ? 3 : 5;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.appsLoaded = false;
      this.$http
        .get(this.url, this.httpParams)
        .then((response) => {
          this.appSeries = response.data;
          this.totalRows = this.appSeries.length;
        })
        .finally(() => { this.appsLoaded = true; });
    },
    /** Add a monitored app given a URL with the desired app template ID encoded. */
    addMonApp(url) {
      this.$http
        .post(url)
        .then(() => {
          this.$bvToast.toast('Successfully added app', { title: 'Notice', variant: 'success' });
          this.httpGet();
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete user', { title: 'Notice', variant: 'danger' });
        });
    },
    appLink(id) {
      this.$router.push({
        name: 'app',
        params: { id },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    readableBytes,
  },
};
</script>

<style>
.table-height-half {
  max-height: 49vh;
}
</style>

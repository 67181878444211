<template>
  <div>
    <h2 class="mb-2">{{ $t("Policy Center") }}</h2>
    <b-tabs
      vertical
      lazy
      pills
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab>
        <template #title>
          <b-icon icon="file-earmark-lock" class="mr-1" />
          <span class="font-weight-bold">{{ tabs.authorization.name }}</span>
        </template>
        <SsidPolicy></SsidPolicy>
        <DnsPolicy></DnsPolicy>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon icon="exclamation-triangle" class="mr-1" />
          <span class="font-weight-bold">{{ tabs.alert.name }}</span>
        </template>
        <AlertPolicy :url="policyUrls.alerts"></AlertPolicy>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AlertPolicy from '@/xvisor/components/policyCenter/AlertPolicy.vue';
import SsidPolicy from '@/xvisor/components/policyCenter/ssid/SsidPolicy.vue';
import DnsPolicy from '@/xvisor/components/policyCenter/dns/DnsPolicy.vue';

export default {
  data() {
    return {};
  },
  components: {
    AlertPolicy,
    SsidPolicy,
    DnsPolicy,
  },
  computed: {
    tabs() {
      return {
        authorization: { name: 'Authorized Entities', index: 0 },
        alert: { name: 'Alert Policies', index: 1 },
      };
    },
    policyUrls() {
      return {
        alerts: {
          app: '/apps/monapps/list.json',
        },
      };
    },
  },
};
</script>

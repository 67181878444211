import Vue from 'vue';
import vSelect from 'vue-select';
import {
  BootstrapVueIcons,
  ModalPlugin,
  ToastPlugin,
  TablePlugin,
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import Main from './Main.vue';

import 'popper.js';
import 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css';

import 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import 'swiper/css/swiper.css';

import 'leaflet/dist/leaflet.css';
import 'vue-select/dist/vue-select.css';

// Global Components
import './globalComponents';
import './globalLeafletInit';
import './globalVeeRules';
import './globalEchart';
import './libs/axios';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

// PrismJS.
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';

// BSV Plugin Registration
Vue.use(BootstrapVueIcons);
Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(ToastPlugin, {
  BToast: { toaster: 'b-toaster-bottom-right', title: 'Notice' },
});

// Composition API
Vue.use(VueCompositionAPI);

Vue.component('v-select', vSelect);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');
require('@/assets/scss/margins.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(Main),
}).$mount('#app');

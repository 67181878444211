<template>
  <b-row>
    <b-col lg="3" md="3" sm="12" order="1">
      <b-card class="sticky-card">
        <h5 class="kb-title">
          <b-icon icon="info-circle" class="mr-1" />
          Covered Topics
        </h5>
        <vue-perfect-scrollbar :settings="scrollSettings" class="scroll-style">
          <ul class="mt-1">
            <li v-for="(topic, index) in relatedTopics" :key="index" class="ml-n2">
              <b-link :href="`#${topic.href}`" class="list-item">{{ topic.name }}</b-link>
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-card>
    </b-col>
    <b-col lg="9" md="9" sm="12" order="2">
      <b-card>
        <h1 class="mb-1" align="center">Introduction to xVisor</h1>
        <div id="Content" class="question-content">
          <IntroXvisorHtmlContent></IntroXvisorHtmlContent>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import IntroXvisorHtmlContent from '@/xvisor/views/knowledgeBase/components/IntroXvisorHtmlContent.vue';

export default {
  components: {
    IntroXvisorHtmlContent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 50,
      },
      relatedTopics: [
        { name: 'Introduction', href: '1-introduction' },
        { name: 'Ennetix xVisor Overview', href: '1-1-ennetix-xvisor-overview' },
        { name: 'XOME Deployment and Data Collection', href: '1-1-1-xome-deployment-and-data-collection' },
        { name: 'xVisor Signup and Installation Process', href: '1-2-ennetix-xvisor-signup-and-installation-process' },
      ],
    };
  },
};
</script>

<!-- InfoCard.vue -->
<template>
  <b-col class="mb-3">
    <b-card border-variant="dark" class="fixed-height-card">
      <template #header>
        <div class="d-inline-flex align-items-center">
          <b-icon :icon="icon" class="mr-2" font-scale="1.3"></b-icon>
          <span class="fs-1 font-weight-bold">{{ title }}</span>
        </div>
      </template>
      <b-table
        :items="items"
        class="table-borderless"
        :small="small"
        :thead-class="'d-none'"
        :fields="tableFields"
      >
        <template #cell(key)="data">
          <span class="font-weight-bold">{{ data.item.key }}</span>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      small: true,
    };
  },
  computed: {
    tableFields() {
      return [
        { key: 'key', label: '', sortable: false },
        { key: 'value', label: '', sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.fixed-height-card {
  height: 200px;
}
.font-weight-bold {
  font-weight: bold;
}
</style>

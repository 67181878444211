<template>
  <Multiselect
    :multiple="true"
    placeholder="Filter by threat"
    :value="selectedThreatPills"
    :options="threatOptions"
    @select="onThreatFilterSelect"
    @remove="onThreatFilterRemove"
    :show-labels="false"
    :closeOnSelect="false"
    :customLabel="getThreatLabel"
  ></Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    threatOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedThreatFilters: [],
      selectedThreatPills: [],
      threatTypeToLabel: {},
    };
  },
  watch: {
    threatOptions: {
      immediate: true,
      handler(updatedThreatOptions) {
        // threatTypeToLabel is dynamically made to accomodate future threat feeds.
        updatedThreatOptions.forEach((option) => {
          this.threatTypeToLabel[option.threatType] = option.threatName;
        });
      },
    },
  },
  methods: {
    getThreatLabel({ threatName, threatCount }) {
      // If threatCount is undefined, render just the threatName (used for showing pills).
      return `${threatName} ${threatCount ? `(${threatCount})` : ''}`;
    },
    getThreatSelectionPills() {
      // After user selects a filter, a pill array is made and functions as component state.
      this.selectedThreatPills = this.selectedThreatFilters.map((filter) => {
        const pillContent = {
          threatName: this.threatTypeToLabel[filter],
          threatType: filter,
        };
        return pillContent;
      });
    },
    onThreatFilterSelect({ threatType }) {
      // Currently is an OR condition for multiple filters, not AND.
      if (threatType === 'all') {
        this.selectedThreatFilters = [threatType];
      } else {
        this.selectedThreatFilters.push(threatType);
        this.selectedThreatFilters = this.selectedThreatFilters.filter((threat) => threat !== 'all');
        if (this.selectedThreatFilters.length === this.threatOptions.length - 1) {
          this.selectedThreatFilters = ['all'];
        }
      }
      this.onThreatFilterChange();
    },
    onThreatFilterRemove({ threatType }) {
      if (threatType === 'all') {
        this.selectedThreatFilters = [];
      } else {
        this.selectedThreatFilters = this.selectedThreatFilters.filter((threat) => threat !== threatType);
      }
      this.onThreatFilterChange();
    },
    onThreatFilterChange() {
      this.getThreatSelectionPills();
      this.$emit('threatFilterChange', this.selectedThreatFilters);
    },
  },
};
</script>

<template>
  <b-modal
    centered
    title="Get XOME Instructions"
    @ok="getInstructions"
    ref="modal"
  >
    Getting instructions will invalidate existing credentials.
    Would you like to continue?
  </b-modal>
</template>

<script>
export default {
  props: {
    onConfirm: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getInstructions() {
      this.onConfirm();
    },
  },
};
</script>

<template>
  <e-chart
    :options="chartOptions"
    autoresize
    @mouseover="(event) => triggerOverEvent(event)"
    @mouseout="(event) => triggerLeaveEvent(event)"
    class="trans-radial-chart"
  ></e-chart>
</template>

<script>
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    data: {
      type: Object,
      required: false,
    },
    categories: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      titleConfig: null,
    };
  },
  computed: {
    categorySeries() {
      if (this.data) {
        return this.categories.map((category) => {
          const found = this.data.counts.find((count) => count.name === category);
          return found ? found.count : 0;
        });
      }
      return [];
    },
    series() {
      return this.categorySeries.map((point, index) => {
        const obj = {
          value: parseFloat(((point / this.data.total) * 100).toFixed(2)),
          amount: point,
          itemStyle: {
            color: this.colors[index],
          },
        };
        return obj;
      });
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    titleFormatter() {
      if (this.titleConfig !== null) {
        return `${this.titleConfig.data.amount}\n${this.titleConfig.data.value.toFixed(2)}%`;
      }
      return `Total\n${this.data.total}`;
    },
    chartOptions() {
      return {
        title: {
          left: 'center',
          top: 'center',
          text: this.titleFormatter,
          textStyle: {
            fontSize: 12,
            color: this.toggleColor,
          },
        },
        polar: {
          radius: ['55%', '100%'],
        },
        angleAxis: {
          max: 100,
          startAngle: 90,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        series: {
          type: 'bar',
          data: this.series,
          coordinateSystem: 'polar',
          color: colorPalette.blue,
          label: {
            show: true,
            position: 'middle',
          },
          showBackground: true,
          backgroundStyle: {
            color: colorPalette.lightgrey,
            opacity: 0.3,
          },
        },
      };
    },
  },
  methods: {
    // Custom event handlers are needed to create the dynamic title changing effect
    triggerOverEvent(event) {
      this.titleConfig = event;
    },
    triggerLeaveEvent() {
      this.titleConfig = null;
    },
  },
};
</script>

<style scoped>
.trans-radial-chart{
  height: 90px;
  width: 150px !important;
}
</style>

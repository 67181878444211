<template>
  <b-button
    class="btn-icon"
    variant="flat"
    v-b-tooltip.hover.variant.bottom
    title="Delete"
    @click="deleteItem()"
  >
    <b-icon icon="trash" />
  </b-button>
</template>

<script>
export default {
  props: {
    url: {
      type: Number,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteItem() {
      this.$http
        .delete(this.url)
        .then(() => {
          this.$bvToast.toast(`${this.itemType} successfully deleted.`, { variant: 'success' });
          this.$emit('deleted');
        })
        .catch(() => {
          this.$bvToast.toast(`Failed to delete ${this.itemType.toLowerCase()}.`, { variant: 'danger' });
        });
    },
  },
};
</script>

<template>
  <b-table :items="zones" :fields="fields" striped>
    <template #cell(subnets)="row">
      {{ row.value && row.value.length ? row.value.join(', ') : "" }}
    </template>
    <template #cell(actions)="row">
      <ZoneAction :zone="row.item" class="float-right"></ZoneAction>
    </template>
  </b-table>
  <!-- TODO: Consider including pagination for the table. -->
</template>

<script>
import ZoneAction from '@/xvisor/components/settings/zones/ZoneAction.vue';

export default {
  props: {
    zones: {
      type: Array,
      required: true,
    },
  },
  components: {
    ZoneAction,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Zone', sortable: true },
        { key: 'subnets', label: 'Subnets' },
        { key: 'actions', label: '' },
      ],
    };
  },
};
</script>

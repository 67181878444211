<template>
  <div>
    <b-card
      no-body
      class="knowledge-base-bg text-center undo-color-overlay change-padding"
      :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
    >
      <b-card-body class="card-body">
        <h2 class="text-primary">FAQ</h2>
        <b-row align-h="center" align-v="center">
          <b-col lg="5" md="10" sm="12">
            <SearchBar size="md">
              <template #input>
                <b-form-input v-model="faqSearchQuery" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <app-collapse
      accordion
      type="margin"
      class="mt-2"
    >
      <app-collapse-item
        v-for="(item, index) in filteredSearchQuery"
        :title="item.question"
        :key="index"
        no-body
        class="mb-1"
      >
        <p class="ml-2 mr-3">{{ item.answer }}</p>
        <b-img v-if="item.img" :src="item.img" :alt="item.img" class="mb-1 mr-1 ml-2 w-75" />
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    SearchBar,
  },
  data() {
    return {
      faqSearchQuery: '',
      qas: [
        {
          question: 'What is Ennetix xVisor?',
          answer: `
            xVisor from Ennetix is the artificial intelligence (AI) powered next-generation IT 
            Operations Management (ITOM) solution for end-to-end Deterministic outcome from Digital 
            Operations. xVisor offers a comprehensive 360o view combining both the "inside-out and 
            outside-in" perspectives. Leveraging a huge amount of data drawn from multiple sources, 
            the platform drills down to individual user and application level to diagnose anomalies in
            real time and provides predictive automated remediation.
          `,
        },
        {
          question: 'How does xVisor work?',
          answer: `
            xVISOR is delivered via a SaaS platform. A light-weight machine is deployed in your 
            operating environment that transmits specially-crafted synthetic probes to collect performance
            data over hybrid cloud infrastructures, and collects and processes transaction-level data from
            the network. In addition, granular data from all devices and nodes in the entire delivery path
            of all application. This essentially translates into a comprehensive and real-time automated
            discovery of the entire network topology, devices, user and the applications that are being
            accessed. These data are then crunched through proprietary Artificial Intelligence Inference
            Engines to yield insights that are accessible through a visual interface or APIs.
          `,
        },
        {
          question: 'What will xVisor do for me?',
          answer: `
            Whether you are managing a digital enterprise, operating a web-based service, or
            managing a network that spans either public, hybrid or cloud infrastructures — xVISOR will
            help you to map out what’s happening in your network in real-time and improve user-experience,
            based on detailed, current as well as historic information.
          `,
          // eslint-disable-next-line global-require
          img: require('@/xvisor/views/knowledgeBase/assets/faq/ennetix-solution-requirements.png'),
        },
        {
          question: 'How long does it take to deploy xVisor?',
          answer: 'A typical deployment takes 1-2 hours',
        },
        {
          question: 'How soon can I expect xVisor benefits to start?',
          answer: 'Within 5 minutes of deployment',
        },
        {
          question: 'Where will my data be stored?',
          answer: `
            xVisor can be deployed on both on-prem and public cloud. So, customers can decide
            where they would like to store their data.
          `,
        },
      ],
    };
  },
  computed: {
    filteredSearchQuery() {
      const faqSearchQueryLower = this.faqSearchQuery.toLowerCase();
      return this.qas.filter((item) => (
        item.question.toLowerCase().includes(faqSearchQueryLower)
          || item.answer.toLowerCase().includes(faqSearchQueryLower)
      ));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.undo-color-overlay {
  background-color: rgba(white, 0.05) !important;
}
.drop-down-button {
  color: white;
  width: 100%;
  text-align: left;
}
.drop-down-button:hover {
  color: #7367f0;
}
</style>

<template>
  <b-table striped hover :fields="fields" :items="dnsFormatted">
    <template #cell(action)="row">
      <DeleteDnsIcon
        class="float-right"
        :dns="row.item.dns"
      ></DeleteDnsIcon>
    </template>
  </b-table>
</template>

<script>
import DeleteDnsIcon from '@/xvisor/components/policyCenter/dns/DeleteDnsIcon.vue';
import DnsEventBus from '@/xvisor/bus/DnsEventBus';

export default {
  components: {
    DeleteDnsIcon,
  },
  data() {
    return {
      dnsListUrl: '/policysettings/dnsServer/list.json',
      dns: [],
      fields: [
        { key: 'dns', label: 'DNS IP' },
        { key: 'action', label: '' },
      ],
    };
  },
  mounted() {
    DnsEventBus.$on('changed', this.httpGet);
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.dnsListUrl)
        .then((response) => {
          this.dns = response.data;
        });
    },
  },
  computed: {
    dnsFormatted() {
      const formatted = this.dns.map((dns) => ({ dns: dns.Ip }));
      return formatted;
    },
  },
};
</script>

import $ from 'jquery';

const DEFAULT_POPOVER_CLASS = 'popover';
const CLOSE_POPOVER_CLASS = 'close-popover';
const CLOSE_SYMBOL = '\u00D7';

const CLOSE_BUTTON = `<button class="pull-right ${CLOSE_POPOVER_CLASS}">${CLOSE_SYMBOL}</button>`;

export default {
  // Register a popover given a jQuery selector. The options object will be passed to the popover construction.
  registerPopover($element, options) {
    let opts = options;
    if (opts == null) opts = {};
    if (opts.selector == null) opts.selector = '[data-toggle="popover"]';
    if (opts.trigger == null) opts.trigger = 'hover';
    if (opts.class == null) opts.class = '';
    if (opts.close == null) opts.close = false;
    const closeButton = opts.close ? CLOSE_BUTTON : '';
    const popoverOptions = {
      selector: opts.selector,
      container: 'body',
      html: true,
      template: `
        <div class="${DEFAULT_POPOVER_CLASS} ${opts.class}" role="tooltip">
          ${closeButton}
          <h3 class="popover-title"></h3>
          <div class="popover-content"></div>
        </div>
      `,
    };
    if (opts.trigger.length > 0) popoverOptions.trigger = opts.trigger;
    $element.popover(popoverOptions);
  },

  // Includes a workaround for Bootstrap 4.3.1 to ensure popover state is closed.
  closePopover($element) {
    const data = $element.popover('hide').data('bs.popover');
    if (data != null && data.inState != null) data.inState.click = false;
  },

  closeAllPopovers() {
    this.closePopover($(`.${DEFAULT_POPOVER_CLASS}`));
  },
};

<template>
  <!-- This component was created as Bootstrap pagination component
  does not allow removal of page numbers and just arrows were needed. -->
  <div class="pagination-container d-flex align-items-center justify-content-end pr-2 mb-1">
    <b-dropdown
      dropup
      :text="documentCounterText"
      v-if="sortOptions.length"
    >
      <b-dropdown-header
        id="dropdown-header-label"
        class="p-1"
        v-if="sortOptions.length"
      >
        Sort By
      </b-dropdown-header>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item
        v-for="option in sortOptions"
        :key="option.key"
        :active="isSortOptionActive(option)"
        @click="onSortOptionClick(option)"
      >
        {{ option.label }}
      </b-dropdown-item>
    </b-dropdown>
    <div class="counter-container" v-else>
      {{ this.documentCounterText }}
    </div>
    <div class="arrows-container d-flex flex-row">
      <div v-on:click="onLeftArrowClick" class="arrow-btn" :class="{active: prevPageAvailable}">
        ‹
      </div>
      <div v-on:click="onRightArrowClick" class="arrow-btn" :class="{active: nextPageAvailable}">
        ›
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nextPageAvailable: {
      type: Boolean,
      required: true,
    },
    paginationParams: {
      type: Object,
      required: true,
    },
    sortOptions: {
      type: Object,
      required: false,
    },
    totalItemsCount: {
      type: Number,
      required: true,
    },
    currentPageCount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onLeftArrowClick() {
      const step = this.paginationParams.from - this.paginationParams.size;
      this.paginationParams.from = step < 0
        ? 0
        : step;
    },
    onRightArrowClick() {
      if (this.nextPageAvailable) {
        const step = this.paginationParams.from + this.paginationParams.size;
        this.paginationParams.from = step;
      }
    },
    onSortOptionClick(option) {
      this.paginationParams.from = 0;
      this.paginationParams.key = option.key;
      this.paginationParams.desc = option.desc;
    },
    isSortOptionActive(option) {
      return option.key === this.paginationParams.key
        && option.desc === this.paginationParams.desc;
    },
  },
  computed: {
    prevPageAvailable() {
      return this.paginationParams.from > 0;
    },
    documentCounterText() {
      const start = `${this.paginationParams.from + 1}`;
      const end = `${this.paginationParams.from + this.currentPageCount}`;
      return `${start} - ${end} of ${this.totalItemsCount}`;
    },
  },
};
</script>

<style>
.pagination-container {
  gap: 1rem;
}
.counter-container {
  font-size: 1rem;
  font-weight: bold;
}
.arrows-container {
  gap: 0.5rem;
}
.arrow-btn {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #242b3d;
  border-radius: 50%;
  text-align: center;
  opacity: 0.3;
}
.arrow-btn:hover {
  cursor: not-allowed;
}
.arrow-btn.active {
  opacity: unset;
}
.arrow-btn.active:hover {
  color: #fff;
  background: #ff9f43;
  cursor: pointer;
}
</style>

export const cloud = `Data generated by xTend will be sent to the xVisor Dashboard, allowing you to monitor and analyze
the information in real-time.`;

export const hybrid = `Data generated by xTend will be exported to the xVisor Dashboard for real-time viewing
and simultaneously written to a log file for further analysis and record-keeping.`;

export const local = `Data generated by xTend will be exported for real-time viewing and written to JSON files. Audit
data will be stored in the /log directory, resource usage data in the /system directory, alerts in the /alerts
directory, and information about installed software will be available in the /packages directory. On Windows these
directories will be located where xTend was installed and for Linux they will be stored in /var/log/xtend.`;

import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';

const COLORS = [
  colorPaletteShade.teal4,
  colorPaletteShade.yellow4,
  colorPaletteShade.green4,
  colorPaletteShade.blue4,
  colorPaletteShade.indigo4,
  colorPaletteShade.purple4,
  colorPaletteShade.pink4,
  colorPaletteShade.gray4,
  colorPaletteShade.teal6,
  colorPaletteShade.yellow6,
  colorPaletteShade.green6,
  colorPaletteShade.blue6,
  colorPaletteShade.indigo6,
  colorPaletteShade.purple6,
  colorPaletteShade.pink6,
  colorPaletteShade.gray6,
  colorPalette.darkPink,
];

export default {
  data() {
    return {
      chartOptionsTemplate: {
        tooltip: {},
        legend: [
          {
            show: true,
            textStyle: {
              color: '',
            },
            right: 0,
            orient: 'vertical',
            top: 200,
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            color: COLORS,
            data: null,
            links: null,
            categories: null,
            roam: true,
            label: {
              position: 'right',
              formatter: '{b}',
            },
            lineStyle: {
              color: 'source',
              curveness: 0.15,
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 10,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    formattedNode(node) {
      return {
        x: node.coordinate.x,
        y: node.coordinate.y,
        id: node.id,
        name: node.name,
        category: node.zoneName,
        type: node.type,
        tooltip: {
          formatter: () => {
            const rows = [
              `Ip: ${node.name}`,
              `Zone Name: ${node.zoneName}`,
            ].join('<br />');
            return `<div class="apache-echarts-tooltip">${rows}</div>`;
          },
        },
      };
    },
  },
};

<template>
  <e-chart v-if="hasData" :options="chartOptions" autoresize id="appSparklineChart"></e-chart>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import granularity from '@/xvisor/utilities/granularity';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: colorPalette.black,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    timeSet() {
      return new Set(this.series.map((item) => item.time).flat());
    },
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    // Return a min and max bytes for the given series.
    minMaxValues() {
      const values = this.series ? this.series.map((point) => point.value) : [];
      const [min, max] = [Math.min(...values), Math.max(...values)];
      return { min, max };
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      const formatted = this.series.map((point) => [moment(point.time).valueOf(), point.value]);
      return formatted;
    },
    chartOptions() {
      return {
        title: {
          left: 40,
          textStyle: {
            color: this.toggleColor,
            fontSize: 12,
          },
        },
        color: [this.color],
        tooltip: {
          position: (point) => [point[0] + 20, point[1] - 35],
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${info[0].marker} <strong>Users</strong>: ${info[0].value[1]}
            </div>
          `,
          axisPointer: {
            type: 'line',
            animation: false,
            label: {
              backgroundColor: colorPalette.tooltipBackground,
            },
          },
        },
        grid: [
          {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          show: false,
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: 'value',
            show: false,
            min: this.minMaxValues.min ? this.minMaxValues.min : 0,
          },
        ],
        series: [
          {
            symbol: 'none',
            smooth: true,
            type: 'line',
            data: this.formattedSeries,
          },
        ],
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style lang="scss">
#appSparklineChart {
  height: 20px !important;
}
</style>

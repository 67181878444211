<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="12">
        <b-card :sub-title="$t('Infra Elements')" sub-title-text-variant="" class="stats-panel">
          <StatsPanel
            :url="url"
            :colors="netStatsColors"
            :timeRange="timeRange"
            routeName="infra/router"
            chartName="top-network-elements-alert"
            :isInfraApp="true"
          ></StatsPanel>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatsPanel from '@/xvisor/components/launchPad/StatsPanel.vue';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';

export default {
  components: {
    StatsPanel,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      netStatsColors: [
        colorPaletteShade.purple7,
        colorPaletteShade.purple3,
        colorPaletteShade.purple5,
        colorPaletteShade.purple2,
        colorPaletteShade.purple4,
        colorPaletteShade.purple6,
        colorPaletteShade.purple3,
      ],
    };
  },
};
</script>

<style scoped>
.stats-panel {
  margin-top: 70px;
}
</style>

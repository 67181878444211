<template>
  <b-dropdown variant="flat-{dark}" right no-caret>
    <template #button-content>
      <b-icon icon="list" variant="dark" />
    </template>
    <b-dropdown-item @click="downloadCsv">Download CSV</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { csvFormat } from 'd3';

export default {
  props: {
    fileName: {
      type: String,
      required: true,
    },
    dataRows: {
      type: Array,
      required: true,
    },
  },
  methods: {
    downloadCsv() {
      const name = `${this.fileName}.csv`;
      const blob = new Blob([csvFormat(this.dataRows)], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, name);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', name);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h5>Syslogs Severity Count</h5>
        <SyslogSeverityCount
          v-if="loaded"
          :url="url.syslogSeverityCountUrl"
          :timeRange="timeRange"
        ></SyslogSeverityCount>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col lg="6" md="12" sm="12">
        <h5>Syslogs - by Facility</h5>
        <SyslogFacilityCount
          v-if="loaded"
          :url="url.syslogFacilityCountUrl"
          :timeRange="timeRange"
        ></SyslogFacilityCount>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <h5>Syslogs - by Service</h5>
        <SyslogTopProgramsCount
          :url="url.syslogTopProgramsCountUrl"
          :timeRange="timeRange"
        ></SyslogTopProgramsCount>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SyslogFacilityCount from '@/xvisor/components/infra/logs/SyslogFacilityCount.vue';
import SyslogSeverityCount from '@/xvisor/components/infra/logs/SyslogSeverityCount.vue';
import SyslogTopProgramsCount from '@/xvisor/components/infra/logs/SyslogTopProgramsCount.vue';
import infraIpsMixin from '@/xvisor/components/infra/infraIpsMixin';

export default {
  mixins: [infraIpsMixin],
  components: {
    SyslogFacilityCount,
    SyslogSeverityCount,
    SyslogTopProgramsCount,
  },
  computed: {
    url() {
      // TODO: Change the first part of URL from `/nes` to `/infra`.
      // And change the ID from integer ID to dynamic string ID depending on infra type.
      const urlPrefix = `/nes/${this.infraElementId}`;
      return {
        syslogSeverityCountUrl: `${urlPrefix}/syslog/severity/countts.json`,
        syslogFacilityCountUrl: `${urlPrefix}/syslog/facility/countts.json`,
        syslogTopProgramsCountUrl: `/infra/routers/${this.infraIp}/syslogcountbyprogram.json`,
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  data() {
    return {
      timeRange: this.$store.state.app.timeRange,
      loaded: false,
      infraElementId: null,
    };
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(`/infra/routers/${this.infraIp}/id.json`)
        .then((response) => { this.infraElementId = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

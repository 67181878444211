<template>
  <b-modal ref="modal" title="Update API Key" centered>
    <validation-observer ref="apiKey">
      <b-form>
        <b-row>
          <b-col cols="12">
            <validation-provider name="Name" rules="required" v-slot="{ errors }">
              <b-form-group label="Name*" label-cols-md="4">
                <b-form-input
                  autofocus
                  placeholder="Name"
                  v-model="updatedApiKey.name"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-cols-md="4">
              <b-form-input
                autofocus
                placeholder="Description"
                v-model="updatedApiKey.description"
                class="form-control-merge"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Permission(s)*" label-cols-md="4">
              <validation-provider rules="required" v-slot="{ errors }">
                <Multiselect
                  :multiple="true"
                  :hideSelected="true"
                  :showLabels="false"
                  :closeOnSelect="false"
                  placeholder="Permission(s)"
                  v-model="updatedApiKey.permissions"
                  :options="permissionOptions"
                  label="name"
                  trackBy="value"
                ></Multiselect>
                <p class="text-danger text-sm" v-if="errors[0]">Please select permissions.</p>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Tag(s)" label-cols-md="4">
              <div id="api-key-tags-input">
                <div>
                  <b-row v-for="(tag, index) in updatedApiKey.tags" :key="index" class="mb-1">
                    <b-col cols="4">
                      <validation-provider rules="required" name="Tag" v-slot="{ errors }">
                        <b-form-input
                          placeholder="Key"
                          v-model="tag[0]"
                          :state="errors[0] ? false: null"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col cols="4">
                      <b-form-input
                        placeholder="Value"
                        v-model="tag[1]"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-button @click="deleteTag(index)" variant="outline-danger">
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <b-row class="mb-1">
                  <b-col cols="12">
                    <span class="text-danger text-xs" v-if="containsDuplicateKeys">
                      Tags cannot contain duplicate keys.
                    </span>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col cols="6">
                    <b-button @click="addTag()" variant="primary" class="mb-1" size="sm">+ Add Tag</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-left mr-1" @click="updateApiKey">Submit</b-button>
        <b-button variant="outline-primary" class="float-left" @click="$refs.modal.hide()">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';

import apiKeyPermissionTypes from '@/xvisor/constants/apiKeyPermissionTypes';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    /** API key object.
     * name The name of API key.
     * description The description.
     * permissions Array of API key permissions(Read, Write).
     * tags A key-value object.
     */
    apiKey: {
      type: Object,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      updatedApiKey: {
        name: this.apiKey.name,
        description: this.apiKey.description,
        permissions: [],
        tags: [],
      },
    };
  },
  computed: {
    permissionOptions() {
      return Object.values(apiKeyPermissionTypes);
    },
    currentPermissions() {
      return this.apiKey.permissions.map((permission) => {
        if (permission === apiKeyPermissionTypes.read.value) return apiKeyPermissionTypes.read;
        return apiKeyPermissionTypes.write;
      });
    },
    tagsObjectToArray() {
      return Object.entries(this.apiKey.tags);
    },
    // Returns true if updatedApiKey tags contains duplicate Keys
    containsDuplicateKeys() {
      const seen = [];
      for (let i = 0; i < this.updatedApiKey.tags.length; i += 1) {
        const value = this.updatedApiKey.tags[i][0];
        if (seen.indexOf(value) !== -1) {
          return true;
        }
        seen.push(value);
      }
      return false;
    },
  },
  mounted() {
    this.updatedApiKey.permissions = this.currentPermissions;
    this.updatedApiKey.tags = this.tagsObjectToArray;
  },
  methods: {
    formatApiKey(apiKey) {
      const formattedApiKey = apiKey;
      formattedApiKey.permissions = apiKey.permissions.map((permission) => permission.value);
      formattedApiKey.tags = Object.fromEntries(apiKey.tags);
      return formattedApiKey;
    },
    updateApiKey() {
      this.$refs.apiKey.validate().then((result) => {
        if (result) {
          this.$http
            .post(this.updateUrl, this.formatApiKey(this.updatedApiKey))
            .then(() => {
              this.$bvToast.toast('Successfully updated API key', { variant: 'success' });
              this.$refs.modal.hide();
              this.$emit('updated');
            })
            .catch(() => { this.$bvToast.toast('Failed to update API key', { variant: 'danger' }); });
        }
      });
    },
    addTag() {
      this.updatedApiKey.tags.push(['', '']);
    },
    deleteTag(index) {
      this.updatedApiKey.tags.splice(index, 1);
    },
  },
};
</script>

<template>
  <div>
    <b-table
      id="xources-table"
      class="table-with-actions-min-height"
      responsive
      :fields="tableFields"
      :items="tableItems"
      primary-key="id"
      :per-page="maxItems"
      :current-page="currentPage"
    >
      <template #cell(enabled)="row">
        <b-badge pill :variant="row.value ? 'success' : 'dark'">
          {{ row.value ? 'ENABLED' : 'DISABLED' }}
        </b-badge>
      </template>
      <template #cell(actions)="row">
        <b-dropdown class="float-right" text="Action">
          <template v-if="row.item.enabled">
            <b-dropdown-item @click="$refs[row.item.name].$refs.modal.show()">Configure</b-dropdown-item>
            <b-dropdown-item @click="disableXource(row.item.name)">Disable</b-dropdown-item>
          </template>
          <template v-else>
            <b-dropdown-item @click="$refs[row.item.name].$refs.modal.show()">Enable</b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="tableItems.length"
      :per-page="maxItems"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      ariaControls="xources-table"
    ></b-pagination>
    <template v-if="xourcesUpdate">
      <NetflowConfigModal
        :ref="xourceNames.netFlow.name"
        v-model="xourcesUpdate[xourceNames.netFlow.name]"
        @ok="httpPost"
      ></NetflowConfigModal>
      <PcapConfigModal
        :ref="xourceNames.pcap.name"
        v-model="xourcesUpdate[xourceNames.pcap.name]"
        @ok="httpPost"
      ></PcapConfigModal>
      <ProbeConfigModal
        :ref="xourceNames.probe.name"
        v-model="xourcesUpdate[xourceNames.probe.name]"
        @ok="httpPost"
      ></ProbeConfigModal>
      <SecurityConfigModal
        :ref="xourceNames.security.name"
        v-model="xourcesUpdate[xourceNames.security.name]"
        @ok="httpPost"
      ></SecurityConfigModal>
      <SflowConfigModal
        :ref="xourceNames.sFlow.name"
        v-model="xourcesUpdate[xourceNames.sFlow.name]"
        @ok="httpPost"
      ></SflowConfigModal>
      <SnmpConfigModal
        :ref="xourceNames.snmp.name"
        v-model="xourcesUpdate[xourceNames.snmp.name]"
        @ok="httpPost"
      ></SnmpConfigModal>
      <SuricataConfigModal
        :ref="xourceNames.suricata.name"
        :xomeName="xomeName"
        v-model="xourcesUpdate[xourceNames.suricata.name]"
        @ok="httpPost"
      ></SuricataConfigModal>
      <SyslogConfigModal
        :ref="xourceNames.syslog.name"
        v-model="xourcesUpdate[xourceNames.syslog.name]"
        @ok="httpPost"
      ></SyslogConfigModal>
      <VpcConfigModal
        :ref="xourceNames.vpc.name"
        v-model="xourcesUpdate[xourceNames.vpc.name]"
        @ok="httpPost"
      ></VpcConfigModal>
      <XpcapConfigModal
        :ref="xourceNames.xpcap.name"
        v-model="xourcesUpdate[xourceNames.xpcap.name]"
        @ok="httpPost"
      ></XpcapConfigModal>
    </template>
  </div>
</template>

<script>
import NetflowConfigModal from '@/xvisor/components/settings/xources/NetflowConfigModal.vue';
import PcapConfigModal from '@/xvisor/components/settings/xources/PcapConfigModal.vue';
import ProbeConfigModal from '@/xvisor/components/settings/xources/ProbeConfigModal.vue';
import SecurityConfigModal from '@/xvisor/components/settings/xources/SecurityConfigModal.vue';
import SflowConfigModal from '@/xvisor/components/settings/xources/SflowConfigModal.vue';
import SnmpConfigModal from '@/xvisor/components/settings/xources/SnmpConfigModal.vue';
import SuricataConfigModal from '@/xvisor/components/settings/xources/SuricataConfigModal.vue';
import SyslogConfigModal from '@/xvisor/components/settings/xources/SyslogConfigModal.vue';
import VpcConfigModal from '@/xvisor/components/settings/xources/VpcConfigModal.vue';
import XpcapConfigModal from '@/xvisor/components/settings/xources/XpcapConfigModal.vue';
import xourceNames from '@/xvisor/constants/xourceNames';

export default {
  props: {
    xomeId: {
      type: Number,
      required: true,
    },
    xomeName: {
      type: String,
      required: true,
    },
  },
  components: {
    NetflowConfigModal,
    PcapConfigModal,
    ProbeConfigModal,
    SecurityConfigModal,
    SflowConfigModal,
    SnmpConfigModal,
    SuricataConfigModal,
    SyslogConfigModal,
    VpcConfigModal,
    XpcapConfigModal,
  },
  data() {
    return {
      // An object where each field represents a xource.
      xources: undefined,
      // The xources with updated fields to post.
      xourcesUpdate: undefined,
      maxItems: 3,
      currentPage: 1,
      tableFields: [
        { key: 'enabled', label: 'Status', sortable: true },
        { key: 'extendedName', label: 'Source Name', sortable: true },
        { key: 'actions', label: '' },
      ],
      xourceNames,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    tableItems() {
      return this.xources ? Object
        .entries(this.xources)
        // Gracefully ignore xource types that haven't been handled by the frontend.
        .filter(([name]) => name in xourceNames)
        .map(([name, config]) => ({
          name,
          extendedName: xourceNames[name].extendedName,
          enabled: config.enabled,
        }))
        .sort((a, b) => b.enabled - a.enabled)
        : [];
    },
  },
  methods: {
    syncConfigs() {
      this.xourcesUpdate = JSON.parse(JSON.stringify(this.xources));
    },
    disableXource(xourceName) {
      this.xourcesUpdate[xourceName].enabled = false;
      this.httpPost();
    },
    httpGet() {
      this.$http
        .get(`/settings/xomes/${this.xomeId}/config.json`)
        .then((response) => {
          this.xources = response.data;
          this.syncConfigs();
        })
        .catch(() => {});
    },
    httpPost() {
      this.$http
        .post(`/settings/xomes/${this.xomeId}/xources/config`, this.xourcesUpdate)
        .then(() => {
          this.$bvToast.toast('Successfully updated XOME Configuration', { title: 'Notice', variant: 'success' });
        })
        .catch(() => {
          this.$bvToast.toast('Failed to update XOME Configuration', { title: 'Notice', variant: 'danger' });
        })
        .finally(() => { this.httpGet(); });
    },
  },
};
</script>

<template>
  <b-modal ref="modal" title="Delete Zone" @ok="deleteZone">
    Are you sure you want to delete zone "{{ zone.name }}"?
    You cannot undo this action and it will result in all associated data being removed.
  </b-modal>
</template>

<script>
import ZonesEventBus from '@/xvisor/bus/ZonesEventBus';

export default {
  props: {
    zone: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteZone() {
      this.$http
        .delete(this.url)
        .then(() => {
          this.$bvToast.toast('Successfully deleted zone', { title: 'Notice', variant: 'success' });
          ZonesEventBus.$emit('refresh');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete zone', { title: 'Notice', variant: 'danger' });
        });
    },
  },
};
</script>

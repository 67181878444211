<template>
  <b-card>
    <b-col>
    <b-row>
      <b-col md="2" offset-md="10">
        <div>
          <b-form-checkbox v-model="checked" name="check-button" switch>
            Switch to Nodes
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col>
        <div>
          <b-modal ref="pathroute-modal" id="modal-center" :title="this.modalTitle" centered hide-footer>
            <div class="d-block text-center">
              <NodeHopRtd
                :nodeHopRtdParams="nodeHopRtdParams"
                :url="url"
                :timeRange="timeRange"
                chartName="Node Hop Rtd">
              </NodeHopRtd>
            </div>
          </b-modal>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <e-chart
        :options="chartOptions"
        @click="showModal"
        autoresize
        :style="`height: ${this.height}vh;`"
      ></e-chart>
    </b-row>
  </b-col>
</b-card>
</template>

<script>
import getLinkId from '@/xvisor/components/app/networkTab/pathroutes/utils';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import PathroutesLink from '@/xvisor/components/app/networkTab/pathroutes/models/pathroutesLink';
import NodeHopRtd from '@/xvisor/components/app/networkTab/pathroutes/NodeHopRtd.vue';

const COLORS = [
  colorPaletteShade.teal4,
  colorPaletteShade.yellow4,
  colorPaletteShade.purple4,
  colorPaletteShade.orange6,
  colorPaletteShade.green4,
  colorPaletteShade.blue4,
  colorPaletteShade.purple4,
  colorPaletteShade.pink4,
  colorPaletteShade.indigo4,
];

const UnresponsiveNodeGroupNum = -3;

export default {
  components: {
    NodeHopRtd,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    nodeIdToNodeObj: {
      type: Object,
      required: true,
    },
    linkIdToLinkObj: {
      type: Object,
      required: true,
    },
    blockIdToBlockObj: {
      type: Object,
      required: true,
    },
    blocksLoaded: {
      type: Boolean,
    },
    asnObj: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      collapsedBlocks: {},
      checked: false,
      modalTitle: '',
      nodeHopRtdParams: [],
    };
  },
  computed: {
    visibleNodeIds() {
      const result = {};
      Object.keys(this.nodeIdToNodeObj).forEach((id) => {
        const { blockId } = this.nodeIdToNodeObj[id];
        // Node is visible if it is not part of a domain or if its domain is not collapsed.
        if (blockId === null || !(blockId in this.collapsedBlocks)) {
          result[id] = true;
        }
      });
      return result;
    },
    visibleLinkIds() {
      const result = {};
      Object.keys(this.linkIdToLinkObj).forEach((id) => {
        const link = this.linkIdToLinkObj[id];

        const sourceNodeId = this.nodeIdToNodeObj[link.sourceId].id;
        const sourceBlockId = this.nodeIdToNodeObj[link.sourceId].blockId;

        const targetNodeId = this.nodeIdToNodeObj[link.targetId].id;
        const targetBlockId = this.nodeIdToNodeObj[link.targetId].blockId;

        const visibleSource = (sourceBlockId in this.collapsedBlocks) ? sourceBlockId : sourceNodeId;
        const visibleTarget = (targetBlockId in this.collapsedBlocks) ? targetBlockId : targetNodeId;

        const linkId = getLinkId(visibleSource, visibleTarget);
        result[linkId] = { visibleSource, visibleTarget };
      });
      return result;
    },
    formattedNodesLinks() {
      return {
        nodes: [...this.formattedNodes, ...this.formattedLinksAndWaypoints.waypointsNodes],
        links: [...this.formattedLinksAndWaypoints.links],
      };
    },
    formattedNodes() {
      const nodes = [];
      Object.keys(this.visibleNodeIds).forEach((id) => {
        nodes.push(this.nodeIdToNodeObj[id].getEchartsFormatted());
      });
      Object.keys(this.collapsedBlocks).forEach((id) => {
        if (this.blockIdToBlockObj[id]) {
          nodes.push(this.blockIdToBlockObj[id].getEchartsFormatted());
        }
      });
      return nodes;
    },
    formattedLinksAndWaypoints() {
      const links = [];
      const waypointsNodes = [];
      const waypointIds = {};
      Object.keys(this.visibleLinkIds).forEach((id) => {
        if (id in this.linkIdToLinkObj) {
          const { waypoints, sublinks } = this.linkIdToLinkObj[id].getEchartsFormatted();
          waypoints.forEach((waypoint) => {
            if (waypoint.name in waypointIds) return;
            waypointsNodes.push(waypoint);
            waypointIds[waypoint.name] = true;
          });
          links.push(...sublinks);
        } else {
          const blockLink = new PathroutesLink({
            id,
            sourceId: this.visibleLinkIds[id].visibleSource,
            targetId: this.visibleLinkIds[id].visibleTarget,
            targetReachable: true,
            causesCycle: false,
            waypoints: [],
            probeIds: [],
            mplsTunnelType: null,
          });
          links.push(...blockLink.getEchartsFormatted().sublinks);
        }
      });
      return { links, waypointsNodes };
    },
    formattedCategories() {
      const categories = Array(Object.keys(this.asnObj).length);
      Object.keys(this.asnObj).forEach((idx) => {
        if (this.asnObj[idx].number === UnresponsiveNodeGroupNum) {
          categories[idx] = {
            name: this.asnObj[idx].name,
            itemStyle: {
              color: 'gray',
            },
          };
        } else {
          categories[idx] = {
            name: this.asnObj[idx].name,
            itemStyle: {
              color: COLORS[idx % COLORS.length],
            },
          };
        }
      });
      return categories;
    },
    chartOptions() {
      return {
        // Important: must set tooltip as empty object or else tooltips won't show.
        tooltip: {},
        legend: [
          {
            bottom: 0,
            itemGap: 20,
            selectedMode: false,
            data: this.formattedCategories.map((category) => category.name),
            textStyle: {
              color: COLORS,
            },
          },
        ],
        series: [
          {
            // Prevent nodes from scaling on hover.
            animation: false,
            type: 'graph',
            layout: 'none',
            colorBy: this.formattedCategories,
            roam: true,
            data: this.formattedNodesLinks.nodes,
            links: this.formattedNodesLinks.links,
            categories: this.formattedCategories,
            lineStyle: {
              curveness: 1e-9,
            },
            nodeScaleRatio: 1,
          },
        ],
      };
    },
  },
  watch: {
    blocksLoaded() {
      this.initialBlockCollapse();
    },
    checked(newStatus, currStatus) {
      if (newStatus !== currStatus) {
        this.initialBlockCollapse();
      }
    },
  },
  mounted() {
    this.initialBlockCollapse();
  },
  methods: {
    initialBlockCollapse() {
      const BlockNodeObjects = Object.values(this.blockIdToBlockObj);
      BlockNodeObjects.forEach((blockNode) => {
        const nodesWithinBlock = blockNode.nodeIds;

        // Collapsing the first node within each block node group.
        this.toggleBlock(nodesWithinBlock[0]);
      });
    },
    showModal(event) {
      let probeIds = '';
      event.data.probeIds.forEach((probeId) => {
        if (probeIds === '') {
          probeIds = probeIds.concat('', probeId);
        } else {
          probeIds = probeIds.concat(',', probeId);
        }
      });
      const nodeId = event.data.name;
      this.nodeHopRtdParams = { nodeId, probeIds };
      this.modalTitle = nodeId;
      if (nodeId.includes('-')) {
        const res = nodeId.split('-');
        [, this.modalTitle] = res;
      }
      if (nodeId && probeIds.length > 0 && !nodeId.includes('Unresponsive')) {
        this.$refs['pathroute-modal'].show();
      }
    },
    toggleBlock(nodeId) {
      const blockId = (nodeId in this.blockIdToBlockObj ? nodeId : this.nodeIdToNodeObj[nodeId].blockId);
      if (blockId in this.collapsedBlocks) {
        this.$delete(this.collapsedBlocks, blockId);
      } else {
        this.$set(this.collapsedBlocks, blockId, true);
      }
    },
  },
};
</script>

<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    :style="`height: ${chartHeight}px;`"
    @click="(event) => triggerEvent(event)"
    autoresize
  ></e-chart>
  <div v-else-if="loaded">
    <span>{{ $t("No Alerts") }}</span>
    <b-icon icon="hand-thumbs-up" variant="success" font-scale="1" style="margin-left: 4px"></b-icon>
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    chartName: {
      type: String,
      required: true,
    },
    isSvcApp: {
      type: Boolean,
      default: false,
    },
    isInfraApp: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      series: [],
      loaded: false,
      barChartTextWidth: 10,
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleBackgroundBarColor() {
      return themeStyle.styleToggle(colorPaletteShade.gray5, colorPaletteShade.indigo9);
    },
    zoneId() {
      return this.$store.state.app.zoneId;
    },
    subCategoriesSet() {
      return new Set(
        this
          .series
          .map((item) => item.alertCounts.map((count) => count.name))
          .flat(),
      );
    },
    // The series formatted for use with apex charts.
    chartSeries() {
      const subCategories = [...this.subCategoriesSet];
      const series = subCategories.map((categoryName) => ({
        name: categoryName,
        type: 'bar',
        stack: 'total',
        barWidth: 10,
        data: this.series.map((item, index) => {
          const found = item.alertCounts.find((count) => count.name === categoryName);
          return {
            value: found ? found.count : 0,
            itemStyle: {
              color: this.colors[index],
            },
          };
        }),
        id: this.series.map((item) => {
          const found = item.alertCounts.find((count) => count.name === categoryName);
          return found ? found.id : undefined;
        }),
      }));
      // The 'Other' subcategory will be a bar at the end for alerts that couldn't be tied to a known subcategory.
      series.push({
        name: 'Other',
        type: 'bar',
        stack: 'total',
        barWidth: 10,
        data: this.series.map((item) => {
          const subTotal = item.alertCounts.reduce((total, each) => total + each.count, 0);
          return item.totalAlerts > subTotal ? item.totalAlerts - subTotal : 0;
        }),
        id: !this.isSvcApp
          ? this.series.map((item) => {
            const subTotal = item.alertCounts.reduce((total, each) => total + each.count, 0);
            return item.totalAlerts > subTotal ? item.entityId : undefined;
          })
          : undefined,
      });
      return series;
    },
    // The given colors to use, sliced to fit the subcategories and terminated with gray (for 'Other').
    colorsSlice() {
      const colors = this.colors.slice(0, this.subCategoriesSet.size);
      colors.push(this.toggleBackgroundBarColor);
      return colors;
    },
    yAxisCategories() {
      return this.series.map((item) => item.name);
    },
    // The height of the chart calculated based on how many rows of bars will be shown.
    chartHeight() {
      const base = 70;
      const barWidth = 10;
      const maxBars = 7;
      if (this.series.length >= maxBars) return base + ((maxBars - 1) * barWidth);
      return (base + ((this.series.length - 1) * barWidth));
    },
    chartOptions() {
      return {
        color: this.colorsSlice,
        grid: [
          {
            left: 10,
            right: 0,
            top: 0,
            bottom: 0,
            containLabel: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${info[0].name}<br>
              ${this.tooltipContent(info)}
            </div>
          `,
          axisPointer: {
            type: 'shadow',
            animation: false,
            label: {
              show: false,
            },
          },
        },
        xAxis: {
          type: 'value',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: this.yAxisCategories,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            formatter: (info) => {
              const alertItem = this.series.find((item) => item.name === info);
              const alertCount = alertItem ? alertItem.totalAlerts : 0;
              return `${collapseText(info, this.barChartTextWidth)} (${alertCount})`;
            },
            textStyle: {
              color: this.toggleColor,
            },
          },
        },
        series: this.chartSeries,
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            zoneId: this.zoneId,
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    /* TODO: Change logic for when the endpoint for network stats is updated
     * from using ne/:id to infra/router/:ip
     */
    triggerEvent(config) {
      if (this.isInfraApp) {
        // TODO: Logic for pushing the ip for the path goes here.
      } else {
        const id = this.chartSeries[config.seriesIndex]
          ? this.chartSeries[config.seriesIndex].id[config.dataIndex]
          : undefined;
        if (id) {
          this.$router.push({
            name: this.routeName,
            params: {
              id,
              isRenderedFromOverview: true,
            },
          });
        }
      }
    },
    tooltipContent(info) {
      let tooltip = '';
      info.forEach((item) => {
        if (item.value > 0) {
          tooltip += `${item.marker} ${item.seriesName}: ${item.value} <br>`;
        }
      });
      return tooltip;
    },
  },
};
</script>

<template>
  <b-card no-body class="mx-n2">
    <b-row class="mx-0-5 my-1" align-h="between" align-v="center">
      <b-col cols="12" md="6">
        <ShowEntries>
          <template #input>
            <b-form-select
              v-model="perPage"
              :options="options"
              size="sm"
            ></b-form-select>
          </template>
        </ShowEntries>
      </b-col>
      <b-col lg="5" md="5" sm="12">
        <SearchBar class="mr-1">
          <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <b-table
      id="integrations-table"
      class="table-with-actions-min-height"
      striped
      responsive
      primary-key="id"
      :items="integrations"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template #cell(actions)="row">
        <IntegrationAction :integration="row.item"></IntegrationAction>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="integrations.length"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      class="mb-0 mt-1"
      ariaControls="integrations-table"
    ></b-pagination>
  </b-card>
</template>

<script>
import IntegrationAction from '@/xvisor/components/integrations/IntegrationAction.vue';
import IntegrationsEventBus from '@/xvisor/bus/IntegrationsEventBus';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';

export default {
  components: {
    IntegrationAction,
    SearchBar,
    ShowEntries,
  },
  props: {
    /** The URL from which to get the list of integrations. */
    url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      integrations: [],
      perPage: 5,
      currentPage: 1,
      filter: null,
      filteredLength: null,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      tableFields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'url', label: 'Url', sortable: true },
        { key: 'version', label: 'Version', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.integrations.length;
    },
  },
  mounted() {
    IntegrationsEventBus.$on('update', this.httpGet);
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.integrations = response.data; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="mx-n2">
    <b-card no-body>
      <b-col cols="12" md="8">
        <h5>Zones</h5>
      </b-col>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="usersFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              select-mode="single"
              selectable
            ></b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import collapseText from '@/xvisor/utilities/collapseText';
import hostnameFormatter from '@/xvisor/utilities/hostnameFormatter';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      componentInfo: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      tableFields: [
        { key: 'name', label: 'Zone' },
        { key: 'numUsers', label: 'Users' },
        { key: 'numServers', label: 'Servers' },
      ],
      validQuery: true,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.componentInfo && this.componentInfo.length > 0;
    },
    paginationTotalRows() {
      return this.componentInfo.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    usersFilter() {
      const retVal = this.componentInfo;
      return retVal;
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.componentInfo = response.data; })
        .finally(() => { this.loaded = true; });
    },
    collapseText,
    hostnameFormatter,
  },
};
</script>

<style scoped>
.form-select{
  min-width: 160px;
  width: 20%;
}
</style>

<template>
  <div>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      autoresize
      class="apache-charts-trans-duration"
    ></e-chart>
    <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import granularity from '@/xvisor/utilities/granularity';
import readableMillis from '@/xvisor/utilities/readableMillis';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    transDataUrl: {
      type: String,
      required: true,
    },
    serverIp: {
      type: String,
      required: true,
    },
    cmptId: {
      type: Number,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transDurTs: [],
      loaded: false,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.transDurTs && this.transDurTs.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    timeSet() {
      return new Set(this.transDurTs.map((item) => item.transCounts.map((point) => point.time)).flat());
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      return this.transDurTs.map((ts) => (
        {
          name: ts.name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          data: ts.transCounts.map((point) => [
            moment(point.time).valueOf(),
            point.avgTransDuration,
          ]),
        }
      ));
    },
    chartOptions() {
      return {
        color: [
          colorPalette.blue,
          colorPalette.green,
          colorPalette.teal,
          colorPalette.orange,
          colorPalette.yellow,
          colorPalette.red,
        ],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              <div class="apache-echarts-tooltip-timestamp">
                ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              </div>
              ${this.tooltipContent(info)}
            </div>
          `,
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              color: this.toggleColor,
              backgroundColor: this.toggleTooltipColor,
            },
          },
        },
        grid: [
          {
            left: 15,
            right: 15,
            top: 15,
            bottom: 15,
          },
        ],
        xAxis: {
          type: 'time',
          show: false,
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            show: false,
            splitLine: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => `${readableMillis(value)}`,
              fontSize: 9,
              show: false,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.transDataUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity12(this.timeRange),
            cmptid: this.cmptId,
            appserver: this.serverIp,
          },
        })
        .then((response) => { this.transDurTs = response.data; })
        .finally(() => { this.loaded = true; });
    },
    tooltipContent(info) {
      let tooltipMessage = '';
      for (let i = 0; i < info.length; i += 1) {
        tooltipMessage += `
          ${info[i].marker} ${info[i].seriesName}: ${readableMillis(info[i].value[1])}
          <br>
        `;
      }
      return tooltipMessage;
    },
  },
};
</script>

<style scoped>
.apache-charts-trans-duration {
  width: 80% !important;
  max-width: 900px !important;
  min-width: 400px !important;
  height: 100px !important;
  margin: 0 auto;
}
</style>

<template>
  <b-card no-body :class="margin">
    <b-row class="mx-0-5 my-1" align-h="between" align-v="center">
      <b-col cols="6">
        <h5>Probes</h5>
      </b-col>
      <b-col cols="12" md="6">
        <SearchBar style="margin-bottom: 5px">
          <template #input>
            <b-form-input v-model="searchValue" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div v-if="hasData">
          <b-table
            hover
            outlined
            responsive
            primary-key="targetId"
            :per-page="perPage"
            :current-page="currentPage"
            :items="probeStatuses"
            :fields="fields"
            :filter="searchValue"
            @filtered="onFiltered"
            select-mode="single"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(urls)="row">
              <HeatmapEchart
                :url="row.item.urls.healthRatioTsUrl"
                :timeRange="timeRange"
                width="200px !important"
                height="30px !important"
                :colorRange="heatmapColorRange"
                tooltipPosition="right"
              ></HeatmapEchart>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="paginationTotalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mr-1"
          />
        </div>
        <div class="ml-1-5 mt-n1 mb-1-5" v-else-if="loaded">{{ $t("No Data Available") }}</div>
        <SpinnerCmpt v-else class="mb-1"></SpinnerCmpt>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import HeatmapEchart from '@/xvisor/components/HeatmapEchart.vue';
import ProbesEventBus from '@/xvisor/bus/ProbesEventBus';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';

const PROBE_ZERO_THRESHOLD = 0.0;
const PROBE_CRITICAL_THRESHOLD = 0.001;
const PROBE_WARNING_THRESHOLD = 0.6;
const PROBE_HEALTHY_MIN_THRESHOLD = 0.8;
const PROBE_HEALTHY_MAX_THRESHOLD = 1.0;

export default {
  components: {
    HeatmapEchart,
    SearchBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    alertId: {
      type: String,
    },
    margin: {
      type: String,
    },
  },
  data() {
    return {
      probeStatuses: [],
      loaded: false,
      perPage: 5,
      currentPage: 1,
      searchValue: '',
      filteredLength: null,
      fields: [
        { key: 'urls', label: 'Health' },
        { key: 'source', label: 'Source', sortable: true },
        { key: 'target', label: 'Target', sortable: true },
        { key: 'zone', label: 'Zone', sortable: true },
        { key: 'numAlerts', label: '# Alerts', sortable: true },
      ],
      selected: [],
    };
  },
  computed: {
    heatmapColorRange() {
      return {
        precision: 1,
        show: false,
        pieces: [
          {
            max: PROBE_HEALTHY_MAX_THRESHOLD,
            min: PROBE_HEALTHY_MIN_THRESHOLD,
            color: colorPalette.green,
          },
          {
            max: PROBE_HEALTHY_MIN_THRESHOLD,
            min: PROBE_WARNING_THRESHOLD,
            color: colorPalette.yellow,
          },
          {
            max: PROBE_WARNING_THRESHOLD,
            min: PROBE_CRITICAL_THRESHOLD,
            color: colorPalette.red,
          },
          {
            max: PROBE_CRITICAL_THRESHOLD,
            min: PROBE_ZERO_THRESHOLD,
            color: colorPalette.white,
          },
        ],
      };
    },
    paginationTotalRows() {
      if (this.searchValue) return this.filteredLength;
      return this.probeStatuses.length;
    },
    hasData() {
      return this.loaded && this.probeStatuses && this.probeStatuses.length > 0;
    },
  },
  mounted() {
    ProbesEventBus.$on('update-probes-table', this.httpGet);
    this.httpGet();
  },
  watch: {
    selected() {
      // Items is an array of the selected row object so our row selection lies at index 0.
      ProbesEventBus.$emit('update-selected-probe', this.selected[0]);
    },
    timeRange() {
      // Simulate reactivity of timeRange by emitting probes event to expanded table row child.
      ProbesEventBus.$emit('update-time-range', this.timeRange);
      this.httpGet();
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            alertId: this.alertId,
          },
        })
        .then((response) => {
          this.probeStatuses = response.data;
        })
        .finally(() => { this.loaded = true; });
    },
    elementId(id) {
      return `probe-${id}`;
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selected = items;
    },
  },
};
</script>

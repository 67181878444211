<template>
  <div v-if="threatFeedInfo && threatFeedInfo.length <= threatIconDisplayLimit" class="threat-feed-icons-container">
    <div v-for="val in getThreatIconInfo(threatFeedInfo, threatFeedTypes)" v-bind:key="val.name">
      <span v-b-tooltip.hover :title="val.title">
        <b-badge pill :style="getThreatPillColor(val.style)" class="text-white">
          {{ val.symbol }}
        </b-badge>
      </span>
    </div>
  </div>
  <div v-else class="threat-feed-icons-container">
    <span v-if="threatFeedInfo && threatFeedInfo.length">
      <b-badge pill class="text-white badge-danger" :id="getPopoverId(uniqueId)">
        ...
      </b-badge>
      <b-popover :target="getPopoverId(uniqueId)" triggers="hover" placement="auto" custom-class="threat-popover">
        <span class="threat-popover-title"> {{ threatFeedInfo.length }} Threats Found</span>
        <div v-for="val in getThreatIconInfo(threatFeedInfo, threatFeedTypes)" v-bind:key="val.name">
          <hr>
          <span><b>Name:</b> {{ val.name }}</span>
          <br>
          <span><b>URL:</b> {{ val.url }}</span>
        </div>
      </b-popover>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    threatFeedInfo: {
      type: Array,
      required: true,
    },
    threatFeedTypes: {
      type: Object,
      required: true,
    },
    uniqueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      threatIconDisplayLimit: 3,
    };
  },
  methods: {
    getThreatIconInfo(threatFeedInfo, threatFeedTypes) {
      const res = threatFeedInfo && Object.keys(threatFeedTypes).length > 1
        ? threatFeedInfo.map((feed) => {
          const threat = threatFeedTypes[feed];
          const title = `${threat.name}\n${threat.url}`;
          const symbol = threat.abbrev;
          return {
            title,
            symbol,
            name: threat.name,
            url: threat.url,
            type: threat.type,
            style: threat.style,
          };
        }) : [];
      return res;
    },
    getPopoverId() {
      return `threat-popover-${String(this.uniqueId)}`;
    },
    getThreatPillColor(feedType) {
      return `background-color:${feedType}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.threat-feed-icons-container {
  display: flex;
  gap: 4px;
}
.threat-popover-title {
  font-size: 14px;
  font-weight: bold;
}
</style>

<template>
  <div>
    <b-row>
      <b-col>
        <h2 class="mb-2">{{ $t('Agents') }}</h2>
      </b-col>
      <b-col>
        <TimeRangeSelector
          :minDate="minDate"
          :maxDate="maxDate"
          :initRange="timeRange"
          @change-time-range="updateStateTimeRange"
        ></TimeRangeSelector>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <Xomes></Xomes>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';

import TimeRangeSelector from '@/xvisor/components/TimeRangeSelector.vue';
import Xomes from '@/xvisor/components/agents/Xomes.vue';
import dateCeil from '@/xvisor/utilities/dateCeil';

export default ({
  components: {
    Xomes,
    TimeRangeSelector,
  },
  data() {
    const now = dateCeil(new Date());
    return {
      maxDate: now,
      minDate: moment(now).subtract(1, 'month').toDate(),
      timeRange: this.$store.state.app.timeRange,
    };
  },
  methods: {
    updateStateTimeRange(timeRange) {
      this.$store.commit('app/UPDATE_TIME_RANGE', timeRange);
      this.timeRange = timeRange;
    },
  },
});
</script>

<template>
  <div v-if="hasData">
    <RadialProgressBar :inputData="parseFloat(this.cpuUsage)" label="CPU"></RadialProgressBar>
  </div>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import RadialProgressBar from '@/xvisor/components/agents/RadialProgressBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  components: {
    RadialProgressBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      cpu: undefined,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.cpu;
    },
    cpuUsage() {
      return (this.cpu * 100).toFixed(1);
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url)
        .then((response) => { this.cpu = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

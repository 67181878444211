<template>
  <div>
    <validation-observer ref="alertConfigForm">

      <!--Application RTD Row-->
      <b-container fluid class="mt-1">
        <b-row class="mr-3 ml-1">
          <p>Application RTD:</p>
          <b-button
            variant="flat"
            v-if="!config.appRtd"
            @click="addConfig('appRtd')"
            class="mt-n1"
          >
            <b-icon icon="plus" variant="dark"></b-icon>
          </b-button>
        </b-row>
        <b-row class="ml-1" no-gutters>
          <template v-if="config.appRtd">
            <b-col cols="4">
              <b-row>
                <b-col cols="9">
                  <validation-provider rules="required|min:0" name="App Threshold" v-slot="{ errors }">
                    <b-form-group>
                      <b-form-input
                        placeholder="threshold"
                        type="number"
                        :state="errors[0] ? false : null"
                        v-model.number="config.appRtd.threshold"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col align-self="center" class="ml-n2">
                  <span>ms</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" class="pl-1">
              <b-row>
                <b-col cols="9">
                  <validation-provider rules="required" name="App Duration Minutes" v-slot="{ errors }">
                    <b-form-group>
                      <b-form-select
                        v-model="config.appRtd.durationMinutes"
                        :state="errors[0] ? false : null"
                        :options="minuteDurations"
                      >
                        <template #first>
                          <b-form-select-option :value="undefined" disabled>duration</b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col align-self="center" class="ml-n2">
                  <span>min</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3" class="pl-1">
              <validation-provider rules="required" name="App Severity" v-slot="{ errors }">
                <b-form-group>
                  <b-form-select
                    v-model="config.appRtd.severity"
                    :state="errors[0] ? false : null"
                    :options="severities"
                  >
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>severity</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-button variant="flat" @click="deleteConfig('appRtd')" class="ml-n1">
                <b-icon icon="trash" variant="dark"></b-icon>
              </b-button>
            </b-col>
          </template>
        </b-row>
      </b-container>
      <hr />

      <!--Service RTD row-->
      <b-container fluid class="mt-1">
        <b-row class="mr-3 ml-1">
          <p>Service RTD:</p>
          <b-button
            variant="flat"
            v-if="!config.svcRtd"
            @click="addConfig('svcRtd')"
            class="mt-n1"
          >
            <b-icon icon="plus" variant="dark"></b-icon>
          </b-button>
        </b-row>
        <b-row class="ml-1" no-gutters>
          <template v-if="config.svcRtd">
            <b-col cols="4">
              <b-row>
                <b-col cols="9">
                  <validation-provider rules="required|min:0" name="Service Threshold" v-slot="{ errors }">
                    <b-form-group>
                      <b-form-input
                        placeholder="threshold"
                        type="number"
                        :state="errors[0] ? false : null"
                        v-model.number="config.svcRtd.threshold"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col align-self="center" class="ml-n2">
                  <span>ms</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" class="pl-1">
              <b-row>
                <b-col cols="9">
                <validation-provider rules="required" name="Service Duration Minutes" v-slot="{ errors }">
                  <b-form-group>
                    <b-form-select
                      v-model="config.svcRtd.durationMinutes"
                      :state="errors[0] ? false : null"
                      :options="minuteDurations"
                    >
                      <template #first>
                        <b-form-select-option :value="undefined" disabled>duration</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                </b-col>
                <b-col align-self="center" class="ml-n2">
                  <span>min</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3" class="pl-1">
              <validation-provider rules="required" name="Service Severity" v-slot="{ errors }">
                <b-form-group>
                  <b-form-select
                    v-model="config.svcRtd.severity"
                    :state="errors[0] ? false : null"
                    :options="severities"
                  >
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>severity</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-button variant="flat" @click="deleteConfig('svcRtd')" class="ml-n1">
                <b-icon icon="trash" variant="dark"></b-icon>
              </b-button>
            </b-col>
          </template>
        </b-row>
      </b-container>
      <hr />

      <!--Per Link Delay row-->
      <b-container fluid class="mt-1">
        <b-row class="mr-3 ml-1">
          <p>Per Link Delay:</p>
          <b-button
            variant="flat"
            v-if="!config.perLinkDelay"
            @click="addConfig('perLinkDelay')"
            class="mt-n1"
          >
            <b-icon icon="plus" variant="dark"></b-icon>
          </b-button>
        </b-row>
        <b-row class="ml-1" no-gutters>
          <template v-if="config.perLinkDelay">
            <b-col cols="4">
              <b-row>
                <b-col cols="9">
                  <validation-provider rules="required|min:0" name="Per Link Delay Threshold" v-slot="{ errors }">
                    <b-form-group>
                      <b-form-input
                        placeholder="threshold"
                        type="number"
                        :state="errors[0] ? false : null"
                        v-model.number="config.perLinkDelay.threshold"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col align-self="center" class="ml-n2">
                  <span>ms</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" class="pl-1">
              <b-row>
                <b-col cols="9">
                <validation-provider rules="required" name="Per Link Delay Duration Minutes" v-slot="{ errors }">
                  <b-form-group>
                    <b-form-select
                      v-model="config.perLinkDelay.durationMinutes"
                      :state="errors[0] ? false : null"
                      :options="minuteDurations"
                    >
                      <template #first>
                        <b-form-select-option :value="undefined" disabled>duration</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                </b-col>
                <b-col align-self="center" class="ml-n2">
                  <span>min</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3" class="pl-1">
              <validation-provider rules="required" name="Per Link Delay Severity" v-slot="{ errors }">
                <b-form-group>
                  <b-form-select
                    v-model="config.perLinkDelay.severity"
                    :state="errors[0] ? false : null"
                    :options="severities"
                  >
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>severity</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-button variant="flat" @click="deleteConfig('perLinkDelay')" class="ml-n1">
                <b-icon icon="trash" variant="dark"></b-icon>
              </b-button>
            </b-col>
          </template>
        </b-row>
      </b-container>
    </validation-observer>
    <hr />
    <b-row align-h="start">
      <b-button class="ml-1 my-1" variant="primary" @click="updateConfig">Update</b-button>
    </b-row>
  </div>
</template>

<script>
import alertSeverities from '@/xvisor/constants/alertSeverities';

export default {
  props: {
    appId: {
      type: Number,
      required: true,
    },
  },
  data() {
    const urlPrefix = `/apps/${this.appId}`;
    return {
      minuteDurations: [5, 10, 15, 30, 45, 60],
      severities: [
        { text: 'Notice', value: alertSeverities.notice },
        { text: 'Warning', value: alertSeverities.warning },
        { text: 'Critical', value: alertSeverities.critical },
      ],
      config: {
        appRtd: undefined,
        svcRtd: undefined,
        perLinkDelay: undefined,
      },

      getUrl: `${urlPrefix}/alerts/config.json`,
      postUrl: `${urlPrefix}/alerts/config`,
    };
  },
  mounted() {
    this.$http
      .get(this.getUrl)
      .then((response) => { this.config = response.data; });
  },
  methods: {
    addConfig(name) {
      this.$set(this.config, name, this.makeConfig());
    },
    deleteConfig(name) {
      this.config[name] = undefined;
    },
    makeConfig() {
      return {
        threshold: undefined,
        durationMinutes: undefined,
        severity: undefined,
      };
    },
    updateConfig() {
      this.$refs.alertConfigForm.validate().then((result) => {
        if (result) {
          this.$http
            .post(this.postUrl, this.config)
            .then(() => {
              this.$bvToast.toast('Successfully updated configurations', { variant: 'success' });
            })
            .catch(() => { this.$bvToast.toast('Failed to update configurations', { variant: 'danger' }); });
        }
      });
    },
  },
};
</script>

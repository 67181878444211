<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.vpc.extendedName} Data Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <validation-provider name="URL" rules="required" v-slot="{ errors }">
        <b-form-group label="URL">
          <b-form-input
            placeholder="URL"
            v-model="config.url"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Container Name" rules="required" v-slot="{ errors }">
        <b-form-group label="Container Name">
          <b-form-input
            placeholder="Container Name"
            v-model="config.containerName"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Account Name" rules="required" v-slot="{ errors }">
        <b-form-group label="Account Name">
          <b-form-input
            placeholder="Account Name"
            v-model="config.accountName"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Account Key" rules="required" v-slot="{ errors }">
        <b-form-group label="Account Key">
          <b-form-input
            placeholder="Account Key"
            v-model="config.accountKey"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        url: '',
        containerName: '',
        accountName: '',
        accountKey: '',
      },
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        url: this.value.url,
        containerName: this.value.containerName,
        accountName: this.value.accountName,
        accountKey: this.value.accountKey,
      };
    },
  },
};
</script>

<template>
  <b-card no-body class="mt-n2">
    <b-row class="mx-1 mt-2 mb-1">
      <b-col cols="6">
        <h4>{{ selectionHeader }}</h4>
      </b-col>
      <b-col cols="12">
        <div v-if="hasServerSelection">
          <b>Hostname(s)</b> : {{ hostnameFormatter(selectedServer.dnsNamesForAddress, ',', ', ') }}
        </div>
      </b-col>
    </b-row>
    <div v-if="this.hasServerSelection" class="mx-1 mb-1">
      <div v-if="this.hasQueries">
        <b-row align-h="between">
          <b-col lg="6" md="12" sm="12">
            <ServiceCounts
              :queries="this.formattedQueries"
              :topGraphField="graphFields.topLeft"
              :bottomGraphField="graphFields.bottomLeft"
            ></ServiceCounts>
          </b-col>
          <b-col lg="6" md="12" sm="12">
            <ServiceCounts
              :queries="this.formattedQueries"
              :topGraphField="graphFields.topRight"
              :bottomGraphField="graphFields.bottomRight"
            ></ServiceCounts>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Connectivity for {{ selectedServerIp }} (as Source)</h6>
            <IpConnectivities
              :url="ipConnectivitiesUrl"
              :timeRange="timeRange"
              ipRole="client"
            ></IpConnectivities>
          </b-col>
          <b-col>
            <h6>Connectivity for {{ selectedServerIp }} (as Destination)</h6>
            <IpConnectivities
              :url="serverIpConnectivitiesUrl"
              :timeRange="timeRange"
              ipRole="server"
            ></IpConnectivities>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="this.loaded" class="mx-1">
        {{ $t("No Data Available") }}
      </div>
      <SpinnerCmpt v-else></SpinnerCmpt>
    </div>
    <b-row>
      <DnsDetails :url="this.dnsSmartSamplerUrl"></DnsDetails>
    </b-row>
    <b-row>
      <ThreatDetails :serviceType="serviceType"></ThreatDetails>
    </b-row>
  </b-card>
</template>

<script>
import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import ServiceCounts from '@/xvisor/components/security/ServiceCounts.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import ThreatDetails from '@/xvisor/components/security/ThreatDetails.vue';
import DnsDetails from '@/xvisor/components/security/DnsDetails.vue';
import IpConnectivities from '@/xvisor/components/security/IpConnectivities.vue';
import hostnameFormatter from '@/xvisor/utilities/hostnameFormatter';
import serviceFields from '@/xvisor/constants/serviceFields';
import threatRole from '@/xvisor/constants/threatRole';
import trafficType from '@/xvisor/constants/trafficType';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    serviceType: {
      type: String,
      required: true,
    },
  },
  components: {
    ServiceCounts,
    SpinnerCmpt,
    ThreatDetails,
    DnsDetails,
    IpConnectivities,
  },
  data() {
    return {
      selectedServer: null,
      selectedRole: threatRole.server.value,
      queries: [],
      loaded: false,
    };
  },
  created() {
    SecurityEventBus.$on('update-selected-server', (server) => {
      this.selectedServer = server;
    });
    SecurityEventBus.$on('update-selected-role', (role) => {
      this.selectedServer = null;
      this.selectedRole = role;
    });
  },
  computed: {
    selectionHeader() {
      if (!this.hasServerSelection) return 'No Address Selected';
      return `Traffic Details for ${this.selectedServer.ip}`;
    },
    hasServerSelection() {
      return this.selectedServer !== null && this.selectedServer !== undefined;
    },
    selectedServerIp() {
      if (!this.hasServerSelection) return '';
      return this.selectedServer.ip;
    },
    ipConnectivitiesUrl() {
      return `/security/${this.selectedServerIp}/clientip/connectivities.json`;
    },
    serverIpConnectivitiesUrl() {
      return `/security/${this.selectedServerIp}/serverip/connectivities.json`;
    },
    dnsSmartSamplerUrl() {
      return `/security/${this.selectedServerIp}/dnssmartsampler.json`;
    },
    queryUrl() {
      return `/security/${this.selectedServerIp}/${this.serviceType}/details.json`;
    },
    hasQueries() {
      return this.loaded && this.queries && this.queries.length > 0;
    },
    formattedQueries() {
      return this.queries.map((query) => ({
        timestamp: query.timestamp,
        queriesReceivedCountsShortTerm: query.queriesReceivedCountsShortTerm,
        queriesMadeCountsShortTerm: query.queriesMadeCountsShortTerm,
        queriesReceivedCountsLongTerm: query.queriesReceivedCountsLongTerm,
        queriesMadeCountsLongTerm: query.queriesMadeCountsLongTerm,
        clientAttemptTpsPeakShortTerm: query.clientAttemptTpsPeakShortTerm,
        clientAcceptTpsPeakShortTerm: query.clientAcceptTpsPeakShortTerm,
        clientBpsSentPeakShortTerm: query.clientBpsSentPeakShortTerm,
        clientBpsReceivedPeakShortTerm: query.clientBpsReceivedPeakShortTerm,
        serverAcceptTpsPeakShortTerm: query.serverAcceptTpsPeakShortTerm,
        serverAcceptTpsPeakLongTerm: query.serverAcceptTpsPeakLongTerm,
        serverBpsSentPeakShortTerm: query.serverBpsSentPeakShortTerm,
        serverBpsReceivedPeakShortTerm: query.serverBpsReceivedPeakShortTerm,
      }));
    },
    graphFields() {
      const graphs = {
        topRight: '',
        bottomRight: '',
        topLeft: '',
        bottomLeft: '',
      };
      if (this.serviceType === trafficType.dns.value) {
        graphs.topRight = serviceFields.queriesReceivedCountsShortTerm.value;
        graphs.bottomRight = serviceFields.queriesMadeCountsShortTerm.value;
        graphs.topLeft = serviceFields.queriesReceivedCountsLongTerm.value;
        graphs.bottomLeft = serviceFields.queriesMadeCountsLongTerm.value;
      } else if (this.selectedRole === threatRole.client.value) {
        graphs.topRight = serviceFields.clientAttemptTpsPeakShortTerm.value;
        graphs.bottomRight = serviceFields.clientAcceptTpsPeakShortTerm.value;
        graphs.topLeft = serviceFields.clientBpsSentPeakShortTerm.value;
        graphs.bottomLeft = serviceFields.clientBpsReceivedPeakShortTerm.value;
      } else if (this.selectedRole === threatRole.server.value) {
        graphs.topRight = serviceFields.serverAcceptTpsPeakShortTerm.value;
        graphs.bottomRight = serviceFields.serverAcceptTpsPeakLongTerm.value;
        graphs.topLeft = serviceFields.serverBpsSentPeakShortTerm.value;
        graphs.bottomLeft = serviceFields.serverBpsReceivedPeakShortTerm.value;
      }
      return graphs;
    },
  },
  watch: {
    selectedServer() {
      if (this.hasServerSelection) this.httpGetQueries();
    },
    timeRange() {
      this.httpGetQueries();
      this.selectedServer = null;
    },
  },
  methods: {
    httpGetQueries() {
      this.loaded = false;
      this.$http
        .get(this.queryUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            role: this.selectedRole,
          },
        })
        .then((response) => { this.queries = response.data; })
        .finally(() => { this.loaded = true; });
    },
    hostnameFormatter,
  },
};
</script>

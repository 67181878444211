<template>
  <div class="d-block">
    <div id="xtend-title-section">
      <h6 v-if="hasData" class="xtend-title d-inline-block">{{ name }}</h6>
      <h6 v-else class="xtend-title d-inline-block">xTend</h6>
      <b-icon icon="info-circle" class="h3" variant="dark" id="xtend-info"></b-icon>
    </div>
    <b-popover
      target="xtend-info"
      triggers="hover"
      placement="bottom"
      class="large-width"
    >
      <div id="xtend-title-inside-icon">
        <h6 v-if="hasData" class="d-inline-block"> IP: {{ ip }}</h6>
        <h6 v-else class="d-inline-block">No Data Available</h6>
      </div>
    </b-popover>
  </div>
</template>

<script>
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      info: undefined,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    name() {
      return this.info && this.info.devicename ? this.info.devicename : 'xTend';
    },
    ip() {
      return this.info && this.info.ip ? this.info.ip : '';
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    hasData() {
      return this.info;
    },
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.info = response.data; });
    },
  },
};
</script>

<style scoped>

#xtend-title-section {
  margin-top: 0.1rem;
  display: flex;
  justify-content: start;
  align-items: center;
}

.xtend-title {
  margin-right: 1.1rem;
}

@media screen and (max-width: 650px) {
  .xtend-title {
    display: none !important;
  }
}
</style>

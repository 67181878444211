/** Convert large numbers to human readable format.
 *
 * @param number The number to convert.
 * @param decimal The number of decimal places in the result.
 * @return Readable format of the number.
 * Ex.
 *  readableNumber(2615000, 2) => 2.61 M
 *  readableBytes(1000, 1) => 1.0 K
 */
export default function readableNumber(number, decimal = 1) {
  /** Units
   * "K" (Thousand) -> 1e3
   * "M" (Million) -> 1e6
   * "B" (Billion) -> 1e9
   * "T" (Trillion) -> 1e12
   * "P" (Peta) -> 1e15
   * "E" (Exa) -> 1e18
   */
  const units = ['K', 'M', 'B', 'T', 'P', 'E'];

  // Base case
  if (number < 1000) return number.toString();
  const base = 1000;
  const index = Math.floor(Math.log(number) / Math.log(base));
  const value = (number / (base ** index)).toFixed(decimal);

  // To remove the ".0" so 1.0 becomes 1.
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  return `${(value.replace(regex, '$1'))}${units[index]}`;
}

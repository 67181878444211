<template>
  <b-button
    class="btn-icon"
    variant="flat"
    v-b-tooltip.hover.variant.bottom
    title="Delete"
    @click="httpDelete"
  >
    <b-icon icon="trash" />
  </b-button>
</template>

<script>
import DnsEventBus from '@/xvisor/bus/DnsEventBus';

export default {
  props: {
    dns: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteDnsUrl: '/policysettings/dnsServer',
    };
  },
  computed: {
    deleteDnsForm() {
      return { dnsServer: this.dns };
    },
  },
  methods: {
    httpDelete() {
      this.$http
        .delete(this.deleteDnsUrl, { data: this.deleteDnsForm })
        .then(() => {
          this.$bvToast.toast('Successfully deleted DNS IP', { variant: 'success' });
          DnsEventBus.$emit('changed');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete DNS IP', { variant: 'danger' });
        });
    },
  },
};
</script>

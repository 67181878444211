<template>
  <div>
    <b-row class="mx-0-5 my-1" align-h="between" align-v="center">
      <b-col cols="12" md="6">
        <h4>{{ $t("Ops Status") }}</h4>
      </b-col>
    </b-row>
    <b-table
      responsive
      selectable
      striped
      primary-key="dataSource"
      :items="reports"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
      table-class="text-nowrap"
      class="table-with-actions-min-height"
    >
      <template #cell(lastTimeStamp)="row">
        {{ formatDate(row.value) }}
      </template>
      <template #cell(status)="row">
        <StatusLabel :status="row.value"></StatusLabel>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      align="right"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      class="mb-1 mr-1"
    />
  </div>
</template>

<script>
import moment from 'moment';

import StatusLabel from '@/xvisor/components/ops/StatusLabel.vue';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  components: {
    StatusLabel,
  },
  data() {
    return {
      reports: [],
      tableFields: [
        { key: 'status', label: 'Status' },
        { key: 'dataSource', label: 'Data Source', sortable: true },
        { key: 'lastTimeStamp', label: 'Last Timestamp', sortable: true },
      ],
      momentTimeFormat,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filteredLength: null,
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.reports.length;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
    httpGet() {
      this.$http
        .get('/ops/status.json')
        .then((response) => { this.reports = response.data; });
    },
    moment,
  },
};
</script>

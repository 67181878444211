<template>
  <b-modal ref="modal" title="Add Aternity Integration" centered>
    <validation-observer ref="addIntegration">
      <b-form>
        <b-row>
          <b-col cols="12">
            <validation-provider name="URL" rules="required|url" v-slot="{ errors }">
              <b-form-group label="URL" label-cols-md="4">
                <b-form-input
                  autofocus
                  placeholder="URL"
                  type="url"
                  v-model="newIntegration.url"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="Version" rules="required" v-slot="{ errors }">
              <b-form-group label="Version" label-cols-md="4">
                <b-form-select
                  v-model="newIntegration.version"
                  :options="integration.versions"
                  :state="errors[0] ? false : null"
                >
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="Username" rules="required" v-slot="{ errors }">
              <b-form-group label="Username" label-cols-md="4">
                <b-form-input
                  autofocus
                  placeholder="Username"
                  v-model="newIntegration.credentials.username"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="Password" rules="required" v-slot="{ errors }">
              <b-form-group label="Password" label-cols-md="4">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    autofocus
                    placeholder="Password"
                    v-model="newIntegration.credentials.password"
                    :type="passwordFieldType"
                    :state="errors[0] ? false : null"
                    class="form-control-merge"
                  ></b-form-input>
                  <b-input-group-append is-text>
                    <b-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="errors[0]">Password is required.</template>
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-left mr-1" @click="addIntegration">Submit</b-button>
        <b-button variant="outline-primary" class="float-left" @click="$refs.modal.hide()">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import IntegrationsEventBus from '@/xvisor/bus/IntegrationsEventBus';
import { integrationTypes } from '@/xvisor/constants/integrations';
import generateHash from '@/xvisor/utilities/generateHash';

export default {
  props: {
    url: {
      type: String,
      require: true,
    },
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newIntegration: {
        type: integrationTypes.aternity,
        url: '',
        version: this.integration.versions[0],
        credentials: {
          username: '',
          password: '',
        },
      },
      passwordFieldType: 'password',
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'eye' : 'eye-slash';
    },
  },
  methods: {
    addIntegration() {
      this.$refs.addIntegration.validate().then((result) => {
        if (result) {
          this.$http
            .post(this.url, {
              ...this.newIntegration,
              credentials: {
                ...this.newIntegration.credentials,
                password: generateHash(this.newIntegration.credentials.password),
              },
            })
            .then(() => {
              this.$bvToast.toast('Successfully added integration', { variant: 'success' });
              IntegrationsEventBus.$emit('update');
            })
            .catch(() => { this.$bvToast.toast('Failed to add integration', { variant: 'danger' }); });
        }
      });
    },
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>

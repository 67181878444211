<template>
  <b-modal :id="modalId" ref="add-xome-modal" title="Add XOME" size="lg" hide-footer>
    <b-row class="mb-1">
      <b-col cols="12">
        <a href="/kb/xome-installation" class="underline">Installation Instructions</a>
      </b-col>
    </b-row>
    <validation-observer ref="addXomeForm">
      <validation-provider rules="required" name="Xome's name" v-slot="{ errors }">
        <b-form-group
          label-cols="2"
          content-cols="10"
          label="Name*"
          label-for="xome-name-input"
        >
          <b-form-input
            autofocus
            title="Add XOME"
            type="text"
            :state="errors[0] ? false: null"
            placeholder="XOME Name"
            v-model="xome.xomeName"
            class="form-control-merge"
            id="xome-name-input"
          >
          </b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group
        label-cols="2"
        content-cols="10"
        label="Location*"
        label-for="xome-location-input"
      >
        <b-button-group id="xome-location-input" class="d-flex">
          <b-button
            v-for="(btn, idx) in buttons"
            :key="idx"
            :pressed.sync="btn.active"
            variant="outline-primary"
            @click="toggleButtons(btn.caption)"
            class="mb-1"
          >
            <b-img v-if="btn.img" :src="btn.img" class="mr-1 provider-icon-height"></b-img>
            <b-icon v-else-if="btn.icon" :icon="btn.icon" class="mr-1 text-primary provider-icon-height"></b-icon>
            {{ btn.caption }}
          </b-button>
        </b-button-group>
        <validation-provider rules="required" name="Xome Location" v-slot="{ errors }">
          <b-form-select
            v-model="xome.zoneId"
            :options="locationOptions"
            :state="errors[0] ? false: null"
          ></b-form-select>
          <span class="text-danger text-xs">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label-cols="2"
        content-cols="10"
        label="Tag(s)"
        label-for="xome-tag-input"
      >
        <div id="xome-tag-input">
          <div>
            <b-row v-for="(tag, index) in xome.tags" :key="index" class="mb-1">
              <b-col cols="4">
                <validation-provider rules="required" name="Tag" v-slot="{ errors }">
                  <b-form-input
                    placeholder="Key"
                    v-model="tag[0]"
                    :state="errors[0] ? false: null"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <b-form-input
                  placeholder="Value"
                  v-model="tag[1]"
                ></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button @click="deleteTag(index)" variant="outline-danger"><b-icon icon="trash"></b-icon></b-button>
              </b-col>
            </b-row>
          </div>
          <b-row class="mb-1">
            <b-col cols="12">
              <span class="text-danger text-xs" v-if="containsDuplicateKeys">
                Tags cannot contain duplicate keys.
              </span>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="4">
              <b-button @click="addTag()" variant="primary" class="mb-1" size="sm">+ Add Tag</b-button>
            </b-col>
          </b-row>
        </div>
      </b-form-group>
    </validation-observer>
    <b-row align-h="start">
      <b-col>
        <b-button @click="addAction" variant="primary" class="mr-1">
          Submit
        </b-button>
        <b-button @click="cancelAction" variant="outline-secondary">
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import XomesEventBus from '@/xvisor/bus/XomesEventBus';

import awsIcon from '@/assets/images/serviceIcons/awsIcon.png';
import azureIcon from '@/assets/images/serviceIcons/azureIcon.png';
import gcpIcon from '@/assets/images/serviceIcons/gcpIcon.png';

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      xome: {
        xomeName: '',
        tags: [],
        zoneId: '',
      },
      provider: '',
      buttons: [
        {
          caption: 'On-Premise',
          active: false,
          icon: 'building',
        },
        {
          caption: 'AWS',
          active: false,
          img: awsIcon,
        },
        {
          caption: 'Azure',
          active: false,
          img: azureIcon,
        },
        {
          caption: 'GCP',
          active: false,
          img: gcpIcon,
        },
      ],
      zones: [],
      onPremiseOffices: [],
      gcpOffices: [],
      awsOffices: [],
      azureOffices: [],
      mainProps: { height: 25 },
    };
  },
  mounted() {
    this.getZones();
  },
  computed: {
    // Return correct location options based on location selection.
    locationOptions() {
      if (this.provider === 'On-Premise') {
        return this.onPremiseOffices;
      } if (this.provider === 'AWS') {
        return this.awsOffices;
      } if (this.provider === 'Azure') {
        return this.azureOffices;
      } if (this.provider === 'GCP') {
        return this.gcpOffices;
      }
      return [];
    },
    // Returns true if xomeTags contains duplicate Keys
    containsDuplicateKeys() {
      const seen = [];
      for (let i = 0; i < this.xome.tags.length; i += 1) {
        const key = this.xome.tags[i][0];
        if (seen.indexOf(key) !== -1) {
          return true;
        }
        seen.push(key);
      }
      return false;
    },
  },
  methods: {
    formatXome(xome) {
      const formattedXome = xome;
      formattedXome.tags = Object.fromEntries(xome.tags);
      return formattedXome;
    },
    addAction() {
      this.$refs.addXomeForm.validate().then((result) => {
        if (result && !this.containsDuplicateKeys) {
          this.$http
            .post('/xomes/add', this.formatXome(this.xome))
            .then((response) => {
              this.$bvToast.toast('Successfully added XOME', { variant: 'success' });
              const xomeRegisterInfo = response.data;

              // Emit both externally on event bus and locally up to parent component.
              XomesEventBus.$emit('added', xomeRegisterInfo);
              this.$emit('added', xomeRegisterInfo);

              this.$refs['add-xome-modal'].hide();
              this.clearForm();
            })
            .catch(() => {
              this.$bvToast.toast('Failed to add XOME', { variant: 'danger' });
            });
        }
      });
    },
    sortZones() {
      this.zones.forEach((zone) => {
        if (zone.cloudProvider == null) {
          this.onPremiseOffices.push(zone);
        } else if (zone.cloudProvider === 'aws') {
          this.awsOffices.push(zone);
        } else if (zone.cloudProvider === 'gcp') {
          this.gcpOffices.push(zone);
        } else if (zone.cloudProvider === 'azure') {
          this.azureOffices.push(zone);
        }
      });
    },
    getZones() {
      this.$http
        .get('/agents/zone/list.json')
        .then((response) => {
          this.zones = response.data.map((zone) => ({
            value: zone.id,
            text: zone.name,
            cloudProvider: zone.cloudProvider,
          }));
        })
        .finally(() => {
          this.loaded = true;
          this.sortZones();
        });
    },
    toggleButtons(name) {
      this.provider = name;
      this.xome.zoneId = '';
      this.buttons.forEach((obj, i) => {
        if (obj.caption === name) {
          this.buttons[i].active = true;
        } else {
          this.buttons[i].active = false;
        }
      });
    },
    addTag() {
      this.xome.tags.push(['', '']);
    },
    deleteTag(index) {
      this.xome.tags.splice(index, 1);
    },
    clearForm() {
      this.xome.xomeName = '';
      this.xome.tags = [];
      this.toggleButtons('');
      this.provider = '';
      this.xome.zoneId = '';
    },
    cancelAction() {
      this.clearForm();
      this.$refs['add-xome-modal'].hide();
    },
  },
};
</script>

/** Convert mega bits per second based on the max value's unit.
 *
 * @param mbps The number of mega bits per second to convert.
 * @param maxValUnit The unit of the max value.
 * @return Converted value based on the max value's unit.
 */
export default function convertMbpsByMaxValUnit(mbps, maxValUnit) {
  const base = 1000;
  let result = 0;
  switch (maxValUnit) {
    case 'bps':
      result = mbps * base * base;
      break;
    case 'Kbps':
      result = mbps * base;
      break;
    case 'Gbps':
      result = mbps / base;
      break;
    case 'Tbps':
      result = mbps / base / base;
      break;
    default:
      result = mbps;
  }
  return result;
}

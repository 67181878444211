<template>
  <div>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="getApiKey"
      v-b-tooltip.hover.variant.bottom
      title="Get API Key"
    >
      <b-icon icon="key" />
    </b-button>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.updateApiKey.$refs.modal.show()"
      v-b-tooltip.hover.variant.bottom
      title="Edit"
    >
      <b-icon icon="pencil" />
    </b-button>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.deleteApiKey.show()"
      v-b-tooltip.hover.variant.bottom
      title="Delete"
    >
      <b-icon icon="trash" />
    </b-button>
    <b-modal ref="apiKeyToken" title="API Key">
      <prism>{{ token }}</prism>
    </b-modal>
    <UpdateApiKey ref="updateApiKey" :url="updateUrl" :apiKey="apiKey" @updated="$emit('updated')"></UpdateApiKey>
    <b-modal ref="deleteApiKey" title="Delete User" @ok="deleteApiKey">
      Are you sure you want to delete this API key?
      You cannot undo this action and it will result in all associated data being removed.
    </b-modal>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

import UpdateApiKey from '@/xvisor/components/settings/api-key/UpdateApiKey.vue';

export default {
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
  },
  components: {
    Prism,
    UpdateApiKey,
  },
  data() {
    const apiKeyActionUrl = `/settings/apikey/${this.apiKey.id}`;
    return {
      token: undefined,
      getApikeyUrl: `${apiKeyActionUrl}/token.json`,
      updateUrl: `${apiKeyActionUrl}/update`,
      deleteUrl: `${apiKeyActionUrl}`,
    };
  },
  methods: {
    deleteApiKey() {
      this.$http
        .delete(this.deleteUrl)
        .then(() => {
          this.$bvToast.toast('Successfully deleted API key', { variant: 'success' });
          this.$refs.deleteApiKey.hide();
          this.$emit('updated');
        })
        .catch(() => { this.$bvToast.toast('Failed to delete API key', { variant: 'danger' }); });
    },
    getApiKey() {
      this.$refs.apiKeyToken.hide();
      this.token = undefined;
      this.$http
        .get(this.getApikeyUrl)
        .then((response) => {
          this.token = response.data;
          this.$refs.apiKeyToken.show();
        })
        .catch(() => { this.$bvToast.toast('Failed to get the API key', { variant: 'danger' }); });
    },
  },
};
</script>

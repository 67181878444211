<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="radial-progress-bar"
  ></e-chart>
  <div v-else-if="loaded">{{ $t("No Alerts") }}<b-icon icon="hand-thumbs-up" shift-h="8"></b-icon></div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import alertSeverities from '@/xvisor/constants/alertSeverities';
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 120,
    },
    url: {
      type: String,
      required: true,
    },
    appId: {
      type: Number,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      urlData: [],
      loaded: false,
    };
  },
  computed: {
    alertSeveritySummary() {
      const alertSummary = {};
      const notices = this.urlData.filter((row) => row.severity === alertSeverities.notice);
      const warnings = this.urlData.filter((row) => row.severity === alertSeverities.warning);
      const criticals = this.urlData.filter((row) => row.severity === alertSeverities.critical);
      if (notices.length > 0) this.$set(alertSummary, 'notice', notices[0].count);
      if (warnings.length > 0) this.$set(alertSummary, 'warning', warnings[0].count);
      if (criticals.length > 0) this.$set(alertSummary, 'critical', criticals[0].count);
      return alertSummary;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    total() {
      if (this.hasData) {
        return this.urlData.map((row) => row.count).reduce((a, b) => a + b);
      }
      return undefined;
    },
    seriesRadiusData() {
      const radiusAxisData = [];
      const seriesData = [];
      this.urlData.forEach((severityCount) => {
        radiusAxisData.push(severityCount.severity);
        seriesData.push({
          value: severityCount.count,
          severityType: this.severityLabel(severityCount.severity),
          totalAlerts: this.total,
          itemStyle: {
            color: this.colors(severityCount.severity),
          },
        });
      });
      return { radiusAxisData, seriesData };
    },
    hasData() {
      return this.loaded && this.urlData && this.urlData.length > 0;
    },
    chartOptions() {
      return {
        title: {
          left: 'center',
          top: 'center',
          text: `Total \n ${this.total}`,
          textStyle: {
            fontSize: 10,
            color: this.toggleColor,
          },
        },
        tooltip: {
          position: (pos, params, dom, rect, size) => {
            /* Tooltip will be fixed on the right if mouse hovering on the left,
             * and on the left if hovering on the right.
             */
            const obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          formatter: (info) => {
            const { data, marker } = info;
            return `
              <div class="apache-echarts-tooltip">
                ${marker} ${data.severityType}: ${data.value}
                </br>
                ${((data.value / data.totalAlerts) * 100).toFixed(1)}%
              </div>
            `;
          },
        },
        polar: {
          radius: ['50%', '90%'],
        },
        angleAxis: {
          max: this.total,
          startAngle: 90,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        radiusAxis: {
          type: 'category',
          data: this.seriesRadiusData.radiusAxisData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        series: {
          type: 'bar',
          data: this.seriesRadiusData.seriesData,
          coordinateSystem: 'polar',
          emphasis: {
            itemStyle: {
              opacity: 0.4,
            },
          },
          showBackground: true,
          backgroundStyle: {
            color: colorPalette.lightgrey,
            opacity: 0.3,
          },
        },
      };
    },
  },
  mounted() {
    this.httpGet();
    this.healthEmit();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    urlData() {
      this.healthEmit();
    },
  },
  methods: {
    healthEmit() {
      if (this.urlData) {
        this.$emit('alertCount', this.appId, this.alertSeveritySummary);
      }
    },
    severityLabel(severityType) {
      if (severityType === alertSeverities.critical) return 'Critical';
      if (severityType === alertSeverities.warning) return 'Warning';
      return 'Notice';
    },
    colors(severityType) {
      if (severityType === alertSeverities.critical) return colorPalette.red;
      if (severityType === alertSeverities.warning) return colorPalette.orange;
      return colorPalette.blue;
    },
    httpGet() {
      this.loaded = false;
      this
        .$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.urlData = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style scoped>
.radial-progress-bar {
  height: 70px;
}
</style>

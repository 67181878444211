<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    :style="{height, width}"
    :class="spacing"
  />
  <div v-else-if="loaded">{{ $t("Yay! Health is fine") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import granularity from '@/xvisor/utilities/granularity';
import readableBytes from '@/xvisor/utilities/readableBytes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    tooltipPosition: {
      type: String,
      default: 'top',
    },
    spacing: {
      type: String,
    },
    width: {
      type: String,
      default: '100%',
    },
    // Specifies the color(s) depending on given value(s)
    colorRange: {
      type: Object,
    },
    hasByteValue: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  components: {
    SpinnerCmpt,
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
      rowLength: 6,
      targetHealthFactorScaleTen: 10,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    timeSet() {
      return new Set(this.series.map((point) => point.time));
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedUtilizationSeries() {
      if (this.hasByteValue) {
        return this.series.map((element) => (
          { ...element, value: element.totalBytes }
        ));
      }
      return this.series.map((element) => (
        { ...element, value: element.value }
      ));
    },
    // Formats the heatmap to have the correct number of cells if it's less than row length.
    seriesFormat() {
      let paddedSeries = [...this.formattedUtilizationSeries];
      if (paddedSeries.length < this.rowLength) {
        for (let i = this.series.length; i < this.rowLength; i += 1) {
          paddedSeries.push({});
        }
        // In the case there are more than 6 buckets, we retrieve the 6 most recent.
      } else if (paddedSeries.length > this.rowLength) {
        paddedSeries = paddedSeries.slice(-6);
      }
      const formattedSeries = paddedSeries.map((element, index) => {
        const x = Math.floor(index / this.rowLength);
        const y = index % this.rowLength;
        return [y, x, element];
      });
      return formattedSeries;
    },
    heatmapData() {
      const formattedSeries = this.seriesFormat
        .map((item) => ({
          time: item[2].time,
          value: [item[0], item[1], item[2].value || '-'],
        }));
      return formattedSeries;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    /** Return a min and max bytes for the given series. */
    minMaxBytes() {
      const bytes = this.series ? this.series.map((point) => point.value) : [];
      const [min, max] = [Math.min(...bytes), Math.max(...bytes)];
      return { min, max };
    },
    visualMapOptions() {
      // In order to correctly display byte values, a min and max calculation is needed.
      if (this.hasByteValue) {
        return {
          precision: 1,
          show: false,
          min: this.minMaxBytes.min ? this.minMaxBytes.min : null,
          max: this.minMaxBytes.max ? this.minMaxBytes.max : null,
          inRange: {
            color: [colorPalette.white, colorPalette.teal],
          },
        };
      }
      // For non-byte values, the options are passed by other components.
      if (this.colorRange) return this.colorRange;
      return {};
    },
    chartOptions() {
      return {
        title: {
          text: this.title ? this.title : '',
          top: 0,
          left: 15,
          textStyle: {
            color: this.toggleColor,
            fontSize: 12,
          },
        },
        tooltip: {
          position: this.tooltipPosition,
          formatter: (params) => {
            const value = this.hasByteValue
              ? readableBytes(params.data.value[2])
              : `Health: ${(params.data.value[2] * this.targetHealthFactorScaleTen).toFixed(1)}`;
            return `
              <div class="apache-echarts-tooltip">
                <div>${moment(params.data.time).format(this.timeSeriesFormat)}</div>
                <div> ${params.marker} ${value}</div>
              </div>
            `;
          },
        },
        grid: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: true,
          },
        },
        visualMap: this.visualMapOptions,
        series: [
          {
            name: 'Data',
            type: 'heatmap',
            data: this.heatmapData,
            itemStyle: {
              borderColor: colorPalette.white,
              borderWidth: 2,
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity6(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

/** Integration Types and Versions. */
export const integrationTypes = {
  newRelic: 'newrelic',
  aternity: 'aternity',
};

export const integrationInfo = {
  [integrationTypes.newRelic]: {
    name: 'NewRelic',
    versions: ['v2'],
    defaultUrl: 'https://api.newrelic.com',
  },
  [integrationTypes.aternity]: {
    name: 'Aternity',
    versions: ['v2'],
  },
};

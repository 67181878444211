<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="mb-n5"
  ></e-chart>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import numberUtils from '@/xvisor/utilities/numberUtils';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const SYMBOL_SIZE_MAX = 40;
const SYMBOL_SIZE_MIN = 10;

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      syslogTs: [],
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  computed: {
    allTimes() {
      return this.syslogTs.map((ts) => ts.syslogPoints.map((point) => point.time)).flat();
    },
    allValues() {
      return this.syslogTs.map((ts) => ts.syslogPoints.map((point) => point.count)).flat();
    },
    timeSet() {
      return new Set(this.allTimes);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    /** Return a min and max timeRange for the axis. */
    minMaxTimeRange() {
      const times = this.allTimes.map((element) => new Date(element));
      const [min, max] = [Math.min(...times), Math.max(...times)];
      return { min, max };
    },
    /** Return a min and max values which is severity counts. */
    minMaxValues() {
      const [min, max] = [Math.min(...this.allValues), Math.max(...this.allValues)];
      return { min, max };
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    seriesNames() {
      return this.syslogTs.map((ts) => ts.name);
    },
    formattedData() {
      return this.syslogTs.map((ts) => ts.syslogPoints.map((point) => ({
        value: [
          point.time,
          point.count,
          point.facilities,
        ],
      })));
    },
    singleAxis() {
      return this.seriesNames.map((_, index) => ({
        min: this.minMaxTimeRange.min,
        max: this.minMaxTimeRange.max,
        left: 150,
        type: 'time',
        boundaryGap: false,
        data: this.allTimes,
        top: this.calculateDistanceBetween(index),
        right: 50,
        height: '5%',
        axisLabel: {
          interval: 2,
          color: this.toggleColor,
          formatter: (value) => moment(value).format(this.timeSeriesFormat),
        },
        axisLine: {
          lineStyle: {
            color: this.toggleColor,
          },
        },
      }));
    },
    titles() {
      const titleSpacingFactor = 15;
      const titleOffsetFactor = 0.5;
      return this.seriesNames.map((name, index) => ({
        textBaseline: 'middle',
        // Aligns the titles with the axis and spaces them evenly.
        top: `${(index + titleOffsetFactor) * titleSpacingFactor}%`,
        text: name,
        textStyle: {
          color: this.toggleColor,
          fontSize: 12,
        },
      }));
    },
    series() {
      return this.seriesNames.map((_, index) => ({
        singleAxisIndex: index,
        coordinateSystem: 'singleAxis',
        type: 'scatter',
        data: this.formattedData[index],
        symbolSize: (value) => this.scaleValue(value[1]),
        symbolOffset: [0, -10],
      }));
    },
    hasData() {
      return this.loaded && this.syslogTs && this.syslogTs.length > 0;
    },
    timeFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    chartOptions() {
      return {
        color: [
          colorPaletteShade.red7,
          colorPalette.red,
          colorPaletteShade.orange7,
          colorPaletteShade.orange5,
          colorPaletteShade.orange3,
          colorPalette.gray,
        ],
        tooltip: {
          trigger: 'axis',
          position: 'top',
          renderMode: 'html',
          formatter: (info) => this.tooltipFormat(info),
        },
        singleAxis: this.singleAxis,
        title: this.titles,
        series: this.series,
      };
    },
  },
  methods: {
    calculateDistanceBetween(seriesIndex) {
      // Positions the axis within frame and spaces them evenly.
      const axisSpacingFactor = 15;
      const axisOffsetFactor = 5;
      return `${(seriesIndex * axisSpacingFactor) + axisOffsetFactor}%`;
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.syslogTs = response.data; })
        .finally(() => { this.loaded = true; });
    },
    scaleValue(value) {
      const scaledValue = numberUtils.mapNumberFromRangeToRange(
        value,
        this.minMaxValues.min,
        this.minMaxValues.max,
        SYMBOL_SIZE_MIN,
        SYMBOL_SIZE_MAX,
      );
      return scaledValue;
    },
    tooltipFormat(info) {
      const seriesIndex = info[0].axisIndex;
      const markerChip = info[0].marker;
      const name = `<th class="float-left">${markerChip}${this.syslogTs[seriesIndex].name}</th>`;
      const time = `<td class="float-left">Time: ${moment(info[0].value[0]).format(this.timeFormat)}</td>`;
      const counts = `<td class="float-left">Counts: ${info[0].value[1]}</td>`;
      const facilities = `<td class="float-left">Facilities: ${info[0].value[2].join(', ')}</td>`;
      return `
        <div class="apache-echarts-tooltip">
          <table class="table-borderless text-center">
            <tr>${name}</tr>
            <tr>${time}</tr>
            <tr>${counts}</tr>
            <tr>${facilities}</tr>
          </table>
        </div>
      `;
    },
  },
};
</script>

<template>
  <div>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      :class="['apache-echarts-default-graph', httpGraphMargin]"
      autoresize
    ></e-chart>
    <div v-else-if="loaded" :class="noDataAvailableMargin">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import probeEventTypes from '@/xvisor/constants/probeEventTypes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const colors = [
  colorPaletteShade.blue4,
  colorPaletteShade.green4,
  colorPaletteShade.orange4,
  colorPaletteShade.red4,
  colorPaletteShade.purple4,
  colorPaletteShade.yellow4,
  colorPaletteShade.teal4,
  colorPaletteShade.indigo4,
  colorPaletteShade.pink4,
  colorPaletteShade.gray4,
];

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
    metric: {
      type: String,
      required: true,
      validator: (value) => [
        'rtd',
        'jitter',
        'avbw',
        'http',
        'domainDelay',
        'domainAvgLoss',
        'domainLinkDelay',
      ].includes(value),
    },
    targetId: {
      type: Number,
      required: true,
    },
    issueTimeSelected: {
      type: Number,
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: [],
      eventSeries: [],
      loaded: false,
    };
  },
  computed: {
    // Margin correctionf or https graphs when there is no data.
    noDataAvailableMargin() {
      return this.metric !== 'http' ? 'mt-1-5' : '';
    },
    // Margin correction for https graphs when they display data.
    httpGraphMargin() {
      return this.metric === 'http' ? 'mt-n1-0' : '';
    },
    probeEventParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
        },
      };
    },
    initialMarkline() {
      // Initializes the line to the end of the graph.
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const seriesData = this.series
        .filter((ts) => ts.points.length !== 0)
        .map((ts) => ({
          data: times.map((time) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
      return seriesData[0].data.at(-1)[0];
    },
    timeSet() {
      return new Set(this.series.map((ts) => ts.points.map((point) => point.time)).flat());
    },
    hasData() {
      return this.loaded
        && this.series && this.series.length > 0
        && (this.series[0] && this.series[0].points && this.series[0].points.length > 0);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      return this.series
        .filter((ts) => ts.points.length !== 0)
        .map((ts) => ({
          name: ts.name,
          type: 'line',
          stack: this.isStacked ? 'Total' : '',
          areaStyle: this.isStacked ? {} : null,
          showSymbol: false,
          markLine: {
            symbol: 'circle',
            lineStyle: {
              type: 'solid',
            },
            data: [{ xAxis: this.issueTimeSelected ? this.issueTimeSelected : this.initialMarkline }],
            label: {
              formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
            },
          },
          markPoint: {
            symbol: 'triangle',
            symbolSize: 15,
            label: {
              offset: [0, 5],
              fontSize: 10,
            },
            itemStyle: {
              color: 'orange',
            },
            tooltip: {
              trigger: 'item',
              formatter: (info) => {
                const cell = info.data;
                const header = `
                  <div class="apache-echarts-tooltip">
                    <div>
                      ${info.marker} ${info.name} / ${moment(cell.xAxis).format(this.timeSeriesFormat)}
                    </div>
                `;
                const eventsRow = `<div># Events: ${cell.value > 0 ? cell.value.toFixed(0) : 0}</div>`;
                let eventMsgs = '';
                eventMsgs = `
                    <div> ${cell.name}: ${cell.msg}.</div>
                `;
                return `${[header, eventsRow, ...eventMsgs].join('')} </div>`;
              },
            },
            data: this.eventSeries ? this.markPointHelper : [],
          },
          data: times.map((time) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
    },
    chartUnit() {
      if (this.metric === 'avbw') return 'Mbps';
      if (this.metric === 'domainAvgLoss') return '%';
      return 'ms';
    },
    chartOptions() {
      return {
        color: colors,
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${this.chartTooltipContent(info)}
            </div>
          `,
        },
        grid: [
          {
            left: 50,
            right: 60,
            top: 30,
            bottom: 30,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: this.chartUnit,
            nameLocation: 'center',
            nameGap: 35,
            min: 0,
            max: this.maxHeightOfChart,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => value.toFixed(1),
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
    maxHeightOfChart() {
      let maxHeight = 0;
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const timeValueMap = new Map();
      times.forEach((time) => {
        this.series.filter((ts) => ts.points.length !== 0)
          .forEach((ts) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            if (timePoint && this.isStacked) {
              timeValueMap.set(timePoint.time,
                timeValueMap.get(timePoint.time)
                  ? timeValueMap.get(timePoint.time) + timePoint.value
                  : timePoint.value);
            } else {
              maxHeight = Math.max(maxHeight, timePoint ? timePoint.value : 0);
            }
          });
      });
      timeValueMap.forEach((val) => {
        maxHeight = Math.max(maxHeight, val);
      });
      this.markPointHelper.forEach((event) => {
        maxHeight = Math.max(maxHeight, event.yAxis);
      });
      return maxHeight;
    },
    markPointHelper() {
      if (this.metric === 'domainLinkDelay') {
        return this.eventSeries.map((event) => ({
          xAxis: event.time,
          yAxis: event.value,
          // There will always be 1 event for each marker.
          value: 1,
          baseline: event.baseline,
          name: event.name,
          msg: event.msg,
          orgName: event.orgName,
        }));
      }
      return this.eventSeries.map((event) => ({
        xAxis: event.time,
        yAxis: event.value,
        // There will always be 1 event for each marker.
        value: 1,
        baseline: event.baseline,
        name: event.name,
        msg: event.msg,
      }));
    },
  },
  watch: {
    targetId() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    calculateUnit(eventName) {
      if (eventName === probeEventTypes.avbw) return 'Mbps';
      if (eventName === probeEventTypes.appPacketLoss) return '';
      if (eventName === probeEventTypes.domainAvgLoss) return '';
      return 'ms';
    },
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.urls.tsUrl, this.httpParams),
          this.$http.get(this.urls.eventUrl, this.probeEventParams),
        ])
        .then(axios.spread((tsRes, eventRes) => {
          this.series = tsRes.data;
          this.eventSeries = eventRes.data;
        }))
        .finally(() => { this.loaded = true; });
    },
    chartTooltipContent(info) {
      let result = '';
      for (let i = 0; i < info.length; i += 1) {
        result += `${info[i].marker} ${info[i].seriesName}: ${info[i].value[1].toFixed(1)} ${this.chartUnit} <br>`;
      }
      return result;
    },
  },
};
</script>

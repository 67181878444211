import getLinkId from '@/xvisor/components/app/networkTab/pathroutes/utils';

export default function checkBlockLinkEvent(nodeIds, metricMap, linkBaselineMap) {
  for (let i = 0; i < nodeIds.length; i += 1) {
    for (let j = 0; j < nodeIds.length; j += 1) {
      const linkId = getLinkId(nodeIds[i], nodeIds[j]);
      if (metricMap[linkId] && linkBaselineMap[linkId]) {
        const delay = Number(metricMap[linkId].delay).toFixed(2);
        const baselineDelay = Number(linkBaselineMap[linkId].delay).toFixed(2);
        const stdDeviation = Number(linkBaselineMap[linkId].stdDevDelay).toFixed(2);
        const maxDelayThreshold = baselineDelay + (2 * stdDeviation);
        if (maxDelayThreshold !== 0 && delay > maxDelayThreshold) {
          return true;
        }
      }
    }
  }
  return false;
}

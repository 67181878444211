<template>
  <b-card no-body>
    <b-row class="mx-1 my-1" align-h="between" align-v="center">
      <b-col cols="12" md="6">
        <ShowEntries>
          <template #input>
            <b-form-select
              v-model="perPage"
              :options="options"
              size="sm"
            ></b-form-select>
          </template>
        </ShowEntries>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <SearchBar class="mr-1">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
          <AddUser class="float-right"></AddUser>
        </div>
      </b-col>
    </b-row>
    <b-table
      id="users-table"
      class="table-with-actions-min-height"
      striped
      responsive
      :items="users"
      :fields="tableFields"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template #cell(name)="row">
        <b-avatar :variant="randomVariant()">{{ getUserInitials(row.value) }}</b-avatar>
        <span class="text-warning ml-1">{{ row.value }}</span>
      </template>
      <template #cell(superAdmin)="row">
        <b-icon variant="warning" :icon="superAdminIcon(row.value)"></b-icon>
        {{ superAdminTitle(row.value) }}
      </template>
      <template #cell(status)="row">
        <b-badge pill :variant="userStatusVariant(row.value)">
          {{ userStatusText(row.value)}}
        </b-badge>
      </template>
      <template #cell(actions)="row">
        <UserAction
          :updateUrl="row.item.updateUrl"
          :deleteUrl="row.item.deleteUrl"
          :resendInviteUrl="row.item.status === userStatus.inactive ? row.item.resendInviteUrl : undefined"
          @update="httpGet"
          :superAdmin="row.item.superAdmin"
        ></UserAction>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="users.length"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      class="mt-1 mr-1"
      ariaControls="users-table"
    ></b-pagination>
  </b-card>
</template>

<script>
import AddUser from '@/xvisor/components/settings/users/AddUser.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';
import UserAction from '@/xvisor/components/settings/users/UserAction.vue';
import UsersEventBus from '@/xvisor/bus/UsersEventBus';
import userStatus from '@/xvisor/constants/status';

export default {
  props: {
    /** The URL from which to get the list of users. */
    url: {
      type: String,
      required: false,
    },
  },
  components: {
    AddUser,
    SearchBar,
    ShowEntries,
    UserAction,
  },
  data() {
    return {
      users: [],
      perPage: 5,
      currentPage: 1,
      filter: null,
      filteredLength: null,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      tableFields: [
        { key: 'name', label: 'USER', sortable: true },
        { key: 'email', label: 'EMAIL', sortable: true },
        { key: 'superAdmin', label: 'ROLE', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'actions', label: 'ACTIONS' },
      ],
      userStatus,
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.users.length;
    },
  },
  mounted() {
    this.httpGet();
    UsersEventBus.$on('added-users', () => { this.httpGet(); });
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.users = response.data; });
    },
    // TODO: Change this function to return a color based on user ID. So a user can get the same color in the table.
    randomVariant() {
      const variants = ['info', 'primary', 'secondary', 'success', 'danger'];
      const index = Math.floor(Math.random() * 5);
      return variants[index - 1];
    },
    getUserInitials(name) {
      return name.split(' ').map((word) => word[0]).join('').toUpperCase();
    },
    userStatusVariant(status) {
      if (status === userStatus.active) return 'success';
      return 'warning';
    },
    userStatusText(status) {
      if (status === userStatus.active) return 'Active';
      return 'Pending';
    },
    superAdminIcon(superAdmin) {
      if (superAdmin) return 'hdd-stack';
      return 'person';
    },
    superAdminTitle(superAdmin) {
      if (superAdmin) return 'Admin';
      return 'Viewer';
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div>
    <b-dropdown text="Action">
      <b-dropdown-item @click="$refs.editOffice.$refs.modal.show()">Edit</b-dropdown-item>
      <b-dropdown-item @click="$refs.deleteOffice.$refs.modal.show()">Delete</b-dropdown-item>
    </b-dropdown>
    <OfficeLocationModal
      ref="editOffice"
      type="update"
      :office="office"
      :url="office.urls.updateUrl"
    ></OfficeLocationModal>
    <DeleteOfficeModal
      ref="deleteOffice"
      :office="office"
      :url="office.urls.deleteUrl"
    ></DeleteOfficeModal>
  </div>
</template>

<script>
import DeleteOfficeModal from '@/xvisor/components/settings/zones/DeleteOfficeModal.vue';
import OfficeLocationModal from '@/xvisor/components/settings/zones/OfficeLocationModal.vue';

export default {
  props: {
    office: {
      type: Object,
      default: null,
    },
  },
  components: {
    DeleteOfficeModal,
    OfficeLocationModal,
  },
};
</script>

export default {
  data() {
    const infraType = this.$route.meta.type;
    const infraIp = this.$route.params.ip;
    const infraId = infraIp;

    // Use integer ID when querying by IP, hostname, etc is not supported.
    // TODO: Remove when all legacy int ID queries have been replaced.

    return {
      infraId,
      infraType,
      infraIp,
    };
  },
  computed: {
    /** Either get the IP as the name or return back the type as a fallback. */
    infraName() {
      return this.infraIp || this.infraType;
    },
  },
};

<template>
  <div class="w-100" v-if="hasData">
    <b-row>
      <b-col lg="3" md="6" sm="6">
        <b-form-select v-model="ifaceSelected" :options="options">
          <template #first>
            <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <IfaceInfo v-if="ifaceSelected" :ifaceInfo="ifaceSelected"></IfaceInfo>
      <div v-if="ifaceSelected" class="badge-class">
        <b-badge pill v-if="ifaceSelected.status === ifaceStatus.active" variant="success">Active</b-badge>
        <b-badge pill v-else variant="danger">Inactive</b-badge>
      </div>
    </b-row>
    <b-row v-if="ifaceSelected" class="mx-2 mt-2">
      <b-col lg="6" md="12" sm="12">
        <h5>Top Client IPs</h5>
        <ProgressBar
          barColor="success"
          :url="topClientIpTraffTotalsUrl"
          :timeRange="timeRange"
        ></ProgressBar>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <h5>Interface Utilization</h5>
        <IfaceLineChart :timeRange="timeRange" :selectedIface="ifaceSelected"></IfaceLineChart>
      </b-col>
    </b-row>
  </div>
  <div v-else-if="loaded" class="ml-1">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import IfaceInfo from '@/xvisor/components/infra/interfaces/IfaceInfo.vue';
import IfaceLineChart from '@/xvisor/components/infra/interfaces/IfaceLineChart.vue';
import InfrastructureEventBus from '@/xvisor/bus/InfrastructureEventBus';
import ProgressBar from '@/xvisor/components/ProgressBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import infraIpsMixin from '@/xvisor/components/infra/infraIpsMixin';
import ifaceStatus from '@/xvisor/constants/ifaceStatus';

export default {
  components: {
    IfaceInfo,
    IfaceLineChart,
    ProgressBar,
    SpinnerCmpt,
  },
  mixins: [infraIpsMixin],
  props: {
    urls: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ifaces: [],
      ifaceSelected: null,
      ifaceStatus,
      loaded: false,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.ifaces && this.ifaces.length > 0;
    },
    options() {
      return this.ifaces.map((iface) => ({ value: iface, text: iface.name }));
    },
    topClientIpTraffTotalsUrl() {
      return this.ifaceSelected
        ? `/infra/routers/${this.infraIp}/iface/${this.ifaceSelected.snmpIndex}/topclients/trafftotals.json`
        : undefined;
    },
  },
  created() {
    InfrastructureEventBus.$on('heatmap-interface-click', (iface) => {
      this.ifaceSelected = this.ifaces
        ? this.ifaces.find((element) => element.snmpIndex === iface.snmpIndex)
        : [];
    });
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.urls.ifacesUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => {
          this.ifaces = response.data;
          // Ifaces can potentially be null, causing an error
          const [firstSelection] = this.ifaces ? this.ifaces : [];
          this.ifaceSelected = firstSelection;
        })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style scoped>
.badge-class {
  margin-top: 10px;
}
</style>

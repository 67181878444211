<template>
  <b-card no-body class="mx-n3 mt-n2">
    <b-row class="mx-1 mt-1">
      <b-card class="header-card">
        <h4 class="mb-1">Alert Details</h4>
        <div v-if="hasAlerts" class="details-row d-flex flex-row mt-2">
          <span>
            <b-icon :style="{ color: iconColors.blue }" class="mr-0-5" icon="info-square-fill"/>
            <b>Name:</b>
            {{ alerts[0].rule && alerts[0].rule.name ? alerts[0].rule.name : 'N/A' }}
          </span>
          <span>
            <b-icon variant="success" class="mr-0-5" icon="tag-fill"/>
            <b>Category:</b>
            {{ alerts[0].rule && alerts[0].rule.category ? alerts[0].rule.category : 'N/A' }}
          </span>
          <span>
            <b-icon variant="danger" class="mr-0-5" icon="exclamation-square-fill"/>
            <b>Severity:</b>
            {{ alerts[0].risk && alerts[0].risk.static_level ? alerts[0].risk.static_level : 'N/A' }}
          </span>
          <span>
            <b-icon :style="{ color: iconColors.purple }" class="mr-0-5" icon="person-fill"/>
            <b>Rule Author:</b>
            {{ alerts[0].rule && alerts[0].rule.author ? alerts[0].rule.author : 'N/A' }}
          </span>
        </div>
      </b-card>
    </b-row>
    <b-row class="ml-0-5 my-1">
      <b-col>
        <div v-if="hasData">
          <b-table
            class="table-with-actions-min-height px-2"
            responsive
            striped
            outlined
            :items="alertsFilter"
            :fields="computedTableFields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(timestamp)="row">
              {{ formatDate(row.value) }}
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="paginationTotalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-2 mx-1"
          />
        </div>
        <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
          {{ $t("No Data Available") }}
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import moment from 'moment';

import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  components: {
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      alerts: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'timestamp', label: 'Time', sortable: true },
        { key: 'user.name', label: 'User Name', sortable: true },
        { key: 'process.executable', label: 'Program', sortable: true },
        { key: 'process.parent.executable', label: 'Parent Process', sortable: true },
      ],
      iconColors: {
        blue: colorPalette.teal,
        purple: colorPalette.purple,
      },
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.alerts && this.alerts.length > 0;
    },
    hasAlerts() {
      return this.alerts.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.alerts.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    alertsFilter() {
      const retVal = this.alerts;
      return retVal;
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateYearTime);
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.alerts = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
  .header-card {
    width: 100%;
  }
  .header-card>.card-body {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .details-row {
    gap: 1rem;
  }
</style>

<template>
  <b-modal
    centered
    title="Delete xTend"
    @ok="deleteXtend"
    ref="modal"
  >
    Are you sure you want to delete this xTend? You cannot undo this action.
  </b-modal>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteXtend() {
      this.$http
        .delete(this.url)
        .then(() => {
          this.$bvToast.toast('Successfully deleted xTend', { variant: 'success' });
          this.$emit('deleted');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete xTend', { variant: 'danger' });
        });
    },
  },
};
</script>

import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';
import checkBlockLinkLoss from '@/xvisor/components/app/networkTab/pathroutes/checkBlockLinkLoss';
import checkBlockLinkEvent from '@/xvisor/components/app/networkTab/pathroutes/checkBlockLinkEvent';

export default class PathroutesBlock {
  constructor(
    block,
    blockNodeIdAsnMap,
    idx,
    linkHasLossMap,
    linkBaselineMap,
    metricMap,
    nodeIdHasLossMap,
    asnEventsMap,
    sizeOfAsns,
  ) {
    this.id = block.id;
    this.nodeIds = block.nodeIds;
    this.coordinate = block.coordinate;
    if (blockNodeIdAsnMap[this.nodeIds[0]]) {
      this.asnInfo = blockNodeIdAsnMap[this.nodeIds[0]];
    }
    // Check if current block contains valid nodes.
    const nodeId = this.nodeIds[0].split(' ');
    if (nodeId[1] === 'Unresponsive') {
      this.validBlock = false;
    } else {
      this.validBlock = true;
    }
    // Used for coloring different categories on echarts.
    this.size = sizeOfAsns - 1;
    this.echartsIndex = idx;

    this.nodeIds.forEach((id) => {
      if (nodeIdHasLossMap[id]) {
        this.blockContainsNodeLoss = true;
      }
    });

    this.blockConatinsLinkLoss = checkBlockLinkLoss(this.nodeIds, linkHasLossMap);
    this.blockConatinsLinkevent = checkBlockLinkEvent(this.nodeIds, metricMap, linkBaselineMap);

    // Domain level events.
    if (this.asnInfo && asnEventsMap[this.asnInfo.asnName]) {
      this.blockContainsDomainEvents = asnEventsMap[this.asnInfo.asnName];
    }
  }

  getEchartsFormatted() {
    return {
      name: this.id,
      x: this.coordinate.x,
      y: this.coordinate.y,
      // Coloring different blocks on echarts.
      // If block has no asninfo, group it into no domain group representing by size.
      category: this.asnInfo ? this.asnInfo.index : this.size,
      emphasis: {
        label: {
          show: false,
        },
      },
      label: {
        show: true,
        formatter: `${this.nodeIds.length}`,
        fontSize: 10,
        color: 'black',
      },
      tooltip: {
        formatter: () => {
          let asnNameFormat = '';
          let asnOrgNameFormat = '';
          let domainEventsFormat = '';
          if (this.asnInfo && this.asnInfo.asnName > 0) {
            asnNameFormat = `(AS ${this.asnInfo.asnName})`;
          }
          if (this.asnInfo && this.asnInfo.orgName) {
            asnOrgNameFormat = `<b>ASN</b> : ${this.asnInfo.orgName}`;
          }
          if (this.blockContainsDomainEvents) {
            domainEventsFormat = '<hr>';
            this.blockContainsDomainEvents.forEach((event) => {
              const ltzDate = (new Date(event.time)).toLocaleString();
              domainEventsFormat = domainEventsFormat.concat('', ltzDate);
              domainEventsFormat = domainEventsFormat.concat('', ',');
              domainEventsFormat = domainEventsFormat.concat(' ', event.msg);
              domainEventsFormat = domainEventsFormat.concat(' ', '<br>');
            });
          }
          const asnInfoFormat = `${asnOrgNameFormat} ${asnNameFormat}`;
          return `
            <div class="apache-echarts-tooltip">
              <span>${asnInfoFormat}</span>
              <span style="color: red">${domainEventsFormat}</span>
            </div>
          `;
        },
      },
      itemStyle: {
        color: this.validBlock ? this.color : 'gray',
        borderColor: this.blockContainsDomainEvents || this.blockConatinsLinkLoss
          || this.blockContainsNodeLoss
          ? 'red'
          : themeStyle.styleToggle(colorPalette.black, colorPalette.white),
        borderWidth: 1.5,
      },
      symbol: 'roundRect',
      symbolSize: 20,
    };
  }
}

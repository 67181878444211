<template>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      autoresize
      class="apache-echarts-default-graph"
    ></e-chart>
    <div v-else-if="loaded">
      {{ $t("No Data Available") }}
    </div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import readableBytes from '@/xvisor/utilities/readableBytes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    allTimes() {
      return this.series.map((item) => item.points.map((obj) => obj.time));
    },
    timeSet() {
      return new Set(...this.allTimes);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    computedSeries() {
      return this.series.map((item) => ({
        name: item.name,
        type: 'bar',
        stack: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: item.points.map(((point) => {
          const obj = {
            name: item.name,
            value: point.value,
          };
          return [point.time, point.value, obj];
        })),
      }));
    },
    chartOptions() {
      return {
        color: [
          colorPaletteShade.teal5,
          colorPaletteShade.red5,
          colorPaletteShade.green5,
          colorPaletteShade.orange5,
          colorPaletteShade.indigo5,
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: 10,
              opacity: 0.2,
            },
          },
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              <div class="apache-echarts-tooltip-timestamp">
                ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              </div>
              ${this.tooltipContent(info)}
            </div>
          `,
        },
        grid: {
          top: 30,
          bottom: 30,
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          min: this.allTimes[0][0],
          max: this.allTimes[0][this.allTimes[0].length - 1],
          offset: 10,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 10,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          show: true,
          name: 'Count',
          nameLocation: 'center',
          nameGap: 35,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            fontSize: 12,
          },
          splitLine: {
            show: true,
          },
        },
        label: {
          show: false,
        },
        series: this.computedSeries,
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    tooltipContent(info) {
      let tooltipMessage = '';
      for (let i = 0; i < info.length; i += 1) {
        tooltipMessage += `
            ${info[i].marker} <b>${info[i].value[2].name}</b>
            <br>
              Count: ${info[i].value[2].value}
            <br>
        `;
      }
      return tooltipMessage;
    },
    readableBytes,
  },
};
</script>

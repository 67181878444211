/** Convert bytes to human readable format.
 *
 * @param bytes The number of bytes to convert.
 * @param decimal The number of decimal places in the result.
 * @return Readable format of bytes.
 * Ex.
 *  readableBytes(2100000000, 2) => 2.10 GB
 *  readableBytes(1000, 1) => 1.0 KB
 */
export default function readableBytes(bytes, decimal = 1) {
  if (bytes < 0) throw new RangeError('Bytes must be non-negative');
  if (bytes === 0) return `${bytes}`;
  const base = 1000;
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let index = Math.floor(Math.log(bytes) / Math.log(base));
  if (index >= units.length) index = units.length - 1;
  return `${(bytes / (base ** index)).toFixed(decimal)} ${units[index]}`;
}

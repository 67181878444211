<template>
  <e-chart v-if="hasData" :options="chartOptions" autoresize class="event-stack-chart"></e-chart>
  <div v-else-if="loaded"></div>
  <SpinnerCmpt v-else class="ml-5"></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import issueLayers from '@/xvisor/constants/issueLayers';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      series: [],
      loaded: false,
      selectedTime: null,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
    IssueTimeBarEventBus.$on('update-filters', (filters) => {
      this.selectedTime = moment(filters.shortTimeRange.start).valueOf();
    });
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    xAxisFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    timeSet() {
      return new Set(this.formatedData.map((ts) => ts.points.map((point) => point.time)).flat());
    },
    tooltipBackgroundColor() {
      return themeStyle.styleToggle(colorPaletteShade.gray3, colorPalette.blueLightBackground);
    },
    toggleBorderColor() {
      return themeStyle.styleToggle(colorPaletteShade.white, colorPalette.blueLightBackground);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    yaxisCategories() {
      return this.formatedData.map((ts) => ts.layer).sort();
    },
    calculateHeight() {
      /** The data coming back from backend may have different length.
        * This calculation make sure that always heatmap cells have consistence size.
        */
      let height = '30%';
      const seriesLength = this.formatedData.length || 0;
      if (seriesLength === 2) height = '20%';
      if (seriesLength === 1) height = '10%';
      return height;
    },
    formatedData() {
      const groupByLayer = this.series.reduce((ts, item) => ({
        ...ts,
        [item.layer]: [...(ts[item.layer] || []), item],
      }), {});
      return Object.entries(groupByLayer).map(([layer, objects]) => {
        const numPoints = objects[0].points.length;
        const points = [];
        for (let i = 0; i < numPoints; i += 1) {
          const events = {};
          objects.forEach((object) => {
            if (object.points[i].value > 0) events[object.name] = object.points[i].value;
          });
          points.push({
            time: objects[0].points[i].time,
            total: objects.map((object) => object.points[i].value).reduce((a, b) => a + b),
            events,
          });
        }
        return { layer, points };
      }).sort((a, b) => (a.layer > b.layer ? 1 : -1));
    },
    chartData() {
      return this.formatedData.map((ts) => {
        const seriesLength = this.formatedData.length ? this.formatedData.length : 0;
        // Apply index number to each layer of data.
        let index = 0;
        if (ts.layer === issueLayers.app.name) index = seriesLength - 1;
        else if (ts.layer === issueLayers.net.name && seriesLength >= 2) index = seriesLength - 2;
        return ts.points.map((point, innerIndex) => [index, innerIndex, point.total]);
      }).flat().map((item) => [item[1], item[0], item[2]]);
    },
    chartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          position: (pos, params, dom, rect, size) => {
            /** tooltip will be fixed on the right if mouse hovering on the left,
              * and on the left if hovering on the right.
              */
            const obj = { top: 30 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          formatter: (info) => {
            const header = `<div class="mb-1">${info[0].name}</div>`;
            const body = info
              .map((row, index) => `
                ${row.marker} ${this.yaxisCategories[index]}: ${row.value[2]}
                <ul>${this.tootipLayerEventList(this.formatedData[index].points[row.value[0]].events)}</ul>
              `)
              .join('');
            return `<div class="apache-echarts-tooltip">${header}${body}</div>`;
          },
          textStyle: {
            color: this.toggleColor,
            fontSize: 8,
          },
          backgroundColor: this.tooltipBackgroundColor,
        },
        legend: {
          show: true,
          bottom: 40,
          textStyle: {
            color: this.toggleColor,
          },
        },
        visualMap: {
          show: false,
          min: 0,
          max: 5,
          orient: 'horizontal',
          left: 'center',
          bottom: 5,
          color: [colorPaletteShade.red5, colorPaletteShade.red3, colorPalette.lackOfDataGray],
        },
        grid: {
          height: this.calculateHeight,
          width: '100%',
          top: '0%',
          left: 5,
          right: 2,
        },
        xAxis: {
          type: 'category',
          data: this.series[0].points.map((point) => moment(point.time).format(this.xAxisFormat)),
          splitArea: {
            show: true,
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: this.yaxisCategories,
          splitArea: {
            show: true,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [{
          type: 'heatmap',
          data: this.chartData,
          label: {
            show: false,
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: this.toggleBorderColor,
          },
          markPoint: {
            symbol: 'triangle',
            symbolSize: 13,
            itemStyle: {
              color: 'white',
              borderColor: colorPaletteShade.gray8,
              borderWidth: 1,
            },
            symbolOffset: [0, 10],
            // Min would place the annotation at the bottom of the stacked bar.
            data: [{ xAxis: moment(this.selectedTime).format(this.xAxisFormat), yAxis: 'min' }],
          },
        }],
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    tootipLayerEventList(events) {
      return Object.entries(events).map(([name, value]) => `<li>${name}: ${value}</li>`);
    },
  },
};
</script>

<style scoped>
.event-stack-chart {
  height: 100px;
}
</style>

/** Xource names. */
export default {
  pcap: { name: 'pcap', extendedName: 'Wire Data Capture' },
  netFlow: { name: 'netFlow', extendedName: 'NetFlow' },
  security: { name: 'security', extendedName: 'Security/Zeek' },
  sFlow: { name: 'sFlow', extendedName: 'SFlow' },
  probe: { name: 'probe', extendedName: 'Probe' },
  snmp: { name: 'snmp', extendedName: 'SNMP' },
  suricata: { name: 'suricata', extendedName: 'Suricata' },
  syslog: { name: 'syslog', extendedName: 'Syslog' },
  vpc: { name: 'vpc', extendedName: 'VPC' },
  xpcap: { name: 'xpcap', extendedName: 'xPcap' },
};

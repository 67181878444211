export default {
  queriesReceivedCountsShortTerm: { value: 'queriesReceivedCountsShortTerm' },
  queriesMadeCountsShortTerm: { value: 'queriesMadeCountsShortTerm' },
  queriesReceivedCountsLongTerm: { value: 'queriesReceivedCountsLongTerm' },
  queriesMadeCountsLongTerm: { value: 'queriesMadeCountsLongTerm' },
  clientAttemptTpsPeakShortTerm: { value: 'clientAttemptTpsPeakShortTerm' },
  clientAcceptTpsPeakShortTerm: { value: 'clientAcceptTpsPeakShortTerm' },
  clientBpsSentPeakShortTerm: { value: 'clientBpsSentPeakShortTerm' },
  clientBpsReceivedPeakShortTerm: { value: 'clientBpsReceivedPeakShortTerm' },
  serverAcceptTpsPeakShortTerm: { value: 'serverAcceptTpsPeakShortTerm' },
  serverAcceptTpsPeakLongTerm: { value: 'serverAcceptTpsPeakLongTerm' },
  serverBpsSentPeakShortTerm: { value: 'serverBpsSentPeakShortTerm' },
  serverBpsReceivedPeakShortTerm: { value: 'serverBpsReceivedPeakShortTerm' },
};

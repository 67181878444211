import xourceNames from '@/xvisor/constants/xourceNames';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      xourceNames,
    };
  },
  computed: {
    postConfig() {},
  },
  mounted() {
    this.syncConfig();
  },
  methods: {
    syncConfig() {},
    saveAction() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.$emit('input', this.postConfig);
          this.$emit('ok');
        }
      });
    },
  },
};

<template>
  <b-modal
    no-close-on-backdrop
    size="lg"
    ref="addReportTabs"
    title="Add Report"
    id="add-report"
    @ok="nextModalStep"
    @cancel="prevModalStep"
    @close="resetModal"
    :cancel-title="getPrevTitle"
    :ok-title="getNextTitle"
    :ok-disabled="isNextDisabled"
  >
    <b-tabs fill>
      <b-tab class="report-stage-one" title="Select Report" :active="tabIndex === 0" @click="tabIndex = 0">
        <b-row align-h="center" class="my-2">
          <h4>
            What kind of report would you like to generate?
          </h4>
        </b-row>
        <b-row align-h="around">
          <b-col
            class="pr-0 d-flex flex-column justify-content-start align-items-start border-right"
          >
            <b-card no-body>
            <h5
              v-if="hasSelectedReportType"
              class="mb-2 mt-2"
            >
              3. Click Next to configure
            </h5>
            <h5
              v-else-if="hasSelectedReportCategory"
              class="mb-2 mt-2"
            >
              2. Select a report type
            </h5>
            <h5
              v-else
              class="mb-2 mt-2"
            >
              1. Select a report category
            </h5>
            <b-table
              ref="reportCategoriesTable"
              :items="reportInfo"
              :fields="reportCategoryTableFields"
              @row-clicked="selectReportCategory"
              hover
              bordered
            >
              <template
                #cell(category)="row"
              >
                <div
                  v-if="!(selectedReportCategory && (selectedReportCategory.category === row.item.category))"
                  class="d-flex justify-content-between"
                >
                  <b>{{ row.item.category }}</b>
                  <b-badge
                    class="ml-2"
                    variant="primary"
                    pill
                  >
                    {{ row.item.types.length }}
                  </b-badge>
                </div>
                <b-list-group
                  flush
                  class="mb-1"
                  v-if="selectedReportCategory && (selectedReportCategory.category === row.item.category)"
                >
                  <b class="mb-1">{{ selectedReportCategory.category }}</b>
                  <b-list-group-item
                    button
                    v-for="(type,idx) in row.item.types"
                    :key="idx"
                    @click="selectReportType(type)"
                    :active="selectedReportType && (selectedReportType.name === type.name)"
                  >
                    {{ type.name }}
                  </b-list-group-item>
                </b-list-group>
              </template>
            </b-table>
            </b-card>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-card no-body>
              <p
                v-html="getReportDescription"
                class="mt-2 report-description"
              >
              </p>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Configure"
        @active="tabIndex === 1"
        @click="tabIndex = 1"
        :disabled="!hasSelectedReportType"
        lazy
      >
        <b-row v-if="hasSelectedReportType" align-h="center" class="my-2">
          <h4>
            Configure Your {{ selectedReportType.name }} Report
          </h4>
        </b-row>
        <AddReportConfig
          ref="addReportRef"
          :selectedReportType="selectedReportType"
        >
        </AddReportConfig>
      </b-tab
      >
    </b-tabs>
  </b-modal>
</template>

<script>
import AddReportConfig from '@/xvisor/components/report/AddReportConfig.vue';
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';
import { reportInfo } from '@/xvisor/constants/reports';

export default {
  mixins: [timeRangeMixin],
  components: {
    AddReportConfig,
  },
  data() {
    return {
      tabIndex: 0,
      reportName: '',
      selectedReportCategory: null,
      selectedReportType: null,
      reportInfo,
      reportCategoryTableFields: [
        {
          key: 'category',
          label: 'Report Categories',
        },
      ],
    };
  },
  computed: {
    hasSelectedReportCategory() {
      return this.selectedReportCategory !== null;
    },
    hasSelectedReportType() {
      return this.selectedReportType !== null;
    },
    getReportDescription() {
      if (this.selectedReportCategory) {
        const report = this.reportInfo.find(({ category }) => category === this.selectedReportCategory.category);
        if (this.selectedReportType) {
          const type = report.types.find(({ name }) => name === this.selectedReportType.name);
          return type.description;
        }
        return report.description;
      }
      return `
        <b>Reports are easy to generate.</b>
        <ul>
          <li>Expand a category and choose a report type</li>
          <li>Customize your parameters</li>
          <li>Choose your delivery methods and submit</li>
        </ul>
      `;
    },
    getTypesFromSelectedReportCategory() {
      const report = this.reportInfo.find(({ category }) => category === this.selectedReportCategory);
      return report.types;
    },
    getPrevTitle() {
      if (this.tabIndex === 0) {
        return 'Cancel';
      }
      return 'Previous';
    },
    getNextTitle() {
      if (this.tabIndex === 1) {
        return 'Submit';
      }
      return 'Next';
    },
    isNextDisabled() {
      if (this.tabIndex === 0 && this.selectedReportType) {
        return false;
      }
      if (this.tabIndex === 1) {
        return false;
      }
      return true;
    },
  },
  methods: {
    selectReportCategory(report) {
      this.selectedReportType = null;
      if (this.selectedReportCategory && (this.selectedReportCategory.category === report.category)) {
        this.selectedReportCategory = null;
      } else {
        this.selectedReportCategory = report;
      }
    },
    selectReportType(reportType) {
      if (this.selectedReportType && (this.selectedReportType.name === reportType.name)) {
        this.selectedReportType = null;
      } else {
        this.selectedReportType = reportType;
      }
    },
    nextModalStep(e) {
      if (this.tabIndex === 1) {
        e.preventDefault();
        this.$refs.addReportRef.addReport(this.$refs.addReportTabs);
      } else {
        this.tabIndex += 1;
        e.preventDefault();
      }
    },
    prevModalStep(e) {
      if (this.tabIndex > 0) {
        this.tabIndex -= 1;
        e.preventDefault();
      } else {
        this.resetModal();
      }
    },
    resetModal() {
      this.tabIndex = 0;
      this.selectedReportType = null;
      this.selectedReportCategory = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.report-description {
  height: 400px;
}
</style>

<template>
  <span>
    <b-badge
      pill
      v-if="showPill"
      :variant="pillVariant"
      @click="showModal"
      class="pill-button"
      href="#"
    >
      {{ pillText }}
    </b-badge>
  </span>
</template>

<script>
import VulnerabilityEventBus from '@/xvisor/components/xtend/tabs/VulnerabilityEventBus';
import scoreToAttributes from '@/xvisor/components/xtend/tabs/VulnerabilityAttributes';

export default {
  props: {
    softwareId: {
      type: String,
      required: true,
    },
    vulnerabilities: {
      type: Array,
      required: true,
    },
  },
  computed: {
    maxScore() {
      const scores = this.vulnerabilities.map((each) => each.score.base);
      return scores.length > 0 ? Math.max(...scores) : 0;
    },
    pillText() {
      const [primary, secondary] = this.primarySecondaryCounts;
      const attributes = this.pillAttributes;

      let text = `${attributes.textPrefix}: ${primary}`;
      if (secondary > 0) {
        text += ` (Total: ${primary + secondary})`;
      }
      return text;
    },
    primarySecondaryCounts() {
      const threshold = this.pillAttributes.scoreThreshold;
      let primary = 0;
      let secondary = 0;
      this.vulnerabilities.forEach((vuln) => {
        if (vuln.score.base >= threshold) {
          primary += 1;
        } else {
          secondary += 1;
        }
      });
      return [primary, secondary];
    },
    pillVariant() {
      return this.pillAttributes.variant;
    },
    pillAttributes() {
      const score = this.maxScore;
      const attributes = scoreToAttributes(score);
      return attributes;
    },
    showPill() {
      return this.vulnerabilities.length > 0;
    },
  },
  methods: {
    showModal() {
      // Emit an event to the event bus
      VulnerabilityEventBus.$emit('show-modal', this.softwareId);
    },
  },
};
</script>

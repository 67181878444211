<template>
  <b-card no-body>
    <h5 class="mb-2"><b>Required Parameters</b></h5>
    <validation-observer ref="addReport">
      <b-form>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <validation-provider name="Name" rules="required" v-slot="{ errors }">
              <b-form-group label="Name" label-cols-md="4">
                <b-form-input
                  autofocus
                  placeholder="Report Name"
                  v-model="newReport.name"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Time Range" label-cols-md="4">
              <TimeRangeSelector
                :minDate="minDate"
                :maxDate="maxDate"
                :initRange="timeRange"
                @change-time-range="setScheduleTime"
                id="wide-time-range"
              ></TimeRangeSelector>
            </b-form-group>
          </b-col>
          <TopNTypeSelection
            v-if="newReport.type == reportTypes.topN"
            :selectedPrimaryEntity="selectedReportType.primaryEntityType"
            @change-topn-params="handleEmittedParams"
          ></TopNTypeSelection>
          <DevicesTypeSelection
            v-else-if="newReport.type == reportTypes.devices"
            @change-devices-params="handleEmittedParams"
          ></DevicesTypeSelection>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import moment from 'moment';

import DevicesTypeSelection from '@/xvisor/components/report/report-types/DevicesTypeSelection.vue';
import TopNTypeSelection from '@/xvisor/components/report/report-types/TopNTypeSelection.vue';
import { reportTypes } from '@/xvisor/constants/reports';
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';

export default {
  mixins: [timeRangeMixin],
  components: {
    DevicesTypeSelection,
    TopNTypeSelection,
  },
  props: {
    selectedReportType: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      newReport: this.initReportForm(),
      appInfras: [],
      reportTypes,
    };
  },
  computed: {
    reportTypeOptions() {
      return Object.values(reportTypes);
    },
  },
  methods: {
    handleEmittedParams(params) {
      // Overwrite the relevant params that were emitted.
      this.newReport.params = { ...this.newReport.params, ...params };
    },
    initReportForm() {
      const end = moment(this.now).startOf('day').subtract(5, 'minutes').toDate();
      const start = moment(this.now).subtract(1, 'day').startOf('day').toDate();
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const newReport = {
        name: '',
        type: this.selectedReportType ? this.selectedReportType.type : undefined,
        startTime: start,
        endTime: end,
        params: {
          timeZone: tz || 'UTC',
          primaryEntityType: this.selectedReportType ? this.selectedReportType.primaryEntityType : null,
          primaryEntityCount: null,
          secondaryEntityType: null,
          secondaryEntityCount: null,
          metricType: null,
        },
      };
      return newReport;
    },
    closeAndResetModal() {
      this.newReport = this.initReportForm();
      this.$bvModal.hide('add-report-window');
    },
    setScheduleTime(value) {
      this.newReport.startTime = value.start;
      this.newReport.endTime = value.end;
    },
    addReport(ref) {
      this.$refs.addReport.validate().then((result) => {
        if (result) {
          this.$http
            .post('/reports/add', this.newReport)
            .then(() => {
              ref.$bvToast.toast('Successfully added report', { variant: 'success' });
              ref.hide();
              this.$emit('added');
            })
            .catch(() => {
              ref.$bvToast.toast('Failed to add report', { variant: 'danger' });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal-line {
  width: 100%;
  padding: 10px 0px;
}
#wide-time-range {
  width: 100%;
}
</style>

<template>
  <div>
    <b-card no-body class="mx-n3">
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col lg="7" md="12" sm="12">
          <b-row align-h="between">
            <b-col lg="4" md="4" sm="12" class="mb-1">
              <div class="form-select">
                Show
                <b-form-select
                  v-model="perPage"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" md="12" sm="12" class="mb-1">
          <b-row align-h="end">
            <SearchBar :hasErrorMessage="true">
              <template #input>
                <b-form-input v-model="filter" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="packageFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
            >
              <template #cell(package.installed)="data">
                <span>{{ formatDate(data.value) }}</span>
              </template>
              <template #cell(package.vulnerability)="data">
                <!-- Using software/package name as ID -->
                <VulnerabilityPill
                  :softwareId="data.item.package.name"
                  :vulnerabilities="data.item.vulnerability"
                />
                <VulnerabilityModal
                  :softwareId="data.item.package.name"
                  :package="data.item.package"
                  :vulnerabilities="data.item.vulnerability"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import VulnerabilityModal from '@/xvisor/components/xtend/tabs/VulnerabilityModal.vue';
import VulnerabilityPill from '@/xvisor/components/xtend/tabs/VulnerabilityPill.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import moment from 'moment';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchBar,
    VulnerabilityModal,
    VulnerabilityPill,
  },
  data() {
    return {
      package: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'package.name', label: 'Name', sortable: true },
        { key: 'package.installed', label: 'Installed On', sortable: true },
        { key: 'package.version', label: 'Version', sortable: true },
        { key: 'package.publisher', label: 'Publisher', sortable: true },
        { key: 'package.vulnerability', label: 'Vulnerabilities' },
      ],
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.package && this.package.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.package.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    packageFilter() {
      const retVal = this.package;
      return retVal;
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateYearTime);
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.package = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    collapseText,
  },
};
</script>

<style scoped>
  .form-select{
    min-width: 160px;
    width: 20%;
  }
</style>

<template>
  <div class="card-container">
    <b-card class="alert-detail-box">
      <b-row>
        <b-col>
          <b-button
            class="float-right mt-n1 p-0 mr-n1"
            @click="$emit('close')"
            variant="flat-primary"
            v-if="indexPre !== 0"
          >
            <b-icon icon="x" font-scale="2" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <e-chart
            v-if="hasData"
            :options="chartOptions"
            autoresize
            @click="handleBarClick"
            style="height: 100px;"
          ></e-chart>
          <SpinnerCmpt v-else></SpinnerCmpt>
          <div id="event-message" v-if="selectedEventMessage">{{ selectedEventMessage }}</div>
          <div v-if="selectedEntities && selectedEntities.length" class="entities-list">
            <h5>Affected Entities:</h5>
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="entity in selectedEntities"
                :key="entity.id || entity.name"
                @click="handleEntityClick(entity)"
              >
                <strong>{{ entity.type }}:</strong> {{ entity.name }}
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import themeStyle from '@/xvisor/utilities/themeStyle';
import colorPalette from '@/xvisor/constants/colorPalette';
import UsersEventBus from '@/xvisor/bus/UsersEventBus';

export default {
  name: 'EventChart',
  props: {
    endTime: {
      type: String,
      required: true,
    },
    alertId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      eventData: [],
      chartOptions: null,
      loaded: false,
      selectedEventMessage: '',
      selectedEntities: [],
    };
  },
  mounted() {
    this.fetchEventData();
  },
  computed: {
    hasData() {
      return this.loaded && this.eventData && this.eventData.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
  },
  watch: {
    endTime() {
      this.fetchEventData();
    },
    toggleColor() {
      this.prepareChartOptions();
    },
  },
  methods: {
    fetchEventData() {
      this.loaded = false;
      this.$http
        .get(`/alerts/${this.alertId}/events/list.json`)
        .then((response) => {
          this.eventData = response.data;
          this.prepareChartOptions();
        })
        .catch(() => {
          this.eventData = undefined;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    handleEntityClick(entity) {
      if (entity.type === 'IPs') {
        this.selectedIps = entity.name.split(', ').map((ip) => ip.trim());
        UsersEventBus.$emit('set-user-tab-ips', this.selectedIps);
      }
    },
    prepareChartOptions() {
      const endTime = new Date(this.endTime);
      const intervals = 12;
      const intervalMinutes = 5;
      const startTime = new Date(endTime.getTime() - (intervals - 1) * intervalMinutes * 60000);

      const timestamps = [];
      for (let i = 0; i < intervals; i += 1) {
        const currentTime = new Date(startTime.getTime() + i * intervalMinutes * 60000);
        const formattedTime = currentTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        timestamps.push(formattedTime);
      }

      const data = [];
      const colors = [];
      let totalEvents = 0;
      timestamps.forEach((time) => {
        const hasEventAtTime = this.eventData.some((event) => {
          const eventTime = new Date(event.time);
          const localEventTime = eventTime.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });
          return localEventTime === time;
        });

        if (hasEventAtTime) {
          totalEvents += 1;
        }

        data.push(10);
        colors.push({
          fill: hasEventAtTime ? colorPaletteShade.red6 : 'transparent',
          border: hasEventAtTime ? colorPaletteShade.red6 : colorPaletteShade.green4,
        });
      });

      this.chartOptions = {
        xAxis: {
          type: 'category',
          data: timestamps,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            textStyle: {
              color: this.toggleColor,
              fontSize: 8,
            },
          },
        },
        yAxis: {
          type: 'value',
          max: 15,
          show: false,
        },
        series: [
          {
            type: 'bar',
            barWidth: '24%',
            data: data.map((value, index) => ({
              value,
              itemStyle: {
                color: colors[index].fill,
                borderColor: colors[index].border,
                borderWidth: 2,
                borderType: 'solid',
              },
            })),
            markPoint: {
              data: [],
            },
          },
        ],
        graphic: [
          {
            type: 'text',
            left: '2%',
            top: '55%',
            style: {
              text: `${totalEvents} ${totalEvents <= 1 ? 'Event' : 'Events'}`,
              fontSize: 14,
              fill: this.toggleColor,
            },
          },
        ],
      };
    },
    handleBarClick(params) {
      const clickedTime = params.name;
      this.chartOptions.series[0].markPoint.data = [
        {
          symbol: 'triangle',
          symbolSize: 12,
          itemStyle: {
            color: 'white',
            borderColor: colorPaletteShade.gray8,
            borderWidth: 1,
          },
          coord: [clickedTime, 90],
          y: 90,
        },
      ];

      const matchedEvent = this.eventData.find((event) => {
        const eventTime = new Date(event.time);
        const localEventTime = eventTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        return localEventTime === clickedTime;
      });

      this.selectedEventMessage = matchedEvent
        ? `${matchedEvent.eventMessage}`
        : 'No event data for this time.';
      this.selectedEntities = matchedEvent ? matchedEvent.entities : [];
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.alert-detail-box {
  max-width: 800px; /* Adjust the width to your desired size */
  width: 100%; /* Ensures responsiveness */
  margin: 0 auto; /* Centers the card */
}

#event-message {
  margin-top: 20px;
  font-size: 14px;
}

.entities-list {
  margin-top: 15px;
}
</style>

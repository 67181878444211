<template>
  <b-row>
    <info-card
      title="Hardware"
      icon="hdd"
      :items="hardwareItems"
    />
    <info-card
      title="Network"
      icon="globe"
      :items="networkItems"
    />
    <info-card
      title="OS"
      icon="grid-fill"
      :items="osItems"
    />
  </b-row>
</template>

<script>
import InfoCard from '@/xvisor/components/xtend/tabs/InfoCard.vue';

export default {
  components: {
    InfoCard,
  },
  props: {
    urls: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: true,
      systemInfo: {
        cpu: {},
        memory: {},
        wifi: {},
        os: {},
      },
    };
  },
  computed: {
    hardwareItems() {
      return [
        { key: 'CPU Cores', value: this.systemInfo.cpu.cores },
        { key: 'Total Memory (GB)', value: this.systemInfo.memory.totalMemory },
      ];
    },
    networkItems() {
      return [
        { key: 'Public IP', value: this.systemInfo.wifi.ip },
        { key: 'Location', value: this.systemInfo.wifi.city },
        { key: 'Most Recent SSID', value: this.systemInfo.wifi.mostRecentSsid },
        { key: 'Most Recent BSSID', value: this.systemInfo.wifi.mostRecentBssid },
      ];
    },
    osItems() {
      return [
        { key: 'Edition', value: this.systemInfo.os.edition },
        { key: 'Version', value: this.systemInfo.os.version },
      ];
    },
  },
  watch: {
    urls() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.urls.systemInfoUrl)
        .then((response) => {
          this.systemInfo = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

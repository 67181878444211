<template>
  <b-badge pill :variant="pillVariant">
    {{ pillText }}
  </b-badge>
</template>

<script>
import scoreToAttributes from '@/xvisor/components/xtend/tabs/VulnerabilityAttributes';

export default {
  props: {
    score: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pillVariant() {
      const attributes = scoreToAttributes(this.score);
      return attributes.variant;
    },
    pillText() {
      const attributes = scoreToAttributes(this.score);
      return `${this.score} (${attributes.textPrefix})`;
    },
  },
};
</script>

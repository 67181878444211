<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import numberUtils from '@/xvisor/utilities/numberUtils';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const SYMBOL_SIZE_MIN = 1;
const SYMBOL_SIZE_MAX = 35;

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    height: {
      Number,
      default: 100,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    ip: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: {},
      loaded: false,
    };
  },
  computed: {
    timeSet() {
      // Since the times will be the same, only server/client is needed.
      const seriesTimes = this.series.server && this.series.server.length > 0
        ? new Set(this.series.server.map((ts) => ts.points.map((point) => point.time)).flat())
        : new Set(this.series.client.map((ts) => ts.points.map((point) => point.time)).flat());
      return seriesTimes;
    },
    hasData() {
      return this.loaded && this.series
        && ((this.series.client && this.series.client.length > 0)
        || (this.series.server && this.series.server.length > 0));
    },
    minMaxValues() {
      // Both server and client are merged to calculate the min and max.
      const serverValues = this.series.server.map((ts) => ts.points.map((point) => point.value)).flat();
      const clientValues = this.series.client.map((ts) => ts.points.map((point) => point.value)).flat();
      const totalValues = [...serverValues, ...clientValues];
      const [min, max] = [Math.min(...totalValues), Math.max(...totalValues)];
      return { min, max };
    },
    topIps() {
      // Both IPs are merged for the chart options angleAxis.
      const serverIps = this.series.server.map((ts) => ({
        // TODO: Change the variable from name to IP once the endpoint updates the field.
        value: ts.name,
      }));
      const clientIps = this.series.client.map((ts) => ({
        // TODO: Change the variable from name to IP once the endpoint updates the field.
        value: ts.name,
      }));
      return [...serverIps, ...clientIps];
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedServerSeries() {
      return this.series.server.map((ts) => ts.points.map((point) => ({
        value: [
          moment(point.time).format(this.timeSeriesFormat),
          // TODO: Change the variable from name to IP once the endpoint updates the field.
          ts.name,
          point.value,
        ],
      }))).flat();
    },
    formattedRadiusAxis() {
      return this.series.server && this.series.server.length > 0
        ? this.series.server[0].points.map((point) => moment(point.time).format(this.timeSeriesFormat))
        : this.series.client[0].points.map((point) => moment(point.time).format(this.timeSeriesFormat));
    },
    formattedClientSeries() {
      return this.series.client.map((ts) => ts.points.map((point) => ({
        value: [
          moment(point.time).format(this.timeSeriesFormat),
          // TODO: Change the variable from name to IP once the endpoint updates the field.
          ts.name,
          point.value,
        ],
      }))).flat();
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.teal4, colorPaletteShade.orange4],
        legend: {
          data: ['Server', 'Client'],
          right: 40,
          textStyle: {
            color: this.toggleColor,
          },
        },
        polar: {},
        tooltip: {
          formatter: (params) => `
            <div class="apache-echarts-tooltip">
              ${params.name}
              <br>
              ${params.marker} Counts: ${params.value[2]}
            </div>
          `,
        },
        angleAxis: {
          type: 'category',
          data: this.topIps,
          boundaryGap: false,
          splitLine: {
            show: true,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: this.toggleColor,
          },
          axisPointer: {
            show: true,
            triggerTooltip: false,
          },
        },
        radiusAxis: {
          type: 'category',
          // Choosing the first points array as reference for time ticks.
          data: this.formattedRadiusAxis,
          axisLine: {
            show: true,
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            rotate: 45,
            fontSize: 8,
            width: 3,
            overflow: 'truncate',
            color: this.toggleColor,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
        },
        series: [
          {
            name: 'Server',
            type: 'scatter',
            coordinateSystem: 'polar',
            symbolSize: (val) => this.scaleValue(val[2]),
            data: this.formattedServerSeries,
            animationDelay: (idx) => idx * 5,
          },
          {
            name: 'Client',
            type: 'scatter',
            coordinateSystem: 'polar',
            symbolSize: (val) => this.scaleValue(val[2]),
            data: this.formattedClientSeries,
            animationDelay: (idx) => idx * 5,
          },
        ],
      };
    },
  },
  watch: {
    ip() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    scaleValue(v) {
      const scaledValue = numberUtils.mapNumberFromRangeToRange(
        v,
        this.minMaxValues.min,
        this.minMaxValues.max,
        SYMBOL_SIZE_MIN,
        SYMBOL_SIZE_MAX,
      );
      return scaledValue;
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity6(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"title":("Configure " + (_vm.xourceNames.vpc.extendedName) + " Data Source")},on:{"ok":_vm.saveAction,"hidden":_vm.syncConfig}},[_c('validation-observer',{ref:"form"},[_c('validation-provider',{attrs:{"name":"URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"URL"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"placeholder":"URL","state":errors[0] ? false : null},model:{value:(_vm.config.url),callback:function ($$v) {_vm.$set(_vm.config, "url", $$v)},expression:"config.url"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Container Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Container Name"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"placeholder":"Container Name","state":errors[0] ? false : null},model:{value:(_vm.config.containerName),callback:function ($$v) {_vm.$set(_vm.config, "containerName", $$v)},expression:"config.containerName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Account Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Account Name"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"placeholder":"Account Name","state":errors[0] ? false : null},model:{value:(_vm.config.accountName),callback:function ($$v) {_vm.$set(_vm.config, "accountName", $$v)},expression:"config.accountName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Account Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Account Key"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"placeholder":"Account Key","state":errors[0] ? false : null},model:{value:(_vm.config.accountKey),callback:function ($$v) {_vm.$set(_vm.config, "accountKey", $$v)},expression:"config.accountKey"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
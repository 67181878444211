<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="6">
        <h2>{{ $t('Applications') }}</h2>
      </b-col>
      <b-col cols="6">
        <TimeRangeSelector
          :minDate="minDate"
          :maxDate="maxDate"
          :initRange="timeRange"
          @change-time-range="updateStateTimeRange"
        ></TimeRangeSelector>
      </b-col>
    </b-row>
    <b-card no-body class="mx-n2">
      <b-row class="mx-0-5 my-1" align-h="between" align-v="center">
        <b-col cols="12" md="6">
        <ShowEntries>
          <template #input>
            <b-form-select
              v-model="perPage"
              :options="options"
              size="sm"
            ></b-form-select>
          </template>
        </ShowEntries>
      </b-col>
        <b-col cols="12" md="6">
          <SearchBar>
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
        </b-col>
      </b-row>
      <b-table
        id="applications-table"
        class="table-with-actions-min-height"
        responsive
        selectable
        select-mode="single"
        small
        primary-key="id"
        :items="styledApplications"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
        @row-clicked="handleRowClick"
      >
        <template #head(alerts)="data">
          <div class="w-100 text-center">{{ data.label }}</div>
        </template>
        <template #head(actions)="data">
          <div class="w-100 text-center">{{ data.label }}</div>
        </template>
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: colWidth(field.key), minWidth: colMinWidth(field.key) }"
          >
        </template>
        <template #cell(name)="row">
          <b-button variant="flat-primary" class="p-0" @click="handleAppNameClick(row.item.id)">
            {{ row.value }}
          </b-button>
        </template>
        <template #cell(alerts)="row">
          <b-row>
            <b-col cols="6">
              <AppAlertRadialChart
                :url="alertRadialChartUrl(row.item.id)"
                @alertCount="handleAlertCount"
                :appId="row.item.id"
                :timeRange="timeRange"
              ></AppAlertRadialChart>
            </b-col>
            <b-col cols="6">
              <TsAlertChart
                :url="getAlertChartUrl(row.item.id)"
                :timeRange="timeRange"
              ></TsAlertChart>
            </b-col>
          </b-row>
        </template>
        <template #cell(status)>
          <span aria-hidden="true">&nbsp;</span>
        </template>
        <template #cell(users)="row">
          <b-row>
            <b-col cols="9">
              <SparklineEchart
                :url="sparkLineUrl(row.item.id)"
                :color="colorPalette.blue"
                :timeRange="timeRange"
                :tooltipPositionRight="true"
              ></SparklineEchart>
            </b-col>
          </b-row>
        </template>
        <template #cell(actions)="row">
          <ApplicationActions class="text-center" :id="row.item.id" @update="httpGet"></ApplicationActions>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="paginationTotalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        class="mr-1 mt-1"
        ariaControls="applications-table"
      ></b-pagination>
    </b-card>
    <AlertsTable :timeRange="timeRange" :selectedAppName="selectedAppName"></AlertsTable>
  </div>
</template>

<script>
import moment from 'moment';

import AlertsTable from '@/xvisor/components/app/AlertsTable.vue';
import AppAlertRadialChart from '@/xvisor/components/app/AppAlertRadialChart.vue';
import ApplicationActions from '@/xvisor/components/app/ApplicationActions.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';
import SparklineEchart from '@/xvisor/components/app/SparklineEchart.vue';
import TimeRangeSelector from '@/xvisor/components/TimeRangeSelector.vue';
import TsAlertChart from '@/xvisor/components/app/TsAlertChart.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import dateCeil from '@/xvisor/utilities/dateCeil';

export default {
  components: {
    AlertsTable,
    AppAlertRadialChart,
    ApplicationActions,
    SearchBar,
    ShowEntries,
    SparklineEchart,
    TimeRangeSelector,
    TsAlertChart,
  },
  data() {
    const now = dateCeil(new Date());
    return {
      applications: [],
      perPage: 5,
      currentPage: 1,
      filter: null,
      filteredLength: null,
      selectedAppName: '',
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      tableFields: [
        { key: 'status', label: '' },
        {
          key: 'name', label: 'App Name', sortable: true, thStyle: { width: '20%' },
        },
        { key: 'users', label: 'Number of Users', thStyle: { width: '10%' } },
        { key: 'alerts', label: 'Alerts Stats', thStyle: { width: '40%' } },
        { key: 'actions', label: 'Actions', thStyle: { width: '30%' } },
      ],

      maxDate: now,
      minDate: moment(now).subtract(1, 'month').toDate(),

      /** The time range for all components on the page. */
      timeRange: this.$store.state.app.timeRange,
      colorPalette,
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.applications.length;
    },
    styledApplications() {
      return this.applications.map((app) => {
        const tmpApp = {
          ...app,
          _cellVariants: {
            status: this.healthStatus(app.status),
          },
        };
        if (tmpApp.isEndpointBased) {
          tmpApp.name += ' (Not Applicable)';
        }
        return tmpApp;
      });
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get('/apps/monapps/list.json')
        .then((response) => { this.applications = response.data; });
    },
    handleAlertCount(appId, alertCounts) {
      let status = 0;
      if (alertCounts.critical || alertCounts.warning > 20) status = 'danger';
      else if (alertCounts.warning) status = 'warning';
      else status = 'success';
      const appFoundIndex = this.applications.findIndex((app) => app.id === appId);
      if (appFoundIndex !== -1) {
        this.$set(this.applications, appFoundIndex, { ...this.applications[appFoundIndex], status });
      }
    },
    sparkLineUrl(appId) {
      return `/apps/${appId}/numusers.json`;
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    alertRadialChartUrl(appId) {
      return `/apps/${appId}/alerts/alertseverity.json`;
    },
    updateStateTimeRange(timeRange) {
      this.$store.commit('app/UPDATE_TIME_RANGE', timeRange);
      this.timeRange = timeRange;
    },
    getAlertChartUrl(appId) {
      return `/apps/${appId}/alerts/summcounts.json`;
    },
    colWidth(fieldKey) {
      switch (fieldKey) {
        case 'status': return '2%';
        case 'name': return '10%';
        case 'actions': return '10%';
        case 'alerts': return '25%';
        case 'users': return '15%';
        default: return '180px';
      }
    },
    colMinWidth(fieldKey) {
      switch (fieldKey) {
        case 'status': return '15px';
        case 'name': return '100px';
        case 'actions': return '90px';
        case 'users': return '200px';
        case 'alerts': return '200px';
        default: return '80px';
      }
    },
    healthStatus(value) {
      switch (value) {
        case 'danger': return 'danger';
        case 'warning': return 'warning';
        case 'success': return 'success';
        default: return '';
      }
    },
    handleAppNameClick(appId) {
      this.$router.push({ name: 'app', params: { id: appId } });
    },
    handleRowClick(app) {
      this.selectedAppName = app.name;
    },
  },
};
</script>

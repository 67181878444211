<template>
  <b-form @submit.prevent="submitNewSsid">
    <b-form-group>
      <b-input-group>
        <b-form-input v-model="ssidForm.ssid" placeholder="Enter SSID" required></b-form-input>
        <b-button type="submit" variant="primary">Add</b-button>
      </b-input-group>
    </b-form-group>
  </b-form>
</template>

<script>
import SsidEventBus from '@/xvisor/bus/SsidEventBus';

export default {
  data() {
    return {
      ssidForm: {
        ssid: '',
      },
      addSsidUrl: '/policysettings/ssid/add',
    };
  },
  methods: {
    submitNewSsid() {
      this.$http
        .post(this.addSsidUrl, this.ssidForm)
        .then(() => {
          this.$bvToast.toast('Successfully added approved SSID', { variant: 'success' });
          SsidEventBus.$emit('changed');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to add approved SSID', { variant: 'danger' });
        });
    },
  },
};
</script>

export default {
  enterpriseServers: {
    text: 'Enterprise Servers',
    value: {
      server: 'enterprise',
      role: 'server',
    },
  },
  enterpriseClients: {
    text: 'Enterprise Clients',
    value: {
      server: 'enterprise',
      role: 'client',
    },
  },
  internetServers: {
    text: 'Internet Servers',
    value: {
      server: 'internet',
      role: 'server',
    },
  },
  internetClients: {
    text: 'Internet Clients',
    value: {
      server: 'internet',
      role: 'client',
    },
  },
  allServers: {
    text: 'All Servers',
    value: {
      server: 'all',
      role: 'server',
    },
  },
  allClients: {
    text: 'All Clients',
    value: {
      server: 'all',
      role: 'client',
    },
  },
};

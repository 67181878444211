<template>
  <b-row>
    <b-col lg="3" md="3" sm="12" order="1">
      <b-card class="sticky-card">
        <h5 class="kb-title">
          <b-icon icon="info-circle" class="mr-1" />
          Covered Topics
        </h5>
        <vue-perfect-scrollbar :settings="scrollSettings" class="scroll-style">
          <ul class="mt-1">
            <li v-for="(topic, index) in relatedTopics" :key="index" class="ml-n2">
              <b-link :href="`#${topic.href}`" class="list-item">{{ topic.name }}</b-link>
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-card>
    </b-col>
    <b-col lg="9" md="9" sm="12" order="2">
      <b-card>
        <h1 class="mb-1" align="center">Security Dashboard</h1>
        <div class="question-content">
          <SecurityGuideContent></SecurityGuideContent>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import SecurityGuideContent from '@/xvisor/views/knowledgeBase/components/SecurityGuideContent.vue';

export default {
  components: {
    SecurityGuideContent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 50,
      },
      relatedTopics: [
        { name: 'Service Tabs', href: 'service-tabs' },
        { name: 'Selecting Addresses of Interest', href: 'selecting-addresses' },
        { name: 'Moving Averages', href: 'moving-averages' },
        { name: 'ASN and location information for an address', href: 'asn' },
        { name: 'DNS fields', href: 'dns-fields' },
        { name: 'SSH, HTTP, and HTTPS fields', href: 'ssh-http-https' },
        { name: 'Charts for selected addresses', href: 'charts' },
        { name: 'Threat Hunting', href: 'threat-hunting' },
        { name: 'Endpoint Analytics', href: 'endpoint-analytics' },
      ],
    };
  },
};
</script>

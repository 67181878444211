<template>
  <div>
    <b-card no-body>
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col lg="7" md="12" sm="12">
          <b-row align-h="between">
            <b-col lg="4" md="4" sm="12" class="mb-1">
              <div class="form-select">
                Show
                <b-form-select
                  v-model="perPage"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" md="12" sm="12" class="mb-1">
          <b-row align-h="end">
            <SearchBar :hasErrorMessage="true">
              <template #input>
                <b-form-input v-model="filter" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="packageFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(deviceCount)="row">
                {{ formatNumberWithCommas(row.value) }}
              </template>
              <template #cell(versionCount)="row">
                {{ formatNumberWithCommas(row.value) }}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
    <PackageDetails :timeRange="timeRange"></PackageDetails>
  </div>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import PackageDetails from '@/xvisor/components/security/PackageDetails.vue';
import formatNumberWithCommas from '@/xvisor/utilities/addCommaToNumber';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchBar,
    PackageDetails,
  },
  data() {
    return {
      packages: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'packageName', label: 'Name', sortable: true },
        { key: 'deviceCount', label: 'Device Count', sortable: true },
        { key: 'versionCount', label: 'Version Count', sortable: true },
      ],
      selected: [],
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.packages && this.packages.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.packages.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    packageFilter() {
      const retVal = this.packages;
      return retVal;
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
    selected() {
      SecurityEventBus.$emit('update-selected-package', this.selected[0]);
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.packages = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    formatNumberWithCommas,
  },
};
</script>

<style scoped>
  .form-select{
    min-width: 160px;
    width: 20%;
  }
</style>

<template>
  <div v-if="hasData" class="mx-n1-5">
    <b-row align-h="end" class="mx-0-5">
      <b-col lg="5" md="5" sm="12" class="mb-1">
        <SearchBar>
          <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <b-table
      :per-page="perPage"
      :current-page="currentPage"
      primary-key="appIdStr"
      hover
      striped
      responsive
      :items="flowTable"
      :fields="fields"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: colWidth(field.key), minWidth: colMinWidth(field.key) }"
        >
      </template>
      <template #cell(appName)="data">
        <span>{{ data.value }}</span>
      </template>
      <template #cell(bytes)="data">
        {{ readableBytes(data.value) }}
      </template>
      <template #cell(activityUrl)="data">
        <HeatmapEchart
          :url="data.value"
          width="200px !important"
          height="35px !important"
          :timeRange="timeRange"
          :hasByteValue="true"
        ></HeatmapEchart>
      </template>
      <template #cell(topSrcIpsUrl)="data">
        <ProgressBar
          barColor="success"
          :url="data.value"
          :totalBytes="data.item.bytes"
          :heightBar="5"
          :timeRange="timeRange"
          :compact="true"
        ></ProgressBar>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      class="my-1 mr-1"
    />
  </div>
  <div v-else-if="loaded" class="ml-1">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else class="my-1"></SpinnerCmpt>
</template>

<script>
import HeatmapEchart from '@/xvisor/components/HeatmapEchart.vue';
import ProgressBar from '@/xvisor/components/ProgressBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import readableBytes from '@/xvisor/utilities/readableBytes';
import SearchBar from '@/xvisor/components/SearchBar.vue';

export default {
  components: {
    HeatmapEchart,
    ProgressBar,
    SearchBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      currentPage: 1,
      filteredLength: null,
      perPage: 3,
      flowTable: [],
      fields: [
        { key: 'appName', label: 'App Name' },
        { key: 'bytes', label: 'Total Traffic' },
        { key: 'activityUrl', label: 'Activity' },
        { key: 'topSrcIpsUrl', label: 'Top Source IPs' },
      ],
      filter: '',
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.flowTable && this.flowTable.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.flowTable.length;
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.flowTable = response.data; })
        .finally(() => { this.loaded = true; });
    },
    colWidth(fieldKey) {
      switch (fieldKey) {
        case 'appName': return '5%';
        case 'bytes': return '10%';
        case 'activityUrl': return '10%';
        case 'topSrcIpsUrl': return '25%';
        default: return '180px';
      }
    },
    colMinWidth(fieldKey) {
      switch (fieldKey) {
        case 'appName': return '20px';
        case 'bytes': return '100px';
        case 'activityUrl': return '90px';
        case 'topSrcIpsUrl': return '300px';
        default: return '80px';
      }
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    readableBytes,
    collapseText,
  },
};
</script>

<template>
  <div v-if="hasData">
    <b-row>
      <b-col class="mt-2">
        <h4>Transaction Stats</h4>
      </b-col>
      <b-col lg="3" md="4" sm="5">
        <b-input-group class="my-1 input-group-merge round">
          <b-input-group-prepend is-text>
            <b-icon icon="search" />
          </b-input-group-prepend>
          <b-form-input v-model="filter" type="search" placeholder="Search" />
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
      :current-page="currentPage"
      :items="formattedServersData"
      :per-page="perPage"
      hover
      striped
      responsive
      primary-key="ip"
      :fields="fields"
      :filter="filter"
      @filtered="onFiltered"
      class="flex"
    >
      <template #cell(ip)="data">
        IP: {{ data.value }}
      </template>
      <template #cell(transTotalsUrl)="data">
        <TransTotal
          :transTotalsUrl="data.value"
          :timeRange="timeRange"
          class="my-n1 py-n1"
        ></TransTotal>
      </template>
      <template #cell(transDurationTsUrl)="data">
        <TransDuration
          :serverIp="data.item.ip"
          :transDataUrl="transDataUrl"
          :cmptId="cmptId"
          :timeRange="timeRange"
          class="my-n1 py-n1"
        ></TransDuration>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      align="right"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      class="my-1"
    />
  </div>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else class="my-2 w-100"></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import TransDuration from '@/xvisor/components/app/TransDuration.vue';
import TransTotal from '@/xvisor/components/app/TransTotal.vue';

export default {
  props: {
    transDataUrl: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    appId: {
      type: Number,
      required: true,
    },
    cmptId: {
      type: Number,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
    TransDuration,
    TransTotal,
  },
  data() {
    return {
      currentPage: 1,
      filter: null,
      filteredLength: null,
      loaded: false,
      perPage: 10,
      fields: [
        { key: 'ip', label: 'Server/Target' },
        { key: 'transTotalsUrl', label: '# Trans' },
        { key: 'transDurationTsUrl', label: 'Response Time', thStyle: { width: '20%' } },
      ],
      serversData: [],
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.formattedServersData && this.formattedServersData.length > 0;
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.serversData.length;
    },
    formattedServersData() {
      const formattedServersData = [];
      this.serversData.forEach((serverData) => {
        formattedServersData.push({
          ip: serverData.ip,
          transTotalsUrl: `/apps/${this.appId}/cmpt/${this.cmptId}/${serverData.ip}/transtotals.json`,
          transDurationTsUrl: `/apps/${this.appId}/cmpt/${this.cmptId}/${serverData.ip}/transdurts.json`,
        });
      });
      return formattedServersData;
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(`/apps/${this.appId}/cmpts/${this.cmptId}/srvrs/list.json`, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            zoneId: this.zoneId,
          },
        })
        .then((response) => { this.serversData = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

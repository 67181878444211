<template>
  <div class="ip-attributes-container">
    <b-row class="mb-1">
      <b-col cols="6">
        <b-row align-h="start" align-v="center">
          <h2>{{ `IP: ${ip}` }}</h2>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row align-h="end" align-v="center">
          <TimeRangeSelector
            :minDate="minDate"
            :maxDate="maxDate"
            :initRange="timeRange"
            @change-time-range="updateStateTimeRange"
          >
          </TimeRangeSelector>
        </b-row>
      </b-col>
    </b-row>
    <div id="panels-row">
      <div class="top-panel d-flex w-100">
        <b-card class="summary-card">
          <div class="d-flex flex-row mb-0 card-title">
            <h4>Information</h4>
            <b-icon-exclamation-triangle-fill
              v-if="displayOldSummaryIcon"
              v-b-tooltip.hover
              :title="getOldSummaryTooltip"
              variant="warning"
            >
            </b-icon-exclamation-triangle-fill>
          </div>
          <div class="mt-2" v-if="hasSummaryData">
            <h5
              v-for="(item, idx) in summaryData"
              :key="idx"
              class="summary-list-items d-flex justify-content-start"
            >
              <span class="w-25">
                <b>{{ item.attribute }}</b>
              </span>
              <span>
                {{ item.value }}
              </span>
            </h5>
          </div>
          <div v-else-if="summaryDataLoaded">{{ $t("No Data Available") }}</div>
          <SpinnerCmpt v-else></SpinnerCmpt>
        </b-card>
        <b-card class="attachment-card">
          <div class="d-flex flex-row mb-0 card-title">
            <h4>Attachment History</h4>
            <b-icon-exclamation-triangle-fill
              v-if="displayOldHistoryIcon"
              v-b-tooltip.hover
              :title="getOldHistoryTooltip"
              variant="warning"
            >
            </b-icon-exclamation-triangle-fill>
          </div>
          <b-table
            v-if="hasAttachmentData"
            class="mt-2"
            :items="attachmentData"
            :fields="attachmentTableFields"
            bordered
            sticky-header="200px"
            head-variant="light"
          >
            <template #cell(timestamp)="row">
              {{ formatDate(row.value) }}
            </template>
          </b-table>
          <div v-else-if="attachmentDataLoaded">
            {{ $t("No Data Available") }}
          </div>
          <SpinnerCmpt v-else></SpinnerCmpt>
        </b-card>
      </div>
      <div id="panels-row">
        <div class="top-panel d-flex w-100">
          <b-card class="traffic-card">
            <h4>Traffic</h4>
            <IpInOutChart
              :timeRange="timeRange"
              :url="IpInOutChartUrl"
            ></IpInOutChart>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import TimeRangeSelector from '@/xvisor/components/TimeRangeSelector.vue';
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';
import IpInOutChart from '@/xvisor/views/IpInOutChart.vue';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  mixins: [timeRangeMixin],
  components: {
    SpinnerCmpt,
    TimeRangeSelector,
    IpInOutChart,
  },
  computed: {
    displayOldSummaryIcon() {
      return this.hasOldSummary;
    },
    displayOldHistoryIcon() {
      return this.hasOldHistory;
    },
    getOldSummaryTooltip() {
      return `Summary information for ${this.ip} between `
        + 'the requested start and end time does not exist. '
        + 'The most recent summary has been returned instead.';
    },
    getOldHistoryTooltip() {
      const dateStr = this.formatDate(this.attachmentData[0].timestamp);
      return `Attachment history for ${this.ip} between `
        + 'the requested start and end time does not exist. '
        + 'The most recent attachment found at date '
        + `${dateStr} has been returned instead.`;
    },
    hasSummaryData() {
      return (
        this.summaryDataLoaded && this.summaryData && this.summaryData.length
      );
    },
    IpInOutChartUrl() {
      return `${this.ip}/inandouttraffic.json`;
    },
    hasAttachmentData() {
      return (
        this.attachmentDataLoaded
        && this.attachmentData
        && this.attachmentData.length
      );
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGetSummary();
      this.httpGetAttachment();
    },
  },
  data() {
    const { ip } = this.$route.params;

    return {
      ip,
      attachmentData: [],
      attachmentDataLoaded: false,
      hasOldHistory: false,
      hasOldSummary: false,
      summaryData: [],
      summaryDataLoaded: false,
      summaryTableFields: [
        { key: 'attribute', label: 'Property' },
        { key: 'value', label: 'Value' },
      ],
      attachmentTableFields: [
        { key: 'timestamp', label: 'Timestamp' },
        { key: 'deviceName', label: 'Device Name' },
        { key: 'username', label: 'User Name' },
        { key: 'macAddress', label: 'MAC Address' },
      ],
    };
  },
  mounted() {
    this.httpGetSummary();
    this.httpGetAttachment();
  },
  methods: {
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateYearTime);
    },
    httpGetSummary() {
      this.$http
        .get(`${this.ip}/attrs.json`, this.httpParams)
        .then((response) => {
          this.summaryData = [];
          if (response.data.length) {
            this.hasOldSummary = moment(
              response.data[0].timestamp,
            ).isBefore(this.timeRange.start.toISOString());
            const [data] = response.data;
            const attrLookup = {
              ip: 'IP',
              timestamp: null,
              macAddress: 'MAC Address',
              deviceName: 'Device Name',
              hostname: 'Host Name',
              username: 'Username',
            };
            Object.keys(data).forEach((key) => {
              const attName = attrLookup[key];
              if (attName) {
                const obj = {
                  attribute: attName,
                  value: data[key],
                };
                this.summaryData.push(obj);
              }
            });
          }
        })
        .finally(() => {
          this.summaryDataLoaded = true;
        });
    },
    httpGetAttachment() {
      this.$http
        .get(`${this.ip}/macaddrhist.json`, this.httpParams)
        .then((response) => {
          if (response.data.length) {
            this.hasOldHistory = moment(
              response.data[0].timestamp,
            ).isBefore(this.timeRange.start.toISOString());
            this.attachmentData = response.data;
          }
        })
        .finally(() => {
          this.attachmentDataLoaded = true;
        });
    },
  },
};
</script>
<style>
#panels-row {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.top-panel {
  gap: 1.5rem;
}
.summary-list-items {
  gap: 1.5rem;
}
.summary-card {
  width: 33%;
}
.attachment-card {
  width: 66%;
}
.traffic-card {
  width: 50%;
}
.card-title {
  gap: 1rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"title":"Add Aternity Integration","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-left mr-1",attrs:{"variant":"primary"},on:{"click":_vm.addIntegration}},[_vm._v("Submit")]),_c('b-button',{staticClass:"float-left",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$refs.modal.hide()}}},[_vm._v(" Cancel ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"addIntegration"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"URL","label-cols-md":"4"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"URL","type":"url","state":errors[0] ? false : null},model:{value:(_vm.newIntegration.url),callback:function ($$v) {_vm.$set(_vm.newIntegration, "url", $$v)},expression:"newIntegration.url"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Version","label-cols-md":"4"}},[_c('b-form-select',{attrs:{"options":_vm.integration.versions,"state":errors[0] ? false : null},model:{value:(_vm.newIntegration.version),callback:function ($$v) {_vm.$set(_vm.newIntegration, "version", $$v)},expression:"newIntegration.version"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Username","label-cols-md":"4"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Username","state":errors[0] ? false : null},model:{value:(_vm.newIntegration.credentials.username),callback:function ($$v) {_vm.$set(_vm.newIntegration.credentials, "username", $$v)},expression:"newIntegration.credentials.username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password","label-cols-md":"4"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autofocus":"","placeholder":"Password","type":_vm.passwordFieldType,"state":errors[0] ? false : null},model:{value:(_vm.newIntegration.credentials.password),callback:function ($$v) {_vm.$set(_vm.newIntegration.credentials, "password", $$v)},expression:"newIntegration.credentials.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePassword}})],1)],1),_c('small',{staticClass:"text-danger"},[(errors[0])?[_vm._v("Password is required.")]:_vm._e()],2)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-row>
      <b-col lg="6" md="12" sm="12"
        v-for="(componentObject, index) in components.slice().reverse()"
        :key="index"
      >
        <SearchPanel
          :componentObject="componentObject"
          :timeRange="timeRange"
          :panelSizeHalf="components.length > 2"
          @save-panel="saveComponent"
          @delete-panel="deleteComponent"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import LaunchpadEventBus from '@/xvisor/bus/LaunchpadEventBus';
import SearchPanel from '@/xvisor/components/launchPad/SearchPanel.vue';

import dynamicComponentsMixin from '@/xvisor/components/launchPad/dynamicComponentsMixin';

export default {
  mixins: [dynamicComponentsMixin],
  components: {
    SearchPanel,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      components: [],
      savedComponents: [],
      duplicateComponents: [],
    };
  },
  mounted() {
    if (localStorage.getItem('savedComponents')) {
      this.savedComponents = JSON.parse(localStorage.getItem('savedComponents'));
      this.savedComponents.forEach((storedComponent) => {
        const componentObject = this.componentEnumToInfo.get(storedComponent.componentEnum);
        this.components.push({
          component: componentObject.component,
          url: componentObject.url,
          title: componentObject.title,
          componentId: storedComponent.componentId,
          componentEnum: storedComponent.componentEnum,
          isSaved: true,
          query: componentObject.query,
          aiResponse: '',
        });
        LaunchpadEventBus.$emit('disable-search-option', componentObject.query);
      });
    } else {
      this.setDefaultComponents();
    }
    LaunchpadEventBus.$on('add-panel', this.componentEnumToAdd);
    LaunchpadEventBus.$on('check-ai-response', this.checkDuplicateComponents);
  },
  watch: {
    savedComponents() {
      localStorage.setItem('savedComponents', JSON.stringify(this.savedComponents));
    },
  },
  methods: {
    componentEnumToAdd(componentInfo) {
      const { value: componentEnumToAdd, aiResponse } = componentInfo;
      const componentObject = this.componentEnumToInfo.get(componentEnumToAdd);
      if (this.components.findIndex((component) => component.componentEnum === componentEnumToAdd) === -1) {
        this.components.push({
          component: componentObject.component,
          url: componentObject.url,
          title: componentObject.title,
          componentId: uuidv4(),
          componentEnum: componentEnumToAdd,
          isSaved: false,
          query: componentObject.query,
          aiResponse,
        });
      } else {
        this.duplicateComponents.push(componentObject.title);
      }
      LaunchpadEventBus.$emit('disable-search-option', componentObject.query);
    },
    checkDuplicateComponents() {
      if (this.duplicateComponents.length) {
        this.$bvToast.toast(`The query response will not render the following panels as they already exist:
        ${this.duplicateComponents.join(', ')}.`, { variant: 'success', noAutoHide: 'true' });
        this.duplicateComponents = [];
      }
    },
    saveComponent(componentId) {
      const componentIndex = this.findComponent(componentId, this.components);
      if (!this.components[componentIndex].isSaved) {
        this.savedComponents.push({
          componentId,
          componentEnum: this.components[componentIndex].componentEnum,
        });
        this.components[componentIndex].isSaved = true;
      } else {
        const savedComponentIndex = this.findComponent(componentId, this.savedComponents);
        this.savedComponents.splice(savedComponentIndex, 1);
        this.components[componentIndex].isSaved = false;
      }
    },
    deleteComponent(componentId) {
      const componentIndex = this.findComponent(componentId, this.components);
      LaunchpadEventBus.$emit('enable-search-option', this.components[componentIndex].query);
      this.components.splice(componentIndex, 1);
    },
    setDefaultComponents() {
      Object.values(this.componentEnum).forEach((componentEnum) => {
        const componentObject = this.componentEnumToInfo.get(componentEnum);
        if (componentObject.isDefaultPanel) {
          this.components.push({
            component: componentObject.component,
            url: componentObject.url,
            title: componentObject.title,
            componentId: uuidv4(),
            componentEnum,
            isSaved: false,
            query: componentObject.query,
          });
          LaunchpadEventBus.$emit('disable-search-option', componentObject.query);
        }
      });
      this.components.forEach((defaultPanel) => this.saveComponent(defaultPanel.componentId));
    },
    findComponent(componentId, componentArray) {
      for (let i = 0; i < componentArray.length; i += 1) {
        if (componentArray[i].componentId === componentId) {
          return i;
        }
      }
      return -1;
    },
  },
};
</script>

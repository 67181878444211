<template>
  <div v-if="hasAppData" class="">
    <b-row class="mb-1" align-v="center" align-h="end">
      <b-col lg="6" md="12" sm="12">
        <SearchBar>
          <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <b-table
      hover
      pagination
      responsive
      :class="reduceTableHeight ? 'table-height-half' : ''"
      :per-page="perPage"
      :current-page="currentPage"
      striped
      :items="itemsFormat"
      primaryKey="name"
      :fields="fields"
      :filter="filter"
      :filterIncludedFields="filterOn"
      @filtered="onFiltered"
    >
      <template #thead-top="">
        <b-tr>
          <b-th>Zone</b-th>
          <b-th colspan="2">In</b-th>
          <b-th colspan="2">Out</b-th>
        </b-tr>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      class="my-1"
    />
  </div>
  <div v-else-if="appsLoaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import readableBytes from '@/xvisor/utilities/readableBytes';

export default {
  components: {
    SearchBar,
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    reduceTableHeight: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      appSeries: [],
      appsLoaded: false,
      currentPage: 1,
      perPage: this.reduceTableHeight ? 3 : 5,
      totalRows: 0,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'zone', label: '' },
        { key: 'inputTraffic', label: 'traffic', sortable: true },
        { key: 'inputAddresses', label: 'addresses', sortable: true },
        { key: 'outputTraffic', label: 'traffic', sortable: true },
        { key: 'outputAddresses', label: 'addresses', sortable: true },
      ],
    };
  },
  computed: {
    hasAppData() {
      return this.appsLoaded && this.appSeries && this.appSeries.length > 0;
    },
    itemsFormat() {
      const res = [];
      if (this.hasAppData) {
        this.appSeries.forEach((item) => {
          const newItem = { ...item };
          newItem.inputTraffic = readableBytes(item.inputTraffic);
          newItem.outputTraffic = readableBytes(item.outputTraffic);
          res.push(newItem);
        });
      }
      return res;
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          zoneId: this.zoneId,
        },
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    reduceTableHeight() {
      this.perPage = this.reduceTableHeight ? 3 : 5;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.appsLoaded = false;
      this.$http
        .get(this.url, this.httpParams)
        .then((response) => {
          this.appSeries = response.data;
          this.totalRows = this.appSeries.length;
        })
        .finally(() => { this.appsLoaded = true; });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    readableBytes,
  },
};
</script>

<style>
.table-height-half {
  max-height: 49vh;
}
</style>

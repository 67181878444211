<template>
  <b-card class="mt-n1">
    <b-row v-if="hasData" no-gutters>
      <b-col md="8" sm="12" class="flowgraph-height">
        <NeighborFlowGraph
          v-if="neighborQueryId"
          :series="series"
          :events="events"
          :categoryNameToCategoryId="categoryNameToCategoryId"
          :minMaxNodeBytes="minMaxNodeBytes"
          :minMaxLinkBytes="minMaxLinkBytes"
          :neighborQueryId="neighborQueryId"
          @clickedNode="addNeighbor"
          @closedGraph="resetNeighborQuery"
        ></NeighborFlowGraph>
        <template v-else>
          <p class="node-expansion-label">
            Click on a node to view its neighbors.
          </p>
          <FlowGraph
            :series="series"
            :events="events"
            :categoryNameToCategoryId="categoryNameToCategoryId"
            :minMaxNodeBytes="minMaxNodeBytes"
            :minMaxLinkBytes="minMaxLinkBytes"
            @clickedNode="addNeighbor"
          ></FlowGraph>
        </template>
      </b-col>
    </b-row>
    <div v-else-if="loaded.series"></div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </b-card>
</template>

<script>
import FlowGraph from '@/xvisor/components/app/flowGraph/FlowGraph.vue';
import NeighborFlowGraph from '@/xvisor/components/app/flowGraph/NeighborFlowGraph.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  components: {
    FlowGraph,
    NeighborFlowGraph,
    SpinnerCmpt,
  },
  props: {
    urls: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    elementId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.resetData();
      this.httpGet();
    },
  },
  data() {
    return {
      series: null,
      // The potential error information returned from the backend.
      events: {},
      loaded: { series: false, events: false },
      // The latest node the user clicked on. Updates on this property prompts an update in the neighbor graph.
      neighborQueryId: null,
    };
  },
  computed: {
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    hasData() {
      return this.loaded.series && this.loaded.events && this.series
        && (this.series.nodes && this.series.nodes.length > 0)
        && (this.series.links && this.series.links.length > 0);
    },
    // The maximum and minimum bytes transferred for all nodes.
    minMaxNodeBytes() {
      const bytes = this.series ? this.series.nodes.map((node) => node.bytes) : [];
      const [min, max] = [Math.min(...bytes), Math.max(...bytes)];
      return { min, max };
    },
    // The maximum and minimum bytes transferred for all links.
    minMaxLinkBytes() {
      const bytes = this.series ? this.series.links.map((link) => link.bytes) : [];
      const [min, max] = [Math.min(...bytes), Math.max(...bytes)];
      return { min, max };
    },
    categoryNameToCategoryId() {
      const categoryToIndex = {};
      const categories = this.series ? [...new Set(this.series.nodes.map((node) => node.category))] : [];
      categories.forEach((category, index) => { categoryToIndex[category] = index; });
      return categoryToIndex;
    },
  },
  methods: {
    // Called when user selects node on either graph.
    addNeighbor(nodeId) {
      this.neighborQueryId = nodeId;
    },
    // Called when user closes neighbor graph.
    resetNeighborQuery() {
      this.neighborQueryId = null;
    },
    resetData() {
      this.loaded = { series: false, events: false };
      this.neighborQueryId = null;
      this.series = null;
      this.events = {};
    },
    httpGet() {
      this.$http
        .get(this.urls.graphUrl, this.httpParams)
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded.series = true; });
      this.$http
        .get(this.urls.eventUrl, this.httpParams)
        .then((response) => {
          response.data.forEach((event) => { this.events[event.appUserIp] = event; });
        })
        .finally(() => { this.loaded.events = true; });
    },
  },
};
</script>

<style scoped>
.flowgraph-height {
  height: 400px;
}
.node-expansion-label {
  right: 0;
  bottom: 0;
  margin: 0;
}
</style>

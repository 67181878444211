<template>
  <Pathroutes
    v-if="hasData"
    :nodeIdToNodeObj="nodeIdToNodeObj"
    :linkIdToLinkObj="linkIdToLinkObj"
    :blockIdToBlockObj="blockIdToBlockObj"
    :blocksLoaded="hasBlockData"
    :asnObj="asnObj"
    :height="echartHeight"
    :url="this.urls.nodeHopRtdUrl"
    :shortTimeRange="shortTimeRange"
    :timeRange="timeRange"
  ></Pathroutes>
  <div v-else>{{ $t("No Data Available") }}</div>
</template>

<script>
import Pathroutes from '@/xvisor/components/app/networkTab/pathroutes/Pathroutes.vue';
import PathroutesNode from '@/xvisor/components/app/networkTab/pathroutes/models/pathroutesNode';
import PathroutesLink from '@/xvisor/components/app/networkTab/pathroutes/models/pathroutesLink';
import PathroutesBlock from '@/xvisor/components/app/networkTab/pathroutes/models/pathroutesBlock';

export default {
  components: {
    Pathroutes,
  },
  props: {
    urls: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rawData: {
        layout: {},
        blocks: {},
        geolocations: {},
        sourcenames: {},
        destnames: {},
        linkMetrics: {},
        linkBaselines: {},
        nodeMetrics: {},
        asns: {},
        domainEvents: {},
        nodeBaselines: {},
      },
    };
  },
  computed: {
    hasLayoutData() {
      const { links, nodes } = this.rawData.layout;
      return (links && links.length > 0) && (nodes && nodes.length > 0);
    },
    hasBlockData() {
      return this.objectNotEmpty(this.rawData.blocks);
    },
    hasGeolocationsData() {
      return this.objectNotEmpty(this.rawData.geolocations);
    },
    hasSourcenamesData() {
      return this.objectNotEmpty(this.rawData.sourcenames);
    },
    hasDestnamesData() {
      return this.objectNotEmpty(this.rawData.destnames);
    },
    hasLinkMetricsData() {
      return this.objectNotEmpty(this.rawData.linkMetrics);
    },
    hasLinkBaselinesData() {
      return this.objectNotEmpty(this.rawData.linkBaselines);
    },
    hasNodeMetricsData() {
      return this.objectNotEmpty(this.rawData.nodeMetrics);
    },
    hasNodeBaselineData() {
      return this.objectNotEmpty(this.rawData.nodeBaselines);
    },
    hasAsnData() {
      return this.objectNotEmpty(this.rawData.asns);
    },
    hasEventsData() {
      return this.objectNotEmpty(this.rawData.domainEvents);
    },
    hasData() {
      // Display the pathroutes when the layout information has loaded.
      return this.hasLayoutData;
    },
    shortTimeRangeParams() {
      return {
        params: {
          start: this.shortTimeRange.start.toISOString(),
          end: this.shortTimeRange.end.toISOString(),
        },
      };
    },
    timeRangeParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    longTimeRangeParams() {
      const startTime = this.timeRange.start;
      const endTime = this.timeRange.end;
      const timeDifference = endTime - startTime;
      const timeDifferenceWanted = timeDifference * 4;

      // Value to extend back the start time to have the time difference wanted.
      const timeDifferenceFactor = timeDifferenceWanted - timeDifference;
      const extendedStartTime = new Date(startTime - timeDifferenceFactor);
      return {
        params: {
          start: extendedStartTime.toISOString(),
          end: endTime.toISOString(),
        },
      };
    },
    nodeIdToNodeObj() {
      const result = {};
      const geolocationMap = {};
      const sourcenameMap = {};
      const destnameMap = {};
      const nodeMetricMap = {};
      const nodeDelayBaselineMap = {};
      const asnMap = {};
      if (this.hasLayoutData === false) return result;
      if (this.hasNodeMetricsData) {
        this.rawData.nodeMetrics.forEach((nodeMetric) => {
          const delays = nodeMetric.delay;
          const loss = nodeMetric.avgLoss;
          nodeMetricMap[nodeMetric.nodeId] = [delays, loss];
        });
      }
      if (this.hasNodeBaselineData) {
        this.rawData.nodeBaselines.forEach((nodeBaseline) => {
          nodeDelayBaselineMap[nodeBaseline.nodeId] = nodeBaseline.delay;
        });
      }
      if (this.hasGeolocationsData) {
        this.rawData.geolocations.forEach((geolocation) => {
          geolocationMap[geolocation.nodeId] = geolocation;
        });
      }
      if (this.hasSourcenamesData) {
        this.rawData.sourcenames.forEach((sourcename) => {
          sourcenameMap[sourcename.nodeId] = sourcename.xomeName;
        });
      }
      if (this.hasDestnamesData) {
        this.rawData.destnames.forEach((destname) => {
          destnameMap[destname.nodeId] = destname.name;
        });
      }
      if (this.hasAsnData) {
        this.rawData.asns.forEach((asn, idx) => {
          if (asn.nodeIds) {
            const asnInfo = {
              asnName: asn.asnName,
              orgName: asn.orgName,
              index: idx,
            };
            asn.nodeIds.forEach((nodeId) => {
              asnMap[nodeId] = asnInfo;
            });
          }
        });
      }
      const sizeOfAsns = this.rawData.asns.length;
      this.rawData.layout.nodes.forEach((nodeLayout) => {
        result[nodeLayout.id] = new PathroutesNode(
          nodeLayout,
          geolocationMap,
          sourcenameMap,
          destnameMap,
          nodeMetricMap,
          nodeDelayBaselineMap,
          asnMap,
          sizeOfAsns,
        );
      });
      Object.keys(this.blockIdToBlockObj).forEach((blockId) => {
        const blockObj = this.blockIdToBlockObj[blockId];
        blockObj.nodeIds.forEach((nodeId) => {
          if (nodeId in result) {
            result[nodeId].setBlockInformation(blockObj.echartsIndex, blockObj.id);
          }
        });
      });
      return result;
    },
    linkIdToLinkObj() {
      const result = {};
      const metricMap = {};
      const linkBaselineMap = {};
      const nodeIdAsnMap = {};
      if (this.hasLinkMetricsData) {
        this.rawData.linkMetrics.forEach((metric) => {
          metricMap[metric.linkId] = metric;
        });
      }
      if (this.hasLinkBaselinesData) {
        this.rawData.linkBaselines.forEach((linkBaseline) => {
          linkBaselineMap[linkBaseline.linkId] = linkBaseline;
        });
      }
      if (this.hasAsnData) {
        this.rawData.asns.forEach((asn) => {
          if (asn.nodeIds) {
            const asnInfo = {
              asnName: asn.asnName,
              orgName: asn.orgName,
            };
            asn.nodeIds.forEach((nodeId) => {
              nodeIdAsnMap[nodeId] = asnInfo;
            });
          }
        });
      }
      if (this.hasLayoutData === false) return result;
      this.rawData.layout.links.forEach((linkLayout) => {
        let metric = null;
        let baseline = null;
        let sourceAsnInfo;
        let targetAsnInfo;
        if (metricMap[linkLayout.id]) {
          metric = metricMap[linkLayout.id];
        }
        if (linkBaselineMap[linkLayout.id]) {
          baseline = linkBaselineMap[linkLayout.id];
        }
        if (nodeIdAsnMap[linkLayout.sourceId]) {
          sourceAsnInfo = {
            asnName: nodeIdAsnMap[linkLayout.sourceId].asnName,
            orgName: nodeIdAsnMap[linkLayout.sourceId].orgName,
          };
        }
        if (nodeIdAsnMap[linkLayout.targetId]) {
          targetAsnInfo = {
            asnName: nodeIdAsnMap[linkLayout.targetId].asnName,
            orgName: nodeIdAsnMap[linkLayout.targetId].orgName,
          };
        }
        result[linkLayout.id] = new PathroutesLink(linkLayout, metric, baseline, sourceAsnInfo, targetAsnInfo);
      });
      return result;
    },
    blockIdToBlockObj() {
      const LOSS_THRESHOLD = 5;
      const result = {};
      const blockNodeIdAsnMap = {};
      const nodeIdHasLossMap = {};
      const linkHasLossMap = {};
      const metricMap = {};
      const linkBaselineMap = {};
      const asnEventsMap = {};
      if (this.hasLinkMetricsData) {
        this.rawData.linkMetrics.forEach((metric) => {
          metricMap[metric.linkId] = metric;
        });
      }
      if (this.hasLinkBaselinesData) {
        this.rawData.linkBaselines.forEach((linkBaseline) => {
          linkBaselineMap[linkBaseline.linkId] = linkBaseline;
        });
      }
      if (this.hasAsnData) {
        this.rawData.asns.forEach((asn, idx) => {
          if (asn.nodeIds) {
            const asnInfo = {
              asnName: asn.asnName,
              orgName: asn.orgName,
              index: idx,
            };
            asn.nodeIds.forEach((nodeId) => {
              blockNodeIdAsnMap[nodeId] = asnInfo;
            });
          }
        });
      }
      if (this.hasNodeMetricsData) {
        this.rawData.nodeMetrics.forEach((nodeMetric) => {
          const loss = nodeMetric.avgLoss;
          if (loss > 0) {
            nodeIdHasLossMap[nodeMetric.nodeId] = true;
          }
        });
      }
      if (this.hasLinkMetricsData) {
        this.rawData.linkMetrics.forEach((metric) => {
          if (metric.avgLoss > LOSS_THRESHOLD) {
            linkHasLossMap[metric.linkId] = true;
          }
        });
      }
      if (this.hasEventsData) {
        this.rawData.domainEvents.forEach((element) => {
          asnEventsMap[element.asn] = element.events;
        });
      }
      const sizeOfAsns = this.rawData.asns.length;
      if (this.hasBlockData === false) return result;
      this.rawData.blocks.forEach((block, idx) => {
        result[block.id] = new PathroutesBlock(
          block,
          blockNodeIdAsnMap,
          idx,
          linkHasLossMap,
          linkBaselineMap,
          metricMap,
          nodeIdHasLossMap,
          asnEventsMap,
          sizeOfAsns,
        );
      });
      return result;
    },
    asnObj() {
      const result = {};
      if (this.hasAsnData === false) return result;
      this.rawData.asns.forEach((asn, idx) => {
        if (asn.nodeIds) {
          result[idx] = {
            number: asn.asnName,
            name: asn.orgName,
          };
        }
      });
      return result;
    },
    echartHeight() {
      let height = 50;
      const nodesSepToHeightCoeff = 100;
      if (this.hasLayoutData === false) return height;
      const { nodes } = this.rawData.layout;
      const values = nodes.map((nodebase) => nodebase.coordinate.y).flat();
      const [min, max] = [Math.min(...values), Math.max(...values)];
      height += Math.ceil((max - min) / nodesSepToHeightCoeff);
      return height;
    },
  },
  watch: {
    shortTimeRange() {
      this.resetData();
      this.httpGet();
    },
    timeRange() {
      this.resetData();
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.urls.layoutUrl, this.shortTimeRangeParams)
        .then((response) => {
          this.rawData.layout = response.data;
          const { reqId } = response.data;
          const shortParamsWithReqId = {
            params: { ...this.shortTimeRangeParams.params, reqId },
          };
          const timeParamsWithReqId = {
            params: { ...this.timeRangeParams.params, reqId },
          };
          const longParamsWithReqId = {
            params: { ...this.longTimeRangeParams.params, reqId },
          };
          this.$http
            .get(this.urls.sourcenamesUrl, timeParamsWithReqId)
            .then((sourcenamesResponse) => { this.rawData.sourcenames = sourcenamesResponse.data; });
          this.$http
            .get(this.urls.destnamesUrl, timeParamsWithReqId)
            .then((destnamesResponse) => { this.rawData.destnames = destnamesResponse.data; });
          this.$http
            .get(this.urls.metricsUrl, timeParamsWithReqId)
            .then((metricsResponse) => { this.rawData.linkMetrics = metricsResponse.data; });
          this.$http
            .get(this.urls.linkBaselineUrl, longParamsWithReqId)
            .then((linkBaselineResponse) => { this.rawData.linkBaselines = linkBaselineResponse.data; });
          this.$http
            .get(this.urls.blocksUrl, shortParamsWithReqId)
            .then((blocksResponse) => { this.rawData.blocks = blocksResponse.data; });
          this.$http
            .get(this.urls.geolocationsUrl, timeParamsWithReqId)
            .then((geolocationsResponse) => { this.rawData.geolocations = geolocationsResponse.data; });
          this.$http
            .get(this.urls.delayAndLossUrl, shortParamsWithReqId)
            .then((delayAndLossResponse) => { this.rawData.nodeMetrics = delayAndLossResponse.data; });
          this.$http
            .get(this.urls.nodeBaselineUrl, longParamsWithReqId)
            .then((nodeBaselineResponse) => { this.rawData.nodeBaselines = nodeBaselineResponse.data; });
          this.$http
            .get(this.urls.asnUrl, timeParamsWithReqId)
            .then((asnResponse) => { this.rawData.asns = asnResponse.data; });
          this.$http
            .get(this.urls.eventsUrl, shortParamsWithReqId)
            .then((eventsResponse) => { this.rawData.domainEvents = eventsResponse.data; });
        });
    },
    objectNotEmpty(obj) {
      if (obj === undefined || obj == null) return false;
      return Object.keys(obj).length > 0;
    },
    resetData() {
      this.rawData = {
        layout: {},
        blocks: {},
        geolocations: {},
        sourcenames: {},
        destnames: {},
        linkMetrics: {},
        linkBaselines: {},
        nodeMetrics: {},
        asns: {},
        domainEvents: {},
        nodeBaselines: {},
      };
    },
  },
};
</script>

<template>
  <div>
    <b-table
      id="offices-table"
      class="table-with-actions-min-height"
      :items="offices"
      :fields="fields"
      responsive
      striped
    >
      <!-- Icon button for expanding the zone details section. -->
      <template #cell(details)="row">
        <b-icon
          @click="row.toggleDetails"
          :icon="row.detailsShowing ? 'chevron-down' : 'chevron-right'"
          style="cursor: pointer;"
        ></b-icon>
      </template>
      <template #cell(zones)="row">
        {{ row.value.map(zone => zone.name).join(', ') }}
      </template>
      <template #cell(location)="row">
        {{ row.item.office ? getLocationName(row.item.office.longitude, row.item.office.latitude) : '' }}
      </template>
      <!-- Actions for editing/deleting an office. -->
      <template #cell(actions)="row">
        <OfficeAction v-if="row.item.office" :office="row.item.office" class="float-right"></OfficeAction>
      </template>
      <!-- Row details with a nested table for Zones with subnet info. -->
      <template #row-details="row">
        <ZonesTable :zones="row.item.zones"></ZonesTable>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="offices.length"
      :per-page="maxItems"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      ariaControls="offices-table"
      class="my-1"
    ></b-pagination>
  </div>
</template>

<script>
import csc from 'country-state-city';

import OfficeAction from '@/xvisor/components/settings/zones/OfficeAction.vue';
import ZonesTable from '@/xvisor/components/settings/zones/ZonesTable.vue';

export default {
  props: {
    offices: {
      type: Array,
      required: true,
    },
  },
  components: {
    OfficeAction,
    ZonesTable,
  },
  data() {
    return {
      fields: [
        { key: 'details', label: '' },
        { key: 'office.name', label: 'Office Name', sortable: true },
        { key: 'zones', label: 'Zones' },
        { key: 'location', label: 'Location', sortable: true },
        { key: 'actions', label: '' },
      ],
      currentPage: 1,
      maxItems: 4,
    };
  },
  methods: {
    getLocationName(longitude, latitude) {
      const cityInfo = csc
        .getAllCities()
        .find((city) => parseFloat(city.latitude) === latitude && parseFloat(city.longitude) === longitude);
      const countryInfo = cityInfo
        ? csc.getAllCountries().find((country) => country.isoCode === cityInfo.countryCode)
        : undefined;
      if (cityInfo && countryInfo) {
        return `${cityInfo.name}, ${countryInfo.name}`;
      }
      return '';
    },
  },
};
</script>

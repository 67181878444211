<template>
  <div v-if="this.hasServerSelection && this.hasTimestampSelected" class="ml-3 mb-2">
    <div class="mt-2 col-md-12"><strong>{{serviceType.toUpperCase()}} Details</strong></div>
    <div v-if="this.hasTimestampSelected">
      <b-table
        :items="formattedThreatDetails"
        :fields="tableFields"
        outlined
        small
        hover
        class="allDetail"
      >
        <template #cell(0)="row">
          <span>
            <p class="text-right">{{ row.value }}</p>
          </span>
        </template>
        <template #cell(1)="row">
          <span>
            <p class="text-left">{{ row.value }}</p>
          </span>
        </template>
      </b-table>
    </div>
    <div class="mt-2 col-md-12"><strong>All Details</strong></div>
    <div v-if="this.hasTimestampSelected">
      <b-table
        :items="formattedThreatAllDetails"
        :fields="tableFields"
        outlined
        small
        hover
        class="allDetail"
      >
        <template #cell(0)="row">
          <span>
            <p class="text-right">{{ row.value }}</p>
          </span>
        </template>
        <template #cell(1)="row">
          <span>
            <p class="text-left">{{ row.value }}</p>
          </span>
        </template>
      </b-table>
    </div>
    <div v-else-if="this.threatDetailsLoaded">
      {{ $t("No Data Available") }}
    </div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import collapseText from '@/xvisor/utilities/collapseText';

export default {
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      threatDetails: [],
      threatDetailsLoaded: false,
      selectedTimestamp: null,
      selectedServer: null,
      tableFields: [
        { key: '0', label: 'Field', thClass: 'text-right' },
        { key: '1', label: 'Value', thClass: 'text-left' },
      ],
    };
  },
  created() {
    SecurityEventBus.$on('update-selected-server', (server) => {
      this.selectedServer = server;
      this.selectedTimestamp = null;
    });
    SecurityEventBus.$on('update-selected-timestamp', (timestamp) => {
      this.selectedTimestamp = timestamp;
    });
  },
  props: {
    serviceType: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedThreatAllDetails() {
      return Object.entries(this.threatDetails).map(([key, value]) => [key, value]);
    },
    formattedThreatDetails() {
      const result = [];
      switch (this.serviceType) {
        case 'dns': {
          Object.entries(this.threatDetails).forEach((detail) => {
            const key = detail[0];
            if (key === 'dnsClientAttemptTpsPeakLongTerm'
                || key === 'dnsClientAcceptTpsPeakLongTerm'
                || key === 'dnsClientRejectTpsPeakLongTerm'
                || key === 'dnsServerAcceptTpsPeakLongTerm'
                || key === 'dnsClientContext'
                || key === 'dnsServerContext') {
              result.push(detail);
            }
          });
          break;
        }
        case 'ssh': {
          Object.entries(this.threatDetails).forEach((detail) => {
            const key = detail[0];
            if (key === 'sshClientAttemptTpsPeakLongTerm'
                || key === 'sshClientAcceptTpsPeakLongTerm'
                || key === 'sshClientRejectTpsPeakLongTerm'
                || key === 'sshServerAcceptTpsPeakLongTerm'
                || key === 'sshClientAttemptCount'
                || key === 'sshClientAcceptCount'
                || key === 'sshClientRejectCount'
                || key === 'sshServerAcceptCount'
                || key === 'sshClientContext'
                || key === 'sshServerContext') {
              result.push(detail);
            }
          });
          break;
        }
        case 'https': {
          Object.entries(this.threatDetails).forEach((detail) => {
            const key = detail[0];
            if (key === 'httpsClientAttemptTpsPeakLongTerm'
                || key === 'httpsClientAcceptTpsPeakLongTerm'
                || key === 'httpsClientRejectTpsPeakLongTerm'
                || key === 'httpsServerAcceptTpsPeakLongTerm'
                || key === 'httpsClientAttemptCount'
                || key === 'httpsClientAcceptCount'
                || key === 'httpsClientRejectCount'
                || key === 'httpsServerAcceptCount'
                || key === 'httpsClientContext'
                || key === 'httpsServerContext') {
              result.push(detail);
            }
          });
          break;
        }
        case 'http': {
          Object.entries(this.threatDetails).forEach((detail) => {
            const key = detail[0];
            if (key === 'httpClientAttemptTpsPeakLongTerm'
                || key === 'httpClientAcceptTpsPeakLongTerm'
                || key === 'httpClientRejectTpsPeakLongTerm'
                || key === 'httpServerAcceptTpsPeakLongTerm'
                || key === 'httpClientAttemptCount'
                || key === 'httpClientAcceptCount'
                || key === 'httpClientRejectCount'
                || key === 'httpServerAcceptCount'
                || key === 'httpClientContext'
                || key === 'httpServerContext') {
              result.push(detail);
            }
          });
          break;
        }
        case 'other': {
          Object.entries(this.threatDetails).forEach((detail) => {
            const key = detail[0];
            if (key === 'otherClientAttemptTpsPeakLongTerm'
                || key === 'otherClientAcceptTpsPeakLongTerm'
                || key === 'otherClientRejectTpsPeakLongTerm'
                || key === 'otherServerAcceptTpsPeakLongTerm'
                || key === 'otherClientAttemptCount'
                || key === 'otherClientAcceptCount'
                || key === 'otherClientRejectCount'
                || key === 'otherServerAcceptCount'
                || key === 'otherClientContext'
                || key === 'otherServerContext') {
              result.push(detail);
            }
          });
          break;
        }
        default: break;
      }
      return result;
    },
    hasTimestampSelected() {
      return this.selectedTimestamp !== null && this.selectedTimestamp !== undefined;
    },
    hasThreatDetails() {
      return this.threatDetailsLoaded && this.threatDetails;
    },
    threatDetailsUrl() {
      return `/security/${this.selectedServer.ip}/details.json`;
    },
    hasServerSelection() {
      return this.selectedServer !== null && this.selectedServer !== undefined;
    },
  },
  watch: {
    selectedTimestamp() {
      if (this.hasServerSelection) {
        this.httpGetThreatDetails();
      }
    },
  },
  methods: {
    httpGetThreatDetails() {
      this.threatDetailsLoaded = false;
      if (this.hasServerSelection) {
        const threatDetailsRequest = this.$http.get(this.threatDetailsUrl, {
          params: {
            time: this.selectedTimestamp,
          },
        });

        threatDetailsRequest
          .then((threatDetailsRes) => {
            this.threatDetails = threatDetailsRes.data;
          })
          .finally(() => {
            this.threatDetailsLoaded = true;
          });
      }
    },
    collapseText,
  },
};
</script>

<style scoped>
.allDetail{
  width: 95%;
  table-layout: fixed;
}
</style>

<template>
  <div>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      autoresize
    ></e-chart>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const colors = [
  colorPaletteShade.blue4,
  colorPaletteShade.green4,
  colorPaletteShade.orange4,
  colorPaletteShade.red4,
  colorPaletteShade.purple4,
  colorPaletteShade.yellow4,
  colorPaletteShade.teal4,
  colorPaletteShade.indigo4,
  colorPaletteShade.pink4,
  colorPaletteShade.gray4,
];

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    issueTimeSelected: {
      type: Number,
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
    nodeHopRtdParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  computed: {
    probeEventParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
          nodeId: this.nodeHopRtdParams.nodeId,
          probeIds: this.nodeHopRtdParams.probeIds,
        },
      };
    },
    initialMarkline() {
      // Initializes the line to the end of the graph.
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const seriesData = this.series
        .filter((ts) => ts.hopRtdTs.length !== 0)
        .map((ts) => ({
          data: times.map((time) => {
            const timePoint = ts.hopRtdTs.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
      return seriesData[0].data.at(-1)[0];
    },
    timeSet() {
      return new Set(this.series.map((ts) => ts.hopRtdTs.map((point) => point.time)).flat());
    },
    hasData() {
      return this.loaded
        && this.series && this.series.length > 0
        && (this.series[0] && this.series[0].hopRtdTs && this.series[0].hopRtdTs.length > 0);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      return this.series
        .filter((ts) => ts.hopRtdTs.length !== 0)
        .map((ts) => ({
          name: ts.xomeName,
          type: 'line',
          stack: this.isStacked ? 'Total' : '',
          areaStyle: this.isStacked ? {} : null,
          showSymbol: false,
          markLine: {
            symbol: 'circle',
            lineStyle: {
              type: 'solid',
            },
            data: [{ xAxis: this.issueTimeSelected ? this.issueTimeSelected : this.initialMarkline }],
            label: {
              formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
            },
          },
          data: times.map((time) => {
            const timePoint = ts.hopRtdTs.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
    },
    chartOptions() {
      return {
        color: colors,
        title: {
          textStyle: {
            color: this.toggleColor,
          },
          text: 'Delay',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${this.chartTooltipContent(info)}
            </div>
          `,
        },
        grid: [
          {
            left: 50,
            right: 60,
            top: 30,
            bottom: 30,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: 'ms',
            nameLocation: 'center',
            nameGap: 35,
            min: 0,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => value.toFixed(1),
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, this.httpParams)
        .then((response) => {
          this.series = response.data;
        })
        .finally(() => { this.loaded = true; });
    },
    chartTooltipContent(info) {
      let result = '';
      for (let i = 0; i < info.length; i += 1) {
        result += `${info[i].marker}
          ${info[i].seriesName}: ${info[i].value[1].toFixed(1)} ms <br>`;
      }
      return result;
    },
  },
};
</script>

<template>
    <b-card no-body class="mt-n2">
      <b-row class="mx-1 mt-2 mb-1">
        <b-card
          v-if="hasAlertSelection"
          class="header-card"
        >
          <h4 class="mb-1">Alert Details</h4>
          <div class="details-row d-flex flex-row mt-2">
            <span>
              <b-icon :style="{ color: iconColors.blue }" class="mr-0-5" icon="info-square-fill"/>
              <b>Name:</b>
              {{ selectedAlert.signature ? selectedAlert.signature : 'N/A' }}
            </span>
            <span>
              <b-icon variant="success" class="mr-0-5" icon="tag-fill"/>
              <b>Category:</b>
              {{ alerts[0].ruleCategory ? alerts[0].ruleCategory : 'N/A' }}
            </span>
            <span>
              <b-icon variant="danger" class="mr-0-5" icon="exclamation-square-fill"/>
              <b>Severity:</b>
              {{ selectedAlert.severity ? selectedAlert.severity : 'N/A' }}
            </span>
            <span>
              <b-icon :style="{ color: iconColors.purple }" class="mr-0-5" icon="person-fill"/>
              <b>Rule Author:</b>
              {{ alerts[0].ruleAuthor ? alerts[0].ruleAuthor : 'N/A' }}
            </span>
          </div>
        </b-card>
        <h4 class="mb-1" v-else>No Alert Signature Selected</h4>
      </b-row>
      <b-row v-if="hasAlertSelection" class="ml-0-5 my-1">
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="alertsFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
            >
              <template #cell(timestamps)="row">
                <AlertDetailsTimes
                  :timestampsInfo="row.item.timestamps"
                  :uniqueId="row.item.xtendId">
                </AlertDetailsTimes>
              </template>
              <template #cell(userName)="row">
                <AlertDetailsUserNames
                  :userNameInfo="row.item.userName"
                  :uniqueId="row.item.xtendId">
                </AlertDetailsUserNames>
              </template>
              <template #cell(program)="row">
                <AlertDetailsPrograms
                  :programInfo="row.item.program"
                  :uniqueId="row.item.xtendId">
                </AlertDetailsPrograms>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </template>

<script>
import moment from 'moment';
import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import AlertDetailsTimes from '@/xvisor/components/security/AlertDetailsTimes.vue';
import AlertDetailsUserNames from '@/xvisor/components/security/AlertDetailsUserNames.vue';
import AlertDetailsPrograms from '@/xvisor/components/security/AlertDetailsPrograms.vue';
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  components: {
    AlertDetailsTimes,
    AlertDetailsUserNames,
    AlertDetailsPrograms,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedAlert: null,
      alerts: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'deviceName', label: 'Device', sortable: true },
        { key: 'timestamps', label: 'Time', sortable: true },
        { key: 'userName', label: 'User Name', sortable: true },
        { key: 'program', label: 'Program', sortable: true },
      ],
      selected: [],
      iconColors: {
        blue: colorPalette.teal,
        purple: colorPalette.purple,
      },
    };
  },
  computed: {
    hasAlertSelection() {
      return this.selectedAlert !== null && this.selectedAlert !== undefined;
    },
    hasData() {
      return this.loaded && this.alerts && this.alerts.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.alerts.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    alertsFilter() {
      const retVal = this.alerts;
      return retVal;
    },
  },
  watch: {
    timeRange() {
      this.selectedAlert = null;
    },
  },
  created() {
    SecurityEventBus.$on('update-selected-alert', (alert) => {
      this.selectedAlert = alert;
      this.httpGet();
    });
  },
  methods: {
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
    httpGet() {
      const detailEndpoint = `/security/endpoint/${this.selectedAlert.ruleId}/alertdetails.json`;

      this.loaded = false;
      this.$http
        .get(detailEndpoint, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.alerts = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
  .header-card {
    width: 100%;
  }
  .header-card>.card-body {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .details-row {
    gap: 5rem;
  }
</style>

<template>
  <div>
    <h2 class="mt-3">
      <a href="#xome-installation">
        1. XOME Installation
      </a>
    </h2>
    <p>
      <strong>On a new Ubuntu server (20.04 recommended) carry out the following steps:</strong>
    </p>
    <p class="mt-n1"><small>
      NOTE: Other linux distros are supported. Contact Ennetix support for instructions.
    </small></p>
    <ul>
      <li><p>
        Ensure XOME will not be blocked by a firewall.
        XOME must be unblocked on HTTPS port 443 and TLS port 9094.
      </p></li>
      <li>
        <p>Ensure XOME can access xVisor API:</p>
        <p class="mt-n1"><code>curl -sL {{ healthcheckApi }}</code></p>
      </li>
      <li>
        <p>
          <a href="https://docs.docker.com/engine/install/ubuntu/" target="_blank">Install Docker >></a>
        </p>
      </li>
    </ul>

    <h2 class="mt-3">
      <a href="#xome-registration">
        2. XOME Registration
      </a>
    </h2>
    <p>
      <strong>Complete registration on the xVisor dashboard:</strong>
    </p>
    <ul>
      <li>
        <p>Click on the "+" on the top bar and select "Add XOME".</p>
        <p>
          <b-img
            src="@/xvisor/views/knowledgeBase/assets/xomeInstall/dashboard-add-xome.png"
            thumbnail
            alt="Add XOME"
            class="responsive-img-lg"
          />
        </p>
        <p>
          <b-img
            src="@/xvisor/views/knowledgeBase/assets/xomeInstall/dashboard-add-xome-name.png"
            thumbnail
            alt="Give a name and location"
            class="responsive-img-lg"
          />
        </p>
      </li>
      <li><p>Give the XOME a descriptive name and select a location.</p></li>
      <li><p>Click "Submit".</p></li>
      <li>
        <p>Copy the command from the popup and save it for future reference.</p>
        <p>
          <b-img
            src="@/xvisor/views/knowledgeBase/assets/xomeInstall/dashboard-xome-register.png"
            thumbnail
            alt="Copy and save the command"
            class="responsive-img-lg"
          />
        </p>
      </li>
      <li>
        <p>
          Run the command on the XOME's command line as root.
        </p>
        <p>
          <b-img
            src="@/xvisor/views/knowledgeBase/assets/xomeInstall/docker-cli-xome-register.png"
            thumbnail
            alt="Run the command"
            class="responsive-img-lg"
          />
        </p>
      </li>
      <li><p>Reboot the XOME server to complete registration.</p></li>
      <li>
        <p>
          If successful XOME will be "Active" on the
          <router-link to="/agents">
            Agents Page
          </router-link>
          within 5-10 minutes.
        </p>
      </li>
    </ul>

    <!-- TODO: Add a "Next -> Configure XOME" link that directs the user to the configuration page. -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      xvisorHost: window.location.host,
    };
  },
  computed: {
    healthcheckApi() {
      const route = 'healthcheck';
      return `${this.xvisorHost}/${route}`;
    },
  },
};
</script>

<style scoped>
.anchor {
  top: -75px;
  position: relative;
  z-index: 5;
}
</style>

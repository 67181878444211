<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.probe.extendedName} Data Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <validation-provider name="External IP" rules="ipValid" v-slot="{ errors }">
        <b-form-group label="External IP">
          <b-form-input
            placeholder="External IP"
            v-model="config.externalIp"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-checkbox v-model="config.useMultipath">
        Use Multiple Path
      </b-form-checkbox>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        externalIp: '',
        useMultipath: true,
      },
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      // Empty string parameters should be POSTed as undefined.
      if (newConfig.externalIp.length === 0) {
        newConfig.externalIp = undefined;
      }
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        externalIp: this.value.externalIp || '',
        useMultipath: this.value.useMultipath,
      };
    },
  },
};
</script>

<template>
  <b-modal ref="modal" title="Edit NewRelic Integration" centered>
    <validation-observer ref="editIntegration">
      <b-form>
        <b-row>
          <b-col cols="12">
            <validation-provider name="URL" rules="required|url" v-slot="{ errors }">
              <b-form-group label="URL" label-cols-md="4">
                <b-form-input
                  autofocus
                  placeholder="URL"
                  type="url"
                  v-model="updatedIntegration.url"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="Version" rules="required" v-slot="{ errors }">
              <b-form-group label="Version" label-cols-md="4">
                <b-form-select
                  v-model="updatedIntegration.version"
                  :options="versions"
                  :state="errors[0] ? false : null"
                >
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="API Key" rules="required" v-slot="{ errors }">
              <b-form-group label="API Key" label-cols-md="4">
                <b-form-input
                  autofocus
                  placeholder="API Key"
                  v-model="updatedIntegration.credentials.apiKey"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <small class="text-danger" v-if="errors[0]">API key is required.</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-left mr-1" @click="editIntegration">Submit</b-button>
        <b-button variant="outline-primary" class="float-left" @click="$refs.modal.hide()">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import IntegrationsEventBus from '@/xvisor/bus/IntegrationsEventBus';
import { integrationTypes } from '@/xvisor/constants/integrations';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    integration: {
      type: Object,
      required: true,
    },
    versions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      updatedIntegration: {
        type: integrationTypes.newRelic,
        url: this.integration.url,
        version: this.integration.version,
        credentials: {
          apiKey: '',
        },
      },
      updateUrl: `/integrations/${this.id}/update`,
    };
  },
  methods: {
    editIntegration() {
      this.$refs.editIntegration.validate().then((result) => {
        if (result) {
          this.$http
            .post(this.updateUrl, this.updatedIntegration)
            .then(() => {
              this.$bvToast.toast('Successfully updated integration', { variant: 'success' });
              this.$refs.modal.hide();
              IntegrationsEventBus.$emit('update');
            })
            .catch(() => {
              this.$bvToast.toast('Failed to update integration', { variant: 'danger' });
            });
        }
      });
    },
  },
};
</script>

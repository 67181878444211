<template>
  <TransRadialChart
    v-if="hasData"
    ref="transRadial"
    :data="transTotals"
    :categories="categories"
    :colors="colors"
    class="my-1"
  ></TransRadialChart>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import TransRadialChart from '@/xvisor/components/TransRadialChart.vue';
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  components: {
    SpinnerCmpt,
    TransRadialChart,
  },
  props: {
    transTotalsUrl: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transTotals: undefined,
      colors: [
        colorPalette.blue,
        colorPalette.green,
        colorPalette.teal,
        colorPalette.orange,
        colorPalette.yellow,
        colorPalette.red,
      ],
      loaded: false,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.transTotals.counts && this.transTotals.counts.length > 0;
    },
    categories() {
      return this.transTotals ? this.transTotals.counts.map((count) => count.name) : [];
    },
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.transTotalsUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.transTotals = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

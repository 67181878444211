<template>
  <InfraBase #default="{ timeRange, urls }">
    <b-tabs card end lazy nav-wrapper-class="sticky-navbar">
      <b-tab title="Traffic">
        <TrafficTab :timeRange="timeRange" :urls="urls"></TrafficTab>
      </b-tab>
      <b-tab title="Interfaces">
        <IfacesTab :timeRange="timeRange"></IfacesTab>
      </b-tab>
      <b-tab title="Logs">
        <LogsTab></LogsTab>
      </b-tab>
      <b-tab title="Metrics" disabled>
        <MetricsTab></MetricsTab>
      </b-tab>
    </b-tabs>
  </InfraBase>
</template>

<script>
import IfacesTab from '@/xvisor/components/infra/interfaces/IfacesTab.vue';
import InfraBase from '@/xvisor/views/InfraBase.vue';
import LogsTab from '@/xvisor/components/infra/logs/LogsTab.vue';
import MetricsTab from '@/xvisor/components/infra/MetricsTab.vue';
import TrafficTab from '@/xvisor/components/infra/traffic/TrafficTab.vue';
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  components: {
    IfacesTab,
    InfraBase,
    LogsTab,
    MetricsTab,
    TrafficTab,
  },
  data() {
    return {
      colorPalette,
    };
  },
};
</script>

<style lang="scss">
.sticky-navbar {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 1.3rem 0.5rem 0;
  width: calc(100% - 1rem);
  border-radius: 0.428rem !important;
  z-index: 12;
}

.sticky-navbar .nav-tabs {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .sticky-navbar {
    width: calc(100% - 4.6rem);
  }
}
.menu-expanded .sticky-navbar {
  width: calc(100% - 4.6rem - 210px);
}
</style>

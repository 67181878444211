/** Convert mega bits per second to readable format.
 *
 * @param mbps The number of mega bits per second to convert.
 * @return Object containing converted bytes and unit.
 */
export default function mbpsConverter(mbps) {
  if (mbps === 0) return { rate: 0, unit: 'Bps' };
  const units = ['Bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
  const base = 1000;
  // Convert Mbps to bps.
  const bps = mbps * 1000000;

  let index = Math.floor(Math.log(bps) / Math.log(base));
  if (index >= units.length) index = units.length - 1;
  if (index < 0) index = 0;
  return { rate: (bps / (base ** index)), unit: units[index] };
}

<template>
  <e-chart
    :options="chartOptions"
    @click="handleGraphClickEvent"
    autoresize
    class="h-100"
  ></e-chart>
</template>

<script>
import flowGraphMixin from '@/xvisor/components/app/flowGraph/flowGraphMixin';

export default {
  mixins: [flowGraphMixin],
  props: {
    series: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: true,
    },
    categoryNameToCategoryId: {
      type: Object,
      required: true,
    },
    minMaxNodeBytes: {
      type: Object,
      required: true,
    },
    minMaxLinkBytes: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'clickedNode',
  ],
  computed: {
    chartData() {
      return {
        nodes: this.series.nodes.map((node) => (
          this.formattedNode(
            node,
            this.minMaxNodeBytes,
            this.events[node.id],
            this.categoryNameToCategoryId,
          )
        )),
        links: this.series.links.map((link) => (
          this.formattedLink(
            link,
            this.minMaxLinkBytes,
          )
        )),
        categories: Object.keys(this.categoryNameToCategoryId).map((key) => ({ name: key })),
      };
    },
    chartOptions() {
      /**
       * First creates deep copy of the chartOptionsTemplate object, so future graphs won't be affected.
       * However, this is not totally required, as the members in future graphs are modified as well,
       * but creating a deep copy clearly expresses that the operations below would change the
       * chartOptionsTemplate object in flowGraphMixin.
      */
      const options = JSON.parse(JSON.stringify(this.chartOptionsTemplate));
      options.series[0].data = this.chartData.nodes;
      options.series[0].links = this.chartData.links;
      options.series[0].categories = this.chartData.categories;
      return options;
    },
  },
  methods: {
    // This will trigger when a node or link on the graph is clicked.
    handleGraphClickEvent(clickEvent) {
      if (clickEvent.dataType === 'node') this.$emit('clickedNode', clickEvent.data.id);
    },
  },
};
</script>

const ELLIPSIS_CHARACTER = '\u2026';

/** Collapse a text to the given width and put a separator in the middle.
 *
 * @param str The string to be collapsed.
 * @param width The width of the returned string.
 * @param separator The separator.
 * @return The collapsed string.
 * Ex.
 *   collapseText("123456789abcde") => "1234...abcde"
 *   collapseText("123456789abcde", 12, "_") => "12345_9abcde"
 */
export default function collapseText(str, width = 10, separator = ELLIPSIS_CHARACTER) {
  const len = str.length;
  if (len > width) {
    const string = str.replace(' ', '');
    const seplen = separator.length;

    /** If separator is larger than width, instead of just showing the separator,
     *  shows the right hand side of the string.
     */
    if (seplen > width) {
      return string.substr(len - width);
    }

    const n = 0.5 * (len - width + seplen);

    // This gives us the center line.
    const center = len / 2;

    const front = string.substr(0, center - n);
    const back = string.substr(len - center + n);

    return front + separator + back;
  }
  return str;
}

<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.suricata.extendedName} Alerts`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <validation-provider
        name="Interface Name"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group label="Network Interface Name">
          <b-form-input
            placeholder="eth0"
            v-model="config.interfaceName"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Alert Log Threshold">
          <b-col
            md="6"
            class="p-0"
          >
            <div class="d-flex threshold-range-selector">
              <span><b>Low</b></span>
              <b-form-input
                  v-model="config.severityLevel"
                  class="form-control-merge rotated-input"
                  min="1"
                  max="3"
                  type="range"
                ></b-form-input>
              <span><b>High</b></span>
              <b-icon icon="info-circle" class="h3" variant="dark" id="suricata-info"></b-icon>
            </div>
          </b-col>
          <b-popover target="suricata-info" triggers="hover" placement="bottom">
            <template #title>Setting Alert Thresholds</template>
              <li>
                <b>Low:</b> Generate logs for all alert severity levels. This
                may result in a large number of unecessary alerts. This threshold
                can be used to test whether Suricata is generating alerts properly.
              </li>
              <li>
                <b>Medium (recommended):</b> Generate logs for moderate to high priority alerts.
              </li>
              <li>
                <b>High:</b> Generate logs for only the highest priority alerts. This may result
                in some relevant/actionable alerts being missed.
              </li>
          </b-popover>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        interfaceName: '',
        severityLevel: '',
        xomeName: '',
      },
    };
  },
  props: {
    xomeName: {
      type: String,
      required: true,
    },
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      newConfig.enabled = true;
      newConfig.severityLevel = Number(newConfig.severityLevel);
      newConfig.xomeName = this.xomeName;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        interfaceName: this.value.interfaceName,
        severityLevel: this.value.severityLevel,
      };
    },
  },
};
</script>
<style>
.threshold-range-selector{
  gap: 1rem;
}
.threshold-range-selector span {
  font-size: 0.9rem;
}
.rotated-input {
  transform: rotate(180deg);
}
</style>

<template>
  <b-row v-if="hasData">
    <b-col>
      <b-row class="mt-n1 mb-n2 ml-1">
        <p class="inline-block">Total: {{ alerts.total }}</p>
        <b-icon
          v-if="alerts.trendRatio !== 1"
          :icon="iconName(alerts.trendRatio)"
          :variant="iconVariant(alerts.trendRatio)"
          shift-h="4"
          shift-v="-2"
        ></b-icon>
      </b-row>
      <b-row>
        <b-col>
          <e-chart :options="chartOptions" autoresize />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
  <div v-else-if="loaded">{{ $t("No Alerts") }}<b-icon icon="hand-thumbs-up" shift-h="8"></b-icon></div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import issueLayers from '@/xvisor/constants/issueLayers';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    /** The URL from which to query data. */
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      alerts: undefined,
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.alerts && this.alerts.total > 0;
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    alertDistributionLabels() {
      return this.alerts.typeCounts.filter((ele) => ele.count).map((ele) => ele.name);
    },
    alertDistributionData() {
      return this.alerts.typeCounts.filter((ele) => ele.count).map((ele) => ele.count);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    height() {
      const base = 85;
      const barHeight = 30;
      return base + (this.alertDistributionData ? (this.alertDistributionData.length - 1) * barHeight : 0);
    },
    chartOptions() {
      return {
        grid: {
          left: 15,
          top: 15,
          right: 0,
          bottom: 0,
          height: 130,
        },
        tooltip: {
          show: true,
          position: 'top',
          formatter: (params) => {
            const alerts = params.data;
            const percentage = this.barPercent(alerts);
            return `Alerts: ${alerts} (${percentage}%)`;
          },
        },
        color: [colorPalette.teal, colorPalette.purple, colorPalette.blue],
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          min: 0,
          max: this.alerts.total,
        },
        yAxis: {
          data: this.alertDistributionLabels,
          type: 'category',
          inverse: true,
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: 'bar',
            barWidth: 15,
            data: this.alertDistributionData,
            label: {
              color: this.toggleColor,
              fontSize: 12,
              formatter: '{b}',
              position: [1, -15],
              show: true,
            },
          },
        ],
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            zoneId: this.zoneId,
          },
        })
        .then((response) => { this.alerts = response.data; })
        .finally(() => { this.loaded = true; });
    },
    iconName(trendRatio) {
      return trendRatio < 1 ? 'arrow-down' : 'arrow-up';
    },
    iconVariant(trendRatio) {
      return trendRatio < 1 ? 'success' : 'danger';
    },
    barPercent(alertCount) {
      if (this.alerts.total === 0) return 0;
      return ((alertCount / this.alerts.total) * 100).toFixed(0);
    },
    triggerEvent(config) {
      IssueTimeBarEventBus.$emit('app-alert-distribution-panel', {
        layer: issueLayers.getByName(this.alerts.typeCounts[config.dataPointIndex].name).value,
        alertsExist: true,
      });
    },
    triggerMouseEvent(event) {
      const e = event;
      e.target.style.cursor = 'pointer';
    },
  },
};
</script>

<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.pcap.extendedName} Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <validation-provider name="Sniffer Interface" rules="required" v-slot="{ errors }">
        <b-form-group label="Sniffer Interface">
          <b-form-input
            placeholder="Sniffer Interface"
            v-model="config.snifferInterface"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Agent IP" rules="ipValid" v-slot="{ errors }">
        <b-form-group label="Agent IP">
          <b-form-input
            placeholder="Agent IP"
            v-model="config.agentIp"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        snifferInterface: '',
        agentIp: '',
      },
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      if (newConfig.agentIp.length === 0) {
        newConfig.agentIp = undefined;
      }
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        snifferInterface: this.value.snifferInterface || '',
        agentIp: this.value.agentIp || '',
      };
    },
  },
};
</script>

/** Formats a hostname string with items separated by commas.
 *
 * @param names The string to be formatted.
 * @param delimiter A string that marks the end of an item.
 * @param separator A string that will replace the delimiter and will separate each item.
 * @return The formatted hostname.
 * Ex.
 *   hostnameFormatter("st1,st2,st3,st4") => "st1, st2, st3, st4"
 *   hostnameFormatter("") => "NA"
 */
export default function hostnameFormatter(names, delimiter = ',', separator = ', ') {
  const namesArr = names.split(delimiter);
  if (namesArr.length === 0) {
    return 'NA';
  }
  return namesArr.join(separator);
}

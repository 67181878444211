<template>
  <div>
    <b-row class="mx-0-5 my-1" align-h="between" align-v="center">
      <b-col cols="12" md="6">
        <h4>{{ $t("Reports") }}</h4>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <SearchBar class="mr-1">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
          <AddReport @added="httpGet"></AddReport>
        </div>
      </b-col>
    </b-row>
    <b-table
      responsive
      selectable
      striped
      primary-key="id"
      :items="reports"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
      table-class="text-nowrap"
      class="table-with-actions-min-height"
    >
      <template #cell(createdTime)="row">
        {{ formatDate(row.value) }}
      </template>
      <template #cell(timeRange)="row">
        {{ formatTimeRange(row.item.startTime, row.item.endTime) }}
      </template>
      <template #cell(parameters)="row">
        <span v-html="parametersFormatter(row.value)"></span>
      </template>
      <template #cell(status)="row">
        <StatusLabel :status="row.value"></StatusLabel>
      </template>
      <template #cell(link)="row">
        <ReportDownload
          :type="row.item.type"
          :createdTime="row.item.createdTime"
          :url="formatDownloadLink(row.item.id)"
          :status="row.item.status"
        ></ReportDownload>
        <DeleteButton
          :url="formatDeleteReportLink(row.item.id)"
          itemType="Report"
          @deleted="httpGet"
        ></DeleteButton>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      align="right"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      class="mb-1 mr-1"
    />
  </div>
</template>

<script>
import moment from 'moment';

import AddReport from '@/xvisor/components/report/AddReport.vue';
import DeleteButton from '@/xvisor/components/report/DeleteButton.vue';
import ReportDownload from '@/xvisor/components/report/ReportDownload.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import StatusLabel from '@/xvisor/components/report/StatusLabel.vue';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  components: {
    AddReport,
    DeleteButton,
    ReportDownload,
    SearchBar,
    StatusLabel,
  },
  data() {
    return {
      reports: [],
      tableFields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'userEmail', label: 'Created By', sortable: true },
        { key: 'createdTime', label: 'Date Created', sortable: true },
        { key: 'timeRange', label: 'Time Range', sortable: true },
        { key: 'parameters', label: 'Parameters', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'link', label: 'Actions' },
      ],
      momentTimeFormat,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filteredLength: null,
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.reports.length;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
    formatDownloadLink(reportId) {
      return `reports/${reportId}/pdf`;
    },
    formatDeleteReportLink(reportId) {
      return `reports/${reportId}`;
    },
    formatTimeRange(startDateStr, endDateStr) {
      const startDate = moment(startDateStr).format(momentTimeFormat.date);
      const endDate = moment(endDateStr).format(momentTimeFormat.date);
      /** If the dates are the same, show one date with the times.
       * ex. July 17 2am - 5pm.
       */
      if (startDate === endDate) {
        const startHour = moment(startDateStr).format(momentTimeFormat.timeHour);
        const endHour = moment(endDateStr).format(momentTimeFormat.timeHour);
        return `${startDate}, ${startHour} - ${endHour}`;
      }
      /** If there are different dates, so omit the times and only show dates.
       * ex. July 17 - July 19.
       */
      return `${startDate} - ${endDate}`;
    },
    parametersFormatter(entry) {
      // For each parameter, they are put on their own lines.
      let result = '';
      Object.entries(entry).forEach((obj) => {
        result += `<b>${obj[0]}</b>: ${obj[1]}<br>`;
      });
      return result;
    },
    httpGet() {
      this.$http
        .get('/reports/list.json')
        .then((response) => { this.reports = response.data; });
    },
    moment,
  },
};
</script>

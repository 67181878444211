<template>
  <span>
    <b-button class="text-nowrap" size="sm" variant="primary" @click="$refs.addZones.$refs.modal.show()">
      + Add Zone(s)
    </b-button>
    <AddZonesModal ref="addZones" :url="url"></AddZonesModal>
  </span>
</template>

<script>
import AddZonesModal from '@/xvisor/components/settings/zones/AddZonesModal.vue';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    AddZonesModal,
  },
};
</script>

<template>
  <validation-observer ref="renameXomeForm">
    <validation-provider rules="required" name="Xome Name" v-slot="{ errors }">
      <b-form-group>
        <b-form-input
          autofocus
          title="Rename XOME"
          type="text"
          :state="errors[0] ? false: null"
          placeholder="XOME Name"
          v-model="xomeName"
          class="form-control-merge"
        >
        </b-form-input>
        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
    <b-row class="justify-content-md-center mt-1">
      <b-button @click="renameAction" variant="secondary">
        Rename XOME
      </b-button>
    </b-row>
  </validation-observer>
</template>

<script>
import XomesEventBus from '@/xvisor/bus/XomesEventBus';

export default {
  props: {
    xomeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      xomeName: '',
    };
  },
  methods: {
    renameAction() {
      this.$refs.renameXomeForm.validate().then((result) => {
        if (result) {
          this.$http
            .post(`/xomes/${this.xomeId}/rename`, {
              xomeName: this.xomeName,
            })
            .then((response) => {
              this.$bvToast.toast('Successfully renamed XOME', { variant: 'success' });
              const xomeRegisterInfo = response.data;
              XomesEventBus.$emit('renamed', xomeRegisterInfo);
            })
            .catch(() => {
              this.$bvToast.toast('Failed to rename XOME', { variant: 'danger' });
            });
        }
      });
    },
  },
};
</script>

<template>
  <b-card no-body class="mx-n2 mt-n1">
    <ReportsTable></ReportsTable>
  </b-card>
</template>

<script>
import ReportsTable from '@/xvisor/components/report/ReportsTable.vue';

export default {
  components: {
    ReportsTable,
  },
};
</script>

import getLinkId from '@/xvisor/components/app/networkTab/pathroutes/utils';

export default class PathroutesLink {
  constructor(layoutData, metric, baseline, sourceAsnInfo, targetAsnInfo) {
    // Layout (the first) pathroutes endpoint.
    this.id = layoutData?.id;
    this.sourceId = layoutData?.sourceId;
    this.targetId = layoutData?.targetId;
    this.targetReachable = layoutData?.targetReachable;
    this.causesCycle = layoutData?.causesCycle;
    this.waypoints = layoutData?.waypoints;
    this.probeIds = layoutData?.probeIds;
    this.mplsTunnelType = layoutData?.mplsTunnelType;

    // Metrics endpoint.
    if (metric) {
      this.avgLoss = Number(metric.avgLoss).toFixed(1);
      this.delay = Number(metric.delay).toFixed(1);
      this.fwdLoss = metric.fwdLoss;
      this.fwdRecorderRate = metric.fwdRecorderRate;
      this.revLoss = metric.revLoss;
      this.revRecorderRate = metric.revRecorderRate;
    } else {
      this.avgLoss = null;
      this.delay = null;
      this.fwdLoss = null;
      this.fwdRecorderRate = null;
      this.revLoss = null;
      this.revRecorderRate = null;
    }
    // Baseline endpoint.
    if (baseline) {
      this.baselineDelay = Number(baseline.delay).toFixed(1);
      this.stdDeviation = Number(baseline.stdDevDelay).toFixed(1);
    } else {
      this.baselineDelay = null;
      this.stdDeviation = null;
    }

    // Asn info
    if (sourceAsnInfo && targetAsnInfo) {
      this.sourceAsn = sourceAsnInfo.asnName;
      this.targetAsn = targetAsnInfo.asnName;
      this.sourceOrgName = sourceAsnInfo.orgName;
      this.targetOrgName = targetAsnInfo.orgName;
    } else if (sourceAsnInfo) {
      this.sourceAsn = sourceAsnInfo.asnName;
      this.targetAsn = this.sourceAsn;
      this.sourceOrgName = sourceAsnInfo.orgName;
      this.targetOrgName = this.sourceOrgName;
    } else {
      this.sourceAsn = null;
      this.targetAsn = null;
      this.sourceOrgName = null;
      this.targetOrgName = null;
    }
    // Check if current link connects with a unresponsive block.
    this.linkToResponsiveNode = false;
    if (this.sourceId && this.targetId) {
      const sourceIdStr = this.sourceId.split(' ');
      const targetIdStr = this.targetId.split(' ');
      this.linkToResponsiveNode = sourceIdStr[1] !== 'Unresponsive' && targetIdStr[1] !== 'Unresponsive';
    }
  }

  getEchartsFormatted() {
    const waypoints = [];
    const sublinks = [];
    if (this.causesCycle === false) {
      if (this.waypoints && this.waypoints.length >= 1) {
        this.waypoints.forEach((coordinate) => {
          waypoints.push({
            name: getLinkId(coordinate.x, coordinate.y),
            x: coordinate.x,
            y: coordinate.y,
            symbolSize: 0,
          });
        });
        const path = [this.sourceId, ...this.waypoints.map((coor) => getLinkId(coor.x, coor.y)), this.targetId];
        for (let i = 0; i < path.length; i += 1) {
          sublinks.push({
            lineStyle: {
              type: this.targetReachable ? 'solid' : 'dotted',
              color: this.targetReachable ? 'grey' : 'red',
              width: 2,
            },
            tooltip: {
              formatter: () => `
                <div class="apache-echarts-tooltip">
                  <span>${this.id}</span><br/>
                </div>
              `,
            },
            source: path[i],
            target: path[i + 1],
          });
        }
      } else {
        const LOSS_THRESHOLD = 5;
        const isRegularLink = this.targetReachable && this.avgLoss <= LOSS_THRESHOLD;
        sublinks.push({
          lineStyle: {
            type: this.targetReachable && this.linkToResponsiveNode ? 'solid' : 'dashed',
            color: isRegularLink ? 'grey' : 'red',
            width: 2,
          },
          emphasis: {
            lineStyle: {
              type: this.targetReachable && this.linkToResponsiveNode ? 'solid' : 'dashed',
              width: 2,
            },
          },
          tooltip: {
            formatter: () => {
              let asnFormat = '';
              let sourceAsnNameFormat = '';
              let targetAsnNameFormat = '';
              let unreachableFormat = '';
              if (!this.targetReachable) {
                unreachableFormat = 'Unreachable Target';
              }
              if (this.sourceAsn > 0) {
                sourceAsnNameFormat = `(AS ${this.sourceAsn})`;
              }
              if (this.targetAsn > 0) {
                targetAsnNameFormat = `(AS ${this.targetAsn})`;
              }
              if (this.sourceAsn && this.targetAsn && this.linkToResponsiveNode) {
                if (this.sourceAsn === this.targetAsn) {
                  asnFormat = `Network: ${this.sourceOrgName} ${sourceAsnNameFormat}`;
                } else {
                  asnFormat = `
                    <div>Source Network: ${this.sourceOrgName} ${sourceAsnNameFormat}</div>
                    <div>Destination Network: ${this.targetOrgName} ${targetAsnNameFormat}</div>
                  `;
                }
              }
              let delayFormat = '';
              let baselineDelayFormat = '';
              if (this.delay) {
                let styling = '';
                if (this.baselineDelay && this.baselineDelay > 0) {
                  styling = this.delay > this.baselineDelay ? 'style="color: red"' : '';
                  baselineDelayFormat = `<div>Baseline Delay: ${this.baselineDelay} ms</div>`;
                } else {
                  baselineDelayFormat = '<div>Baseline Delay: N/A</div>';
                }
                if (this.delay > 0) {
                  delayFormat = `<div ${styling}>Delay: ${this.delay} ms</div>`;
                } else {
                  delayFormat = '<div>Delay: N/A</div>';
                }
              }
              let lossFormat = '';
              if (this.avgLoss) {
                if (this.avgLoss > 0) {
                  lossFormat = `${this.avgLoss}%`;
                } else {
                  lossFormat = 'N/A';
                }
              }
              let highLossFormat = '';
              if (this.avgLoss > LOSS_THRESHOLD) {
                highLossFormat = 'High Link Loss';
              }
              const sourceIp = this.sourceId.includes('-') ? this.sourceId.split('-')[1] : this.sourceId;
              const targetIp = this.targetId.includes('-') ? this.targetId.split('-')[1] : this.targetId;
              return `
                <div class="apache-echarts-tooltip">
                  <div>${sourceIp} &#8594; ${targetIp}</div><hr>
                  <div>${asnFormat}</div>
                  <div>${delayFormat}</div>
                  ${lossFormat !== '' ? `<div>Average Loss: ${lossFormat}</div>` : ''}
                  <div>${baselineDelayFormat}</div>
                  <div style="color: red">${highLossFormat}</div>
                  ${unreachableFormat !== '' ? `<div style="color: red">${unreachableFormat}</div>` : ''}
                </div>
              `;
            },
          },
          source: this.sourceId,
          target: this.targetId,
        });
      }
    }
    return {
      waypoints, sublinks,
    };
  }
}

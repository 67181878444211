<template>
  <div class="mx-n2">
    <b-card no-body>
        <b-col cols="12" md="8">
          <h5>Components Overview</h5>
        </b-col>
        <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="usersFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
            <template #cell(name)="row">
              <span data-html="true" v-b-tooltip.hover.html="showTrafficRules(row.value)">
                <p class="text-left">{{ row.value }}
                  <b-modal size="xl" ref="my-modal" hide-footer title="Class Rules">
                    <b-table responsive :items="items" :fields="fields"></b-table>
                  </b-modal>
                  <b-button class="btn float-right" size="sm" @click="showModal(row.value)">
                    Class Rules Details
                  </b-button>
                </p>
              </span>
            </template>
            <template #cell(type)="row">
              <span>
                <p class="text-left">{{ row.value }}</p>
              </span>
            </template>
            <template #cell(numUsers)="row">
              <span>
                <p class="text-left">{{ row.value }}</p>
              </span>
            </template>
            <template #cell(numServers)="row">
              <span>
                <p class="text-left">{{ row.value }}</p>
              </span>
            </template>
            <template #cell(numZones)="row">
              <span>
                <p class="text-left">{{ row.value }}</p>
              </span>
            </template>
          </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';

import collapseText from '@/xvisor/utilities/collapseText';
import hostnameFormatter from '@/xvisor/utilities/hostnameFormatter';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    trafficRuleUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      componentInfo: [],
      trafficRules: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      tableFields: [
        { key: 'name', label: 'Component' },
        { key: 'type', label: 'Type' },
        { key: 'numUsers', label: 'Users' },
        { key: 'numServers', label: 'Servers' },
        { key: 'numZones', label: 'Zones' },
      ],
      validQuery: true,
      fields:
        [
          'classId',
          'rank',
          'nbarVendorId',
          'nbarClassEngineId',
          'nbarSelectorId',
          'paloAltoAppId',
          'srvrAppName',
          'prefix',
          'minPort',
          'maxPort',
          'transProto',
          'isCustomRule',
          'vlanId',
        ],
      items: [],
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.componentInfo && this.componentInfo.length > 0;
    },
    paginationTotalRows() {
      return this.componentInfo.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    usersFilter() {
      const retVal = this.componentInfo;
      return retVal;
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.url, {
            params: {
              start: this.timeRange.start.toISOString(),
              end: this.timeRange.end.toISOString(),
            },
          }),
          this.$http.get(this.trafficRuleUrl),
        ])
        .then(axios.spread((componentRes, trafficRuleRes) => {
          this.componentInfo = componentRes.data;
          this.trafficRules = trafficRuleRes.data;
        }))
        .finally(() => { this.loaded = true; });
    },
    onRowSelected(items) {
      this.$emit('select-row', items[0]);
    },
    showModal(cmptName) {
      this.items = [];
      this.trafficRules.forEach((trafficRule) => {
        if (cmptName === trafficRule.cmptName) {
          const rules = Array.from(trafficRule.rules);
          this.items = rules;
        }
      });
      this.$refs['my-modal'].show();
    },
    showTrafficRules(cmptName) {
      let res = '';
      this.trafficRules.forEach((trafficRule) => {
        if (cmptName === trafficRule.cmptName) {
          const rules = Array.from(trafficRule.rules);
          const firstRule = rules[0];
          res += `<table><tr><td>ClassId</td><td>${firstRule.classId}</td></tr>`;
          res += `<tr><td>Rank</td><td>${firstRule.rank}</td></tr>`;
          res += `<tr><td>NbarVendorId</td><td>${firstRule.nbarVendorId}</td></tr>`;
          res += `<tr><td>NbarClassEngineId</td><td>${firstRule.nbarClassEngineId}</td></tr>`;
          res += `<tr><td>NbarClassEngineId</td><td>${firstRule.nbarSelectorId}</td></tr>`;
          res += `<tr><td>PaloAltoAppId</td><td>${firstRule.paloAltoAppId}</td></tr>`;
          res += `<tr><td>SrvrAppName</td><td>${firstRule.srvrAppName}</td></tr>`;
          res += `<tr><td>Prefix</td><td>${firstRule.prefix}</td></tr>`;
          res += `<tr><td>MinPort</td><td>${firstRule.minPort}</td></tr>`;
          res += `<tr><td>MaxPort</td><td>${firstRule.maxPort}</td></tr>`;
          res += `<tr><td>TransProto</td><td>${firstRule.transProto}</td></tr>`;
          res += `<tr><td>IsCustomRule</td><td>${firstRule.isCustomRule}</td></tr>`;
          res += `<tr><td>VlanId</td><td>${firstRule.vlanId}</td></tr></table>`;
          if (rules.length > 1) {
            res += `<strong>${rules.length - 1} more traffic rule...</strong>`;
          }
        }
      });
      return res;
    },
    collapseText,
    hostnameFormatter,
  },
};
</script>

<style scoped>
.form-select{
  min-width: 160px;
  width: 20%;
}
</style>

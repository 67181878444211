<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.security.extendedName} Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <validation-provider name="Interface" rules="required" v-slot="{ errors }">
        <b-form-group label="Interface">
          <b-form-input
            placeholder="Interface"
            v-model="config.interface"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from '@/xvisor/components/settings/xources/configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        interface: '',
      },
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        interface: this.value.interface || '',
      };
    },
  },
};
</script>

<template>
  <b-modal @show="getOffices" ref="modal" id="add-zones" title="Add Zone(s)" size="lg">
    <validation-observer ref="form" @keydown.enter="addZones">
      <div class="m-1">
        <b-row class="mb-1">
          <b-col>
            <h5>Zone(s)</h5>
          </b-col>
        </b-row>
        <b-row v-for="(zone, index) in zones" :key="index">
          <b-col>
            <validation-provider rules="required" name="name" v-slot="{ errors }">
              <b-form-group>
                <b-form-input
                  placeholder="Zone Name"
                  v-model="zone.name"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider rules="prefixListValid|required" name="prefix" v-slot="{ errors }">
              <b-form-group>
                <b-form-input
                  placeholder="IP Prefixes"
                  v-model="prefixesStrings[index]"
                  :state="errors[0] ? false : null"
                  class="form-control-merge"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-select
                placeholder="Office"
                v-model="zone.officeId"
                :options="offices"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button size="sm" variant="outline-secondary" @click="deleteZone(index)" v-if="index !== 0">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="3">
            <b-button size="sm" variant="primary" @click="addZone">
              <b-icon icon="plus"></b-icon> Add Zone
            </b-button>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-left mr-1" @click="addZones">Submit</b-button>
        <b-button variant="outline-primary" class="float-left" @click="$bvModal.hide('add-zones')">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ZonesEventBus from '@/xvisor/bus/ZonesEventBus';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      prefixesStrings: [],
      zones: [{
        name: '',
        subnets: [],
        officeId: null,
      }],
      offices: [],
    };
  },
  methods: {
    getOffices() {
      this.$http
        .get('/offices/ids.json')
        .then((response) => { this.offices = response.data; });
    },
    updateZonesObject() {
      this.prefixesStrings.forEach((prefixesStr, index) => {
        this.zones[index].subnets = prefixesStr.replace(/\s+/g, '').split(',');
      });
    },
    addZone() {
      this.zones.push({
        name: '',
        subnets: [],
        officeId: null,
      });
    },
    deleteZone(index) {
      this.zones.splice(index, 1);
      this.prefixesStrings.splice(index, 1);
    },
    addZones(modalEvent) {
      modalEvent.preventDefault();
      this.$refs.form.validate().then((result) => {
        if (result) {
          this.updateZonesObject();
          this.$http
            .post(this.url, this.zones)
            .then(() => {
              this.$bvToast.toast('Successfully added zone(s)', { variant: 'success' });
              this.$refs.modal.hide();
              ZonesEventBus.$emit('refresh');
            })
            .catch(() => {
              this.$bvToast.toast('Failed to add zone(s)', { variant: 'danger' });
            });
        }
      });
    },
  },
};
</script>

<template>
  <b-badge pill :variant="statusVariant" class="badge-width">
    {{ statusCheck(this.status) }}
  </b-badge>
</template>

<script>
import opsStatuses from '@/xvisor/constants/ops';

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opsStatus: {
        ok: opsStatuses.ok,
        failed: opsStatuses.failed,
      },
    };
  },
  computed: {
    statusVariant() {
      if (this.status === this.opsStatus.ok) return 'success';
      return 'danger';
    },
  },
  methods: {
    // Return failed if status isn't a valid string.
    statusCheck(status) {
      if (status !== this.opsStatus.ok && status !== this.opsStatus.failed) {
        return this.reportStatus.failed;
      }
      return status;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-width{
  width: 75px;
}
</style>

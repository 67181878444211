/** Convert bits per second to human readable format.
 *
 * @param bps The number of bits per second to convert.
 * @param decimal The number of decimal places in the result.
 * @return Readable format of bits per second.
 * Ex.
 *  readableBps(2100000000, 2) => 2.10 Gbps
 *  readableBps(1000, 1) => 1.0 Kbps
 */
export default function readableBps(bps, decimal = 1) {
  if (bps < 0) throw new RangeError('Bits per second must be non-negative');
  if (bps === 0) return `${bps}`;
  const base = 1000;
  const units = ['Bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
  let index = Math.floor(Math.log(bps) / Math.log(base));
  if (index >= units.length) index = units.length - 1;
  return `${(bps / (base ** index)).toFixed(decimal)} ${units[index]}`;
}

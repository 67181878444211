<template>
    <span v-if="!ipAttrsLoaded">
      <SpinnerCmpt></SpinnerCmpt>
    </span>
    <span v-else-if="showOnlyDeviceName">
      {{ getDeviceName }}
    </span>
    <span
      v-else
      v-b-tooltip.hover
      :title="getIpAttrsTooltip"
      @click="navigateTo"
      class="ip-wrapper"
    >
      {{ ip }}
    </span>
</template>
<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  props: {
    ip: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    showOnlyDeviceName: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      ipAttrsTooltip: '',
      ipAttrsLoaded: false,
      ipAttrsUrl: `/ip/${this.ip}/attrs.json`,
      deviceName: '',
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  computed: {
    getIpAttrsTooltip() {
      if (this.ipAttrsTooltip && this.ipAttrsLoaded) {
        const {
          deviceName,
          hostName,
          ip,
          macAddress,
          username,
        } = this.ipAttrsTooltip;

        const tooltipText = `
          ${deviceName ? `Device Name: ${deviceName}\n` : ''}
          ${username ? `Username: ${username}\n` : ''}
          ${hostName ? `Host Name: ${hostName}\n` : ''}
          ${ip ? `IP: ${ip}\n` : ''}
          ${macAddress ? `MAC Address: ${macAddress}\n` : ''}
        `;

        return tooltipText;
      }
      return 'IP Attributes Data Unavailable';
    },
    getDeviceName() {
      return this.deviceName ? this.deviceName : '-';
    },
  },
  methods: {
    navigateTo() {
      this.$router.push({
        name: 'ipattrs',
        params: {
          ip: this.ip,
        },
      });
    },
    httpGet() {
      this.$http
        .get(this.ipAttrsUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => {
          if (response.data.length) {
            [this.ipAttrsTooltip] = response.data;
            if (this.showOnlyDeviceName) {
              this.deviceName = this.ipAttrsTooltip.deviceName;
            }
          }
        })
        .finally(() => { this.ipAttrsLoaded = true; });
    },
  },
};
</script>
<style>
  span.ip-wrapper {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
  span.ip-wrapper:hover {
    text-decoration: underline;
  }
</style>

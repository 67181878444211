<template>
  <div>
    <h2>1. Introduction</h2>
    <p>
      This document gives an overview of Ennetix's Application-Centric AIOps
      Platform (xVisor) solution: installation, configuration, and operation. The
      xVisor solution is a cloud-based software-as-a-service (SaaS) solution for
      end-user performance management, analytics, and diagnostics.
    </p>
    <span id="1-1-ennetix-xvisor-overview" class="anchor"></span>
    <h3>1.1. xVisor Overview</h3>
    <p>
      The xVisor solution uses an active measurement agent (called
      Enneti<strong>X</strong> <strong>O</strong>nsite <strong>M</strong>anagement
      <strong>E</strong>ntity (XOME)) to continuously monitor the performance of
      network paths and traffic flows through switches and routers. XOME is easy to
      deploy, has a small operational footprint, and runs on any general-purpose
      computer or virtual machine. XOME automates streaming of information to the
      Ennetix Cloud, where the data is continuously analyzed, and creates
      performance profiles for the monitored applications, paths, and network
      elements. Figure 1a shows a representation of the xVisor solution
      architecture.
    </p>
    <p><img src="../assets/ennetix-overview.png" class="responsive-img-lg" /></p>
    <p>Fig. 1a: xVisor solution architecture</p>
    <p>
      xVisor solution provides end-user performance analytics for an
      enterprise's business-critical applications. It generates real-time
      notifications when it detects end-user performance anomalies. It also
      generates end-of-day performance summaries, highlighting performance issues
      during the reporting period, and also performance trends that indicate
      recurring and emerging issues. The xVisor solution also provides additional
      analytics information on network elements and paths which constitute the
      underlying application-delivery infrastructure of the enterprise network.
    </p>
    <span id="1-1-1-xome-deployment-and-data-collection" class="anchor"></span>
    <h4>1.1.1. Xome Deployment and Data Collection</h4>
    <p>
      As shown in Fig. 1b, XOME is installed inside the enterprise network. XOME is
      designed as containerized software; so it can run on any host operating
      systems supporting containers. XOME can be directly attached to the border
      router (on mirror/tap ports) of the enterprise office or installed in a
      machine/VM in the client network. As shown in Fig. 1b, XOME can collect
      passive data (such as packet captures, flow, SNMP, logs, etc.) and active
      measurements by sending probes. Data collected by XOME, after sanitization and
      summarization, are sent (over a secure tunnel) to xVisor cloud platform for
      analytics (Fig. 1b).
    </p>
    <p><img src="../assets/ennetix-deployment.png" class="responsive-img-lg"/></p>
    <p>Fig. 1b: xVisor - XOME deployment and data collection.</p>
    <p>
      XOME collects passive measurements from the network by accumulating
      packet-level data (by port mirroring from the border router, Fig. 1b) and/or
      flow data (e.g., NetFlow, SFlow, IPFIX, etc.), and SNMP/syslogs from the
      routers/switches in the network of enterprise office. One very important thing
      to note that, XOME does not inspect any data inside the packets, and no
      private user data is collected. From these collected passive data, xVisor
      converts them to application-level transactions/flows, discover applications,
      users, and their network service dependencies (e.g., DNS, LDAP,
      authentication, etc.). XOME also sends active probes to discovered
      destinations (e.g., application servers, network services, and
      routers/switches in Intranet and Internet) to collect hop-by-hop,
      segment-by-segment, and end-to-end performance data (such as delay, loss,
      jitter, available bandwidth, etc.).
    </p>
    <span id="1-2-ennetix-xvisor-signup-and-installation-process" class="anchor"></span>
    <h3>1.2. xVisor Signup and installation Process</h3>
    <p>
      Figure 2 summarizes the steps involved in the signup and installation process
      of xVisor.
    </p>
    <p><img src="../assets/ennetix-solution.png" class="responsive-img-lg" /></p>
    <p>Fig. 2: xVisor solution - signup and installation process.</p>
    <p>These steps are as follows:</p>
    <ul>
      <li>
        <strong>Sign Up</strong>: An xVisor-user (in this document, xVisor-user is
        specifically used to identify the administrators of client using xVisor,
        different from end-users of monitored applications) can sign up for the
        xVisor solution by contacting Ennetix team at
        <strong><a href="https://ennetix.com/">https://ennetix.com/</a></strong>.
        Once the setup process is complete, Ennetix team will inform the
        xVisor-user about the client-specific xVisor web portal, a unique
        URL for "Client A" can be
        <strong><a href="https://clienta.xvisor.ai/">https://clienta.xvisor.ai/</a></strong>
        (In this specific case, the web portal URL is <strong>{{ rootLink }}</strong>).
        Ennetix team will also invite xVisor-user to sign up as an admin on the
        web portal and provide XOME installation instructions in a separate
        document. After sign up, xVisor-user can also invite other collaborators to
        sign up using the xVisor portal.
      </li>
      <li>
        <strong>Download XOME</strong>: Once the sign up step is complete, the
        xVisor-user can download XOME virtual appliance in two ways: (1) from
        xVisor portal (on ADD XOME panel, Section 2.2.1) or (2) contacting
        Ennetix. For the latest version, please contact Ennetix staff (information
        listed at top of this document). XOME is packaged as a Ubuntu-Linux-based
        virtual machine. There are several platforms (e.g., Debian, RHEL, etc.) of
        the virtual machine available. XOME can also be deployed in a stand-alone
        physical machine. When high volume of traffic needs to be captured,
        installing XOME on physical machine is the best. If the xVisor-user wants a
        specific platform, he/she can contact Ennetix and get XOME virtual appliance
        or installation scripts in the desired platform along with installation
        instructions.
      </li>
      <li>
        <strong>Deploy XOME</strong>: XOME virtual machine can be deployed on
        stand-alone machines or shared servers. Recommended resources for the
        virtual machine are: 2 cores, 4 GB RAM, 16 GB HDD space, and at least a 100
        Mbps network connection. After deploying the XOME, xVisor-user can follow
        the "XOME Installation Configuration" document provided by the
        Ennetix team to complete the registration.
      </li>
      <li>
        <strong>Configure xVisor</strong>: Once the XOME registration
        process is complete, xVisor-user should enable data sources to collect
        various types of data (more on this can be found Section 2.8.2) on XOME by
        going to Settings page XOME(s) tab. xVisor user can also add applications to
        monitor and configure notifications settings on xVisor web portal to
        start the monitoring process. More on these steps can be found in Section
        2.2.
      </li>
      <li>
        <strong>Monitor Application Performance</strong>: After configuring Ennetix
        xVisor, xVisor-users will start getting granular performance of each and
        every end-user of the monitored applications and performance anomalies, and
        receive summary reports. They can also configure new applications and
        network elements to monitor using xVisor.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rootLink: window.location.hostname,
    };
  },
};
</script>

<style scoped>
.anchor {
  top: -75px;
  position: relative;
  z-index: 5;
}
</style>

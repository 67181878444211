<template>
  <div>
    <div><strong>Filters</strong> (e.g. name == "XomeName")</div>
    <div
      v-for="(field, index) in filterFields"
      :key="index"
      class="ml-1"
    >
      <span class="text-primary">{{ field.name }}</span> {{ field.example }}
    </div>
    <div><strong>Operators</strong> (e.g. Query1 OR Query2)</div>
    <div><strong>Logical Operators</strong></div>
    <div class="text-primary ml-1">and, not, or</div>
    <div class="ml-2">e.g. Query1 and not Query2</div>
    <div><strong>Comparators</strong></div>
    <div class="text-primary ml-1">==</div>
    <div class="ml-2">e.g. field == "Search Term"</div>
  </div>
</template>

<script>
export default {
  props: {
    filterFields: {
      type: Array,
      required: true,
    },
  },
};
</script>

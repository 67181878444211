<template>
  <div>
    <Multiselect
      class="search-bar-top-screen"
      :allow-empty="false"
      :internal-search="false"
      @search-change="changeSearchResults"
      resetAfter=true
      track-by="query"
      label="label"
      :disabled="generatingAiResponse"
      :options="releventQuestions"
      v-model="selectedQuestion"
    >
      <template slot="placeholder">
        {{ getPlaceholderText }}
      </template>
    </Multiselect>
  </div>
</template>

<script>
import LaunchpadEventBus from '@/xvisor/bus/LaunchpadEventBus';
import Multiselect from 'vue-multiselect';

import dynamicComponentsMixin from '@/xvisor/components/launchPad/dynamicComponentsMixin';

export default {
  mixins: [dynamicComponentsMixin],
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedQuestion: null,
      releventQuestions: [],
      useAI: false,
      generatingAiResponse: false,
      url: '/genai/dashboard/panelrec.json',
      questionToComponentEnum: new Map(),
    };
  },
  mounted() {
    Object.values(this.componentEnum).forEach((componentEnum) => {
      this.questionToComponentEnum.set(this.componentEnumToInfo.get(componentEnum).query, componentEnum);
    });
    this.releventQuestions = this.getAllQuestions();
    LaunchpadEventBus.$on('disable-search-option', this.disableQuestion);
    LaunchpadEventBus.$on('enable-search-option', this.enableQuestion);
  },
  watch: {
    selectedQuestion() {
      if (this.selectedQuestion != null) {
        if (this.useAI) {
          this.httpGet();
        } else {
          const obj = {
            value: this.questionToComponentEnum.get(this.selectedQuestion.query),
            aiResponse: '',
          };
          LaunchpadEventBus.$emit('add-panel', obj);
        }
      }
    },
  },
  methods: {
    changeSearchResults(typedQuery) {
      if (typedQuery === '') {
        this.useAI = false;
        this.releventQuestions = this.getAllQuestions();
      } else {
        this.useAI = true;
        this.releventQuestions = [
          {
            label: `Use AI to search for "${typedQuery}"`,
            query: typedQuery,
            $isDisabled: false,
          },
        ];
      }
    },
    getAllQuestions() {
      return Array.from(this.componentEnumToInfo.values()).map(({ query }) => (
        { query, label: query, $isDisabled: false }
      ));
    },
    disableQuestion(query) {
      const questionIndex = this.findQuestionIndex(query);
      this.releventQuestions[questionIndex].$isDisabled = true;
    },
    enableQuestion(query) {
      const questionIndex = this.findQuestionIndex(query);
      this.releventQuestions[questionIndex].$isDisabled = false;
    },
    findQuestionIndex(query) {
      for (let i = 0; i < this.releventQuestions.length; i += 1) {
        if (this.releventQuestions[i].query === query) {
          return i;
        }
      }
      return -1;
    },
    httpGet() {
      this.generatingAiResponse = true;
      this.$http
        .post(this.url, {
          question: this.selectedQuestion.query,
        })
        .then((response) => {
          const answer = response.data;
          if (!answer.panelKey.length) {
            this.$bvToast.toast(
              `AI could not find an answer to that question.
              Try rewording your query and include key words relevant to the dashboard.`,
              { title: 'Notice', variant: 'danger' },
            );
          } else {
            answer.panelKey.forEach((key, idx) => {
              LaunchpadEventBus.$emit('add-panel', {
                value: key,
                aiResponse: answer.explanation[idx],
              });
            });
          }
        })
        .catch(() => {
          this.$bvToast.toast(
            'Something went wrong with your request. Please try again in a few moments.',
            { title: 'Notice', variant: 'danger' },
          );
        })
        .finally(() => {
          this.generatingAiResponse = false;
          LaunchpadEventBus.$emit('check-ai-response');
        });
    },
  },
  computed: {
    getPlaceholderText() {
      if (this.generatingAiResponse) {
        return 'Generating a response for your query...';
      }
      return '🔍 What would you like to know about?';
    },
  },
};
</script>

<template>
  <div v-if="totalAlerts > 0">
    <b-row>
      <b-col>
        Total: {{ totalAlerts }}
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-b-tooltip.hover :title="tooltipText">
          <b-progress
            height="10"
            :value="criticalAlerts"
            :max="totalAlerts"
            variant="danger"
          ></b-progress>
        </div>
      </b-col>
    </b-row>
  </div>
  <div v-else>{{ $t("No Alerts") }}</div>
</template>

<script>
export default {
  props: {
    criticalAlerts: {
      type: Number,
      required: true,
    },
    totalAlerts: {
      type: Number,
      required: true,
    },
  },
  computed: {
    criticalPercent() {
      if (this.totalAlerts !== 0) return (this.criticalAlerts / this.totalAlerts) * 100;
      return 0;
    },
    tooltipText() {
      return `Critical Alerts: ${this.criticalAlerts} (${this.criticalPercent.toFixed(1)} %)`;
    },
  },
};
</script>

<template>
  <div v-if="hasData">
    <b-row
      v-for="(data, index) in dataOrSeries.slice(0, numberShow)"
      :key="index"
      :class="[compact ? '' : 'mb-1', 'mx-0', 'dynamic-font']"
    >
      <b-row class="w-100">
        <b-col>
          <div v-if="data.name.length > textWidth" v-b-tooltip.hover :title="data.name">
            {{ collapseText(data.name, textWidth) }}
          </div>
          <div v-else>{{ data.name }}</div>
        </b-col>
        <b-col align="right">
          {{ readableBytes(data.bytes) }}
        </b-col>
      </b-row>
      <b-row class="w-100">
        <b-col cols="12">
          <div v-b-tooltip.hover :title="tooltipText(data.bytes)">
            <b-progress
              :value="data.bytes"
              :max="totalBytesWithDefault"
              class="w-100"
              :variant="barColor"
              :height="heightBar+'px'"
            ></b-progress>
          </div>
        </b-col>
      </b-row>
    </b-row>
  </div>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import collapseText from '@/xvisor/utilities/collapseText';
import readableBytes from '@/xvisor/utilities/readableBytes';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    barColor: {
      type: String,
      required: true,
    },
    /** The URL from which to query data, should not be defined if data is passed in through the data prop. */
    url: {
      type: String,
    },
    /** The data passed in from the parent, should not be defined if URL is defined. */
    data: {
      type: Array,
    },
    /** The total traffic bytes to use as the total for the progress bar. */
    totalBytes: {
      type: Number,
    },
    numberShow: {
      type: Number,
      default: 3,
    },
    heightBar: {
      type: Number,
      default: 5,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    collapsedTitle: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
      textWidth: this.collapsedTitle ? 8 : 18,
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      if (this.url) this.httpGet();
    },
    url() {
      if (this.url) this.httpGet();
    },
  },
  computed: {
    dataOrSeries() {
      return this.data || this.series;
    },
    hasData() {
      return this.loaded
        && ((this.series && this.series.length > 0) || (this.data && this.data.length > 0))
        && this.totalBytesWithDefault !== 0;
    },
    totalBytesWithDefault() {
      return this.totalBytes || this.series.reduce((runningSum, dataPoint) => runningSum + dataPoint.bytes, 0);
    },
  },
  methods: {
    bytesPercent(bytes) {
      return (bytes / this.totalBytesWithDefault) * 100;
    },
    tooltipText(bytes) {
      return `${this.bytesPercent(bytes).toFixed(1)}%`;
    },
    httpGet() {
      if (this.url) {
        this.loaded = false;
        this.$http
          .get(this.url, {
            params: {
              start: this.timeRange.start.toISOString(),
              end: this.timeRange.end.toISOString(),
            },
          })
          .then((response) => { this.series = response.data; })
          .finally(() => { this.loaded = true; });
      } else if (this.data) {
        this.loaded = true;
      }
    },
    readableBytes,
    collapseText,
  },
};
</script>

<style lang="scss" scoped>
.dynamic-font {
  font-size: calc(0.4rem + 0.5vw);
}
</style>

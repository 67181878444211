<template>
  <div>
    <b-dropdown text="Action">
      <b-dropdown-item @click="$refs.editZone.$refs.modal.show()">Edit</b-dropdown-item>
      <b-dropdown-item @click="$refs.deleteZone.$refs.modal.show()">Delete</b-dropdown-item>
    </b-dropdown>
    <EditZoneModal
      ref="editZone"
      type="update"
      :zone="zone"
      :url="zone.urls.updateUrl"
    ></EditZoneModal>
    <DeleteZoneModal
      ref="deleteZone"
      :zone="zone"
      :url="zone.urls.deleteUrl"
    ></DeleteZoneModal>
  </div>
</template>

<script>
import DeleteZoneModal from '@/xvisor/components/settings/zones/DeleteZoneModal.vue';
import EditZoneModal from '@/xvisor/components/settings/zones/EditZoneModal.vue';

export default {
  props: {
    zone: {
      type: Object,
      default: null,
    },
  },
  components: {
    DeleteZoneModal,
    EditZoneModal,
  },
};
</script>

<template>
  <div>
    <b-row align-h="between">
      <b-col lg="2" md="2" sm="12">
        <h2>{{ $t('Launchpad') }}</h2>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <SearchWizard class="search-bar-top-screen"
        ></SearchWizard>
      </b-col>
      <b-col lg="4" md="4" sm="12">
        <TimeRangeSelector
          :minDate="minDate"
          :maxDate="maxDate"
          :initRange="timeRange"
          @change-time-range="updateStateTimeRange"
        ></TimeRangeSelector>
      </b-col>
    </b-row>
    <PanelsContainer :timeRange="timeRange"></PanelsContainer>
  </div>
</template>

<script>
import timeRangeMixin from '@/xvisor/components/timeRangeMixin';
import dynamicComponentsMixin from '@/xvisor/components/launchPad/dynamicComponentsMixin';
import SearchWizard from '@/xvisor/components/launchPad/SearchWizard.vue';
import PanelsContainer from '@/xvisor/components/launchPad/PanelsContainer.vue';

export default {
  mixins: [timeRangeMixin, dynamicComponentsMixin],
  components: {
    SearchWizard,
    PanelsContainer,
  },
  methods: {
    updateStateTimeRange(timeRange) {
      this.$store.commit('app/UPDATE_TIME_RANGE', timeRange);
      this.timeRange = timeRange;
    },
  },
};
</script>

<style scoped>
.search-bar-top-screen{
  margin-bottom: 20px;
}
</style>

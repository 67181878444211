<template>
  <b-card>
    <b-row align-h="center">
      <b-col>
        <p class="text-center" style="font-size: 10.5px">
          DISCLAIMER - xVisor is an AIOps platform which automatically generates performance alerts based on
          advanced machine-learning algorithms. But, if you want to augment xVisor's auto-alerting mechanisms with
          unacceptable performance rules of applications/services based on domain knowledge, please update the
          following rules at your discretion. Nevertheless, xVisor will continue to generate alerts in absence of
          any such rules.
        </p>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col lg="4" md="12" sm="12">
        <b-form-select
          v-model="appSelected"
          :options="appOptions"
          value-field="id"
          text-field="name"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>Select an Application</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col lg="8" md="12" sm="12">
        <b-card sub-title="Alert Conditions" sub-title-text-variant="">
          <AlertConfig v-if="appSelected" :appId="appSelected"></AlertConfig>
          <div v-else>No Application Selected</div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AlertConfig from '@/xvisor/components/policyCenter/AlertConfig.vue';

export default {
  props: {
    url: {
      type: Object,
      required: false,
    },
  },
  components: {
    AlertConfig,
  },
  data() {
    return {
      appSelected: null,
      appOptions: [],
    };
  },
  computed: {},
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url.app)
        .then((response) => {
          this.appOptions = response.data;
          const [firstSelection] = this.appOptions;
          this.appSelected = firstSelection.id;
        });
    },
  },
};
</script>

<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="traffic-stats-chart"
  ></e-chart>
  <div v-else-if="loaded">{{ $t("No Data Available")}}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import mbpsConverter from '@/xvisor/utilities/mbpsConverter';
import convertMbpsByMaxValUnit from '@/xvisor/utilities/convertMbpsByMaxValUnit';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    timeSet() {
      return new Set(this.series.map((item) => item.points.map((traffic) => traffic.time)));
    },
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    formattedSeries() {
      return this.series.map((item) => ({
        name: item.name,
        smooth: 0.3,
        type: 'line',
        showSymbol: false,
        symbol: 'circle',
        areaStyle: {
          opacity: 0.3,
        },
        lineStyle: {
          width: 5,
        },
        data: item.points.map((point) => [
          moment(point.time).valueOf(),
          point.value,
        ]),
      }));
    },
    yAxisLabelUnits() {
      let max = 0;
      this.series.forEach((ele) => {
        ele.points.forEach((point) => {
          if (point.value > max) {
            max = point.value;
          }
        });
      });
      return mbpsConverter(max).unit;
    },
    chartOptions() {
      return {
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.orange5,
          colorPaletteShade.indigo4,
          colorPaletteShade.purple4,
          colorPaletteShade.green4,
        ],
        grid: [
          {
            left: 50,
            right: 30,
            top: 45,
            bottom: 80,
          },
        ],
        legend: {
          bottom: 4,
          textStyle: {
            color: this.toggleColor,
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format('MMM D h:mm A')} <br>
              ${this.tooltipFormatter(info)}
            </div>
          `,
        },
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            show: false,
          },
        },
        xAxis: {
          type: 'time',
          min: this.timeRange.start.toISOString(),
          max: this.timeRange.end.toISOString(),
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format('MMM D h:mm A'),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: this.yAxisLabelUnits,
            nameLocation: 'center',
            nameGap: 27,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => this.yAxisLabelFormatter(value),
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity24(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    yAxisLabelFormatter(value) {
      let MaxVal = 0;
      this.series.forEach((item) => {
        item.points.forEach((point) => {
          if (point.value > MaxVal) {
            MaxVal = point.value;
          }
        });
      });
      const maxValUnit = mbpsConverter(MaxVal).unit;
      return `${convertMbpsByMaxValUnit(value, maxValUnit)}`;
    },
    tooltipFormatter(info) {
      let tooltipMessage = '';
      info.forEach((element) => {
        const { rate, unit } = mbpsConverter(element.value[1]);
        tooltipMessage += `
          ${element.marker} ${element.seriesName}: ${rate.toFixed(2)} ${unit}
          <br>
        `;
      });
      return tooltipMessage;
    },
  },
};
</script>

<style scoped>
.traffic-stats-chart {
  height: 400px !important;
}
</style>

<template>
  <b-card class="mx-n2">
    <h5>Firewall</h5>
    <!-- Table and sorting header -->
    <div v-if="hasData">
      <b-row>
        <!-- table -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableData"
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
            select-mode="single"
            selectable
            @row-selected="onRowSelected"
          >
          </b-table>
        </b-col>
        <!-- pagination -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="paginationTotalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else-if="loaded">{{ $t("No Firewall(s)") }}</div>
  </b-card>
</template>

<script>

export default {
  props: {
    filter: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'infraId', label: 'Infra Id', sortable: true },
      ],
      filteredLength: null,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.tableData.length;
    },
    tableData() {
      const data = [];
      this.series.forEach((infraElement) => {
        data.push(infraElement);
      });
      return data;
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get('/infra/firewall/list.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(item) {
      this.$emit('firewall-click', item[0].infraId);
    },
  },
};
</script>

import moment from 'moment';

import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import readableBytes from '@/xvisor/utilities/readableBytes';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import numberUtils from '@/xvisor/utilities/numberUtils';

const COLORS = [
  colorPaletteShade.teal4,
  colorPaletteShade.yellow4,
  colorPaletteShade.green4,
  colorPaletteShade.blue4,
  colorPaletteShade.indigo4,
  colorPaletteShade.purple4,
  colorPaletteShade.pink4,
  colorPaletteShade.gray4,
];

// Displays a red circle next to the text on a event tooltip.
const EVENT_MARKER_STYLE = ['display: inline-block',
  'margin-right: 5px',
  'border-radius: 10px',
  'width: 10px',
  'height: 10px',
  'background-color: #FC032C'].join(';');

const SYMBOL_SIZE_MIN = 3.5;
const SYMBOL_SIZE_MAX = 10;

const LINK_WIDTH_MIN = 1;
const LINK_WIDTH_MAX = 8;

export default {
  data() {
    return {
      chartOptionsTemplate: {
        tooltip: {},
        legend: [
          {
            show: false,
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            color: COLORS,
            data: null,
            links: null,
            categories: null,
            roam: true,
            label: {
              position: 'right',
              formatter: '{b},',
            },
            lineStyle: {
              color: 'source',
              curveness: 0.15,
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 10,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Returns a node object formatted in the way that echarts series.nodes requires.
     *
     * @param node - A node object in the format returned by the backend.
     * @param minMaxNodeBytes - Minimum and maximum bytes for nodes in the entire flow graph.
     * @param events - A map from a node's id to its associated event. (if it exists).
     * @param categoryNameToCategoryId - Maps node's category to a category id.
     * @returns - A node object formatted in the style echarts requires.
     */
    formattedNode(node, minMaxNodeBytes, event, categoryNameToCategoryId) {
      let abnormalities = '';
      if (event) {
        const eventMarker = `<span style="${EVENT_MARKER_STYLE}"></span>`;
        abnormalities = `
          ${eventMarker} ${node.id} scanned ${event.value} endpoints
          between ${moment(event.startTime).format(momentTimeFormat.time)}
          and ${moment(event.endTime).format(momentTimeFormat.time)}.
        `;
      }
      const symbolSize = numberUtils.mapNumberFromRangeToRange(
        node.bytes,
        minMaxNodeBytes.min,
        minMaxNodeBytes.max,
        SYMBOL_SIZE_MIN,
        SYMBOL_SIZE_MAX,
      );
      return {
        event,
        symbolSize,
        x: node.coordinate.x,
        y: node.coordinate.y,
        value: node.bytes,
        id: node.id,
        name: node.name,
        category: categoryNameToCategoryId[node.category],
        itemStyle: event ? { borderColor: 'red' } : {},
        tooltip: {
          formatter: () => `
            <div class="apache-echarts-tooltip">
              ${node.name}: ${readableBytes(node.bytes)}
              <br>
              ${abnormalities}
            </div>
          `,
        },
      };
    },
    /**
     * Returns a link object formatted in the way that echarts series.links requires.
     *
     * @param link - A link object in the format returned by the backend.
     * @param minMaxLinkBytes - Minimum and maximum bytes for links in the entire flowgraph.
     * @returns - A link object formatted in the style echarts requires.
     */
    formattedLink(link, minMaxLinkBytes) {
      const linkWidth = numberUtils.mapNumberFromRangeToRange(
        link.bytes,
        minMaxLinkBytes.min,
        minMaxLinkBytes.max,
        LINK_WIDTH_MIN,
        LINK_WIDTH_MAX,
      );
      return {
        source: link.source,
        target: link.target,
        tooltip: {
          formatter: () => {
            const rows = [
              `<b>${link.source} -> ${link.target}</b>`,
              `Protocols: ${link.protocol.join(', ')}`,
              `Bytes: ${readableBytes(link.bytes)}`,
              `Packets: ${link.packets}`,
              `Ports: ${link.ports}`,
            ].join('<br />');
            return `<div class="apache-echarts-tooltip">${rows}</div>`;
          },
        },
        lineStyle: {
          width: linkWidth,
        },
      };
    },
  },
};

export default function scoreToAttributes(score) {
  let attributes = {};
  if (score > 6.66) {
    attributes = {
      variant: 'danger',
      textPrefix: 'High',
      scoreThreshold: 6.66,
    };
  } else if (score > 3.33) {
    attributes = {
      variant: 'warning',
      textPrefix: 'Medium',
      scoreThreshold: 3.33,
    };
  } else if (score > 0) {
    attributes = {
      variant: 'info',
      textPrefix: 'Low',
      scoreThreshold: 0,
    };
  }
  return attributes;
}

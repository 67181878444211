<template>
  <e-chart
    v-if="hasData"
    theme="theme-color"
    :options="chartOptions"
    autoresize
  />
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      syslogTs: [],
      loaded: false,
      colors: [
        colorPaletteShade.purple6,
        colorPaletteShade.purple8,
        colorPaletteShade.orange6,
        colorPaletteShade.orange8,
        colorPaletteShade.green6,
        colorPaletteShade.green8,
        colorPaletteShade.blue6,
        colorPaletteShade.blue8,
        colorPaletteShade.teal6,
        colorPaletteShade.teal8,
        colorPaletteShade.yellow6,
        colorPaletteShade.yellow8,
        colorPaletteShade.purple4,
        colorPaletteShade.indigo6,
        colorPaletteShade.indigo8,
        colorPaletteShade.pink4,
        colorPaletteShade.pink6,
        colorPaletteShade.pink8,
        colorPaletteShade.gray6,
        colorPaletteShade.gray8,
        colorPaletteShade.red4,
        colorPaletteShade.red6,
        colorPaletteShade.red8,
      ],
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  computed: {
    timeSet() {
      return new Set(this.syslogTs.map((facility) => facility.syslogPoints.map((ts) => ts.time)).flat());
    },
    chartOptions() {
      return {
        color: this.colors,
        tooltip: {
          position: 'right',
          formatter: (info) => {
            const { dataIndex, marker } = info;
            return this.tooltipFormat(dataIndex - 1, marker);
          },
        },
        series: {
          name: 'Syslog Facility Counts',
          type: 'treemap',
          breadcrumb: false,
          visibleMin: 300,
          label: {
            show: true,
            formatter: '{b}',
          },
          width: '100%',
          height: '80%',
          bottom: '18%',
          upperLabel: {
            show: true,
            height: 30,
            color: themeStyle.styleToggle(colorPalette.lightThemeText, colorPalette.white),

          },
          itemStyle: {
            borderColor: colorPalette.white,
          },
          levels: this.getLevelOption(),
          data: this.formattedSeries,
        },
      };
    },
    formattedSeries() {
      return this.syslogTs.map((facility) => ({
        name: facility.name,
        value: facility.syslogPoints.length,
      }));
    },
    series() {
      return this.syslogTs.map((facility) => ({
        name: facility.name,
        data: [{
          x: facility.name,
          y: this.totalCount(facility),
        }],
      }));
    },
    hasData() {
      return this.loaded && this.syslogTs && this.syslogTs.length > 0;
    },
  },
  methods: {
    getLevelOption() {
      return [
        {
          // Styles the border outside of overall treemap.
          itemStyle: {
            borderColor: themeStyle.styleToggle(colorPalette.lightGray, colorPalette.fadedGray),
            borderWidth: 0,
            gapWidth: 1,
          },
          upperLabel: {
            show: false,
          },
        },
        {
          // Styles the borders inside between each facility.
          itemStyle: {
            borderColor: themeStyle.styleToggle(colorPalette.lightThemeGreyBorder, colorPalette.midGray),
            borderWidth: 5,
            gapWidth: 1,
          },
        },
      ];
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.syslogTs = response.data; })
        .finally(() => { this.loaded = true; });
    },
    totalCount(ts) {
      return ts.syslogPoints.map((point) => point.totalCount).reduce((a, b) => a + b);
    },
    tooltipFormat(seriesIndex, marker) {
      if (this.syslogTs[seriesIndex]) {
        const facility = this.syslogTs[seriesIndex];
        const fm = timeFormat.momentFormat(this.timeSet, new Date(), true);
        const severityNames = new Set(
          facility.syslogPoints.map((ts) => ts.severityCount.map((point) => point.name)).flat(),
        );
        const formatedTotalNumber = this.totalCount(facility).toLocaleString();
        const title = `
          <div>
            ${marker}<b>${facility.name}</b>: ${formatedTotalNumber}
          </div>
        `;
        const headers = ['Time', 'Totol Count'].concat([...severityNames]);
        const tableRows = facility
          .syslogPoints
          .map((ts) => {
            const time = `<td>${moment(ts.time).format(fm)}</td>`;
            const total = `<td>${ts.totalCount.toLocaleString()}</td>`;
            const severityTds = Array
              .from(severityNames)
              .map((name) => {
                const found = ts.severityCount.find((severity) => severity.name === name);
                const value = found ? found.count.toLocaleString() : '';
                return `<td> ${value} </td>`;
              })
              .join('');
            return `<tr>${time}${total}${severityTds}</tr>`;
          })
          .join('');
        const headerHtml = headers.map((header) => `<th>${header}</th>`).join('');

        return `
          <div class="apache-echarts-tooltip">
            <table class="table-borderless text-center">
              <tr>${title}</tr>
              <tr>${headerHtml}</tr>
              ${tableRows}
            </table>
          </div>
        `;
      }
      return '';
    },
  },
};
</script>

<template>
  <b-card no-body>
    <b-row class="mx-1 mb-1 mt-1" align-h="between" align-v="center">
      <b-col cols="12" md="6">
        <ShowEntries>
          <template #input>
            <b-form-select
              v-model="perPage"
              :options="options"
              size="sm"
              class="mx-50"
            ></b-form-select>
          </template>
        </ShowEntries>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <SearchBar class="mr-1">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
          <AddApiKey class="float-right"></AddApiKey>
        </div>
      </b-col>
    </b-row>
    <b-table
      id="api-key-table"
      class="table-with-actions-min-height"
      striped
      responsive
      :items="apiKeys"
      :fields="tableFields"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template #cell(creationTime)="row">
        Added on {{ moment(row.value).format(momentTimeFormat.dateYear) }}<br />
        by {{ row.item.creatorEmail }}
      </template>
      <template #cell(access)="row">
        <LastAccessTime :url="getLastAccessUrl(row.item.id)"></LastAccessTime>
      </template>
      <template #cell(permissions)="row">
        {{ permissionTypes(row.value) }}
      </template>
      <template #cell(actions)="row">
        <ApiKeyAction :apiKey="row.item" @updated="httpGet"></ApiKeyAction>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      class="mt-1 mr-1"
      ariaControls="api-key-table"
    ></b-pagination>
  </b-card>
</template>

<script>
import moment from 'moment';

import AddApiKey from '@/xvisor/components/settings/api-key/AddApiKey.vue';
import ApiKeyAction from '@/xvisor/components/settings/api-key/ApiKeyAction.vue';
import LastAccessTime from '@/xvisor/components/settings/api-key/LastAccessTime.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';
import apiKeyPermissionTypes from '@/xvisor/constants/apiKeyPermissionTypes';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  props: {
    /** The URL from which to get the list of API keys. */
    url: {
      type: String,
      required: false,
    },
  },
  components: {
    AddApiKey,
    ApiKeyAction,
    LastAccessTime,
    ShowEntries,
    SearchBar,
  },
  data() {
    return {
      apiKeys: [],
      perPage: 5,
      currentPage: 1,
      filter: null,
      filteredLength: null,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      tableFields: [
        { key: 'name', label: 'NAME', sortable: true },
        { key: 'creationTime', label: 'CREATED', sortable: true },
        { key: 'access', label: 'LAST ACCESSED', sortable: true },
        { key: 'permissions', label: 'PERMISSIONS', sortable: true },
        { key: 'actions', label: 'ACTIONS' },
      ],
      momentTimeFormat,
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.apiKeys.length;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.apiKeys = response.data; });
    },
    permissionTypes(permissions) {
      const permissionTypes = permissions.map((permission) => {
        if (permission === apiKeyPermissionTypes.read.value) return apiKeyPermissionTypes.read.name;
        if (permission === apiKeyPermissionTypes.write.value) return apiKeyPermissionTypes.write.name;
        return '';
      });
      return permissionTypes.join(', ');
    },
    getLastAccessUrl(id) {
      return `/settings/apikey/${id}/accesstime.json`;
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    moment,
  },
};
</script>

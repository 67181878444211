<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.netFlow.extendedName} Data Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form" @keydown.enter="saveAction">
      <validation-provider name="Ports" rules="required|portListValid" v-slot="{ errors }">
        <b-form-group label="Ports">
          <b-form-input
            placeholder="Ports"
            v-model="config.ports"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        ports: '',
      },
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      newConfig.ports = newConfig.ports.split(',').map(Number);
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        ports: this.value.ports.join(', ') || '',
      };
    },
  },
};
</script>

import getLinkId from '@/xvisor/components/app/networkTab/pathroutes/utils';

export default function checkBlockLinkLoss(nodeIds, linkHasLossMap) {
  for (let i = 0; i < nodeIds.length; i += 1) {
    for (let j = 0; j < nodeIds.length; j += 1) {
      if (linkHasLossMap[getLinkId(nodeIds[i], nodeIds[j])]) {
        return true;
      }
    }
  }
  return false;
}

<template>
  <div>
    <span id="2-ennetix-xvisor-web-portal" class="anchor"></span>
    <h2>Ennetix xVisor Web Portal</h2>
    <p>
      In this section, we present the Ennetix xVisor web portal and how to use the
      portal for operations.
    </p>
    <span id="2-1-sign-up-and-log-in" class="anchor"></span>
    <h3>1. Sign Up and Log In</h3>
    <p>
      To access Ennetix xVisor, an xVisor-user from an organization (i.e., a client)
      needs to sign up. Sign up is easy and can be done by contacting Ennetix team
      using contact form at
      <strong><a href="https://ennetix.com/contact/">https://ennetix.com/contact/</a></strong>.
      Once the sign up is complete, xVisor-user can log in at
      <strong><a :href="rootLink">{{ rootLink }}</a></strong>.
      Figure 3 shows a screenshot of the login page.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-login.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 3: Ennetix xVisor portal sign up and log in page.</p>
    <span id="2-2-overview-page" class="anchor"></span>
    <h3>2. Overview Page</h3>
    <p>
      In this section, we explain various components of the Overview page. This is
      the landing page of Ennetix xVisor when an xVisor-user logs in. The goal of
      the page is to make the xVisor-user cognizant of what is going on in its
      network and performances of mission-critical applications. Therefore, the
      Overview page contains panels that summarize the activity and performance of
      various network entities (applications, paths, and network elements).
      Initially, this page is empty. But, as the xVisor-user adds applications and
      network elements, to monitor, various tiles of the Overview page will be
      populated with alert stats, traffic summaries, and performance profiles.
    </p>
    <p>
      There are a few subtle features of the Ennetix xVisor portal, which appear on
      the left and right corners of the top bar (Fig. 4). The left button (right of
      Ennetix xVisor logo when you are hovering over the logo in the corner) expands
      the menu. There's a toggle next to the word "English" that allows you to switch
      from light to dark mode. The language button (right of English) allows the
      xVisor-user to choose different languages.The "+" button (left of xVisor-user
      name) allows the xVisor-user to add new entities (e.g., new XOME or Application)
      on xVisor. Finally, the profile button (right of xVisor-user name) allows the
      xVisor-user to look at their account information or logout.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xvisor-overview.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 4: Ennetix xVisor Overview page - Part 1.</p>
    <span id="2-2-1-add-xome" class="anchor"></span>
    <h4>2.1. Add XOME</h4>
    <p>
      Assuming the xVisor-user has downloaded and installed XOME, the first step on
      Ennetix xVisor portal is to register XOME. For that, the xVisor-user can use
      the "+" icon at the top panel of the page to register a XOME (Fig.
      5a).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xvisor-overview2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 5a: Ennetix xVisor Overview page - XOME registration.</p>
    <p>
      xVisor-user can use "Add XOME" button (as shown in Fig. 5b) to
      register XOME with xVisor portal. During XOME installation, xVisor-user can
      follow the instructions provided in a separate "XOME Installation
      Configuration" document (link is provided in "Add XOME" panel).
      Once the task of registering the XOME is complete, the Ennetix xVisor is now
      ready to receive data from that specific XOME. The xVisor-user can add as many
      XOMEs as required to collect network performance data.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-settings-page.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 5b: Ennetix xVisor Settings page - add XOME panel.</p>
    <p>
      After XOME is registered, xVisor-user can configure network elements on the
      network to send packet data by port mirroring or flow data (e.g.,
      NetFlow/SFlow) by configuring routers/switches, to XOME. XOME should also be
      configured to enable data sources to be able to receive various data types
      (e.g., packet captures, NetFlow, SFlow, probe data, etc.). More on this can be
      found on Section 8.2.
    </p>
    <span id="2-2-2-rest-of-overview-page" class="anchor"></span>
    <h4>2.2. Rest of Overview Page</h4>
    <p>
      The goal of the Overview page is to give the xVisor-user a birds-eye view of
      how the network and monitored applications are performing.
      xVisor presents an overview of application traffic on the network by default (Fig.
      6a). It shows top-five applications over the past 6 hours on the stacked area
      chart in Fig. 6a. All the discovered applications on the network are also
      shown along with their traffic volume in the table beside the chart. Ennetix
      xVisor uses AI-powered methods to classify observed traffic on the network
      into various applications. All discovered applications can be seen along with
      traffic for the past 6 hours in the table (Fig. 6a).
      From this view (Fig. 6a), xVisor-user can use "Add" button to the
      right of application row to add the application to monitor in detail. Once the
      application is added for monitoring, xVisor will keep detailed information on
      each and every application end-user's performance along with issues
      impacting end-user performance at 5-min granularity.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-overview-application-traffic.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 6a: Ennetix xVisor Overview page - Application Traffic</p>
    <p>
      A powerful AI built-in search bar (Fig. 6b1) is on the top of that page. After clicking on the search bar,
      users can input any questions or select from the recommended questions in a dropdown
      menu (Fig. 6b1) to give them a view of how the network and monitored applications perform. When
      users choose from given questions, a new card shows on the page. Users can bookmark the
      card by clicking the bookmark icon or delete the card by clicking the trashbin icon as needed.
      When the user inputs a question, AI will recommend related cards according to the question (Fig. 6b2).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-overview-dropdown.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 6b1: Ennetix xVisor Overview page - Dropdown Menu</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-overview-search-bar.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 6b2: Ennetix xVisor Overview page - AI Search Bar</p>
    <span id="2-2-3-add-custom-application" class="anchor"></span>
    <h4>2.3. Add Custom Application</h4>
    <p>
      On top of discovered applications on the network (as discussed in Section
      2.2), xVisor also gives the xVisor-user options to add custom applications.
      As shown in Fig. 5a, the xVisor-user can use the "+" icon at the top
      panel of the page to add a custom application (Fig. 5a). xVisor-user can add
      two types of custom applications. One type is based on IP prefixes and port
      ranges. Such custom application definition is called
      "Traffic-based". Figure 7a shows the form to add such as
      application. Essentially, using information added in this
      "Traffic-based" application definition, xVisor can classify traffic
      flows to specific user-defined application. In the "Traffic-based"
      application definition, xVisor-user can add various application component
      names, their IP prefixes, port ranges, and protocol (Fig. 7a). In the
      "Traffic-based" application definition, xVisor-user can also select
      to allow xVisor to automatically discover the application's dependent
      network services (required for accessing the application). For auto-discovered
      applications, xVisor automatically discovers these dependent network services.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-add-custom-app.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 7a: Ennetix xVisor Overview page - Add Traffic-based Custom App.</p>
    <p>
      xVisor-user can also manually add custom "Endpoint-based"
      applications to monitor (Fig. 7b). The "Endpoint-based" application
      definition will not be used to classify traffic flows. These definitions will
      only be used to collect performance data by sending probes to the application
      endpoints. An "Endpoint-based" application essentially defines an
      application where only the endpoints (IP or URL) of the application components
      are known. To add an "Endpoint-based" application (Fig. 7b), the
      xVisor-user will need the following information: component name, source
      XOME(s), destination endpoint URL/IP, network layer measurements to collect,
      and application layer measurements to collect. xVisor-user can add multiple
      application components and dependent network services and measurements to be
      collected for each of those components and services.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-add-custom-app2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 7b: Ennetix xVisor Overview page - Add Endpoint-based Custom App.</p>
    <p>
      After such "Endpoint-based" application definition is added, xVisor
      will start sending custom probes to the defined application and service
      endpoints, and collect various performance data such as path routes, delay,
      loss, jitter, bandwidth, HTTP/S measurements. All the performance information
      for the custom applications will be accessible in Applications Summary/Details
      page. More on these follow.
    </p>
    <span id="2-3-applications-summary-page" class="anchor"></span>
    <h3>3. Applications Summary Page</h3>
    <p>
      This page shows a summary of all the applications' health. As
      shown in Fig. 8.1, the tiles display high-level overview of the
      applications' health over the selected time period (Fig. 8). The tile is
      highlighted with "orange" to signify performance alerts which the
      xVisor-user could potentially review; if there is no such activity/alert, the
      tile will be highlighted as "green". The tiles also show alert
      distributions (in bar chart) in different time buckets. More information on
      each of these applications can be accessed by clicking the "Details"
      button on the summary tile.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-applications-page.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 8.1: Ennetix xVisor Applications page.</p>
    <p>
      This page also shows alerts summaries of all applications as shown in Fig. 8.2.
      xVisor-users can check applications' status and events details by click on the
      "orange" event button. There is a search bar on the right top which allows
      xVisor-users to search alerts by keyword.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-applications-page2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 8.2: Ennetix xVisor Applications page.</p>
    <span id="2-4-application-details-page" class="anchor"></span>
    <h3>4. Application Details Page</h3>
    <p>
      Clicking on the Application Summary tile's "Details" button for
      a specific application (Fig. 8) reveals the Application Details page. The
      detail page is the starting point of all performance analytics information
      related to the application and its end-users' activities. One can go back
      in time to view historical performances, alerts/events as well by selecting
      the relevant time frame in time selector (top right of Fig. 9a). Fig. 9b shows
      the time-selector panel. Ennetix xVisor keeps 5-min granularity data for each
      application monitored for up to a month. If needed, xVisor can keep this data
      (of 5-min or a different granularity) even for longer time period.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 9a: Ennetix xVisor Application Details page - top panel.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-time-selector.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 9b: Ennetix xVisor Application Details page - time selector.</p>
    <p>
      The top of the "Application Details" page shows three panels which
      give stats on alerts, alert distribution by zone (which is a physical area of
      the client's network such as building, floor, etc.), and status of network
      services relevant to the application (Fig. 9a). xVisor-user can click on any
      of the bars or boxes (of alert counts) on these panels and get information on
      all the alerts of that type in the alert panel below the time bar (Fig. 9c).
      More on this alert panel can be found in Section 2.4.7.
    </p>
    <p>
      After all the top panels, there is a time bar (Fig. 9c) which shows alerts and
      events that occurred for the application over the selected timeframe (default
      set to past six hours). If there is no alert for a time bar, that bar will be
      in "gray" color. xVisor-user can click on any of the bars with
      alerts (highlighted by purple, orange, or red colors; Note: these colors
      signify alert severities, with purple being lowest-severity alert (called
      "notice"), orange being "warning" alert, and red being
      "critical" alert), and get information on alerts that happened
      during that time. The alert information will appear in a box below the time
      bar (more on this can be found on Section 2.4.7). The current time selector on
      the time bar is shown as a "white ball" on top of the corresponding
      time bar.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-time-bar.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 9c: Ennetix xVisor Application Details page - time bar.</p>
    <p>
      As shown in Fig 9c, just below the time bar, xVisor Application Details page
      has multiple tabs where various information are presented on the application
      and end-user performance. These tabs are: (a) Overview, (b) Users/Devices, (c)
      Transactions, (d) Flows, (e) Network. Sections 2.4.1 - 2.4.6 present details
      on each of these tabs.
    </p>
    <span id="2-4-1-overview-tab" class="anchor"></span>
    <h4>4.1. Overview Tab</h4>
    <p>
      The Overview tab presents a performance overview of the
      application. It presents a components overview table (Fig. 10a1) to show all related components
      of the application, clicking on the purple button "Class Rules Details" will show
      traffic rules related to the current component in a prompt table (Fig. 10a2).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-comp-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 10a1: Ennetix xVisor Application Details page - Overview tab - Components Table.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-class-rules-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 10a2: Ennetix xVisor Application Details page - Overview tab - Class Rules Details.</p>
    <p>
      Clicking on a row of the components table will show a zones table under the current table
      for more details about zone information.
      (Fig. 10b).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-zone-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 10b: Ennetix xVisor Application Details page - Zones Table.</p>

    <span id="2-4-2-users-tab" class="anchor"></span>
    <h4>4.2. Users Tab</h4>
    <p>
      This is the most important tab on Detailed Application page. Figs. 11a - 11d
      present various aspects of this tab. On this tab, a list of all application
      end-users is given (Fig. 11a). Using the search feature of the panel, specific
      end-user/device can be found, and end-users from one zone can be selected
      (Fig. 11a).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-users-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 11a: Ennetix xVisor Applications Details page - Users tab (part 1).</p>
    <p>
      To review performance information and analytics for each end-user, clicking
      on each row will expand extra details below corresponding to the user's
      performance and analytics. As shown in Fig. 11b, end-user 128.120.32.227
      is selected and expanded to show detailed performance analytics specific
      to the end-user.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-users-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 11b: Ennetix xVisor Applications Details page - Users tab (part 2).</p>
    <span id="2-4-3-transactions-tab" class="anchor"></span>
    <h4>4.3. Transactions Tab</h4>
    <p>
      All application transactions for the selected time period are displayed in
      Transactions tab (as shown in Fig. 12a). Tabs separate different application
      components. There is a time series chart on the left shows all transactions counts in a time range.
      Various statistical insights on application transactions and their abnormalities
      appear on the "Insights" panel of this tab. These insights give xVisor-user
      critical information on overall performance of the application; and
      irregularities, abnormalities, and things to look for in regards to the
      application.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-transactions.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 12a: Ennetix xVisor Application Details page - Transactions tab (Part-1).</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-transactions-stats.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 12b: Ennetix xVisor Application Details page - Transactions tab (Part-2).</p>
    <span id="2-4-4-flows-tab" class="anchor"></span>
    <h4>4.4. Flows Tab</h4>
    <p>
      As shown in Fig. 13, the flow radial diagram shows for each application
      component (separated by tabs) the incoming and outgoing flows for the
      application at each time period (5-min default). There is a time bar with
      which xVisor-user can select specific time period and see the flow data for
      that specific time period. Again, selected period is highlighted by the white
      ball over the corresponding time bar (Fig. 13). The application is represented
      by the orange bar and different flows are represented by the chords in/out to
      the application. For example, one of the chords shows that 13.4 MB of data was
      sent from the "MS Teams" application to the end-user 128.120.25.1,
      while the application received 445.1 KB of data from the same end-user.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-flows.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 13: Ennetix xVisor Application Details page - Flows tab (Part-1).</p>
    <p>
      More information such as bytes, packets, average packet size, etc. can be
      found by hovering on each of the chords of flows on the radial diagram (Fig.
      13). Analytical insights and abnormalities on flow statistics can be found on
      the "Insights" panel beside the radial flow diagram. These insights
      are useful to figure out abnormal behavior of end-users of the application.
      Flow and end-user statistics per application server can also be found on the
      "Flows" tab just below the radial diagram (Fig. 14). Abnormal events
      (based on flow analysis of end-user's behavior) will also show in this
      radial diagram.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-flows-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 14: Ennetix xVisor Application Details page - Flows tab (Part-2).</p>
    <span id="2-4-5-network-tab" class="anchor"></span>
    <h4>4.5. Network Tab</h4>
    <p>
      This tab shows the combined view of all the application-service pathroutes and
      network-specific characteristics such as delay, jitter, and bandwidth, when
      the application is accessed from different zones (which could be
      geographically distributed). This is a Google-map like view where client can
      easily find out which hop/link on the network is creating problem for the
      end-users of the application. As shown in Fig. 15a, all the network paths
      (along with hop-by-hop performance) and their end-to-end characteristics
      (shown in the charts below the network topology) are presented in this tab.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-network-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 15a: Ennetix xVisor Application Details page - Network tab (Part 1).</p>
    <p>
      At the top of the page, there is a Path Health/Performance bar (Fig. 15a).
      This bar shows alerts/events that got generated for the selected time period
      using various colors. If one of the bars is gray, that means there is no
      "network" performance alerts/events. By clicking on any bar, the
      xVisor-user can select a specific time slot (highlighted by white ball over
      the time bar). The pathroute view on this page will highlight data at that
      time period.
    </p>
    <p>
      The xVisor-user can enhance the visualization of the pathroute view by
      clicking on each individual node. Differing colors indicate different domains
      within the route; i.e., in Fig. 15a, yellow domain indicates all the routers
      within "ASN 6192" domain, and blue indicates all the routers within
      Amazon (ASN 16509) domain. For each node, information on delay and loss
      statistics (such as max, min, average, baseline) along with interface
      information (such as name and IP) can be found on hovering tooltip (Fig. 15b).
      Also, various performance events will be shown as red color on links/nodes of
      this pathroute visualization. If any link/node is having performance issues
      (such as high loss and/or delay), it will show as a red line/border to signify
      the issue (Fig. 15a). Each of these nodes can also be clicked to look at more
      information on individual "Network Element" page (Section 2.6) (if
      such information is available).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-network-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 15b: Ennetix xVisor Application Details page - Network tab (Part 2).</p>
    <p>
      Below the Pathroutes, there is a table (called "Probe Status")
      showing all the active probes that are collecting network performance data
      from different zones to the application and service components (Fig. 15c).
      Each row presents one probe with information on health, source XOME, Target
      Application/Service component, Zone, and number of alerts for the time period.
      The health of the probe indicates whether the network performance is good/bad
      (red is bad, yellow is alarming, and green is good). Again, health is a
      combined index of all the performance data collected by that probe, and is
      represented on a scale of 0-10 (10 being best or most healthy).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-network-tab3.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 15c: Ennetix xVisor Application Details page - Network tab (Part 3).</p>
    <p>
      For each of the probe rows, there is a ">" which give the visual
      representation of various performance profiles of the probe. These performance
      profiles can be, at network layer: delay, jitter, bandwidth; and at
      application layer: HTTP/S profiles, DNS profiles, etc.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-network-tab4.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 15d: Ennetix xVisor Application Details page - Network tab (probe
      performance profiles).
    </p>
    <p>
      There is a tab option to view the distribution of the probe performance
      through packet delay variation, also known as jitter. The xVisor-user can
      access "Packet Delay Variation" by clicking the tab for jitter. This
      type of variation tracks only the performance of individual delay probe
      packets. Hovering over the distribution provides specific numbers for each
      delay performance statistic. There is another tab on this same view, which
      shows the statistics on performance data such as Available Bandwidth.
      Bandwidth is measured in Mbps (megabits per second), which assesses the
      available transfer speed between the source and the destination of the network
      path of the probe.
    </p>
    <p>
      The Application performance panel (Fig. 15d) tracks the performance of
      applications running on top of the network path. Several specific
      applications' performance profiles can be displayed, such as HTTP, HTTPs,
      DNS, etc. HTTP/HTTPs performance profile data can be distributed over various
      steps of HTTP/S application performance, such as name lookup, connect, SSL,
      response, and fetch time (Fig. 15d). The xVisor-user can utilize this
      application profile to view and compare the performance of specific
      applications. Hovering over particular points of the distribution can provide
      more information of each application's performance on the path. The events
      distribution panel on the right (Fig. 15d) shows the distribution of events
      for that probe during the time period.
    </p>
    <span id="2-4-7-alerts-panel" class="anchor"></span>
    <h4>4.7. Alerts Panel</h4>
    <p>
      This panel shows the alerts (generated for the application) along with all the
      relevant information for a specific time. The "Alerts" panel is a
      box (with alerts organized in a swiper carousel) (Fig. 17a) that appears below
      the time bar when xVisor-user clicks on - (a) a bar of the top time bar (Fig.
      9c) of the application page, (b) the alert stats bars of overview page top
      three panels (Fig. 6a), or (c) the alert stats bars at the top of
      "Application" page (Fig. 9a). As shown in Fig. 17a, when the orange
      bar is clicked, the carousel panel appears and shows details of the alerts
      generated for that time slot. Each alert will have a badge showing its type
      (in Fig. 17a, it is of "Service" type) and severity (shown in orange
      in Fig. 17a), along with a message containing details of the alerts. If there
      are multiple alerts available for a specific time slot, they will show up as
      pages inside the alert box (Fig. 17b). If there are multiple carousels of
      alerts for multiple time slots, xVisor-user can swipe through them to look at
      those alerts (Figs. 17a, 17b).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-alerts-panel.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 17a: Ennetix xVisor Applications Details page - Alerts panel (Part 1).</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-alerts-panel2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 17b: Ennetix xVisor Applications Details page - Alerts panel (Part 2).</p>
    <p>
      When xVisor-user wants to explore more on a specific alert, he/she can click
      on the "Details" of the alert message (Figs. 17a, 17b). Then, a
      message box appears with details of the alert along with "Troubleshooting
      Hint(s)" and "Next Step(s)" to follow to mitigate the
      performance issue (Fig. 17c).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-alerts-panel3.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 17c: Ennetix xVisor Applications Details page - Alerts panel
      troubleshooting and next steps.
    </p>
    <p>
      The "Details" on "Troubleshooting Hint(s)" (Fig. 17c) will
      help xVisor-user to navigate to specific "tabs" and get more details
      on performance issue. For example, if the performance issue is captured by a
      performance probe on the "Network" tab, clicking the
      "Details" on "Troubleshooting Hint(s)" will switch to
      "Network" tab and highlight the probe that shows the performance
      issue in detail (Fig. 17d). Similarly, if end-users are getting affected by
      the alert, clicking "Details" may open the "Users" tab and
      show all the end-users highlighted.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-application-detail-page-alerts-panel4.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 17d: Ennetix xVisor Applications Details page - Alerts panel
      troubleshooting and next steps.
    </p>
    <span id="2-5-network-elements-page" class="anchor"></span>
    <h3>5. Network Elements Page</h3>
    <p>
      This page (Fig. 18a) gives the list of network elements that are being
      monitored on the network. Ennetix xVisor supports various standard protocols
      to gather a network element's traffic and operations data. Some of these
      protocols are packet capture, NetFlow, SFlow, Syslog, SNMP, etc. The
      xVisor-user can utilize the search box at the top right corner by manually
      searching IP addresses from the list.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-network-elements-page.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-network-elements-page2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 18a: Ennetix xVisor Network Elements page.</p>
    <p>
      As shown in Fig. 18a, this page lists the network elements (classified by
      zones). Inside each zone, the network elements are sorted by the alert
      statistics. To get more information on an individual network element, the
      xVisor-user can either click the network element name or ">"
      symbol at the end of the row. This will bring the xVisor-user to the
      individual network element's page.
    </p>
    <span id="2-6-individual-network-element-page" class="anchor"></span>
    <h3>6. Individual Network Element Page</h3>
    <p>
      This page gives various statistics on applications, flows, and IPs on a
      network element. As shown in Fig. 18b, at the top of the page, there are three
      tiles. The xVisor-user can view Top Apps, Top Source IPs, and Top Interfaces
      by Utilization for the network element.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 18b: Ennetix xVisor Individual Network Element page - Part-1.</p>
    <p>
      The following panels (Figs. 19a-19c) serve as visual representations of
      input/output traffic load on a network element. The xVisor-user can use the
      time-selector option at the top right of the page to personalize the time
      period (6 hours, 24 hours, 2 days, or 1 week); visualizations provided in Fig.
      19a-19c are within a period of 6 hours. By clicking the tabs on "Traffic
      Profiles" panel, the xVisor-user can access various distributions of
      traffic profiles such as Total, Protocol, and Application.
    </p>
    <p>
      For "Total" distribution (Fig. 19a), there is a visualization of how
      much total traffic is flowing through the network element.
      "Protocol" distribution visualizes the types of protocol that can be
      seen on the network element (Fig. 19b). "Application" distribution
      tracks the application traffic visible within a network element (Fig. 19c).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 19a: Ennetix xVisor Individual Network Element page - Traffic Activity
      (In &amp; Out).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page3.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 19b: Ennetix xVisor Individual Network Element page - Traffic Activity
      (Protocol).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page4.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 19c: Ennetix xVisor Individual Network Element page - Traffic Activity
      (Application).
    </p>
    <p>
      The Alerts panel (Fig. 19d) shows recently-raised alerts from the performance
      data collected from the network element.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page5.png"
        class="responsive-img-xs"
      />
    </p>
    <p>Fig. 19d: Ennetix xVisor Individual Network Element page - Alerts Panel.</p>
    <p>
      At the top of the page, the xVisor-user can access more specific information
      about the network element within three tabs. "Description" tab (Fig.
      20) provides particular details about a network element, with the left column
      including its name, type, agent IP, vendor, model, and status.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page6.png"
        class="responsive-img-xs"
      />
    </p>
    <p>Fig. 20: Ennetix xVisor Individual Network Element page - Description tab.</p>
    <p>
      "Flow Analysis" tab gives detailed flow analysis of the network
      element. The table on the left gives applications, their total traffic, top
      sources IPs using those applications, along with activity heatmap of the
      application traffic for the selected time period (Fig. 21a).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page7.png"
        class="responsive-img-xs"
      />
    </p>
    <p>Fig. 21a: Ennetix xVisor Individual Network Element page - Flows tab.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-flows-tab-diagram.png"
        class="responsive-img-xs"
      />
    </p>
    <p>Fig. 21b: Flows tab - Flow diagram.</p>
    <p>
      A very interesting visualization in the "Flow Analysis" tab is the
      "Flow Diagram". This visualization gives the xVisor-user information
      about where their traffic is flowing (which source/destination prefix and how
      much). Flows are aggregated using IP prefixes (Fig. 21b), which literally
      shows traffic activity coming from or going into multiple devices within an IP
      address block. The information flow diagrams list are protocol type,
      application type, and total amount of traffic traveling from source IP to
      destination IP prefixes or Applications.
    </p>
    <p>
      In the Interfaces table, interface-specific information is given for the
      selected time period (Fig. 22). This information includes status, name of the
      interface, SNMP index, top Apps visible on the interface, top source IPs using
      the interface, along with utilization heat map for the time period.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-individual-network-elements-page8.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 22: Ennetix xVisor Individual Network Element page - Interfaces tab.</p>
    <span id="2-7-xtends-page" class="anchor"></span>
    <h3>7. xTends Page</h3>
    <p>
      The xTend table (Fig. 22a) lists all devices running xTend and summarizes alert statistics for each
      device in a network environment, including the device name, IP address, and various alert metrics.
      When the user clicks on any device's name, the web page will redirect to show an individual xTend
      page for more detailed information about the currently selected device.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22a: Ennetix xVisor Individual xTend Page - Status.</p>
    <span id="2-8-individual-xtend-page" class="anchor"></span>
    <h3>8. Individual xTend Page</h3>
    <p>
      The status page (Fig. 22b) provides a comprehensive summary of the system's resource utilization
      and network status by three time series charts. It includes details on CPU usage,
      memory usage, and Wi-Fi signal strength. The status page offers valuable insights into
      the system's performance and connectivity. This data is essential for monitoring
      resource consumption, ensuring optimal performance, and maintaining a stable network
      connection.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-status-chart.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22b: Ennetix xVisor Individual xTend Page - Status.</p>
    <p>
      The processes table (Fig. 22c) provides an overview of the various processes running on the system.
      It includes key details such as the process name, user count, execution count,
      and parent process of each process. The table
      provides a comprehensive view of process activity and dependencies within the system.
      This data is essential for system administrators and users to monitor resource
      utilization, identify potential issues with processes that have high execution counts
      or user counts, and understand the hierarchical structure of process relationships.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-processes-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22c: Ennetix xVisor Individual xTend Page - Processes Table.</p>
    <p>
      The traffic table (Fig. 22d) provides a comprehensive overview of system traffic, detailing
      the program name, destination IPs, protocol used, and the total number of
      connections for each entry. The table offers
      valuable insights into the network activity on the system. It enables administrators
      and users to monitor which programs are generating traffic, identify the target
      destinations, understand the communication protocols in use, and assess the volume
      of connections. This data is crucial for maintaining network security, managing
      bandwidth usage, and optimizing network performance.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-traffic-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22d: Ennetix xVisor Individual xTend Page - Traffic Table.</p>
    <p>
      The packages table (Fig. 22e) provides a detailed overview of the packages installed on a system.
      It includes the package name, the date of installation, the version number,
      and the publisher of each package. The table
      helps users and administrators keep track of the software installed, ensuring
      that all packages are up-to-date and from reputable sources. This data is essential
      for system maintenance, security audits, and software management.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-packages-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22e: Ennetix xVisor Individual xTend Page - Packages Table.</p>
    <p>
      The alerts table (Fig. 22f1) provides a detailed overview of system alerts, categorizing
      each alert by its severity, the signature that triggered the alert, the
      count of occurrences, the number of affected users, and the number of programs
      involved. The table helps administrators
      quickly identify and prioritize critical issues, understand the scope of the
      impact, and allocate resources effectively for resolution. This data is essential
      for maintaining system security, ensuring prompt responses to potential threats,
      and managing overall system health.
      When the user clicks on a row of alerts table, A sub-table (Fig. 22f2) will show under the current
      table to provide additional details for each system alert, including the timestamp of the
      alert, the username associated with the alert, the program involved, and the parent process
      of the program.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-alert-summary-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22f1: Ennetix xVisor Individual xTend Page - Alerts Table.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xtend-alert-details-table.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 22f2: Ennetix xVisor Individual xTend Page - Alerts Details Table.</p>
    <span id="2-9-reports-page" class="anchor"></span>
    <h3>9. Reports Page</h3>
    <p>
      xVisor generates reports on application performance by day in a given month.
      Each day generates various performance metrics for the application servers,
      end-users, and related network elements (Figs. 23a--23b).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-report-page.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-report-page2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 23a: Ennetix xVisor Reports page - Part 1.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-report-page3.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-report-page4.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-report-page5.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 23b: Ennetix xVisor Reports page - Part 2.</p>
    <span id="2-10-settings-page" class="anchor"></span>
    <h3>10. Settings Page</h3>
    <p>
      On Settings page, various settings of system, xVisor-user, zones, maintenance,
      security, and report configurations can be configured on various tabs (Figs.
      24a—24j).
    </p>
    <span id="2-10-1-system-tab" class="anchor"></span>
    <h4>10.1. System Tab</h4>
    <p>
      On Systems tab (Fig. 24a), IP whitelist is a list of IPs from where the
      xVisor-user can access the Ennetix xVisor portal. From the edit button at the
      top right, the xVisor-user may input system-specific information such as IP
      whitelist (from where xVisor web portal can be accessed).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-setting-page-system-configuration.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig 24a: Ennetix xVisor Settings page - System Configuration panel.</p>
    <span id="2-10-2-xome-s-tab" class="anchor"></span>
    <h4>10.2. XOME(s) Tab</h4>
    <p>
      On XOME(s) tab, xVisor-user can enable various data sources for each of the
      XOMEs. Once the source is active with required parameters, the XOME should be
      able to collect that type of data. For example, to add "Wire Data
      Capture" source (also known as port mirroring/SPAN port data capture),
      xVisor-user needs to specify sniffer interface on XOME where port-mirrored
      data is coming on XOME machine. [Note: If the data sources are not enabled on
      XOME, XOME will not be able to collect data from that specific source.]
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xome-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig.24b:Ennetix xVisor XOME(s) tab on setting page.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-xome-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig.24c:Ennetix xVisor XOME(s) tab on setting page.</p>
    <span id="2-10-3-user-s-tab" class="anchor"></span>
    <h4>10.3. User(s) Tab</h4>
    <p>
      This tab is used to manage users of Ennetix xVisor. A list of current
      xVisor-users is given on this page (Fig. 24d).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig.24d: Ennetix xVisor User(s) page.</p>
    <p>
      Administrator of a client can add a new user using the "Add User"
      modal (Fig. 24e) and update the xVisor-user using "Update User"
      modal (Fig. 24f). When xVisor-user is added, an email is sent to xVisor-user;
      and the xVisor-user needs to use the link on that email to finish the
      registration process. Until the xVisor-user finishes the registration, the
      "Pending" list (Fig. 24d) will show the pending registrations.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-tab3.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 24e: Ennetix xVisor Users page - Add User modal.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-tab4.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-tab5.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 24f: Ennetix xVisor User(s) tab - Update User modal.</p>
    <span id="2-10-4-team-s-tab" class="anchor"></span>
    <h4>10.4. Team(s) Tab</h4>
    <p>
      This tab along with xVisor-users tab provides xVisor administrators define
      role-based access to xVisor-users. A team is a group of xVisor-users with
      various access roles defined.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-teams-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-teams-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 24g: Ennetix xVisor Team(s) tab on Settings page.</p>
    <p>
      As shown in Figure 24g, teams can be of type "App Admin" where
      xVisor-users will only have access to applications pages, not network elements
      pages. Administrators can define new teams using "Add Team" button
      (Fig. 24g).
    </p>
    <span id="2-10-5-offices-zones-tab" class="anchor"></span>
    <h4>10.5. Offices/Zones Tab</h4>
    <p>
      This tab lets the administrator to configure zones (such as building a,
      building b, campus z, etc.) and offices (geographical locations) of the client
      (Fig. 24h). In the location hierarchy, zone is contained in an office, i.e.,
      an office can have many zones. While adding zone, administrator can specify IP
      prefixes used in the zone and office name (Fig 24h). While adding office,
      administrator can specify office's geographical location (e.g., city).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-page-office-zone-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-page-office-zone-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-page-office-zone-tab3.png"
        class="responsive-img-xs"
      />
    </p>
    <p>Fig. 24h: Ennetix xVisor Users page - Offices/Zones tab.</p>
    <span id="2-10-6-maintenance-tab" class="anchor"></span>
    <h4>10.6. Maintenance Tab</h4>
    <p>
      Maintenance tab (Fig. 24i) lets the administrator to define a maintenance
      window (when application infrastructure maintenance would be done) so that
      alerts/events do not get generated during maintenance. If not configured, many
      false-positive performance events/alerts may be generated due to
      application-infrastructure and/or components not performing well while being
      in maintenance mode.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-page-maintenance-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-page-maintenance-tab2.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 24i: Ennetix xVisor Users page - Maintenance tab.</p>
    <span id="2-10-7-security-tab" class="anchor"></span>
    <h4>10.7. Security Tab</h4>
    <p>
      On Security tab (Fig. 24j), administrators may configure single sign on or
      two-factor authentication. Single sign on allows access control on multiple
      platforms when xVisor-user logs in at one time. Two-factor authentication
      requires two factors to authenticate the xVisor-user, such as a password and a
      randomly-generated code given through email.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-setting-page-security-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>
      Fig. 24j: Ennetix xVisor Settings page - Single Sign On and Two-Factor
      Authentication panels.
    </p>
    <span id="2-10-8-reports-tab" class="anchor"></span>
    <h4>10.8. Reports Tab</h4>
    <p>
      On Reports tab (Fig. 24k), xVisor can be configured to send daily reports in
      few time intervals, e.g., once daily, at 7.00 am (Fig. 24k) (this setting is
      configurable). Reports may include, but are not limited to, application
      statistics, end-user performance statistics, network element statistics, etc.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-setting-page-reports-tab.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 24k: Ennetix xVisor Settings page - Report Configuration panel.</p>
    <span id="2-10-9-activity-logs" class="anchor"></span>
    <h4>10.9. Activity Logs</h4>
    <p>
      xVisor maintains a user activity log to keep information on xVisor-users'
      activities. This Ennetix xVisor log can also be found on "Activity
      Logs" panel of Settings page (Fig. 24l).
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-service-page-activity-log.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 24l: Ennetix xVisor Services page - Ennetix xVisor Logs.</p>
    <span id="2-11-user-profile-page" class="anchor"></span>
    <h3>11. User Profile Page</h3>
    <p>
      Each xVisor-user can update their own profile using the "User
      Profile" page (link can be found on the dropdown menu beside the user
      name on the top bar). Here, the xVisor-user can update name and phone
      information, and can also change password.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-users-profile-page.png"
        class="responsive-img-xs"
      />
    </p>
    <p>Fig. 25: Ennetix xVisor User Profile page.</p>
    <span id="2-11-1-reset-password" class="anchor"></span>
    <h4>11.1. Reset Password</h4>
    <p>
      An xVisor-user can reset their password (in case password is forgotten) for
      the xVisor portal using the login window (Fig. 3). When prompted, an email
      with instructions to reset the password will be sent to the xVisor-user's
      registered email address. xVisor-user can follow the instructions to reset the
      password.
    </p>
    <span id="2-12-status-page" class="anchor"></span>
    <h3>12. Status Page</h3>
    <p>
      The Status page gives the status of various data collectors (XOMEs) of Ennetix
      xVisor. Figure 26 shows the status of various XOMEs that are deployed in the
      network.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/webPortal/ennetix-service-page-xome-status.png"
        class="responsive-img-lg"
      />
    </p>
    <p>Fig. 26: Ennetix xVisor Services page - XOME status.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rootLink: window.location.hostname,
    };
  },
};
</script>

<style scoped>
.anchor {
  top: -75px;
  position: relative;
  z-index: 5;
}
</style>

<template>
  <div>
    <b-row>
      <b-col lg="4" md="12" sm="12">
        <h5>Total</h5>
        <TrafficProfile
          :url="urls.totalTraffUrl"
          :timeRange="timeRange"
          chartName="traffic-profiles-total"
        ></TrafficProfile>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <h5>Application</h5>
        <TrafficProfile
          :url="urls.traffByTopAppsUrl"
          :timeRange="timeRange"
          chartName="traffic-profiles-application"
        ></TrafficProfile>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <h5>Protocol</h5>
        <TrafficProfile
          :url="urls.traffByTopProtosUrl"
          :timeRange="timeRange"
          chartName="traffic-profiles-protocol"
        ></TrafficProfile>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12">
        <h5>Apps</h5>
        <FlowAnalysis
          :url="urls.appTraffTotalsUrl"
          :timeRange="timeRange"
        ></FlowAnalysis>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h5>Flows</h5>
        <SankeyEchart :url="urls.appFlowGraphUrl" :timeRange="timeRange"></SankeyEchart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FlowAnalysis from '@/xvisor/components/infra/traffic/FlowAnalysis.vue';
import SankeyEchart from '@/xvisor/components/infra/traffic/SankeyEchart.vue';
import TrafficProfile from '@/xvisor/components/infra/traffic/TrafficProfile.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
  components: {
    FlowAnalysis,
    SankeyEchart,
    TrafficProfile,
  },
};
</script>

<template>
  <b-button
    class="btn-icon"
    variant="flat"
    v-b-tooltip.hover.variant.bottom
    title="Delete"
    @click="httpDelete"
  >
    <b-icon icon="trash" />
  </b-button>
</template>

<script>
import SsidEventBus from '@/xvisor/bus/SsidEventBus';

export default {
  props: {
    ssid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteSsidUrl: '/policysettings/ssid',
    };
  },
  computed: {
    deleteSsidForm() {
      return { ssid: this.ssid };
    },
  },
  methods: {
    httpDelete() {
      this.$http
        .delete(this.deleteSsidUrl, { data: this.deleteSsidForm })
        .then(() => {
          this.$bvToast.toast('Successfully deleted SSID', { variant: 'success' });
          SsidEventBus.$emit('changed');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete SSID', { variant: 'danger' });
        });
    },
  },
};
</script>

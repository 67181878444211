<template>
  <b-card no-body class="mt-n2">
    <b-row class="mx-1 mt-1">
      <b-card
        v-if="hasAlertSignatureSelection"
        class="header-card"
      >
        <h4 class="mb-1">Alert Details</h4>
        <div class="details-row d-flex flex-row mt-2">
          <span>
            <b-icon :style="{ color: iconColors.blue}" class="mr-0-5" icon="info-square-fill"/>
            <b>Name:</b>
            {{ alertDetails.signature ? alertDetails.signature : 'N/A' }}
          </span>
          <span>
            <b-icon variant="success" class="mr-0-5" icon="tag-fill"/>
            <b>Category:</b>
            {{ alertDetails.category ? alertDetails.category : 'N/A' }}
          </span>
          <span>
            <b-icon variant="danger" class="mr-0-5" icon="exclamation-square-fill"/>
            <b>Severity:</b>
            {{ alertDetails.severityLevel ? severityMap[alertDetails.severityLevel] : 'N/A' }}
          </span>
          <span>
            <b-icon :style="{ color: iconColors.purple }" class="mr-0-5" icon="hash"/>
            <b>Rule ID:</b>
            {{ alertDetails.signatureId ? alertDetails.signatureId : 'N/A' }}
          </span>
        </div>
      </b-card>
      <h4 class="mb-1" v-else>No Alert Signature Selected</h4>
    </b-row>
    <b-row class="mx-1 mb-0-5" align-h="between" v-if="hasData">
      <b-col lg="8" md="8" sm="12">
        <b-row align-h="between" align-v="middle">
          <b-col lg="2" md="4" sm="12" class="mb-1 mt-0-5 d-flex align-items-center">
            <div class="form-select">
              Show
              <b-form-select
                value="10"
                v-on:change="getSelectedPageSize"
                :options="options"
                style="width: 60px;"
                size="sm"
              ></b-form-select>
              Entries
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" md="4" sm="12" class="mb-1 d-flex align-items-center justify-content-end">
        <b-row>
          <SearchBar :hasToolTip="false" :hasErrorMessage="true">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          v-if="hasData"
          id="alerts-table"
          class="table-with-actions-min-height px-2"
          responsive
          striped
          outlined
          :filter="filter"
          @filtered="onFiltered"
          :items="detailsFilter"
          :fields="computedTableFields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(@timestamp)="row">
            {{ formatDate(row.value) }}
          </template>
          <template #cell(totalBytes)="row">
            {{ formatBytes(row.value) }}
          </template>
        </b-table>
        <div v-else-if="hasAlertSignatureSelection && loaded" class="mx-1 pl-1 my-1">
          {{ $t("No Data Available") }}
        </div>
        <SpinnerCmpt v-else-if="hasAlertSignatureSelection && !loaded" class="mb-1"></SpinnerCmpt>
      </b-col>
    </b-row>
    <b-row v-if="hasData">
      <b-col>
          <h5
            class="mt-2 mx-1 text-right"
          >
            <span
              class="total-signatures-wrapper"
              v-b-tooltip.hover
              :title="getTotalRecordsTooltip"
            >
              Total Records: {{ getFilteredLength }}
            </span>
          </h5>
        </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="getFilteredLength"
          :per-page="perPage"
          align="right"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="my-2 mx-1"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import moment from 'moment';

import colorPalette from '@/xvisor/constants/colorPalette';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import readableBytes from '@/xvisor/utilities/readableBytes';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    selectedAlert: {
      type: Object,
      required: true,
    },
  },
  components: {
    SearchBar,
    SpinnerCmpt,
  },
  data() {
    return {
      loaded: false,
      currentPage: 1,
      iconColors: {
        blue: colorPalette.teal,
        purple: colorPalette.purple,
      },
      perPage: 10,
      details: [],
      filteredLength: null,
      filter: null,
      severityMap: {
        1: 'High',
        2: 'Medium',
        3: 'Low',
      },
      tableFields: [
        {
          key: '@timestamp',
          label: 'Time',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'sourceIp',
          label: 'Source IP',
        },
        {
          key: 'sourcePort',
          label: 'Source Port',
        },
        {
          key: 'destinationIp',
          label: 'Destination IP',
        },
        {
          key: 'destinationPort',
          label: 'Destination Port',
        },
        {
          key: 'proto',
          label: 'Proto',
        },
        {
          key: 'totalBytes',
          label: 'Total Bytes',
          sortable: true,
        },
        {
          key: 'totalPkts',
          label: 'Total Packets',
          sortable: true,
        },
      ],
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
    };
  },
  watch: {
    selectedAlert() {
      this.details = [];
      if (this.selectedAlert.length) {
        this.httpGet();
      }
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.details && this.details.length > 0;
    },
    url() {
      return `/alerts/suricata/signature/${this.selectedAlert[0].signatureId}/details.json`;
    },
    detailsFilter() {
      return this.details.map((item) => {
        const newItem = {
          ...item,
          totalBytes: item.numBytesClntToSrvr + item.numBytesSrvrToClnt,
          totalPkts: item.numPktsClntToSrvr + item.numPktsSrvrToClnt,
        };
        return newItem;
      });
    },
    getFilteredLength() {
      return this.filteredLength;
    },
    getTotalRecordsTooltip() {
      return 'Count of the most recent (up to 1000) records with this signature';
    },
    computedTableFields() {
      return this.tableFields;
    },
    selectionHeader() {
      if (!this.hasAlertSignatureSelection) return 'No Alert Signature Selected';
      return 'Details';
    },
    hasAlertSignatureSelection() {
      return this.selectedAlert !== null && this.selectedAlert !== undefined && this.selectedAlert.length;
    },
    alertDetails() {
      return this.selectedAlert[0];
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
  },
  methods: {
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
    formatBytes(bytes) {
      return readableBytes(bytes);
    },
    getSelectedPageSize(selected) {
      this.currentPage = 1;
      this.perPage = selected;
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, this.httpParams)
        .then((response) => {
          this.details = response.data;
          this.filteredLength = response.data.length;
        }).finally(() => { this.loaded = true; });
    },
  },
};
</script>
<style scoped>
.header-card {
  width: 100%;
}
.header-card>.card-body {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.details-row {
  gap: 5rem;
}
</style>

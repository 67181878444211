<template>
  <b-table striped hover :fields="fields" :items="ssidsFormatted">
    <template #cell(action)="row">
      <DeleteSsidIcon
        class="float-right"
        :ssid="row.item.ssid"
      ></DeleteSsidIcon>
    </template>
  </b-table>
</template>

<script>
import DeleteSsidIcon from '@/xvisor/components/policyCenter/ssid/DeleteSsidIcon.vue';
import SsidEventBus from '@/xvisor/bus/SsidEventBus';

export default {
  components: {
    DeleteSsidIcon,
  },
  data() {
    return {
      ssidListUrl: '/policysettings/ssid/list.json',
      ssids: [],
      fields: [
        { key: 'ssid', label: 'SSID' },
        { key: 'action', label: '' },
      ],
    };
  },
  mounted() {
    SsidEventBus.$on('changed', this.httpGet);
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.ssidListUrl)
        .then((response) => {
          this.ssids = response.data;
        });
    },
  },
  computed: {
    ssidsFormatted() {
      const formatted = this.ssids.map((ssid) => ({ ssid }));
      return formatted;
    },
  },
};
</script>

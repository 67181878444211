<template>
  <div v-if="hasData">
    <b-row class="mb-1" align-h="end">
      <b-col lg="6" md="6" sm="12">
        <SearchBar class="mr-1">
          <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <b-table
      :per-page="perPage"
      :current-page="currentPage"
      hover
      striped
      responsive
      primary-key="ip"
      :items="formattedServersData"
      :fields="fields"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template #cell(traffTsUrl)="data">
        <b-row>
          <b-col cols="4">
          <StackedBar
            :height="120"
            :width="150"
            :url="data.item.traffTsUrl"
            :timeRange="timeRange"
          ></StackedBar>
          </b-col>
          <b-col cols="8">
            <UserCountBubbleChart
              :url="data.item.userCountUrl"
              :timeRange="timeRange"
            ></UserCountBubbleChart>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      align="right"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      class="my-1"
    />
  </div>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else class="my-4"></SpinnerCmpt>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import StackedBar from '@/xvisor/components/StackedBarChart.vue';
import UserCountBubbleChart from '@/xvisor/components/app/UserCountBubbleChart.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    appId: {
      type: Number,
      required: true,
    },
    cmptId: {
      type: Number,
      required: true,
    },
  },
  components: {
    SearchBar,
    SpinnerCmpt,
    StackedBar,
    UserCountBubbleChart,
  },
  data() {
    return {
      loaded: false,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filteredLength: null,
      fields: [
        { key: 'ip', label: 'Device' },
        { key: 'traffTsUrl', label: 'Traffic Stats' },
      ],
      serversData: [],
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.formattedServersData && this.formattedServersData && this.formattedServersData.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.serversData.length;
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    formattedServersData() {
      const formattedServersData = [];
      this.serversData.forEach((serverData) => {
        formattedServersData.push({
          ip: serverData.ip,
          traffTsUrl: `/apps/${this.appId}/cmpts/${this.cmptId}/${serverData.ip}/traffts.json`,
          userCountUrl: `/apps/${this.appId}/cmpts/${this.cmptId}/${serverData.ip}/users/countts.json`,
        });
      });
      return formattedServersData;
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(`/apps/${this.appId}/cmpts/${this.cmptId}/srvrs/list.json`, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            zoneId: this.zoneId,
          },
        })
        .then((response) => { this.serversData = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

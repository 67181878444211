<template>
  <div>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.updateUser.show()"
      v-b-tooltip.hover.variant.bottom
      title="Edit"
    >
      <b-icon icon="pencil" />
    </b-button>
    <b-button
      class="btn-icon"
      variant="flat"
      v-if="resendInviteUrl !== undefined"
      @click="resendInvite"
      v-b-tooltip.hover.variant.bottom
      title="Resend Invitation"
    >
      <b-icon icon="envelope" />
    </b-button>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.deleteUser.show()"
      v-b-tooltip.hover.variant.bottom
      title="Delete"
    >
      <b-icon icon="trash" />
    </b-button>
    <b-modal ref="updateUser" title="Update User" @ok="updateUser">
      <b-form-checkbox v-model="updatedUser.superAdmin">Admin</b-form-checkbox>
    </b-modal>
    <b-modal ref="deleteUser" title="Delete User" @ok="deleteUser">
      Are you sure you want to delete this user?
      You cannot undo this action and it will result in all associated data being removed.
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    updateUrl: {
      type: String,
      required: true,
    },
    deleteUrl: {
      type: String,
      required: true,
    },
    resendInviteUrl: {
      type: String,
    },
    superAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      updatedUser: {
        superAdmin: this.superAdmin,
      },
    };
  },
  methods: {
    updateUser() {
      this.$http
        .post(this.updateUrl, this.updatedUser)
        .then(() => {
          this.$bvToast.toast('Successfully updated user', { title: 'Notice', variant: 'success' });
          this.$emit('update');
        })
        .catch(() => {
          this.$bvToast.toast('Update unsuccessful', { variant: 'danger' });
        });
    },
    deleteUser() {
      this.$http
        .delete(this.deleteUrl)
        .then(() => {
          this.$bvToast.toast('Successfully deleted user', { variant: 'success' });
          this.$emit('update');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete user', { variant: 'danger' });
        });
    },
    resendInvite() {
      this.$http
        .post(this.resendInviteUrl)
        .then(() => {
          this.$bvToast.toast('Invitation sent', { variant: 'success' });
        })
        .catch(() => {
          this.$bvToast.toast('Failed to send the invitation', { variant: 'danger' });
        });
    },
  },
};
</script>

<template>
  <b-modal ref="modal" title="Delete Office" @ok="deleteOffice">
    <p>
      Are you sure you want to delete office "{{ office.name }}"?
      You cannot undo this action and it will result in all associated data being removed.
    </p>
    <div>
      <p>Do you want to delete the zones within this office? </p>
      <b-form-checkbox v-model="deleteZones">Yes</b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
import ZonesEventBus from '@/xvisor/bus/ZonesEventBus';

export default {
  props: {
    office: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteZones: false,
    };
  },
  methods: {
    deleteOffice() {
      this.$http
        .delete(this.url, { data: { deleteZones: this.deleteZones } })
        .then(() => {
          this.$bvToast.toast('Successfully deleted office', { title: 'Notice', variant: 'success' });
          ZonesEventBus.$emit('refresh');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete office', { title: 'Notice', variant: 'danger' });
        });
    },
  },
};
</script>

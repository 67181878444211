<template>
  <e-chart
    v-if="loaded && hasData"
    :options="chartOptions"
    autoresize
    :style="`height: ${this.height}px;`"
  ></e-chart>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    height: {
      Number,
      default: 100,
    },
    chartId: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    issueTimeSelected: {
      type: Number,
    },
    domainObject: {
      type: Object,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      delayEvents: [],
      lossEvents: [],
      delayPoints: [],
      baselinePoints: [],
      lossPoints: [],
      loaded: false,
      asnAndOrgName: null,
    };
  },
  computed: {
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    initialMarkline() {
      // Initializes the line to the end of the graph.
      const seriesData = this.series.map((point) => [
        moment(point.time).valueOf(),
        point.delayTimeMs,
      ]);
      return seriesData.at(-1)[0];
    },
    hasData() {
      return this.series && this.series.length > 0;
    },
    timeSet() {
      return new Set(this.series.map((item) => item.time));
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formatData() {
      return this.delayPoints.map((ts, index) => ({
        time: ts.time,
        delayTimeMs: ts.value,
        delayBaselineTimeMs: this.baselinePoints[index].value,
        delayLoss: index < this.lossPoints.length ? this.lossPoints[index].value : 0,
      }));
    },
    chartOptions() {
      return {
        title: {
          text: this.asnAndOrgName.orgName,
          left: 40,
          textStyle: {
            color: this.toggleColor,
            fontSize: 12,
          },
        },
        color: [colorPaletteShade.teal4],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${info[0].marker} Delay: ${info[0].value[1].toFixed(1)} ms <br>
              ${info[1].marker} Delay baseline: ${info[1].value[1].toFixed(1)} ms <br>
              ${info[2].marker} Loss: ${info[2].value[1].toFixed(1)}% <br>
            </div>
          `,
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              show: false,
            },
          },
        },
        grid: [
          {
            left: 50,
            right: 50,
            top: 30,
            bottom: 30,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: 'Delay (ms)',
            nameLocation: 'center',
            nameGap: 30,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => `${value}`,
              fontSize: 9,
            },
          },
          {
            type: 'value',
            name: 'Loss (%)',
            nameLocation: 'center',
            nameGap: 25,
            min: 0,
            max: 100,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => `${value}`,
              fontSize: 9,
            },
          },
        ],
        series: [
          {
            name: 'Delay',
            type: 'line',
            showSymbol: false,
            yAxisIndex: 0,
            itemStyle: {
              color: this.findCololrOfDelayLine(),
            },
            data: this.series.map((point) => [
              moment(point.time).valueOf(),
              point.delayTimeMs,
            ]),
            markPoint: {
              symbol: 'triangle',
              symbolSize: 15,
              label: {
                offset: [0, 4],
                fontSize: 9,
              },
              itemStyle: {
                color: '#ffd966',
              },
              tooltip: {
                trigger: 'item',
                formatter: (info) => this.tooltipContent(info),
              },
              data: this.delayEvents ? this.markPointHelper(this.delayEvents) : [],
            },
          },
          {
            name: 'Delay baseline',
            type: 'line',
            showSymbol: false,
            yAxisIndex: 0,
            itemStyle: {
              color: colorPalette.orange,
            },
            lineStyle: {
              type: 'dashed',
            },
            data: this.series.map((point) => [
              moment(point.time).valueOf(),
              point.delayBaselineTimeMs,
            ]),
            markLine: {
              symbol: 'circle',
              lineStyle: {
                type: 'solid',
              },
              data: [{ xAxis: this.issueTimeSelected ? this.issueTimeSelected : this.initialMarkline }],
              label: {
                formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
              },
            },
          },
          {
            name: 'Loss',
            type: 'bar',
            barWidth: 2,
            yAxisIndex: 1,
            itemStyle: {
              color: colorPalette.red,
            },
            data: this.series.map((point) => {
              if (point.delayLoss === 0) {
                return [moment(point.time).valueOf(), 0];
              }
              return [
                moment(point.time).valueOf(),
                (point.delayLoss),
              ];
            }),
            markPoint: {
              symbol: 'triangle',
              symbolSize: 15,
              label: {
                offset: [0, 4],
                fontSize: 9,
              },
              itemStyle: {
                color: 'orange',
              },
              tooltip: {
                trigger: 'item',
                formatter: (info) => this.tooltipContent(info),
              },
              data: this.lossEvents ? this.markPointHelper(this.lossEvents) : [],
            },
          },
        ],
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    domainObject() {
      this.httpGet();
      this.getDomainValues();
    },
  },
  mounted() {
    this.httpGet();
    this.getDomainValues();
  },
  methods: {
    markPointHelper(events) {
      return events.map((event) => ({
        xAxis: event.time,
        yAxis: event.value ? event.value : 0,

        // There will always be 1 event for each marker.
        value: 1,
        baseline: event.baseline ? event.baseline : 0,
        name: event.name,
        msg: event.msg,
      }));
    },
    tooltipContent(info) {
      const cell = info.data;
      const header = `
        <div class="apache-echarts-tooltip">
          <div>
            ${info.marker} ${info.name}
          </div>
      `;
      const eventsRow = `<div># Events: ${cell.value > 0 ? cell.value.toFixed(0) : 0}</div>`;
      let eventMsgs = '';
      eventMsgs = `
        <div> ${cell.name}: ${cell.msg}.</div>
      `;
      return `${[header, eventsRow, ...eventMsgs].join('')} </div>`;
    },
    findCololrOfDelayLine() {
      const delayAsn = parseInt(this.domainObject.asnNumber, 10);
      let delayLineColor = colorPaletteShade.blue4;
      if (delayAsn > 10000) {
        delayLineColor = colorPaletteShade.yellow4;
      } else if (delayAsn > 0) {
        delayLineColor = colorPaletteShade.purple4;
      }
      return delayLineColor;
    },
    getDomainValues() {
      this.delayPoints = this.domainObject.points;
      this.baselinePoints = this.domainObject.baseline;
      this.lossPoints = this.domainObject.loss;
      this.series = this.formatData;
    },
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(`/asns/${this.domainObject.asnNumber}/orgname.json`),
          this.$http.get(
            `/probes/${this.targetId}/event/${this.domainObject.asnNumber}/domaindelay.json`, this.httpParams,
          ),
          this.$http.get(
            `/probes/${this.targetId}/event/${this.domainObject.asnNumber}/domainavgloss.json`, this.httpParams,
          ),
        ])
        .then(axios.spread((orgNameRes, delayEventRes, lossEventRes) => {
          this.asnAndOrgName = orgNameRes.data;
          this.delayEvents = delayEventRes.data;
          this.lossEvents = lossEventRes.data;
        }))
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

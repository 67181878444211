<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="apache-echarts-default-graph"
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import readableBytes from '@/xvisor/utilities/readableBytes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    cmptId: {
      type: String,
      required: false,
    },
    userIp: {
      type: String,
      required: false,
    },
    issueTimeSelected: {
      type: Number,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    allTimes() {
      return this.series.map((item) => item.transCounts.map((obj) => obj.time));
    },
    timeSet() {
      return new Set(...this.allTimes);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    initialMarkline() {
      // Initializes the line to the end of the graph.
      const seriesData = this.series
        .map((item) => ({
          data: item.transCounts.map((point) => {
            const obj = {
              name: item.name,
              transCount: point.transCount,
            };
            return [point.time, point.transCount, obj];
          }),
        }));
      return seriesData[0].data.at(-1)[0];
    },
    computedSeries() {
      return this.series.map((item) => ({
        name: item.name,
        type: 'bar',
        stack: 'bar',
        emphasis: {
          focus: 'series',
        },
        markLine: {
          symbol: 'circle',
          lineStyle: {
            type: 'solid',
          },
          data: [{ xAxis: this.issueTimeSelected ? this.issueTimeSelected : this.initialMarkline }],
          label: {
            formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
          },
        },
        data: item.transCounts.map(((point) => {
          const obj = {
            name: item.name,
            transCount: point.transCount,
          };
          return [point.time, point.transCount, obj];
        })),
      }));
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.teal4, colorPaletteShade.blue4,
          colorPaletteShade.indigo5, colorPaletteShade.purple5],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: 10,
              opacity: 0.2,
            },
          },
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              <div class="apache-echarts-tooltip-timestamp">
                ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              </div>
              ${this.tooltipContent(info)}
            </div>
          `,
        },
        grid: {
          top: 30,
          bottom: 30,
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          min: this.allTimes[0][0],
          max: this.allTimes[0][this.allTimes[0].length - 1],
          offset: 7,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          show: true,
          name: 'Count',
          nameLocation: 'center',
          nameGap: 35,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            fontSize: 9,
          },
          splitLine: {
            show: true,
          },
        },
        label: {
          show: false,
        },
        series: this.computedSeries,
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            cmptid: this.cmptId,
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
            appuser: this.userIp,
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    tooltipContent(info) {
      let tooltipMessage = '';
      for (let i = 0; i < info.length; i += 1) {
        tooltipMessage += `
            ${info[i].marker} <b>${info[i].value[2].name}</b>
            <br>
            Total Transactions: ${info[i].value[2].transCount}
            <br>
        `;
      }
      return tooltipMessage;
    },
    readableBytes,
  },
};
</script>

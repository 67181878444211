<template>
  <div>
    <div v-if="hasData" class="text-nowrap">
      <b-icon
        v-if="zoneInfo.cloudProvider == null"
        :icon="getProviderIcon()"
        class="text-primary"
      ></b-icon>
      <b-img v-else :src="getProviderIcon()" class="provider-icon-height"></b-img>
      <span v-if="zoneInfo.name.length > textWidth" v-b-tooltip.hover :title="zoneInfo.name">
        {{ zoneInfo.name }}
      </span>
      <span v-else>{{ zoneInfo.name }}</span>
    </div>
    <div v-else-if="!hasData"> <span style="color: red;">&#10005;</span>
      {{ $t("None") }}
    </div>
  </div>
</template>

<script>
import awsIcon from '@/assets/images/serviceIcons/awsIcon.png';
import azureIcon from '@/assets/images/serviceIcons/azureIcon.png';
import gcpIcon from '@/assets/images/serviceIcons/gcpIcon.png';

export default {
  props: {
    zoneInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      textWidth: 8,
    };
  },
  computed: {
    hasData() {
      return this.zoneInfo && this.zoneInfo.name !== '';
    },
  },
  methods: {
    getProviderIcon() {
      if (this.zoneInfo.cloudProvider === 'aws') {
        return awsIcon;
      } if (this.zoneInfo.cloudProvider === 'azure') {
        return azureIcon;
      } if (this.zoneInfo.cloudProvider === 'gcp') {
        return gcpIcon;
      } if (this.zoneInfo.cloudProvider == null) {
        return 'building';
      }
      return '';
    },
  },
};
</script>

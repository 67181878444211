<template>
  <div>
    <b-row align-h="between" align-v="center">
      <b-col cols="12" md="3">
        <h2>{{ $t('Infrastructure Elements') }}</h2>
      </b-col>
      <b-col cols="12" md="4">
        <SearchBar>
          <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
      <b-col cols="12" md="5">
        <TimeRangeSelector
          :minDate="minDate"
          :maxDate="maxDate"
          :initRange="timeRange"
          @change-time-range="updateStateTimeRange"
          class="my-1"
        ></TimeRangeSelector>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Table View" active>
        <InfraTable
          :timeRange="timeRange"
          :filter="filter"
          @infra-click="navToInfraPage"
        ></InfraTable>
        <FirewallTable
          :filter="filter"
          :timeRange="timeRange"
          @firewall-click="navToFirewallPage"
        ></FirewallTable>
        <XtendTable :timeRange="timeRange"></XtendTable>
      </b-tab>
      <b-tab title="Map View">
        <InfraAdjacencyGraphWrapper
          :urls="infraAdjacencyUrls"
          :timeRange="timeRange"
          @infra-click="navToInfraPage"
        ></InfraAdjacencyGraphWrapper>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import moment from 'moment';

import InfraAdjacencyGraphWrapper from '@/xvisor/components/infra/InfraAdjacencyGraphWrapper.vue';
import InfraTable from '@/xvisor/components/infra/InfraTable.vue';
import FirewallTable from '@/xvisor/components/infra/FirewallTable.vue';
import XtendTable from '@/xvisor/components/infra/XtendTable.vue';
import SearchBar from '@/xvisor/components/SearchBar.vue';
import TimeRangeSelector from '@/xvisor/components/TimeRangeSelector.vue';
import dateCeil from '@/xvisor/utilities/dateCeil';

export default {
  components: {
    InfraAdjacencyGraphWrapper,
    InfraTable,
    FirewallTable,
    SearchBar,
    TimeRangeSelector,
    XtendTable,
  },
  data() {
    const now = dateCeil(new Date());
    return {
      maxDate: now,
      minDate: moment(now).subtract(1, 'month').toDate(),

      /** The time range for all components on the page. */
      timeRange: this.$store.state.app.timeRange,
      filter: '',
    };
  },
  computed: {
    infraAdjacencyUrls() {
      return {
        graphUrl: '/infra/adjacencygraph/layout.json',
        infraZoneUrl: '/infra/adjacencygraph/zone.json',
        infraElementsUrl: '/infra/elements.json',
      };
    },
  },
  methods: {
    updateStateTimeRange(timeRange) {
      this.$store.commit('app/UPDATE_TIME_RANGE', timeRange);
      this.timeRange = timeRange;
    },
    navToInfraPage(infraElem) {
      // NOTE: May need to choose field other than IP depending on infra type.
      // Certain devices may not reliably have a unique and consistent IP.
      const path = `/infra/${infraElem.type}/${infraElem.ip}`;
      this.$router.push({ path });
    },
    navToFirewallPage(infraId) {
      const path = `/infra/firewall/${infraId}`;
      this.$router.push({ path });
    },
  },
};
</script>

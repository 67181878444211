<template>
  <div>
    <h2 class="mb-2">{{ $t('Integrations') }}</h2>
    <IntegrationsTable :url="integrationsUrl"></IntegrationsTable>
    <AvailableIntegrations></AvailableIntegrations>
  </div>
</template>

<script>
import AvailableIntegrations from '@/xvisor/components/integrations/AvailableIntegrations.vue';
import IntegrationsTable from '@/xvisor/components/integrations/IntegrationsTable.vue';

export default {
  data() {
    return {
      integrationsUrl: '/integrations/list.json',
      usersUrl: '/user/list.json',
    };
  },
  components: {
    AvailableIntegrations,
    IntegrationsTable,
  },
};
</script>

<template>
  <div v-if="hasData" class="mr-2 mt-n2">
    <b-list-group v-if="insights.length > 0">
      <h5 class="text-warning" style="box-shadow: 0 3px 0; padding: 5px 0px;">Insights</h5>
      <b-list-group-item
        class="d-flex align-items-center"
        style="box-shadow: 0 1px #333d59; padding: 5px 0px;"
        v-for="(insight, index) in insights.slice(start, end)"
        :key="index"
      >
        <b-icon icon="shield" class="mx-1" />
        {{ insight.text }}
      </b-list-group-item>
    </b-list-group>
    <b-pagination
      v-if="insights.length > maxItems"
      :total-rows="insights.length"
      :per-page="maxItems"
      v-model="currentPage"
      align="right"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      class="my-1"
    ></b-pagination>
  </div>
  <div v-else-if="loaded"></div>
  <SpinnerCmpt v-else class="my-2"></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      currentPage: 1,
      loaded: false,
      maxItems: 4,
      insights: [],
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.insights && this.insights.length > 0;
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    start() { return (this.currentPage - 1) * this.maxItems; },
    end() { return ((this.currentPage - 1) * this.maxItems) + this.maxItems; },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            zoneId: this.zoneId,
          },
        })
        .then((response) => { this.insights = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

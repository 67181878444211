<template>
  <div>
    <b-row class="mx-0-5 my-0-5">
      <h2>XOME Control</h2>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <h4>Command Input</h4>
          <b-row class="mx-1 my-3 selection-row">
            <p><b>Choose a XOME:</b></p>
            <Multiselect
              class="cmd-select"
              v-model="selectedXome"
              :options="xomeList"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              placeholder="Available XOMEs"
              track-by="id"
              label="name"
              >
            </Multiselect>
          </b-row>
          <b-row class="mx-1 my-3 selection-row">
            <p><b>Choose Command(s): </b></p>
            <Multiselect
              class="cmd-select"
              v-model="selectedCommands"
              :options="defaultCommands"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              placeholder="Default Commands"
              :disabled="!xomeIsSelected"
              @select="onCustomCommandSelect"
              @remove="onCustomCommandDeselect"
            >
            </Multiselect>
          </b-row>
          <b-row
            v-if="renderCustomCommandInput"
            class="mx-1 mt-3 selection-row left"
          >
            <div class="d-flex flex-column">
              <label for="custom-command">Multiple commands should be comma separated.</label>
              <b-form-input
                style="width: 500px"
                id="custom-command"
                v-model="customCommandText"
                type="text"
                placeholder="ps aux,ifconfig,df"
              />
            </div>
          </b-row>
          <b-row class="mx-1 mt-3 selection-row center">
            <b-button
              @click="submitXomeCmds"
              :disabled="!commandsSelected"
            >
              Submit
            </b-button>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-3">Command Output</h4>
          <h1 v-if="showGettingCommandsResults">
            <SpinnerCmpt></SpinnerCmpt>
          </h1>
          <b-row
            v-else-if="hasCommandsResults"
            class="d-flex flex-column align-items-center"
          >
            <pre
              id="command-results-box"
              class="p-1 mb-3 results-box"
            >
              {{ commandResultsString }}
            </pre>
            <b-button
              style="width:150px;"
              @click="copyCommandResults"
            >
              Copy Results
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  components: {
    Multiselect,
    SpinnerCmpt,
  },
  data() {
    return {
      getXomeListUrl: '/xomes/idsnames.json',
      selectedCommands: [],
      defaultCommands: [
        'ps aux',
        'ifconfig',
        'df',
        'lsof -i TCP -i UDP',
        'uptime',
        'timeout 5 tcpdump -c 100 -i any',
        'Custom Command',
      ],
      xomeList: [],
      selectedXome: null,
      showCustomCommandInput: false,
      customCommandText: '',
      loopForCommandsResults: false,
      commandResultsString: '',
    };
  },
  mounted() {
    this.httpGetXomesList();
  },
  computed: {
    getSelectedXomeName() {
      return this.selectedXome.name;
    },
    xomeIsSelected() {
      return this.selectedXome !== null;
    },
    renderCustomCommandInput() {
      return this.showCustomCommandInput === true;
    },
    commandsSelected() {
      return this.selectedCommands.length > 0;
    },
    showGettingCommandsResults() {
      return this.loopForCommandsResults === true;
    },
    hasCommandsResults() {
      return this.commandResultsString.length > 0;
    },
  },
  methods: {
    httpGetXomesList() {
      this.$http.get(this.getXomeListUrl).then((response) => {
        this.xomeList = response.data[0].idsNames;
      });
    },
    onCustomCommandSelect(command) {
      if (command === 'Custom Command') {
        this.customCommandText = '';
        this.showCustomCommandInput = true;
      }
    },
    onCustomCommandDeselect(command) {
      if (command === 'Custom Command') {
        this.customCommandText = '';
        this.showCustomCommandInput = false;
      }
    },
    getSubmitXomeCmdsUrl() {
      return `/admin/xomes/${this.selectedXome.id}/submitcmds`;
    },
    getXomeCmdsResultsUrl() {
      return `/admin/xomes/${this.selectedXome.id}/getcmdsresults`;
    },
    getCommandsList() {
      const commands = this.selectedCommands.filter((el) => el !== 'Custom Command');
      return {
        commands: this.customCommandText.length
          ? commands.concat(this.customCommandText.split(','))
          : commands,
      };
    },
    copyCommandResults() {
      navigator.clipboard.writeText(this.commandResultsString);
      this.$bvToast.toast('Copied text to clipboard', { variant: 'success' });
    },
    submitXomeCmds() {
      this.$http
        .post(this.getSubmitXomeCmdsUrl(), this.getCommandsList())
        .then(() => {
          this.$bvToast.toast('XOME Commands Submitted', { variant: 'success' });
          this.loopForCommandsResults = true;
          this.commandResultsString = '';
        }).finally(() => {
          (async () => {
            while (this.loopForCommandsResults) {
              // eslint-disable-next-line
              await new Promise((r) => {
                this.$http
                  .get(this.getXomeCmdsResultsUrl())
                  .then((response) => {
                    if (response.data.length) {
                      response.data.forEach((obj) => {
                        this.commandResultsString += `\n<----- RESULT START ID: ${obj.id} ----->\n\n`;
                        this.commandResultsString += obj.output;
                        this.commandResultsString += `\n<-----RESULT END ID: ${obj.id} ----->\n`;
                      });
                      this.loopForCommandsResults = false;
                    }
                  });
                setTimeout(r, 5000);
              });
            }
          })();
        });
    },
  },
};
</script>
<style scoped>
.selection-row{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: baseline;
}
.selection-row.center{
  justify-content: center;
}
.selection-row.left{
  justify-content: start;
}
.cmd-select{
  width: 400px;
}
.results-box {
  max-width: 800px;
  max-height: 200px;
  overflow-y: scroll;
}
</style>

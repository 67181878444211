export const reportTypes = {
  topN: 'Top-N',
  devices: 'Devices',
  xtendPackages: 'Xtend Installed Packages',
};
export const primaryEntityTypes = {
  applications: 'applications',
  infraElements: 'infra-elements',
  users: 'users',
  servers: 'servers',
};
export const reportInfo = [
  {
    category: 'Application and User Analytics',
    isActive: false,
    description: `
      <h4>Application and User Analytics</h4>
      <b>When to use?</b>
      <p>This set of reports provides digital experience and user productivity analytics.</p>
      <b>Who should use?</b>
      <p>IT Operations, Application Admins, CXO, Business Owner, etc.</p>
      <b>Who should use?</b>
      <p>DevOps, Operations, SRE, Developer, QA, Support, Business owner.</p>
      <b>Tips for using</b>
      <ul>
        <li>Make sure XOME is collecting flow and/or wire data.</li>
        <li>For some reports, endpoint data (using xTend) may be needed.</li>
      </ul>
    `,
    types: [
      {
        name: 'Top Application Servers',
        isActive: false,
        type: reportTypes.topN,
        primaryEntityType: primaryEntityTypes.servers,
        description: `
          <h4>Top Application Servers</h4>
          <b>When to use?</b>
          <p>To report a list of top application servers by usage volume.</p>
          <b>Who should use?</b>
          <p>IT Operations, Network Admins, Applications Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>
            <li>Make sure XOME is collecting flow and/or wire data. </li>
          </ul>
        `,
      },
      {
        name: 'Top Application Usage',
        isActive: false,
        type: reportTypes.topN,
        primaryEntityType: primaryEntityTypes.applications,
        description: `
          <h4>Top Application Usage</h4>
          <b>When to use?</b>
          <p>To report a list of top applications by usage volume.</p>
          <b>Who should use?</b>
          <p>IT Operations, Network Admins, Applications Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>
            <li>Make sure XOME is collecting flow and/or wire data. </li>
          </ul>
        `,
      },
      {
        name: 'Top Users',
        isActive: false,
        type: reportTypes.topN,
        primaryEntityType: primaryEntityTypes.users,
        description: `
          <h4>Top Users</h4>
          <b>When to use?</b>
          <p>To report a list of top users by usage volume. </p>
          <b>Who should use?</b>
          <p>IT Operations, Network Admins, Applications Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>configStep
          <b>When to use?</b>
          <p>To report a list of top applications servers by usage volume.</p>
          <b>Who should use?</b>
          <p>IT Operations, Applications Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>
            <li>Make sure XOME is collecting flow and/or wire data.</li>
          </ul>
        `,
      },
      {
        name: 'Top Devices',
        isActive: false,
        type: reportTypes.devices,
        primaryEntityType: null,
        description: `
          <h4>Top Devices</h4>
          <b>When to use?</b>
          <p>To report a list of all applications/software on the devices.</p>
          <b>Who should use?</b>
          <p>Security Operations, Security Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>
            <li>Make sure xTends are deployed on end devices
            (such as laptops, desktops, servers, etc.) and are collecting data.
            </li>
          </ul>
        `,
      },
    ],
  },
  {
    category: 'Network and Infra Analytics',
    isActive: false,
    description: `
      <h4>Network and Infra Analytics</h4>
      <b>When to use?</b>
      <p>This set of reports provides analytics on network and other infrastructure nodes.</p>
      <b>Who should use?</b>
      <p>IT Operations, Network Admins, Infra Admins, CXO, Business Owner, etc. </p>
      <b>Who should use?</b>
      <p>DevOps, Operations, SRE, Developer, QA, Support, Business owner.</p>
      <b>Tips for using</b>
      <ul>
        <li>Make sure XOME is collecting network and infrastructure data.</li>
      </ul>
    `,
    types: [
      {
        name: 'Top Infra Elements',
        isActive: false,
        type: reportTypes.topN,
        primaryEntityType: primaryEntityTypes.infraElements,
        description: `
          <h4>Top Infra Elements</h4>
          <b>When to use?</b>
          <p>To report a list of top infra elements.</p>
          <b>Who should use?</b>
          <p>Security Operations, Security Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>
            <li>Make sure xTends are deployed on end devices
            (such as laptops, desktops, servers, etc.) and are collecting data.
            </li>
          </ul>
        `,
      },
    ],
  },
  {
    category: 'Security and Threat Analytics',
    isActive: false,
    description: `
      <h4>Security and Threat Analytics</h4>
      <b>When to use?</b>
      <p>This set of reports provides security and threat analytics of IT infrastructure.</p>
      <b>Who should use?</b>
      <p>Security admins, CXO, Business Owner, etc.</p>
      <b>Tips for using</b>
      <ul>
        <li>Make sure XOME is collecting flow and/or wire data.</li>
        <li>For some reports, endpoint data (using xTend) may be needed.</li>
      </ul>
    `,
    types: [
      {
        name: 'Installed Packages/Software',
        isActive: false,
        type: reportTypes.xtendPackages,
        primaryEntityType: null,
        description: `
          <h4>Installed Packages/Software</h4>
          <b>When to use?</b>
          <p>To report a list of all installed packages and software.</p>
          <b>Who should use?</b>
          <p>Security Operations, Security Admins, Infra Admins, CXO, Business Owner, etc.</p>
          <b>Tips for using</b>
          <ul>
            <li>Make sure xTends are deployed on end devices
            (such as laptops, desktops, servers, etc.) and are collecting data.
            </li>
          </ul>
        `,
      },
    ],
  },
];
export const reportStatuses = {
  ready: 'ready',
  pending: 'pending',
};

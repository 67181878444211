<template>
  <b-tabs v-model="activeTab" card end lazy nav-wrapper-class="sticky-navbar">
    <b-tab v-if="!appInfo.isEndpointBased" :title="tabs.overview.name">
      <OverviewTabs
        :urls="urls.depTabUrls"
        :timeRange="timeRange"
        @zone-node-click="handleZoneNodeEvent"
        @app-cmpt-node-click="handleAppCmptNodeEvent"
        :appId="appId"
      ></OverviewTabs>
    </b-tab>
    <b-tab v-if="!appInfo.isEndpointBased" :title="tabs.users.name">
      <UserTab
        :monAppId="urls.monAppId"
        :url="urls.usersListUrl"
        :countUrl="urls.usersCountUrl"
        :timeRange="timeRange"
      >
      </UserTab>
    </b-tab>
    <b-tab v-if="!appInfo.isEndpointBased" :title="tabs.trans.name">
      <TransactionTab :appCmptList="appCmptListFormatted" :timeRange="timeRange"></TransactionTab>
    </b-tab>
    <b-tab v-if="!appInfo.isEndpointBased" :title="tabs.flows.name">
      <FlowCmptTabs
        :appCmptList="appCmptListFormatted"
        :timeRange="timeRange"
        :shortTimeRange="shortTimeRange"
        :monAppId="appId"
      ></FlowCmptTabs>
    </b-tab>
    <b-tab title="Network">
      <NetworkTab
        :urls="urls.networkTabUrls"
        :timeRange="timeRange"
        :shortTimeRange="shortTimeRange"
        :isEndPointBasedApp="appInfo.isEndpointBased"
        :alertId="alertId"
      ></NetworkTab>
    </b-tab>
  </b-tabs>
</template>

<script>
import OverviewTabs from '@/xvisor/components/app/overviewTab/OverviewTabs.vue';
import UserTab from '@/xvisor/components/app/userTab/UserTab.vue';
import FlowCmptTabs from '@/xvisor/components/app/flowsTab/FlowCmptTabs.vue';
import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import NetworkTab from '@/xvisor/components/app/networkTab/NetworkTab.vue';
import ProbesEventBus from '@/xvisor/bus/ProbesEventBus';
import TransactionTab from '@/xvisor/components/app/transactionsTab/TransactionTab.vue';
import UsersEventBus from '@/xvisor/bus/UsersEventBus';

export default {
  props: {
    appInfo: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
    alertId: {
      type: String,
    },
    urls: {
      type: Object,
      required: true,
    },
    userIp: {
      type: String,
    },
  },
  components: {
    OverviewTabs,
    FlowCmptTabs,
    TransactionTab,
    NetworkTab,
    UserTab,
  },
  data() {
    return {
      selectedIps: [],
      appCmptList: [],
      activeTab: this.userIp ? 1 : 0,
    };
  },
  mounted() {
    this.httpGet();
    IssueTimeBarEventBus.$on('issue-troubleshooting-focus-tab', this.updateCurrentTab);
    UsersEventBus.$on('set-user-tab-ips', (ips) => {
      this.setIps(ips);
      this.activeTab = this.tabs.users.index;
    });
  },
  computed: {
    appId() {
      return parseInt(this.$route.params.id, 10);
    },
    appCmptListUrl() {
      return `/apps/${this.appId}/cmpts/list.json`;
    },
    appCmptListFormatted() {
      return this.appCmptList && this.appCmptList.length
        ? this.appCmptList.map((cmpt) => ({
          name: cmpt.name,
          cmptId: cmpt.cmptId,
          appId: this.appId,
          userCmptFlowUrl: `/apps/${this.appId}/cmpts/${cmpt.cmptId}/flows/usercmptgraph.json`,
          flowsInsightsUrl: `/apps/${this.appId}/cmpts/${cmpt.cmptId}/flows/insights.json`,
          transDataUrl: `/apps/${this.appId}/transcountts.json`,
          transCountUrl: `/apps/${this.appId}/cmpts/${cmpt.cmptId}/trans/typecountts.json`,
          transInsightsUrl: `/apps/${this.appId}/cmpts/${cmpt.cmptId}/trans/insights.json`,
          flowEventCountTsUrl: `/apps/${this.appId}/cmpts/${cmpt.cmptId}/flows/events/countts.json`,
        })) : [];
    },
    tabs() {
      return this.appInfo.isEndptBased
        ? { network: { name: 'Network', index: 0 } }
        : {
          overview: { name: 'Overview', index: 0 },
          users: { name: 'Users/Devices', index: 1 },
          trans: { name: 'Transactions', index: 2 },
          flows: { name: 'Flows', index: 3 },
          network: { name: 'Network', index: 4 },
        };
    },
  },
  methods: {
    setIps(ips) {
      this.selectedIps = ips;
    },
    updateCurrentTab(tabName) {
      if (tabName === 'Network') ProbesEventBus.$emit('update-probes-table');
      Object
        .keys(this.tabs)
        .forEach((key) => {
          if (this.tabs[key].name === tabName) this.activeTab = this.tabs[key].index;
        });
    },
    handleZoneNodeEvent(zoneInfo) {
      if (this.appInfo.isEndptBased) return;
      this.usersListZoneInfo = zoneInfo;
      this.activeTab = this.tabs.users.index;
    },
    handleAppCmptNodeEvent() {
      if (this.appInfo.isEndptBased) return;
      this.activeTab = this.tabs.flows.index;
    },
    httpGet() {
      this.$http
        .get(this.appCmptListUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.appCmptList = response.data; });
    },
  },
};
</script>

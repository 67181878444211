<template>
  <b-card>
    <b-button size="sm" variant="secondary" href="/kb/xome-configuration" class="mb-1">
      <b-icon icon="link"></b-icon> Configuration Guide
    </b-button>
    <b-tabs lazy>
      <b-tab v-for="xome in xomesXtends" :key="xome.id" :title="xome.name">
        <XourcesTable :xomeName="xome.name" :xomeId="xome.id"></XourcesTable>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import XourcesTable from '@/xvisor/components/settings/xources/XourcesTable.vue';

export default {
  data() {
    return {
      xomes: [],
    };
  },
  components: {
    XourcesTable,
  },
  computed: {
    xomesXtends() {
      // TODO: Remove this computed field when the front-end reflect two separate configurations for XOMEs and xTends.
      return this.xomes.map((agentType) => agentType.idsNames).flat().filter((object) => object != null);
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get('/xomes/idsnames.json')
        .then((response) => { this.xomes = response.data; })
        .catch(() => {});
    },
  },
};
</script>

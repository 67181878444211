<template>
  <e-chart
    autoresize
    v-if="hasData"
    class="issue-time-bar"
    :options="chartOptions"
    :style="{ height: echartCanvasHeight + 'px' }"
    @click="getDataIndex"
  >
  </e-chart>
  <div v-else-if="loaded" class="d-flex justify-content-center p-2 mt-1">No Data Available</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import alertSeverities from '@/xvisor/constants/alertSeverities';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

const BAR_HEIGHT = 5;

export default {
  props: {
    alertUrl: {
      type: String,
      required: true,
    },
    eventUrl: {
      type: String,
    },
    height: {
      type: Number,
      default: 30,
    },
    appUserId: {
      type: Number,
    },
    layer: {
      type: Number,
    },
    severity: {
      type: Number,
      default: alertSeverities.notice,
    },
    isUserTab: {
      type: Boolean,
      default: false,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      alertData: [],
      eventData: [],
      loaded: false,
      selectedTime: 'max',
      echartCanvasHeight: 80,
    };
  },

  computed: {
    hasData() {
      return this.loaded && (this.alertData.length > 0 || this.eventData.length > 0);
    },
    chartMarkerPosition() {
      const yAxisOffset = 10;
      const yAxisCentre = this.echartCanvasHeight / 2;
      return yAxisCentre - yAxisOffset + this.height + BAR_HEIGHT;
    },
    zoneId() {
      return this.$store ? this.$store.state.zoneId : undefined;
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
          zoneId: this.zoneId,
        },
      };
    },
    chartOptions() {
      const defaultYAxis = {
        type: 'value',
        min: 0,
        max: this.eventData ? this.eventTotalsMax : BAR_HEIGHT,
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      };
      return {
        grid: {
          show: false,
          height: this.height,
          top: 30,
          left: 15,
          right: 10,
        },
        xAxis: {
          type: 'time',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          defaultYAxis,
          defaultYAxis,
          defaultYAxis,
          defaultYAxis,
          defaultYAxis,
        ],
        color: [
          colorPalette.green,
          colorPalette.blue,
          colorPalette.orange,
          colorPalette.red,
          colorPalette.purple,
        ],
        series: this.series,
        tooltip: {
          show: true,
          position: ['45%', 80, 0, 0],
          formatter: (info) => this.toolTipFormat(info.dataIndex),
        },
      };
    },
    series() {
      const issueSeries = [
        this.getNoCount,
        this.getNoticeCount,
        this.getWarningCount,
        this.getCriticalCount,
      ].map((fn) => {
        const dataArr = this.alertData.map((alert) => [
          new Date(alert.time).valueOf(),
          fn(alert),
        ]);
        return {
          name: '',
          type: 'bar',
          stack: 'alert',
          barCategoryGap: '10%',
          markPoint: {
            symbol: 'triangle',
            symbolSize: 12,
            itemStyle: {
              color: 'white',
              borderColor: colorPaletteShade.gray8,
              borderWidth: 1,
            },
            tooltip: {
              trigger: 'item',
            },
            data: [{ xAxis: this.selectedTime, y: this.chartMarkerPosition }],
          },
          data: dataArr,
        };
      });
      if (this.eventData && this.eventData.length > 0) {
        issueSeries.push({
          name: '',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 3,
          },
          data: this.eventData.map((event) => [
            new Date(event.time).valueOf(),
            event.types.reduce((total, type) => type.count + total, 0),
          ]),
        });
      }
      return issueSeries;
    },
    /** Combines the counts together and ensures there is a data point for each time in the interval. */
    combinedData() {
      if (this.alertData) this.augmentAlertData(this.alertData);
      if (this.eventData) this.augmentEventData(this.eventData);
      const combineData = [];
      this.alertData.forEach((timeBucket, index) => {
        const timeEvent = { time: timeBucket.time, endTime: timeBucket.endTime };
        if (timeBucket.types.length > 0) {
          timeEvent.alert = timeBucket;
        }
        if (this.eventData.length > 0) {
          if (this.eventData[index].types.length > 0) timeEvent.event = this.eventData[index];
        }
        combineData.push(timeEvent);
      });
      return combineData;
    },

    /** The max total event count out of the event totals at each time bucket. */
    eventTotalsMax() {
      return this.eventData.length > 0
        ? Math.max(...this.eventData.map((event) => event.types.reduce((total, type) => type.count + total, 0)), 1)
        : undefined;
    },
    barHeight() {
      return this.eventData.length >= 1 ? this.eventTotalsMax : BAR_HEIGHT;
    },
  },

  mounted() {
    this.httpGet();
  },

  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },

  methods: {
    /** Returns true if the alert count has some alerts of the given severity, false otherwise. */
    hasSeverity(alert, severity) {
      return alert.types.reduce((total, type) => total + type[severity], 0) > 0;
    },
    /* Check if current time bucket has no alerts. */
    getNoCount(alert) {
      return !this.hasSeverity(alert, 'noticeCount')
        && !this.hasSeverity(alert, 'warningCount')
        && !this.hasSeverity(alert, 'criticalCount')
        ? this.barHeight
        : 0;
    },
    /* Check if current time bucket has only Notice alerts. */
    getNoticeCount(alert) {
      return this.hasSeverity(alert, 'noticeCount')
        && !this.hasSeverity(alert, 'warningCount')
        && !this.hasSeverity(alert, 'criticalCount')
        ? this.barHeight
        : 0;
    },
    /* Check if current time bucket has Warning alerts. */
    getWarningCount(alert) {
      return this.hasSeverity(alert, 'warningCount')
        && !this.hasSeverity(alert, 'criticalCount') ? this.barHeight : 0;
    },
    /* Check if current time bucket has only Critical alerts. */
    getCriticalCount(alert) {
      return this.hasSeverity(alert, 'criticalCount') ? this.barHeight : 0;
    },
    httpGet() {
      this.loaded = false;
      if (this.alertUrl && this.eventUrl) {
        Promise
          .all([
            this.$http.get(this.alertUrl, this.httpParams),
            this.$http.get(this.eventUrl, this.httpParams),
          ])
          .then(axios.spread((alertRes, eventRes) => {
            this.alertData = alertRes.data;
            this.eventData = eventRes.data;
            this.timeRangeEvent();
          }))
          .finally(() => { this.loaded = true; });
      } else if (this.alertUrl) {
        this.$http
          .get(this.alertUrl, this.httpParams)
          .then((response) => {
            this.alertData = response.data;
            this.timeRangeEvent();
            if (this.isUserTab) {
              this.triggerEvent(this.combinedData.length - 1);
            }
          })
          .finally(() => { this.loaded = true; });
      }
    },
    timeRangeEvent() {
      const timeBuckets = this.combinedData.map((item) => ({ startTime: item.time, endTime: item.endTime }));
      this.$emit('time-buckets', timeBuckets);
    },
    getDataIndex(event) {
      this.selectedTime = event?.data[0];
      this.triggerEvent(event.dataIndex);
    },
    triggerEvent(index) {
      const point = this.combinedData[index];
      IssueTimeBarEventBus.$emit('update-filters', {
        shortTimeRange: { start: point.time, end: point.endTime },
        appUserId: this.appUserId,
        layer: this.layer,
        severity: this.severity,
        alertsExist: point.alert !== undefined,
      });
      if (this.isUserTab) {
        const alerts = {
          time: point.time,
          types: [],
        };
        if (point.alert) {
          const alertTypes = [];
          point.alert.types.forEach((type) => {
            if (type.warningCount || type.criticalCount || type.noticeCount) {
              alertTypes.push({
                name: type.name,
                warningCount: type.warningCount,
                criticalCount: type.criticalCount,
                noticeCount: type.noticeCount,
              });
            }
          });
          alerts.types = alertTypes;
        }
        this.$emit('bar-alerts', alerts);
      }
    },
    toolTipFormat(dataPointIndex) {
      const data = this.combinedData[dataPointIndex];
      const header = moment(data.time).format(momentTimeFormat.dateTime);
      let body;
      if (data.alert || data.event) {
        const alertContent = data.alert ? this.countAlertContent(data.alert, 'Total Alerts') : null;
        const eventContent = data.event ? this.countContent(data.event, 'Total Events') : null;
        const contents = [];
        if (alertContent) contents.push(alertContent);
        if (eventContent) contents.push(eventContent);
        body = contents.join('<hr />');
      } else {
        body = 'No Alert or Event';
      }
      return `
        <div class="apache-echarts-tooltip">
          <div><b>${header}</b></div>
          <div style="fontSize: 15px" class="m-1">${body}</div>
        </div>
      `;
    },
    countAlertContent(count, text) {
      const tooltipHtmlArr = [`<div>${text}: ${count.total}</div>`];
      count.types.forEach((type) => {
        const criticalCount = type.criticalCount || 0;
        const warningCount = type.warningCount || 0;
        const noticeCount = type.noticeCount || 0;

        if (criticalCount > 0 || warningCount > 0 || noticeCount > 0) {
          tooltipHtmlArr.push(`<div>${type.name}:</div>`);
          if (criticalCount > 0) tooltipHtmlArr.push(`<div>&nbsp;&nbsp;Critical: ${criticalCount}</div>`);
          if (warningCount > 0) tooltipHtmlArr.push(`<div>&nbsp;&nbsp;Warning: ${warningCount}</div>`);
          if (noticeCount > 0) tooltipHtmlArr.push(`<div>&nbsp;&nbsp;Notice: ${noticeCount}</div>`);
        }
      });

      return tooltipHtmlArr.join('\n');
    },
    countContent(count, text) {
      return [
        `<div>${text}: ${count.total}</div>`,
        count.types.map((type) => `<div>${type.name}: ${type.count}</div>`).join(''),
      ].join('\n');
    },

    // Mutates data from the endpoint to have convenient fields.
    augmentEventData(data) {
      data.forEach((obj) => {
        const datum = obj;
        datum.time = new Date(datum.time);
        // The total count at that time.
        datum.total = 0;
        datum.types.forEach((type) => { datum.total += type.count; });
      });
    },

    augmentAlertData(data) {
      data.forEach((obj) => {
        const datum = obj;
        datum.time = new Date(datum.time);
        datum.endTime = new Date(datum.endTime);
        // The total count at that time.
        datum.total = 0;
        datum.types.forEach((type) => {
          datum.total += type.noticeCount;
          datum.total += type.warningCount;
          datum.total += type.criticalCount;
        });
      });
    },
  },
};
</script>

<template>
  <div>
    <b-dropdown text="Action" variant="secondary" size="sm" right>
      <b-dropdown-item @click="$refs.xomeInstructionConfirm.$refs.modal.show()">
        Instruction
      </b-dropdown-item>
      <b-dropdown-item @click="$refs.renameXome.show()">
        Rename
      </b-dropdown-item>
      <b-dropdown-item @click="$refs.deleteXome.$refs.modal.show()">
        Delete
      </b-dropdown-item>
    </b-dropdown>
    <DeleteXomeConfirm
      :url="deleteUrl"
      ref="deleteXome"
    ></DeleteXomeConfirm>
    <XomeInstructionsConfirm
      :onConfirm="getRunCmd"
      ref="xomeInstructionConfirm"
    >
    </XomeInstructionsConfirm>
    <b-modal hide-footer centered title="XOME Instruction" ref="xomeInstruction">
      <XomeRegisterInstructions
        :xomeRegisterInfo="xomeRegisterInfo"
        @finished="$refs.xomeInstruction.hide()"
      ></XomeRegisterInstructions>
    </b-modal>
    <b-modal hide-footer centered title="Rename XOME" ref="renameXome">
      <RenameXome :xomeId="xomeId"></RenameXome>
    </b-modal>
  </div>
</template>

<script>
import DeleteXomeConfirm from '@/xvisor/components/xome/DeleteXomeConfirm.vue';
import RenameXome from '@/xvisor/components/xome/RenameXome.vue';
import XomeRegisterInstructions from '@/xvisor/components/xome/XomeRegisterInstructions.vue';
import XomeInstructionsConfirm from '@/xvisor/components/xome/XomeInstructionsConfirm.vue';

export default {
  components: {
    DeleteXomeConfirm,
    RenameXome,
    XomeRegisterInstructions,
    XomeInstructionsConfirm,
  },
  props: {
    deleteUrl: {
      type: String,
      required: true,
    },
    xomeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      xomeRegisterInfo: undefined,
    };
  },
  methods: {
    getRunCmd() {
      this.$http
        .get(`/xomes/${this.xomeId}/runcmd.json`)
        .then((response) => {
          this.xomeRegisterInfo = response.data;
          this.$refs.xomeInstruction.show();
        })
        .catch(() => {
          this.$bvToast.toast('Failed to get instruction', { variant: 'danger' });
        });
    },
  },
};
</script>

<template>
  <b-button
    class="btn-icon"
    variant="flat"
    v-b-tooltip.hover.variant.bottom
    title="Download PDF"
    :disabled="status !== 'ready'"
    @click.prevent="downloadPdf"
  >
    <b-icon icon="download" />
  </b-button>
</template>

<script>
import regex from '@/xvisor/constants/regex';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    createdTime: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    downloadPdf() {
      const timeWithoutSpecialChars = this.createdTime.replace(regex.specialCharacters, '-');
      const fileName = `${this.type}-${timeWithoutSpecialChars}.pdf`;
      this.$http
        .get(this.url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          this.$bvToast.toast('PDF downloaded', { variant: 'success' });
        })
        .catch(() => {
          this.$bvToast.toast('Failed to download PDF', { variant: 'danger' });
        });
    },
  },
};
</script>

<template>
  <div v-if="loaded">
    <b-badge v-if="active" pill :variant="badgeVariant">Active</b-badge>
    <b-badge v-else pill :variant="badgeVariant">Inactive</b-badge>
  </div>
</template>

<script>
import status from '@/xvisor/constants/status';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    badgeVariant() {
      return this.active ? 'success' : 'dark';
    },
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => {
          if (parseInt(response.data, 10) === status.active) {
            this.active = true;
          }
          this.loaded = true;
        });
    },
  },
};
</script>

<template>
  <b-modal
    centered
    title="Delete XOME"
    @ok="deleteXome"
    ref="modal"
  >
    Are you sure you want to delete this XOME? You cannot undo this action.
  </b-modal>
</template>

<script>
import XomesEventBus from '@/xvisor/bus/XomesEventBus';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteXome() {
      this.$http
        .delete(this.url)
        .then(() => {
          this.$bvToast.toast('Successfully deleted XOME', { variant: 'success' });
          XomesEventBus.$emit('deleted');
          this.$emit('deleted');
        })
        .catch(() => {
          this.$bvToast.toast('Failed to delete XOME', { variant: 'danger' });
        });
    },
  },
};
</script>

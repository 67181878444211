<template>
  <div>
    <p>Register your XOME by running the following command on the XOME device:</p>
    <b-button
      class="float-right"
      variant="flat-secondary"
      v-clipboard:copy="xomeRegisterInfo.runCmd"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      <b-icon icon="files" font-scale="1.5"/>
    </b-button>
    <Prism>{{ xomeRegisterInfo ? xomeRegisterInfo.runCmd : '' }}</Prism>
    <b-button class="p-0" variant="flat-primary" @click="goToSettings">
      <span>Next, enable data sources for this XOME</span>
      <b-icon icon="chevron-right" />
    </b-button>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import '@/libs/clipboard';

import XomesEventBus from '@/xvisor/bus/XomesEventBus';

export default {
  props: {
    xomeRegisterInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    Prism,
  },
  methods: {
    onCopy() {
      this.$bvToast.toast('Successfully copied to clipboard!', { variant: 'success' });
    },
    onError() {
      this.$bvToast.toast('Failed to copy to clipboard!', { variant: 'danger' });
    },
    goToSettings() {
      this.$emit('finished');
      if (this.$route.name === 'settings') {
        // Already on the settings page, update the route params and handle link with bus event.
        this.$route.params.xomeId = this.xomeRegisterInfo.xomeId;
        XomesEventBus.$emit('configure');
      } else {
        this.$router.push({
          name: 'settings',
          params: {
            xomeId: this.xomeRegisterInfo.xomeId,
          },
        });
      }
    },
  },
};
</script>

<template>
  <b-card class="mx-n3" v-if="appCmptList.length === 0">{{ $t("No Data Available") }}</b-card>
  <b-card class="mx-n3" v-else>
    <b-tabs lazy>
      <b-tab v-for="cmpt in appCmptList.slice(start, end)" :key="cmpt.cmptId" :title="cmpt.name">
        <b-row class="mb-1">
          <b-col class="mt-0-5" cols="12">
            <FlowEventHeatMap :url="flowEventUrl(cmpt.cmptId)" :timeRange="timeRange"></FlowEventHeatMap>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            align-self="center"
          >
            <FlowGraphWrapper
              :urls="flowGraphUrls(cmpt.cmptId)"
              :timeRange="shortTimeRange"
              :elementId="elementId(cmpt.cmptId)"
            ></FlowGraphWrapper>
          </b-col>
          <b-col
            align-self="center"
          >
            <FlowChord
              :url="cmpt.userCmptFlowUrl"
              :timeRange="shortTimeRange"
              :elementId="elementId(cmpt.cmptId)"
              @flow-no-data="(noData) => moveCmptToEnd(cmpt, noData)"
            ></FlowChord>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5>Flow Stats</h5>
            <FlowSrvrsTable
              :appId="cmpt.appId"
              :cmptId="cmpt.cmptId"
              :timeRange="timeRange"
            ></FlowSrvrsTable>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import FlowChord from '@/xvisor/components/app/flowsTab/FlowChord.vue';
import FlowEventHeatMap from '@/xvisor/components/app/flowsTab/FlowEventHeatMap.vue';
import FlowGraphWrapper from '@/xvisor/components/app/flowGraph/FlowGraphWrapper.vue';
import FlowSrvrsTable from '@/xvisor/components/app/flowsTab/FlowSrvrsTable.vue';

export default {
  props: {
    appCmptList: {
      type: Array,
      required: true,
    },
    monAppId: {
      type: Number,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    FlowChord,
    FlowEventHeatMap,
    FlowGraphWrapper,
    FlowSrvrsTable,
  },
  data() {
    return {
      /** The current page's number in the pagination. */
      currentPage: 1,
      /** The maximum number of tiles per page. */
      maxPageItems: 9,
      /** Store the cmpt's id that do not have any transaction data. */
      cmptIdsNoData: [],
    };
  },
  methods: {
    moveCmptToEnd(cmpt, noData) {
      if (this.cmptIdsNoData.find((id) => id === cmpt.cmptId) === undefined && noData) {
        this.cmptIdsNoData.push(cmpt.cmptId);
        const appIndex = this.appCmptList.findIndex((item) => item.cmptId === cmpt.cmptId);
        // Remove the application from the list.
        this.appCmptList.splice(appIndex, 1);
        // Add the application to the end of the list.
        this.appCmptList.push(cmpt);
      }
    },
    elementId(index) {
      return `flows-chord-${index}`;
    },
    // TODO Change this URL to be computed form the backend after v2 is merged.
    flowEventUrl(cmptId) {
      return `/apps/${this.monAppId}/cmpts/${cmptId}/flows/events/countts.json`;
    },
    flowGraphUrls(cmptId) {
      return {
        graphUrl: `/apps/${this.monAppId}/cmpts/${cmptId}/flowgraph.json`,
        eventUrl: `/apps/${this.monAppId}/cmpts/${cmptId}/flowgraph/event.json`,
      };
    },
  },
  computed: {
    start() { return (this.currentPage - 1) * this.maxPageItems; },
    end() { return this.start + this.maxPageItems; },
  },
};
</script>

<template>
  <b-modal
    v-if="xtend !== undefined"
    ref="modal"
    v-model="showModal"
    visible
  >
    <template #modal-title="{ }">
      {{ configTitle }}
    </template>
    <div>{{ configDesc }}</div>
    <b>Update doesn't occur until xTend restarts.</b>
    <template #modal-footer="{ }">
      <b-button v-if="xtend.config !== config" size="sm" variant="success" @click="update">
        Update
      </b-button>
      <b-button size="sm" variant="danger" @click="$refs.modal.hide()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  props: {
    xtend: {
      type: Object,
    },
    configDesc: {
      type: String,
      required: true,
    },
    configTitle: {
      type: String,
      required: true,
    },
    config: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    update() {
      this.$emit('update', this.xtend, this.config);
      this.$refs.modal.hide();
    },
    cancel() {
      this.$emit.modal.hide();
    },
  },
};
</script>

<template>
  <b-modal ref="modal" id="add-office" :title="typeMap[type].title">
    <validation-observer ref="form" @keydown.enter="httpPost">
      <validation-provider name="Name" rules="required" v-slot="{ errors }">
        <b-form-group label="Name *">
          <b-form-input
            autofocus
            v-model="office.name"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Country" rules="required" v-slot="{ errors }">
        <b-form-group label="Country *">
          <b-form-select
            v-model="countryIsoCode"
            :options="countries"
            value-field="isoCode"
            text-field="name"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-select>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group label="State *">
        <b-form-select
          v-model="stateIsoCode"
          :options="states"
          value-field="isoCode"
          text-field="name"
        ></b-form-select>
      </b-form-group>
      <validation-provider name="City" rules="required" v-slot="{ errors }">
        <b-form-group label="City *">
          <b-form-select
            v-model="city"
            :options="citySelectOptions"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-select>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-left mr-1" @click="handleOk">Submit</b-button>
        <b-button variant="outline-primary" class="float-left" @click="$bvModal.hide('add-office')">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import csc from 'country-state-city';

import ZonesEventBus from '@/xvisor/bus/ZonesEventBus';

export default {
  props: {
    /** The current office object (if existing) or else initialized to a default value. */
    office: {
      type: Object,
      default: () => ({
        name: '',
        longitude: undefined,
        latitude: undefined,
      }),
    },
    /** The type of office modal, to either 'add' or 'update' an office. */
    type: {
      type: String,
      required: true,
      validator: (str) => ['add', 'update'].indexOf(str) !== -1,
    },
    /** The URL for the action associated with this modal. */
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newOffice: this.office,
      countries: [],
      states: [],
      cities: [],
      countryIsoCode: undefined,
      stateIsoCode: undefined,
      city: {},

      typeMap: {
        add: {
          title: 'Add Office',
          action: 'adding',
        },
        update: {
          title: 'Update Office',
          action: 'updating',
        },
      },
    };
  },
  computed: {
    citySelectOptions() {
      return this.cities.map((city) => ({
        value: city,
        text: city.name,
      }));
    },
  },
  watch: {
    countryIsoCode() {
      if (this.countryIsoCode) {
        this.cities = csc.getCitiesOfCountry(this.countryIsoCode);
        this.states = csc.getStatesOfCountry(this.countryIsoCode);
      } else {
        this.cities = [];
        this.states = [];
      }
    },
    stateIsoCode() {
      if (this.countryIsoCode && this.stateIsoCode) {
        this.cities = csc.getCitiesOfState(this.countryIsoCode, this.stateIsoCode);
      } else {
        this.cities = [];
      }
    },
    city() {
      this.newOffice.latitude = parseFloat(this.city.latitude);
      this.newOffice.longitude = parseFloat(this.city.longitude);
    },
  },
  mounted() {
    this.countries = csc.getAllCountries();
  },
  methods: {
    handleOk(modalEvent) {
      modalEvent.preventDefault();
      this.httpPost();
    },
    httpPost() {
      this.$refs.form.validate().then((result) => {
        const { action } = this.typeMap[this.type];
        if (result && this.city.name && this.countryIsoCode) {
          this.$http
            .post(this.url, this.newOffice)
            .then(() => {
              this.successToast(action);
              this.$refs.modal.hide();
              ZonesEventBus.$emit('refresh');
            })
            .catch(() => {
              this.failToast(action);
            });
        } else {
          this.failToast(action);
        }
      });
    },
    successToast(action) {
      this.$bvToast.toast(`Success ${action} office`, { variant: 'success' });
    },
    failToast(action) {
      this.$bvToast.toast(`Failed ${action} office`, { variant: 'danger' });
    },
  },
};
</script>

<template>
  <div v-if="neighborGraphActive" class="m-0 p-0 h-100">
    <div class="close-button-container right-0 position-absolute">
      <b-button
        @click="closeNeighborGraph"
        variant="btn-primary-outline" class="p-1">
        <b-icon icon="x" scale="3" class="close-button" />
      </b-button>
    </div>
    <p class="parent-ip-label position-absolute">
      Selected: {{ neighborQueryId }}
    </p>
    <FlowGraph
      :series="neighborGraphSeries"
      :events="events"
      :categoryNameToCategoryId="categoryNameToCategoryId"
      :minMaxNodeBytes="minMaxNodeBytes"
      :minMaxLinkBytes="minMaxLinkBytes"
      @clickedNode="(id) => this.$emit('clickedNode', id)"
    ></FlowGraph>
  </div>
</template>

<script>
import FlowGraph from '@/xvisor/components/app/flowGraph/FlowGraph.vue';

export default {
  components: {
    FlowGraph,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: true,
    },
    categoryNameToCategoryId: {
      type: Object,
      required: true,
    },
    minMaxNodeBytes: {
      type: Object,
      required: true,
    },
    minMaxLinkBytes: {
      type: Object,
      required: true,
    },
    // The id of the node currently selected, or null if no node is selected.
    neighborQueryId: {
      type: String,
      required: true,
    },
  },
  emits: [
    'clickedNode',
    'closedGraph',
  ],
  computed: {
    neighborGraphActive() {
      return this.neighborQueryId !== null;
    },
    nodeIdToNodeObject() {
      const idToNodeMap = {};

      // Need to create a copy of each node to prevent main graph from being changed.
      this.series.nodes.forEach((node) => {
        idToNodeMap[node.id] = JSON.parse(JSON.stringify(node));
      });
      return idToNodeMap;
    },
    // Maps a node to all links that have that node as a source or target.
    adjacentLinks() {
      const adj = {};
      this.series.links.forEach((link) => {
        // Ensures that for the source and target id in links, there is a corresponding id in nodes.
        if ((link.source in this.nodeIdToNodeObject) && (link.target in this.nodeIdToNodeObject)) {
          if (!(link.source in adj)) adj[link.source] = [];
          if (!(link.target in adj)) adj[link.target] = [];
          adj[link.source].push(link);
          adj[link.target].push(link);
        }
      });
      return adj;
    },
    neighborGraphSeries() {
      if (!this.neighborGraphActive) return [];
      const series = {
        nodes: [this.nodeIdToNodeObject[this.neighborQueryId]],
        links: [],
      };
      // Represents nodes that have already been processed since duplicate nodes gives an error.
      const nodeInAlready = { [this.neighborQueryId]: true };
      this.adjacentLinks[this.neighborQueryId].forEach((link) => {
        // Currently, the neighborId could be link.source or link.target, need to figure out which.
        let neighborId = null;
        if (link.source !== this.neighborQueryId) neighborId = link.source;
        if (link.target !== this.neighborQueryId) neighborId = link.target;

        // NeighborId is null when link.source === link.target === parentId, so it is skipped.
        if (neighborId !== null) {
          series.links.push(link);
          // Duplicate nodes cannot exist. Ie: two links (A -> B) and (B -> A) represent two nodes, not four.
          if (!(neighborId in nodeInAlready)) {
            nodeInAlready[neighborId] = true;
            series.nodes.push(this.nodeIdToNodeObject[neighborId]);
          }
        }
      });

      // Subtract 1, because the center node is not considered a neighbor node.
      const coordinates = this.neighborGraphCoordinates(series.nodes.length - 1);
      for (let i = 0; i < series.nodes.length; i += 1) {
        series.nodes[i].coordinate.x = coordinates[i].x;
        series.nodes[i].coordinate.y = coordinates[i].y;
      }
      return series;
    },
  },
  methods: {
    closeNeighborGraph() {
      this.$emit('closedGraph');
    },
    neighborGraphCoordinates(numberNeighbors) {
      const coordinates = {
        parentCoordinate: [{ x: 0, y: 0 }],
        neighborCoordinates: [],
      };

      // The number of nodes to render per shell.
      const nodesInnerShell = 15;
      const addShellNodesIncrease = 5;

      /**
       * TODO: Investigate the relationship between symbolSize and (x, y) coorindates.
       * Currently, it seems like they are represented differently.
       * Two nodes whose coordinates are 1 unit apart, but whose symbolSizes are 30, do not intersect.
      */

      // The radius of the innermost shell.
      const innerShellRadius = 2.5;
      const addShellRadiusIncrease = 1;

      // The angle to render nodes at the innermost shell.
      const thetaStart = 0;
      const addShellThetaDisplace = Math.PI / 16;

      let neighborIndex = 0;
      for (let shellIndex = 0; neighborIndex < numberNeighbors; shellIndex += 1) {
        const currentShellRadius = innerShellRadius + shellIndex * addShellRadiusIncrease;
        const currentThetaStart = thetaStart + shellIndex * addShellThetaDisplace;

        // This is the number of nodes in the current shell if theres enough nodes it fill it completely.
        const theoreticalNodesInShell = nodesInnerShell + shellIndex * addShellNodesIncrease;

        // Take into account if there's not enough nodes remaining to flll the current shell.
        const actualNodesInShell = Math.min(numberNeighbors - neighborIndex, theoreticalNodesInShell);
        const thetaIncrement = (2 * Math.PI) / actualNodesInShell;

        // Iterate using "i" instead of theta directly to avoid floating point issues.
        for (let i = 0; i < actualNodesInShell; i += 1) {
          const theta = currentThetaStart + i * thetaIncrement;
          coordinates.neighborCoordinates.push({
            x: currentShellRadius * Math.cos(theta),
            y: currentShellRadius * Math.sin(theta),
          });
        }
        // NeighborIndex is advanced by the exact number of nodes that were just processed.
        neighborIndex += actualNodesInShell;
      }
      return [...coordinates.parentCoordinate, ...coordinates.neighborCoordinates];
    },
  },
};
</script>

<style scoped>
.right-0 {
  right: 0;
}
.parent-ip-label {
  right: 0;
  bottom: 0;
  margin: 0;
}
.close-button {
  color: #d0d2d6;
}
.close-button-container {
  z-index: 1;
}
</style>

import { render, staticRenderFns } from "./XomeConfigurationContent.vue?vue&type=template&id=b10d0b08&scoped=true&"
var script = {}
import style0 from "./XomeConfigurationContent.vue?vue&type=style&index=0&id=b10d0b08&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b10d0b08",
  null
  
)

export default component.exports
<template>
  <div>
    <b-button
      class="btn-icon"
      variant="flat"
      @click="$refs.deleteXtend.$refs.modal.show()"
      v-b-tooltip.hover.variant.bottom
      title="Delete"
    >
      <b-icon icon="trash" />
    </b-button>
    <DeleteXtendConfirm
      :url="deleteUrl"
      @deleted="onDelete"
      ref="deleteXtend"
    ></DeleteXtendConfirm>
  </div>
</template>

<script>
import DeleteXtendConfirm from '@/xvisor/components/xtend/DeleteXtendConfirm.vue';

export default {
  props: {
    deleteUrl: {
      type: String,
      required: true,
    },
    xtendId: {
      type: Number,
      required: true,
    },
  },
  components: {
    DeleteXtendConfirm,
  },
  methods: {
    onDelete() {
      this.$refs.deleteXtend.$refs.modal.hide();
      this.$emit('deleted');
    },
  },
};
</script>

<template>
  <e-chart
    v-if="hasData"
    :options="this.chartOptions"
    autoresize
    class="apache-echarts-default-graph"
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import readableBytes from '@/xvisor/utilities/readableBytes';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    url() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    allPoints() {
      return this.series[0].points.concat(this.series[1].points);
    },
    allTimes() {
      return this.allPoints.map((point) => point.time);
    },
    timeSet() {
      return new Set(this.allTimes);
    },
    maxBytes() {
      const allBytes = this.allPoints.map((point) => point.value);
      return Math.max(...allBytes);
    },
    timeRangeMax() {
      const maxDate = new Date(
        Math.max(...this.allTimes.map((element) => new Date(element))),
      );
      return maxDate;
    },
    timeRangeMin() {
      const minDate = new Date(
        Math.min(
          ...this.allTimes.map((element) => new Date(element)),
        ),
      );
      return minDate;
    },
    initialTimeFromMarkline() {
      // Initializes the line to the end of the graph.
      const seriesData = this.series[0].points.map((point) => [
        moment(point.time).valueOf(),
        point.value,
      ]);
      return seriesData.at(-1)[0];
    },
    initialTimeToMarkline() {
      // Initializes the line to the end of the graph.
      const seriesData = this.series[1].points.map((point) => [
        moment(point.time).valueOf(),
        point.value,
      ]);
      return seriesData.at(-1)[0];
    },
    fromPoints() {
      return this.pointMap(this.series[0].points);
    },
    toPoints() {
      return this.pointMap(this.series[1].points);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.teal4, colorPaletteShade.blue4],
        tooltip: {
          trigger: 'axis',
          position: [50, -150],
          renderMode: 'html',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              <div class="apache-echarts-tooltip-timestamp">
                ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              </div>
              <table class="apache-echarts-tooltip-table">
                <tr>
                  <th>${info[0].marker} ${info[0].seriesName}</th>
                  <td>${this.readableBytes(info[0].value[1])}</td>
                </tr>
                <tr>
                  <th>${info[1].marker} ${info[1].seriesName}</th>
                  <td>${this.readableBytes(info[1].value[1])}</td>
                </tr>
              </table>
            </div>
          `,
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: 10,
              opacity: 0.2,
            },
          },
          order: 'seriesAsc',
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all',
            },
          ],
        },
        grid: [
          {
            top: '12%',
            left: 50,
            right: 50,
            containLabel: true,
            height: '38%',
          },
          {
            left: 50,
            right: 50,
            containLabel: true,
            top: '54%',
            height: '38%',
          },
        ],
        xAxis: [
          {
            type: 'time',
            min: this.timeRangeMin,
            max: this.timeRangeMax,
            offset: 7,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
                alignWithLabel: true,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              onZero: true,
            },
            axisLabel: {
              formatter: (value) => moment(value).format(this.timeSeriesFormat),
              fontSize: 9,
            },
            splitLine: {
              show: false,
            },
          },
          {
            gridIndex: 1,
            type: 'time',
            min: this.timeRangeMin,
            max: this.timeRangeMax,
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
              onZero: true,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
                alignWithLabel: true,
              },
            },
            position: 'top',
            show: true,
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: 'In',
            nameLocation: 'center',
            type: 'value',
            max: this.maxBytes,
            axisLabel: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            splitLine: {
              show: true,
            },
          },
          {
            name: 'Out',
            nameLocation: 'center',
            type: 'value',
            alignTicks: true,
            inverse: true,
            gridIndex: 1,
            max: this.maxBytes,
            axisLabel: {
              show: false,
            },
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            splitLine: {
              show: true,
            },
          },
        ],
        series: [
          {
            name: 'In',
            barWidth: '100%',
            data: this.fromPoints,
            type: 'bar',
            markLine: {
              symbol: 'circle',
              lineStyle: {
                type: 'solid',
              },
              data: [{ xAxis: this.initialTimeFromMarkline }],
              label: {
                formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
              },
            },
          },
          {
            name: 'Out',
            yAxisIndex: 1,
            xAxisIndex: 1,
            barWidth: '100%',
            data: this.toPoints,
            type: 'bar',
            showSymbol: false,
            markLine: {
              symbol: 'circle',
              lineStyle: {
                type: 'solid',
              },
              data: [{ xAxis: this.initialTimeToMarkline }],
              label: {
                formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity72(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    pointMap(arr) {
      return arr.map((point) => [point.time, point.value]);
    },
    readableBytes,
  },
};
</script>

<template>
  <div v-if="userNameInfo && userNameInfo.length <= userNamesDisplayLimit" style="display: flex;">
    <div v-for="(val, index) in userNameInfo" v-bind:key="index" style="margin-right: 10px;">
      <span>{{ val }}</span>
      <span v-if="index+1 < userNameInfo.length">, </span>
    </div>
  </div>
  <div v-else>
    <span
      data-html="true"
      v-b-tooltip.hover.html="userNamesTooltipContent"
    >
      <b-badge pill class="text-white badge-primary" :id="getPopoverId(uniqueId)">
        ...
      </b-badge>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    userNameInfo: {
      type: Array,
      required: true,
    },
    uniqueId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    userNamesTooltipContent() {
      return `${this.userNameInfo.length} Users<hr>${this.userNameInfo.join('<br>')}`;
    },
  },
  data() {
    return {
      userNamesDisplayLimit: 2,
    };
  },
  methods: {
    getPopoverId() {
      return `times-popover-${String(this.uniqueId)}`;
    },
  },
};
</script>

<template>
  <b-badge
    pill
    :variant="behaviorVariant"
    class="badge-width"
    v-b-tooltip.hover
    :title="this.behaviorCheck(this.behavior)"
  >
    {{ this.collapseText(this.behaviorCheck(this.behavior), 14) }}
  </b-badge>
</template>

<script>
import collapseText from '@/xvisor/utilities/collapseText';
import securityBehavior from '@/xvisor/constants/securityBehavior';

export default {
  props: {
    behavior: {
      type: String,
      required: true,
    },
  },
  computed: {
    behaviorVariant() {
      if (this.behavior === securityBehavior.normal.name) return 'success';
      return 'danger';
    },
  },
  methods: {
    // Return Normal if behavior isn't a valid string.
    behaviorCheck(behavior) {
      if (behavior === securityBehavior.normal.name) {
        return securityBehavior.normal.name;
      }
      return behavior;
    },
    collapseText,
  },
};
</script>

<style scoped>
.badge-width{
  min-width: 80px;
  width: 90%;
}
</style>

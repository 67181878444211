<template>
  <b-card>
    <b-container>
      <b-row>
        <b-card>
          <h2>xTend Configurations</h2>
        xTend has different configurations to fit your needs. <b>Cloud</b> is the default configuration that will
        export data to the xVisor Dashboard. <b>Local</b> configuration will store logs in the host machine. On
        <i>Windows</i> the logs will be stored in the directory where xTend is installed and on <i>Linux</i>, logs
        will be stored in <b>/var/log/xtend</b>.
        </b-card>
      </b-row>
      <b-row>
        <b-col align-self="center">
          <b-button size="sm" variant="secondary" href="/kb/xtend-guide" class="mb-1">
            <b-icon icon="link"/> Configuration Guide
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-tabs lazy>
        <XtendsTable/>
    </b-tabs>
  </b-card>
</template>

<script>

import XtendsTable from '@/xvisor/components/settings/xtends/XtendsTable.vue';

export default {
  components: {
    XtendsTable,
  },
};
</script>

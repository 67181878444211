<template>
  <b-card no-body>
    <b-row class="mx-1 mb-1 mt-1" align-h="between" align-v="center">
      <b-col cols="12" md="6">
        <ShowEntries>
          <template #input>
            <b-form-select
              v-model="perPage"
              :options="options"
              size="sm"
              class="mx-50"
            ></b-form-select>
          </template>
        </ShowEntries>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <SearchBar class="mr-1">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
        <XtendsRegistrationKey class="button-spacing"></XtendsRegistrationKey>
        <DownloadXtend></DownloadXtend>
        </div>
      </b-col>
    </b-row>
    <b-table
      id="xtend-key-table"
      class="table-with-actions-min-height"
      striped
      responsive
      :items="xtendKeys"
      :fields="tableFields"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
    >
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="paginationTotalRows"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      align="right"
      class="mt-1 mr-1"
      ariaControls="xtend-key-table"
    ></b-pagination>
  </b-card>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';
import XtendsRegistrationKey from '@/xvisor/components/xtend/XtendsRegistrationKey.vue';
import DownloadXtend from '@/xvisor/components/xtend/DownloadXtend.vue';

export default {
  components: {
    ShowEntries,
    SearchBar,
    XtendsRegistrationKey,
    DownloadXtend,
  },
  data() {
    return {
      xtendKeys: [],
      perPage: 5,
      currentPage: 1,
      filter: null,
      filteredLength: null,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      tableFields: [
        { key: 'name', label: 'NAME', sortable: true },
        { key: 'creationTime', label: 'CREATED', sortable: true },
        { key: 'access', label: 'LAST ACCESSED', sortable: true },
        { key: 'permissions', label: 'PERMISSIONS', sortable: true },
        { key: 'actions', label: 'ACTIONS' },
      ],
    };
  },
  computed: {
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.xtendKeys.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
  .button-spacing {
    margin-right: 10px; /* Adjust the value as needed */
  }
</style>

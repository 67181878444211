<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="alertChartWidth"
  ></e-chart>
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      alertCounts: undefined,
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
    this.healthEmit();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    alertCounts() {
      this.healthEmit();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.alertCounts && this.alertCounts.counts.length > 0;
    },
    allTimes() {
      return this.alertCounts.counts.map((count) => count.time);
    },
    timeSet() {
      return new Set(this.allTimes);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    series() {
      return {
        name: 'Alert Counts',
        type: 'bar',
        barWidth: '80%',
        barMaxWidth: '30',
        data: this.alertCounts.counts.map((point) => [point.time, point.value]),
      };
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.blue4],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          min: this.allTimes[0],
          max: this.allTimes[this.allTimes.length - 1],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)} <br>
              ${info[0].marker} Alerts: ${info[0].value[1]}
            </div>
          `,
          position: (pos, params, dom, rect, size) => {
            /* Tooltip will be fixed on the right if mouse hovering on the left,
             * and on the left if hovering on the right.
             */
            const obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
        },
        grid: {
          top: 5,
          bottom: 5,
          left: 10,
          right: 10,
        },
        series: this.series,
      };
    },
  },
  methods: {
    healthEmit() {
      if (this.alertCounts) {
        this.$emit('alertCount', this.appId, this.alertCounts.total);
      }
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity6(this.timeRange),
          },
        })
        .then((response) => { this.alertCounts = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style scoped>
.alertChartWidth{
  min-width: 100px;
  max-width: 200px;
  width: 80%;
  height: 60px;
}
</style>

<template>
  <e-chart
    v-if="hasData"
    autoresize
    class="echart-div-size"
    :options="chartOptions"
  ></e-chart>
  <div v-else-if="loaded">{{ $t("Not Available Now") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import granularity from '@/xvisor/utilities/granularity';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 120,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    chartId: {
      type: String,
      required: true,
    },
    chartUnit: {
      type: String,
      required: true,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity12(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
  computed: {
    hasData() {
      return this.loaded && (this.series && this.series.length > 0);
    },
    timeSet() {
      return this.series && this.series.length > 0 ? new Set(this.series.map((item) => item.time)) : new Set();
    },
    yAxisBound() {
      let values = [];
      values = this.series.map((point) => point.value);
      const maxValue = Math.max(...values);
      return maxValue === 0 ? 1 : maxValue;
    },
    chartSeries() {
      if (this.series === undefined || this.series.length === 0) return [];
      return {
        data: this.series.map((item) => [moment(item.time).valueOf(), item.value]),
        name: this.chartId,
      };
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        grid: {
          id: this.chartId,
          height: this.height,
          top: 10,
          left: 30,
          right: 5,
        },
        color: [colorPalette.indigo],
        xAxis: {
          type: 'time',
          axisLabel: {
            show: false,
            formatter: (_, timestamp) => {
              const xAxisFormat = timeFormat.momentFormat(this.timeSet, new Date());
              return moment(timestamp).format(xAxisFormat);
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisPointer: {
            show: true,
            snap: true,
            label: {
              backgroundColor: themeStyle.styleToggle(colorPalette.white, colorPalette.blueLightBackground),
              color: this.toggleColor,
              formatter: (params) => {
                const xAxisFormat = timeFormat.momentFormat(this.timeSet, new Date());
                return moment(params.value).format(xAxisFormat);
              },
            },
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          splitNumber: 1,
          min: 0,
          max: this.yAxisBound,
          name: this.chartUnit,
          nameLocation: 'middle',
          nameRotate: 90,
          nameTextStyle: {
            color: this.toggleColor,
            fontWeight: 'bold',
          },
          axisLabel: {
            color: this.toggleColor,
            formatter(value) {
              return value.toFixed(0).toString();
            },
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            type: 'line',
            name: this.chartSeries.name,
            smooth: true,
            symbol: 'none',
            lineStyle: {
              width: 5,
            },
            data: this.chartSeries.data,
          },
        ],
        tooltip: {
          backgroundColor: themeStyle.styleToggle(colorPalette.white, colorPalette.black),
          formatter: (params) => {
            const obj = params[0];
            const unit = this.chartUnit === 'ms' ? 'ms' : this.chartUnit;
            const tooltipVal = `
              <div class="apache-echarts-tooltip">
                ${obj.marker} ${this.chartId}: ${obj.data[1]}${unit}
              </div>
            `;
            const tooltipValWithPercentage = `
              <div class="apache-echarts-tooltip">
                ${obj.marker} ${(obj.data[1] * 100).toFixed(1)}%
              </div>
            `;
            return this.percentage ? tooltipValWithPercentage : tooltipVal;
          },
          textStyle: {
            color: this.toggleColor,
            fontSize: 12,
          },
        },
      };
    },
  },
};
</script>

<style scoped>

/* Styles the height of the E-Chart div that is created when using the '<e-chart/>' tag. */
.echart-div-size {
  height: 95%;
}

</style>

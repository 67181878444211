<template>
  <div>
    <div v-if="hasData">
      <e-chart
        :options="chartOptions"
        autoresize
        @click="onMapPointClick"
        style="height: 400px; width: 100%;"
      ></e-chart>
    </div>
    <div v-else-if="loaded" :class="noDataAvailableMargin">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>

    <b-modal v-model="showModal" title="Devices" hide-footer>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>OS</th>
            <th>Alerts Count</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="device in selectedDeviceDetails"
            :key="device.xtendId"
            @click="redirectToXtnedAlertPage(device.xtendId)"
            style="cursor: pointer;"
          >
            <td>{{ device.Name }}</td>
            <td>{{ device.Os }}</td>
            <td>{{ device.totalAlerts }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import 'echarts/map/js/world';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    alertsInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      devices: [],
      loaded: false,
      centerUSCoord: [-95.7129, 37.0902],
      centerUSZoomSize: 6,
      defaultCenterCoord: null,
      defaultZoomSize: 1.2,
      showModal: false,
      selectedDeviceDetails: [],
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.devices.length > 0;
    },
    chartOptions() {
      const coordinates = this.devices.map((device) => {
        let color = colorPalette.green;
        const alerts = this.alertsInfo?.deviceDetails;
        if (alerts && device.deviceDetails) {
          device.deviceDetails.some((detail) => {
            const matchingAlert = alerts.find(
              (alertDetail) => alertDetail.xtendId === detail.xtendId,
            );

            if (matchingAlert) {
              if (matchingAlert.critical > 0) {
                color = colorPalette.red;
                return true;
              } if (matchingAlert.warning > 0) {
                color = colorPalette.orange;
                return true;
              } if (matchingAlert.notice > 0) {
                color = colorPalette.purple;
                return false;
              }
            }
            return false;
          });
        }

        return {
          deviceCount: device.deviceCount,
          value: device.coord,
          city: device.city,
          country: device.country,
          color,
        };
      });

      return {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            const device = coordinates.find((d) => d.value[0] === params.value[0] && d.value[1] === params.value[1]);
            if (device) {
              return `${device.deviceCount}
                Devices<br>City: ${device.city}<br>
                Country: ${device.country}<br>`;
            }
            return '';
          },
        },
        geo: {
          map: 'world',
          roam: true,
          center: this.devices.every((device) => device.country === 'United States')
            ? this.centerUSCoord : this.defaultCenterCoord,
          zoom: this.devices.every((device) => device.country === 'United States')
            ? this.centerUSZoomSize : this.defaultZoomSize,
          label: {
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: '#9b989c',
              borderColor: '#111',
            },
            emphasis: {
              areaColor: '#9b989c',
            },
          },
        },
        series: [
          {
            name: 'Device Locations',
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              show: false,
            },
            itemStyle: {
              color: (params) => {
                const device = coordinates.find(
                  (d) => d.value[0] === params.value[0] && d.value[1] === params.value[1],
                );
                return device ? device.color : '#fc810d';
              },
            },
            symbolSize: (val) => {
              const device = this.devices.find((d) => d.coord[0] === val[0] && d.coord[1] === val[1]);
              if (device) {
                const size = Math.sqrt(device.deviceCount) * 4;
                return Math.min(Math.max(size, 4), 12);
              }
              return 4;
            },
            data: coordinates,
          },
        ],
      };
    },
  },
  watch: {
    timeRange() {
      this.loadDevices();
    },
  },
  mounted() {
    this.loadDevices();
  },
  methods: {
    loadDevices() {
      this.loaded = false;
      this.$http
        .get('/xtends/deviceslocation.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => {
          this.devices = response.data.filter(
            (device) => device.city && device.city.toLowerCase() !== 'n/a',
          );
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    onMapPointClick(params) {
      const device = this.devices.find((d) => d.coord[0] === params.value[0] && d.coord[1] === params.value[1]);
      if (device && device.deviceDetails) {
        this.selectedDeviceDetails = device.deviceDetails.map((detail) => {
          const alertInfo = this.alertsInfo.deviceDetails.find((alert) => alert.xtendId === detail.xtendId);
          const totalAlerts = alertInfo ? (alertInfo.critical + alertInfo.warning + alertInfo.notice) : 0;

          return {
            ...detail,
            totalAlerts,
          };
        });
        this.showModal = true;
      }
    },
    redirectToXtnedAlertPage(id) {
      this.$router.push({ name: 'xtend alert tab', params: { id } });
    },
  },
};
</script>

import { render, staticRenderFns } from "./SyslogTopProgramsCount.vue?vue&type=template&id=2a4569c2&scoped=true&"
import script from "./SyslogTopProgramsCount.vue?vue&type=script&lang=js&"
export * from "./SyslogTopProgramsCount.vue?vue&type=script&lang=js&"
import style0 from "./SyslogTopProgramsCount.vue?vue&type=style&index=0&id=2a4569c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4569c2",
  null
  
)

export default component.exports
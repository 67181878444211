<template>
  <div v-if="protocolInfo && protocolInfo.length <= protocolDisplayLimit" style="display: flex;">
    <div v-for="(val, index) in protocolInfo" v-bind:key="index" style="margin-right: 10px;">
      <span>{{ val.toUpperCase() }}</span>
      <span v-if="index+1 < protocolInfo.length">, </span>
    </div>
  </div>
  <div v-else>
    <span
      data-html="true"
      v-b-tooltip.hover.html="protocolTooltipContent"
    >
      <b-badge pill class="text-white badge-primary" :id="getPopoverId(uniqueId)">
        ...
      </b-badge>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    protocolInfo: {
      type: Array,
      required: true,
    },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  computed: {
    protocolTooltipContent() {
      const uppercaseProtocols = this.protocolInfo.map((protocol) => protocol.toUpperCase());
      return `${this.protocolInfo.length} Protocols<hr>${uppercaseProtocols.join('<br>')}`;
    },
  },
  data() {
    return {
      protocolDisplayLimit: 3,
    };
  },
  methods: {
    getPopoverId() {
      return `protocol-popover-${String(this.uniqueId)}`;
    },
  },
};
</script>

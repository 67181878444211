import { render, staticRenderFns } from "./AlertsTable.vue?vue&type=template&id=ceed5628&scoped=true&"
import script from "./AlertsTable.vue?vue&type=script&lang=js&"
export * from "./AlertsTable.vue?vue&type=script&lang=js&"
import style0 from "./AlertsTable.vue?vue&type=style&index=0&id=ceed5628&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceed5628",
  null
  
)

export default component.exports
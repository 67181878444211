<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="12">
        <b-card :sub-title="$t('Applications')" sub-title-text-variant="" class="stats-panel">
          <StatsPanel
            :url="url"
            :timeRange="timeRange"
            :colors="appStatsColors"
            routeName="app"
            chartName="top-applications-alert"
          ></StatsPanel>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatsPanel from '@/xvisor/components/launchPad/StatsPanel.vue';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';

export default {
  components: {
    StatsPanel,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appStatsColors: [
        colorPaletteShade.teal7,
        colorPaletteShade.teal3,
        colorPaletteShade.teal5,
        colorPaletteShade.teal2,
        colorPaletteShade.teal4,
        colorPaletteShade.teal6,
        colorPaletteShade.teal3,
      ],
    };
  },
};
</script>

<style scoped>
.stats-panel {
  margin-top: 70px;
}
</style>

<template>
  <b-col cols="12">
    <b-row>
      <!-- Primary Entity -->
      <b-col cols="7">
        <validation-provider name="Entity Count" rules="required" v-slot="{ errors }">
          <b-form-group :label="'Find Top N ' + selectedPrimaryEntity" label-cols-md="7">
            <b-form-select
              v-model="params.primaryEntityCount"
              :options="entityCount"
              :state="errors[0] ? false : null"
              @change="emitUpdateSelection"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Entity Count</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <!-- Metric Type -->
    <b-row>
      <b-col cols="7">
        <validation-provider name="Metric Type" rules="required" v-slot="{ errors }">
          <b-form-group label="By" label-cols-md="7">
            <b-form-select
              v-model="params.metricType"
              :options="metricTypes"
              :state="errors[0] ? false : null"
              @change="emitUpdateSelection"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Metric Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row  v-b-toggle.collapse-optional @click="showOptionalParams = !showOptionalParams" class="mt-2">
      <b-col cols="7">
        <div>
          <h5>
            <b>Optional Parameters</b>
            <b-icon v-if="!showOptionalParams" icon="chevron-right"></b-icon>
            <b-icon v-else icon="chevron-down"></b-icon>
          </h5>
        </div>
      </b-col>
    </b-row>
    <b-collapse id="collapse-optional" class="w-100">
    <b-row>
      <!-- Secondary Entity (optional) -->
      <b-col cols="7">
        <b-form-group :label="`Within ${selectedPrimaryEntity}, find top N`" label-cols-md="7">
          <b-form-select
            v-model="params.secondaryEntityCount"
            :options="entityCount"
            @change="emitUpdateSelection"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Entity Count</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label-cols-md="0">
          <b-form-select
            v-model="params.secondaryEntityType"
            :options="secondaryEntityTypes"
            @change="emitUpdateSelection"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Secondary Entity Type</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-collapse>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      entityCount: [5, 10, 20],
      primaryEntityTypes: [
        'applications',
        'infra-elements',
        'users',
        'servers',
      ],
      metricTypes: [
        'bytes',
        'packets',
        'flows',
      ],
      params: {
        primaryEntityType: null,
        primaryEntityCount: null,
        secondaryEntityType: null,
        secondaryEntityCount: null,
        metricType: null,
      },
      showOptionalParams: false,
    };
  },
  props: {
    selectedPrimaryEntity: {
      type: String,
      required: false,
    },
  },
  computed: {
    // Secondary Entity Types would be everything but what is chosen as the primary type.
    secondaryEntityTypes() {
      return this.primaryEntityTypes.filter((type) => type !== this.selectedPrimaryEntity);
    },
  },
  methods: {
    emitUpdateSelection() {
      this.$emit('change-topn-params', {
        ...this.params,
        primaryEntityType: this.selectedPrimaryEntity ? this.selectedPrimaryEntity : null,
      });
    },
  },
};
</script>

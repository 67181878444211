<template>
  <div class="d-block" v-if="ifaceInfo">
    <b-dropdown variant="transparent" no-caret left class="d-inline-block info-dropdown">
      <template #button-content>
        <b-icon icon="info-circle" variant="dark"></b-icon>
      </template>
      <b-dropdown-item v-if="ifaceInfo.name">
        <h6 class="d-inline-block">Name:</h6>
        {{ ifaceInfo.name }}
      </b-dropdown-item>
      <b-dropdown-item v-if="ifaceInfo.ips">
        <h6 class="d-inline-block">IP(s): </h6>
        {{ ifaceInfo.ips.join(' ,') }}
      </b-dropdown-item>
      <b-dropdown-item v-if="ifaceInfo.capacityMbps">
        <h6 class="d-inline-block">Capacity: </h6>
        {{ capacityConverter(ifaceInfo.capacityMbps) }}
      </b-dropdown-item>
      <b-dropdown-item v-if="ifaceInfo.snmpIndex">
        <h6 class="d-inline-block">SNMP Index: </h6>
        {{ ifaceInfo.snmpIndex }}
      </b-dropdown-item>
      <b-dropdown-item v-if="ifaceInfo.virtualIndex">
        <h6 class="d-inline-block">Virtual Index: </h6>
        {{ ifaceInfo.virtualIndex }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import mbpsConverter from '@/xvisor/utilities/mbpsConverter';

export default {
  props: {
    ifaceInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    capacityConverter(capacity) {
      const { rate, unit } = mbpsConverter(capacity);
      return `${rate} ${unit}`;
    },
  },
};
</script>

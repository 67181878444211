<template>
  <b-card class="mx-n2">
    <!-- Table and sorting header -->
    <h5>Infra Elements</h5>
    <div v-if="hasData">
      <b-row>
        <!-- table -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableData"
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(status)="row">
              <b-badge pill v-if="row.value === 1" variant="success">
                Active
              </b-badge>
              <b-badge pill v-else variant="dark">
                Inactive
              </b-badge>
            </template>
            <template #cell(name)="row">
              <b-button
                variant="flat-warning"
                @click="handleClickEvent(row.item)"
                class="p-0">
                {{row.value}}
              </b-button>
            </template>
            <template #cell(type)="row">
              <span>{{row.item.type}}</span>
            </template>
            <template #cell(alertCount)="row">
              <AlertProgressBar
                :criticalAlerts="row.item.criticalCount"
                :totalAlerts="row.item.alertCount"
              ></AlertProgressBar>
            </template>
            <template #cell(nePageLink)="row">
              <b-button
                @click="handleClickEvent(row.item)"
                class="btn-icon"
                variant="flat-primary">
                <b-icon icon="chevron-right"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>

        <!-- pagination -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="paginationTotalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else-if="loaded">{{ $t("No Infra Element(s)") }}</div>
  </b-card>
</template>
<script>
import AlertProgressBar from '@/xvisor/components/AlertProgressBar.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'status', label: 'Status', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'alertCount', label: 'Alert Stats', sortable: true },
        { key: 'nePageLink', label: '', sortable: false },
      ],
      filteredLength: null,
    };
  },
  mounted() {
    this.httpGet();
  },
  components: {
    AlertProgressBar,
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.tableData.length;
    },
    tableData() {
      const data = [];
      this.series.forEach((zone) => {
        zone.infras.forEach((networkElement) => {
          data.push(networkElement);
        });
      });
      return data;
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get('/infra/list.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    handleClickEvent({ id, ip, type }) {
      this.$emit('infra-click', { id, ip, type });
    },
  },
};
</script>

<template>
  <div>
    <b-row v-if="loaded">
      <b-col>
        <div class="d-flex justify-content-between align-items-right">
          <div class="text-right">
            <h5>{{ alertsummary.deviceCount }} Devices</h5>
          </div>
          <div class="text-right">
            <h5 :style="{ color: colorPalette.red }">{{ alertsummary.criticalCount }} Criticals</h5>
            <p></p>
          </div>
          <div class="text-right">
            <h5 :style="{ color: colorPalette.orange }">{{ alertsummary.warningCount }} Warnings</h5>
          </div>
          <div class="text-right">
            <h5 :style="{ color: colorPalette.purple }">{{ alertsummary.noticeCount }} Notices</h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <SpinnerCmpt v-else></SpinnerCmpt>
    <XtendDevicesWorldMap
      :timeRange="timeRange"
      :alertsInfo="alertsummary"
      ></XtendDevicesWorldMap>
  </div>
</template>

<script>
import colorPalette from '@/xvisor/constants/colorPalette';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import XtendDevicesWorldMap from '@/xvisor/components/launchPad/XtendDevicesWorldMap.vue';

export default {
  components: {
    SpinnerCmpt,
    XtendDevicesWorldMap,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alertsummary: {},
      loaded: false,
      colorPalette,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.alertsummary = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<template>
  <span>
    <b-button class="text-nowrap" size="sm" variant="primary" @click="$refs.addOffice.$refs.modal.show()">
      + Add Office
    </b-button>
    <OfficeLocationModal ref="addOffice" type="add" :url="url"></OfficeLocationModal>
  </span>
</template>

<script>
import OfficeLocationModal from '@/xvisor/components/settings/zones/OfficeLocationModal.vue';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    OfficeLocationModal,
  },
};
</script>

<template>
  <b-row>
    <b-col lg="3" md="3" sm="12" order="1">
      <b-card class="sticky-card">
        <h5 class="kb-title">
          <b-icon icon="info-circle" class="mr-1" />
          Covered Topics
        </h5>
        <vue-perfect-scrollbar :settings="scrollSettings" class="scroll-style">
          <ul class="mt-1">
            <li v-for="(topic, index) in relatedTopics" :key="index" class="ml-n2">
              <b-link :href="`#${topic.href}`" class="list-item">{{ topic.name }}</b-link>
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-card>
    </b-col>
    <b-col lg="9" md="9" sm="12" order="2">
      <b-card>
        <h1 class="mb-1" align="center">XOME Configuration</h1>
        <div class="question-content">
          <XomeConfigurationContent></XomeConfigurationContent>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import XomeConfigurationContent from '@/xvisor/views/knowledgeBase/components/XomeConfigurationContent.vue';

export default {
  components: {
    VuePerfectScrollbar,
    XomeConfigurationContent,
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 50,
      },
      relatedTopics: [
        { name: 'Configuring Probe Data Source', href: '1-configuring-probe-data-source' },
        { name: 'Configuring Netflow Data Source', href: '2-configuring-netflow-data-source' },
        { name: 'Configuring SFlow Data Source', href: '3-configuring-sflow-data-source' },
        { name: 'Configuring Wire Data Capture Source', href: '4-configuring-wire-data-capture-source' },
        { name: 'Configuring SNMP Data Source', href: '5-configuring-snmp-data-source' },
        { name: 'Configuring Syslog Data Source', href: '6-configuring-syslog-data-source' },
      ],
    };
  },
};
</script>

<template>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      autoresize
    ></e-chart>
    <div v-else-if="loaded">
      {{ $t("No Data Available") }}
    </div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    ipRole: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      connectivities: {},
      loaded: false,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.connectivities;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    formattedData() {
      const res = [];
      if (this.ipRole === 'client') {
        res.push({
          itemStyle: {
            color: colorPaletteShade.green4,
          },
          symbolSize: 14,
          name: this.connectivities.sourceIp,
        });
        this.connectivities.targetIps.forEach((ip) => {
          res.push({
            itemStyle: {
              color: colorPaletteShade.blue4,
            },
            symbolSize: 14,
            name: ip,
          });
        });
      } else if (this.ipRole === 'server') {
        res.push({
          itemStyle: {
            color: colorPaletteShade.blue4,
          },
          symbolSize: 14,
          name: this.connectivities.sourceIp,
        });
        this.connectivities.targetIps.forEach((ip) => {
          res.push({
            itemStyle: {
              color: colorPaletteShade.green4,
            },
            symbolSize: 14,
            name: ip,
          });
        });
      }
      return res;
    },
    formattedLink() {
      const res = [];
      if (this.ipRole === 'client') {
        this.connectivities.targetIps.forEach((ip) => {
          res.push({
            value: 1000,
            symbolSize: [0, 10],
            source: this.connectivities.sourceIp,
            target: ip,
          });
        });
      } else if (this.ipRole === 'server') {
        this.connectivities.targetIps.forEach((ip) => {
          res.push({
            value: 1000,
            symbolSize: [0, 10],
            source: ip,
            target: this.connectivities.sourceIp,
          });
        });
      }
      return res;
    },
    chartOptions() {
      return {
        series: [
          {
            type: 'graph',
            layout: 'force',
            force: {
              repulsion: 480,
              edgeLength: 120,
            },
            roam: true,
            label: {
              show: true,
              position: 'bottom',
              color: this.toggleColor,
            },
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 10],
            edgeLabel: {
              fontSize: 20,
            },
            data: this.formattedData,
            links: this.formattedLink,
            lineStyle: {
              curveness: 0,
            },
          },
        ],
      };
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.connectivities = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

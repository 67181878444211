<template>
  <b-tabs v-model="activeTab" card end lazy nav-wrapper-class="sticky-navbar">
    <b-tab :title="tabs.status.name">
      <StatusTab
        :timeRange="timeRange"
        :urls="urls.statusUrls"
      ></StatusTab>
    </b-tab>
    <b-tab :title="tabs.processes.name">
      <ProcessesTab
        :timeRange="timeRange"
        :url="urls.processCreationUrl"
      ></ProcessesTab>
    </b-tab>
    <b-tab :title="tabs.traffic.name">
      <TrafficTab
        :timeRange="timeRange"
        :url="urls.networkConnectionUrl"
      ></TrafficTab>
    </b-tab>
    <b-tab :title="tabs.packages.name">
      <PackageTab
        :timeRange="timeRange"
        :url="urls.xtendPackageUrl"
      ></PackageTab>
    </b-tab>
    <b-tab :title="tabs.alerts.name">
      <AlertsTab
        :timeRange="timeRange"
        :shortTimeRange="shortTimeRange"
        :url="urls.xtendAlertsUrlPrefix"
      ></AlertsTab>
    </b-tab>
  </b-tabs>
</template>

<script>
import StatusTab from '@/xvisor/components/xtend/tabs/StatusTab.vue';
import ProcessesTab from '@/xvisor/components/xtend/tabs/ProcessesTab.vue';
import TrafficTab from '@/xvisor/components/xtend/tabs/TrafficTab.vue';
import AlertsTab from '@/xvisor/components/xtend/tabs/AlertsTab.vue';
import PackageTab from '@/xvisor/components/xtend/tabs/PackageTab.vue';

export default {
  components: {
    StatusTab,
    ProcessesTab,
    TrafficTab,
    AlertsTab,
    PackageTab,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    shortTimeRange: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
    defaultAlertTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  created() {
    if (this.defaultAlertTab) {
      this.activeTab = 4;
    }
  },
  watch: {
    defaultAlertTab(newVal) {
      if (newVal) {
        this.activeTab = 4;
      }
    },
  },
  computed: {
    tabs() {
      return {
        status: { name: 'Status', index: 0 },
        processes: { name: 'Processes', index: 1 },
        traffic: { name: 'Traffic', index: 2 },
        packages: { name: 'Packages', index: 3 },
        alerts: { name: 'Alerts', index: 4 },
      };
    },
  },
};
</script>

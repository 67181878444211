import readableBytes from './readableBytes';

export default {
  // Create a memory usage bytes text.
  bytesText(memory) {
    return memory
      ? `${readableBytes(memory.usedBytes)} / ${readableBytes(memory.totalBytes)}`
      : undefined;
  },

  // Create a memory percent text.
  percentText(memory) {
    return memory
      ? `${((memory.usedBytes / memory.totalBytes) * 100).toFixed(1)}%`
      : undefined;
  },

  // Create a memory usage array
  percentArray(memory) {
    return [((memory.usedBytes / memory.totalBytes) * 100).toFixed(1)];
  },
};

<template>
  <b-card
    title="Authorized DNS Server(s)"
    sub-title="These DNS Server(s) are authorized for the users of this organization"
  >
    <AddApprovedDns></AddApprovedDns>
    <ApprovedDnsList></ApprovedDnsList>
  </b-card>
</template>

<script>
import AddApprovedDns from '@/xvisor/components/policyCenter/dns/AddApprovedDns.vue';
import ApprovedDnsList from '@/xvisor/components/policyCenter/dns/ApprovedDnsList.vue';

export default {
  components: {
    AddApprovedDns,
    ApprovedDnsList,
  },
};
</script>

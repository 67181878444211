<template>
  <b-tabs class="w-100" lazy>
    <b-tab
      v-for="(label, key) in keyToLabel"
      :key="key"
      :active="key === 'dns'"
      :disabled="key === 'totalServers' || key === 'addresses'"
      @click="resetServerDetails()"
      lazy
    >
      <template #title>
        <div>
          <strong>{{ label }}</strong>
          <p v-if="serverLengthsLoaded">Total: {{ serviceToEnterpriseServersLength[seviceToServerCountLabel[key]] }}</p>
        </div>
      </template>
      <div v-if="validTab(key)">
        <ServiceTable
          :timeRange="timeRange"
          :url="serviceUrl(key)"
          :addressCountUrl="addressCountServiceUrl(key)"
          :serviceType="key"
        >
        </ServiceTable>
        <ServerDetails :timeRange="timeRange" :serviceType="key"></ServerDetails>
      </div>
      <div v-else>
        <b-row>
          <b-tabs class="w-100 mx-2" lazy>
            <b-tab title="Suricata" active>
              <SuricataAlertsTable
                :timeRange="timeRange"
              ></SuricataAlertsTable>
            </b-tab>
            <b-tab title="Other">
              <ServiceTable
                :timeRange="timeRange"
                :url="serviceUrl(key)"
                :addressCountUrl="addressCountServiceUrl(key)"
                :serviceType="key"
              >
              </ServiceTable>
              <ServerDetails :timeRange="timeRange" :serviceType="key"></ServerDetails>
            </b-tab>
          </b-tabs>
        </b-row>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import SecurityEventBus from '@/xvisor/bus/SecurityEventBus';
import ServerDetails from '@/xvisor/components/security/ServerDetails.vue';
import ServiceTable from '@/xvisor/components/security/ServiceTable.vue';
import SuricataAlertsTable from '@/xvisor/components/security/SuricataAlertsTable.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    ServerDetails,
    ServiceTable,
    SuricataAlertsTable,
  },
  data() {
    return {
      keyToLabel: {
        totalServers: 'Total Servers',
        dns: 'DNS',
        ssh: 'SSH',
        http: 'HTTP',
        https: 'HTTPS',
        other: 'Other',
        addresses: 'Addresses',
        alert: 'Alerts',
      },
      serviceToEnterpriseServersLength: {},
      seviceToServerCountLabel: {
        totalServers: 'enterpriseAddresses',
        dns: 'dnsServerCount',
        ssh: 'sshServerCount',
        http: 'httpServerCount',
        https: 'httpsServerCount',
        other: 'otherServerCount',
        addresses: 'totalAddresses',
      },
      serverLengthsLoaded: false,
    };
  },
  mounted() {
    this.populateServerLengths();
  },
  watch: {
    timeRange() {
      this.populateServerLengths();
    },
  },
  methods: {
    serviceUrl(key) {
      return `/security/${key}/list.json`;
    },
    addressCountServiceUrl(key) {
      return `/security/${key}/totaladdresses.json`;
    },
    resetServerDetails() {
      SecurityEventBus.$emit('update-selected-server', null);
    },
    // TODO: This is used to prevent unused tabs from displaying any data. Remove this when other tabs are completed.
    validTab(key) {
      const validList = ['dns', 'ssh', 'http', 'https', 'other'];
      return validList.includes(key);
    },
    populateServerLengths() {
      this.serverLengthsLoaded = false;
      this.$http
        .get('/security/totalservers.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.serviceToEnterpriseServersLength = response.data; })
        .finally(() => { this.serverLengthsLoaded = true; });
    },
  },
};
</script>

<template>
  <e-chart
    :options="chartOptions"
    @click="handleClickEvent"
    autoresize
    class="h-100"
  ></e-chart>
</template>

<script>
import flowGraphMixin from '@/xvisor/components/infra/infraAdjacencyGraphMixin';
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  mixins: [flowGraphMixin],
  props: {
    series: {
      type: Object,
      required: true,
    },
    categoryNames: {
      type: Array,
      required: true,
    },
    nodeIdToCategoryName: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        nodes: this.series.nodes.map((node) => (
          this.formattedNode(
            node,
            this.nodeIdToCategoryName,
          )
        )),
        links: this.series.links.map((link) => (
          this.formattedLink(
            link,
          )
        )),
        categories: this.categoryNames.map((category) => ({ name: category })),
      };
    },
    chartOptions() {
      const options = JSON.parse(JSON.stringify(this.chartOptionsTemplate));
      options.series[0].data = this.chartData.nodes;
      options.series[0].links = this.chartData.links;
      options.series[0].categories = this.chartData.categories;
      options.legend[0].textStyle.color = this.toggleColor;
      return options;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
  },
  methods: {
    handleClickEvent(event) {
      if ('name' in event.data) {
        const infra = {
          // TODO: Currently the type of the node is hard coded to be 'router'.
          //  We might need to change the endpoint to contain this information.
          type: 'router',
          ip: event.data.name,
        };
        this.$emit('infra-click', infra);
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 100vh !important;
}
</style>

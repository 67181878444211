<template>
  <div v-if="loaded">
    <span v-if="lastAccessTime">{{ timePeriodText }}</span>
    <span v-else>Not Recorded Yet</span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastAccessTime: undefined,
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    timePeriodText() {
      const now = new Date();
      return this.lastAccessTime
        ? `${moment.duration(moment(now).diff(this.lastAccessTime)).humanize()} ago`
        : undefined;
    },
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => {
          this.lastAccessTime = response.data;
          this.loaded = true;
        });
    },
  },
};
</script>

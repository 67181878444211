<template>
  <e-chart v-if="hasData" :options="chartOptions()" autoresize />
  <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import readableNumber from '@/xvisor/utilities/readableNumber';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    categories() {
      return this.series.map((obj) => (obj.service));
    },
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    timeSeriesFormat() {
      const multiTimeSeries = this.series.map((obj) => (obj.points));
      const format = timeFormat.momentFormat(multiTimeSeries, new Date());
      return format;
    },
    multiValues() {
      const multiValueSeries = this.series.map((obj) => {
        const pointValue = obj.points.map((point) => [point.time, point.value]);
        const valueObj = {
          name: obj.service,
          type: 'line',
          data: pointValue,
        };
        return valueObj;
      });
      return multiValueSeries;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleColorIndicator() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.blueLightBackground);
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity12(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    chartOptions() {
      return {
        grid: {
          right: 5,
        },
        color: [
          colorPaletteShade.teal4,
          colorPaletteShade.blue4,
          colorPaletteShade.green4,
          colorPaletteShade.inidigo4,
          colorPaletteShade.purple4,
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: colorPalette.tooltipBackground,
            },
          },
          formatter: (info) => {
            let content = '';
            const time = info[0].data[0] ? info[0].data[0] : '';
            const formattedTime = moment(time).format(this.timeSeriesFormat);
            info.forEach((graph) => {
              let contentRow = '';
              const label = graph.seriesName;
              const value = graph.data[1];
              const defaultMarker = `
                <span
                  style="
                    display:inline-block;
                    margin-right:5px;
                    border-radius:10px;
                    width:10px;
                    height:10px;
                    background-color:${this.toggleColorIndicator};
                  ">
                </span>`;
              const colorIndicator = graph.marker === '' ? defaultMarker : graph.marker;
              contentRow = `<div>${colorIndicator} ${label}: ${value}</div>`;
              content += contentRow;
            });
            return `
              <div class="apache-echarts-tooltip">
                <div>${formattedTime}</div>
                ${content}
              </div>
            `;
          },
        },
        legend: {
          data: this.categories,
          left: 10,
          top: 10,
          textStyle: {
            color: this.toggleColor,
          },
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
          },
        },
        yAxis: [
          {
            type: 'value',
            name: 'Number of Logs',
            nameLocation: 'center',
            nameGap: 50,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => readableNumber(value),
            },
          },
        ],
        series: this.multiValues,
      };
    },
  },
};
</script>
<style scoped>
.echarts {
  width: 100% !important;
  height: 350px !important;
}
</style>

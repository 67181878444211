<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.syslog.extendedName} Data Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <b-form-group label="Time Zone">
        <b-form-select
          placeholder="Time Zone"
          v-model="config.timezone"
          :options="timezones"
        ></b-form-select>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import csc from 'country-state-city';

import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        timezone: null,
      },
      timezones: csc
        .getAllCountries()
        .map((country) => country.timezones)
        .flat()
        .sort((a, b) => (a.zoneName > b.zoneName ? 1 : -1))
        .map((timezone) => ({
          value: timezone.zoneName,
          text: `${timezone.zoneName} (${timezone.gmtOffsetName})`,
        })),
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      // Post null as undefined to omit field entirely when serializing to JSON.
      if (newConfig.timezone === null) newConfig.timezone = undefined;
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      this.config = {
        timezone: this.value.timezone || null,
      };
    },
  },
};
</script>

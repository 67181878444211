<template>
  <div>
    <div v-if="nextHopsHasData">
      <InfraNextHop
        :categoryNames="categoryNames"
        :series="nextHops"
        :nodeIdToCategoryName="nodeIdToCategoryName"
        @infra-click="handleClickEvent"
    ></InfraNextHop>
    </div>
    <SpinnerCmpt v-else-if="!loadedNextHops"></SpinnerCmpt>
    <div v-if="infraElementsHasData">
      <InfraNoTraffic
        :categoryNames="categoryNamesForInfraElements"
        :series="infraElements"
        @infra-click="handleClickEvent"
      ></InfraNoTraffic>
    </div>
    <SpinnerCmpt v-else-if="!loadedInfraElements"></SpinnerCmpt>
    <div v-else>
      {{ $t("No Data Available") }}
    </div>
  </div>
</template>

<script>
import InfraNextHop from '@/xvisor/components/infra/InfraNextHop.vue';
import InfraNoTraffic from '@/xvisor/components/infra/InfraNoTraffic.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  components: {
    InfraNextHop,
    InfraNoTraffic,
    SpinnerCmpt,
  },
  props: {
    urls: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.resetData();
      this.httpGet();
    },
  },
  data() {
    return {
      nextHops: [],
      loadedNextHops: false,
      loadedInfraElements: false,
      infraElements: [],
      nextHopZones: [],
      infraElementZones: [],
    };
  },
  computed: {
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    nextHopsHasData() {
      return this.nextHops
        && (this.nextHops.nodes && this.nextHops.nodes.length > 0)
        && (this.nextHops.links && this.nextHops.links.length > 0);
    },
    infraElementsHasData() {
      return this.infraElements
        && this.infraElements.length > 0;
    },
    categoryNames() {
      const categories = this.nextHopZones ? [...new Set(this.nextHopZones.map((zone) => zone.zoneName))] : [];
      return categories;
    },
    categoryNamesForInfraElements() {
      const categories = this.infraElements
        ? [...new Set(this.infraElements.map((node) => node.zoneName))] : [];
      return categories;
    },
    nodeIdToCategoryName() {
      const nodeIdToCategoryName = {};
      this.nextHopZones.forEach((infraZone) => { nodeIdToCategoryName[infraZone.nodeId] = infraZone.zoneName; });
      return nodeIdToCategoryName;
    },
  },
  methods: {
    resetData() {
      this.loadedNextHops = false;
      this.loadedInfraElements = false;
      this.nextHops = [];
      this.infraElements = [];
      this.nextHopZones = [];
      this.infraElementZones = [];
    },
    httpGet() {
      this.resetData();
      this.loadedNextHops = false;
      this.loadedInfraElements = false;
      this.$http
        .get(this.urls.graphUrl, this.httpParams)
        .then((response) => {
          this.nextHops = response.data;
          const { reqId } = response.data;
          this.$http
            .get(this.urls.infraZoneUrl, { params: { reqId } })
            .then((zonesResponse) => { this.nextHopZones = zonesResponse.data; });
          this.$http
            .get(this.urls.infraElementsUrl, { params: { reqId } })
            .then((unconnectedNodes) => { this.infraElements = unconnectedNodes.data; })
            .finally(() => { this.loadedInfraElements = true; });
        }).finally(() => { this.loadedNextHops = true; });
    },
    handleClickEvent(infraElem) {
      this.$emit('infra-click', infraElem);
    },
  },
};
</script>

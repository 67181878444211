<template>
  <b-card title="Authorized SSID(s)" sub-title="These SSID(s) are authorized for the users of this organization">
    <AddApprovedSsid></AddApprovedSsid>
    <ApprovedSsidList></ApprovedSsidList>
  </b-card>
</template>

<script>
import AddApprovedSsid from '@/xvisor/components/policyCenter/ssid/AddApprovedSsid.vue';
import ApprovedSsidList from '@/xvisor/components/policyCenter/ssid/ApprovedSsidList.vue';

export default {
  components: {
    AddApprovedSsid,
    ApprovedSsidList,
  },
};
</script>

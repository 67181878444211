<template>
 <div>
    <b-button size="sm" variant="primary" v-b-modal.add-api-key class="text-nowrap">
      + Add API Key
    </b-button>
    <b-modal id="add-api-key" ref="add-api-key" title="Add API Key" size="lg" centered>
      <validation-observer ref="addApiKey" @keydown.enter="addApiKey">
        <b-form>
          <validation-provider name="API Key Name" rules="required" v-slot="{ errors }">
            <b-form-group
              label-cols="4"
              content-cols="8"
              label="Name*"
              label-for="api-key-name"
            >
              <b-form-input
                autofocus
                title="Add API Key"
                placeholder="Name"
                type="text"
                v-model="apiKey.name"
                :state="errors[0] ? false : null"
                class="form-control-merge"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label-cols="4"
            content-cols="8"
            label="Description"
            label-for="api-key-description"
          >
            <b-form-input
              title="Add API Description"
              placeholder="Description"
              type="text"
              v-model="apiKey.description"
              class="form-control-merge"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols="4"
            content-cols="8"
            label="Permissions*"
            label-for="api-key-permissions"
          >
            <validation-provider rules="required" v-slot="{ errors }">
              <Multiselect
                :multiple="true"
                :hideSelected="true"
                :showLabels="false"
                :closeOnSelect="false"
                placeholder="Permission(s)"
                v-model="apiKey.permissions"
                :options="permissionOptions"
                label="name"
                trackBy="value"
              ></Multiselect>
              <p class="text-danger text-sm" v-if="errors[0]">Please select permissions.</p>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-cols="4"
            content-cols="8"
            label="Tag(s)"
            label-for="api-key-tag-input"
          >
            <div id="api-key-tag-input">
              <div>
                <b-row v-for="(tag, index) in apiKey.tags" :key="index" class="mb-1">
                  <b-col cols="4">
                    <validation-provider rules="required" name="Tag" v-slot="{ errors }">
                      <b-form-input
                        placeholder="Key"
                        v-model="tag[0]"
                        :state="errors[0] ? false: null"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input
                      placeholder="Value"
                      v-model="tag[1]"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-button @click="deleteTag(index)" variant="outline-danger">
                      <b-icon icon="trash"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row class="mb-1">
                <b-col cols="12">
                  <span class="text-danger text-xs" v-if="containsDuplicateKeys">
                    Tags cannot contain duplicate keys.
                  </span>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="4">
                  <b-button @click="addTag()" variant="primary" class="mb-1" size="sm">+ Add Tag</b-button>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-left mr-1" @click="addApiKey">Submit</b-button>
          <b-button variant="outline-primary" class="float-left" @click="cancelAction()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal ref="apiKeyToken" title="API Key">
      <prism>{{ token }}</prism>
    </b-modal>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import Multiselect from 'vue-multiselect';

import apiKeyPermissionTypes from '@/xvisor/constants/apiKeyPermissionTypes';

export default {
  components: {
    Prism,
    Multiselect,
  },
  data() {
    return {
      apiKey: {
        name: '',
        description: '',
        permissions: [],
        tags: [],
      },
      token: undefined,
      multiselectTouched: false,
    };
  },
  computed: {
    formattedApiKey() {
      const formattedApiKey = { ...this.apiKey };
      formattedApiKey.permissions = this.apiKey.permissions.map((permission) => permission.value);
      formattedApiKey.tags = Object.fromEntries(this.apiKey.tags);
      return formattedApiKey;
    },
    // Returns true if apiKeyTags contains duplicate Keys
    containsDuplicateKeys() {
      const seen = [];
      for (let i = 0; i < this.apiKey.tags.length; i += 1) {
        const key = this.apiKey.tags[i][0];
        if (seen.indexOf(key) !== -1) {
          return true;
        }
        seen.push(key);
      }
      return false;
    },
    permissionOptions() {
      return Object.values(apiKeyPermissionTypes);
    },
  },
  methods: {
    addApiKey() {
      this.$refs.addApiKey.validate().then((result) => {
        if (result && !this.containsDuplicateKeys) {
          this.$http
            .post('/settings/apikey/create', this.formattedApiKey)
            .then((response) => {
              this.$bvToast.toast('Successfully added API Key', { variant: 'success' });
              this.$refs['add-api-key'].hide();
              this.token = response.data;
              this.$refs.apiKeyToken.show();
              this.clearApiKeyData();
            })
            .catch(() => {
              this.$bvToast.toast('Failed to add API Key', { variant: 'danger' });
            });
        }
      });
    },
    addTag() {
      this.apiKey.tags.push(['', '']);
    },
    deleteTag(index) {
      this.apiKey.tags.splice(index, 1);
    },
    cancelAction() {
      this.clearApiKeyData();
      this.$refs['add-api-key'].hide();
    },
    clearApiKeyData() {
      this.apiKey.name = '';
      this.apiKey.description = '';
      this.apiKey.permissions = [];
      this.apiKey.tags = [];
    },
  },
};
</script>

<template>
<div>
  <b-card no-body :class="margin">
    <b-row class="mx-0-5 mt-2 mb-1">
      <b-col cols="6">
        <h6>{{ selectedHeader }}</h6>
      </b-col>
    </b-row>
    <b-row v-if="hasProbeSelection" class="mx-1 mb-1">
      <b-col>
        <b-row>
          <b-col lg="6" md="6" sm="12" v-if="selectedProbe.urls.rtdMillisTsUrl">
            <h6>Delay</h6>
            <ProbeMetrics
              :timeRange="timeRange"
              :urls="probeUrls.rtd"
              :targetId="selectedProbe.targetId"
              metric="rtd"
              :issueTimeSelected="issueTimeSelected"
            ></ProbeMetrics>
          </b-col>
          <b-col lg="6" md="6" sm="12" v-if="selectedProbe.urls.jitterMillisTsUrl">
            <h6>Jitter</h6>
            <ProbeMetrics
              :timeRange="timeRange"
              :urls="probeUrls.jitter"
              :targetId="selectedProbe.targetId"
              metric="jitter"
              :issueTimeSelected="issueTimeSelected"
            ></ProbeMetrics>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12" v-if="selectedProbe.urls.avbwMbpsTsUrl">
            <div class="mt-2-0">
              <h6>Bandwidth</h6>
              <ProbeMetrics
                :timeRange="timeRange"
                :urls="probeUrls.avbw"
                :targetId="selectedProbe.targetId"
                metric="avbw"
                :issueTimeSelected="issueTimeSelected"
              ></ProbeMetrics>
            </div>
          </b-col>
          <b-col
            lg="6" md="6" sm="12"
            v-if="selectedProbe.urls.dnsMeasTsUrl
              || selectedProbe.urls.httpLoadMillisTsUrl
              || selectedProbe.urls.httpLoadBaselineMillisTsUrl"
          >
            <div v-if="selectedProbe.urls.dnsMeasTsUrl" class="mt-2-0">
              <h6>DNS</h6>
              <LineColumnChart
                chartId="dns"
                :urls="probeUrls.dns"
                :timeRange="timeRange"
                :height="200"
                :issueTimeSelected="issueTimeSelected"
              ></LineColumnChart>
            </div>
            <!-- HTTPS metrics -->
            <b-tabs id="http-tabs" v-else-if="selectedProbe.urls.httpLoadMillisTsUrl" lazy>
              <b-tab id="https-title" disabled title="HTTPS Profile:"></b-tab>
              <b-tab id="https-current" title="Current">
                <ProbeMetrics
                  :timeRange="timeRange"
                  :urls="probeUrls.httpLoad"
                  :targetId="selectedProbe.targetId"
                  metric="http"
                  :issueTimeSelected="issueTimeSelected"
                  :isStacked="true"
                ></ProbeMetrics>
              </b-tab>
              <b-tab id="https-baseline" title="Baseline">
                <ProbeMetrics
                  :timeRange="timeRange"
                  :urls="probeUrls.httpLoadBaseline"
                  :targetId="selectedProbe.targetId"
                  metric="http"
                  :issueTimeSelected="issueTimeSelected"
                  :isStacked="true"
                ></ProbeMetrics>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
        <div  v-if="selectedProbe.urls.domainDelayTsUrl
          && selectedProbe.urls.domainAvgLossTsUrl" class="mt-2-0">
          <h6>Domain Metrics</h6>
          <DomainMetricContainer
            chartId="domain metrics"
            :urls="probeUrls.domainMetric"
            :targetId="selectedProbe.targetId"
            :timeRange="timeRange"
            :height="200"
            :issueTimeSelected="issueTimeSelected"
          ></DomainMetricContainer>
          <b-row>
            <b-col lg="6" md="6" sm="12" v-if="selectedProbe.urls.interDomainDelayUrl">
              <h6>Inter-Domain Link Metrics</h6>
              <InterDomainMetrics
                :timeRange="timeRange"
                :urls="probeUrls.interDomainMetric"
                :targetId="selectedProbe.targetId"
                :issueTimeSelected="issueTimeSelected"
              ></InterDomainMetrics>
            </b-col>
            <b-col lg="6" md="6" sm="12" v-if="selectedProbe.urls.domainLinkDelayTsUrl">
              <h6>Domain Delay - Link Aggregation</h6>
              <ProbeMetrics
                :timeRange="timeRange"
                :urls="probeUrls.domainLinkDelay"
                :targetId="selectedProbe.targetId"
                metric="domainLinkDelay"
                :issueTimeSelected="issueTimeSelected"
              ></ProbeMetrics>
            </b-col>
            <b-col lg="6" md="6" sm="12" v-if="selectedProbe.urls.domainAvgLossTsUrl">
              <h6>Domain Loss</h6>
              <ProbeMetrics
                :timeRange="timeRange"
                :urls="probeUrls.domainAvgLoss"
                :targetId="selectedProbe.targetId"
                metric="domainAvgLoss"
                :issueTimeSelected="issueTimeSelected"
              ></ProbeMetrics>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>
import moment from 'moment';

import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import LineColumnChart from '@/xvisor/components/app/LineColumnChart.vue';
import ProbesEventBus from '@/xvisor/bus/ProbesEventBus';
import ProbeMetrics from '@/xvisor/components/app/networkTab/ProbeMetrics.vue';
import DomainMetricContainer from '@/xvisor/components/app/DomainMetricContainer.vue';
import InterDomainMetrics from '@/xvisor/components/app/InterDomainMetrics.vue';

export default {
  components: {
    LineColumnChart,
    ProbeMetrics,
    DomainMetricContainer,
    InterDomainMetrics,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    alertId: {
      type: String,
    },
    margin: {
      type: String,
    },
  },
  data() {
    return {
      selectedProbe: null,
      issueTimeSelected: null,
    };
  },
  computed: {
    selectedHeader() {
      if (!this.hasProbeSelection) return 'No Probe Selected';
      return `
        Source: ${this.selectedProbe.source},
        Target: ${this.selectedProbe.target}
      `;
    },
    hasProbeSelection() {
      return this.selectedProbe !== null && this.selectedProbe !== undefined;
    },
    probeUrls() {
      const { targetId } = this.selectedProbe;
      return {
        avbw: {
          tsUrl: `/probes/${targetId}/avbw/mbpsts.json`,
          eventUrl: `/probes/${targetId}/event/avbw.json`,
        },
        rtd: {
          tsUrl: `/probes/${targetId}/rtd/millists.json`,
          eventUrl: `/probes/${targetId}/event/rtd.json`,
        },
        dns: {
          tsUrl: `/probes/${targetId}/dnsmeasts.json`,
          eventUrl: `/probes/${targetId}/event/dns.json`,
        },
        jitter: {
          tsUrl: `/probes/${targetId}/jitter/millists.json`,
          eventUrl: `/probes/${targetId}/event/jitter.json`,
        },
        httpLoad: {
          tsUrl: `/probes/${targetId}/httpload/millists.json`,
          eventUrl: `/probes/${targetId}/event/httpload.json`,
        },
        httpLoadBaseline: {
          tsUrl: `/probes/${targetId}/httpload/baseline/millists.json`,
          eventUrl: `/probes/${targetId}/event/httpload.json`,
        },
        domainAvgLoss: {
          tsUrl: `/probes/${targetId}/domain/avglossts.json`,
          eventUrl: `/probes/${targetId}/event/domainavgloss.json`,
        },
        domainMetric: {
          tsUrl: `/probes/${targetId}/domain/delayts.json`,
          lossUrl: `/probes/${targetId}/domain/avglossts.json`,
        },
        interDomainMetric: {
          delayUrl: `/probes/${targetId}/interdomain/delayts.json`,
          lossUrl: `/probes/${targetId}/interdomain/avglossts.json`,
          eventUrl: `/probes/${targetId}/interdomain/events.json`,
        },
        domainLinkDelay: {
          tsUrl: `/probes/${targetId}/domain/linkdelayts.json`,
          eventUrl: `/probes/${targetId}/event/domainlinkdelay.json`,
        },
      };
    },
  },
  watch: {
    timeRange() {
      this.selectedProbe = null;
    },
  },
  created() {
    ProbesEventBus.$on('update-selected-probe', (probe) => {
      this.selectedProbe = probe;
    });
  },
  mounted() {
    ProbesEventBus.$on('update-time-range', (newTimeRange) => { this.timeRange = newTimeRange; });
    IssueTimeBarEventBus.$on('update-filters', (filters) => {
      this.issueTimeSelected = moment(filters.shortTimeRange.start).valueOf();
    });
  },
};
</script>

<style lang="scss">
// Styles Https Profile title to match echarts graph title
#http-tabs__BV_tab_controls_ {
  margin-top: 1.2rem !important;
  margin-left: -1.2rem;
}

.nav-tabs
.nav-item
#https-title___BV_tab_button__.nav-link.disabled {
  color: #5e5873;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}
.dark-layout
.nav-tabs
.nav-item
#https-title___BV_tab_button__.nav-link.disabled {
  color: #d0d2d6;
}

</style>

<template>
  <b-modal
    ref="modal"
    :title="`Configure ${xourceNames.snmp.extendedName} Data Source`"
    @ok="saveAction"
    @hidden="syncConfig"
  >
    <validation-observer ref="form">
      <validation-provider name="Community String" rules="required" v-slot="{ errors }">
        <b-form-group label="Community String">
          <b-form-input
            placeholder="Community String"
            v-model="config.communityString"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Servers" rules="prefixListValid" v-slot="{ errors }">
        <b-form-group label="Servers">
          <b-form-input
            placeholder="Servers"
            v-model="config.servers"
            :state="errors[0] ? false : null"
            class="form-control-merge"
          ></b-form-input>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import configModalMixin from './configModalMixin';

export default {
  mixins: [configModalMixin],
  data() {
    return {
      config: {
        communityString: '',
        servers: '',
      },
    };
  },
  computed: {
    postConfig() {
      // Deep copy to avoid form errors during type conversions.
      const newConfig = JSON.parse(JSON.stringify(this.config));
      newConfig.servers = newConfig.servers.length === 0 ? [] : newConfig.servers.split(',');
      newConfig.enabled = true;
      return { ...this.value, ...newConfig };
    },
  },
  methods: {
    /** Sync the form fields `config` data member with the `value` prop holding the original config. */
    syncConfig() {
      const servers = this.value.servers === null ? [] : this.value.servers;
      this.config = {
        communityString: this.value.communityString || '',
        servers: servers.join(', ') || '',
      };
    },
  },
};
</script>

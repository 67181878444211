<template>
  <div>
    <b-button
      size="sm"
      variant="primary"
      id="show-btn"
      @click="showModal"
    >
      Registration Key
    </b-button>

    <b-modal ref="key-modal" hide-footer title="Xtend Registration Key">
      <div class="d-block text-center">
        <b-button
          class="float-right"
          variant="flat-secondary"
          v-clipboard:copy="info.shareKey"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <b-icon icon="files" font-scale="1.5"/>
        </b-button>
        <Prism>{{ info ? info.shareKey : '' }}</Prism>
      </div>
      <b-button class="mt-3" variant="primary" block @click="hideModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  components: {
    Prism,
  },
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    onCopy() {
      this.$bvToast.toast('Successfully copied to clipboard!', { variant: 'success' });
    },
    onError() {
      this.$bvToast.toast('Failed to copy to clipboard!', { variant: 'danger' });
    },
    httpGet() {
      this.$http
        .get('/xtends/sharedkey.json')
        .then((response) => { this.info = response.data; })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$bvToast.toast('Unauthorized!', { variant: 'danger' });
          }
        });
    },
    showModal() {
      if ('shareKey' in this.info) {
        this.$refs['key-modal'].show();
      } else {
        this.$bvToast.toast('Failed to find key!', { variant: 'danger' });
      }
    },
    hideModal() {
      this.$refs['key-modal'].hide();
    },
  },
};
</script>

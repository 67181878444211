/** Issue layer names and values. */
export default {
  app: { name: 'Application', value: 1 },
  net: { name: 'Network', value: 2 },
  svc: { name: 'Service', value: 3 },
  getByName(name) {
    return Object
      .values(this)
      .find((layer) => layer.name === name);
  },
};
